import "./styles.scss";

import React from "react";

import { PartnerConfig } from "../../models/business-partners.model";
import { SortingModes } from "../../../../common/enums/sorting-mode.enum";
import SortAlphabetically from "../../../../common/components/sortAlphabetically";
import SearchInput from "../../../../common/submodules/form/components/searchInput";

interface IProps {
  partnerConfig: PartnerConfig;
  onSearch(searchValue: string): Promise<void>;
  setSortingMode(sortingMode: SortingModes): void;
}

export default class PartnerSearchSort extends React.Component<IProps> {
  render() {
    return (
      <div className="search-sort-container">
        <SearchInput
          isAsynchronous={true}
          defaultSearchValue={this.props.partnerConfig.searchValue}
          onSearch={this.props.onSearch}
        />

        <SortAlphabetically
          sortingMode={this.props.partnerConfig.sortingMode}
          isSortLoading={this.props.partnerConfig.isLoading}
          setSortingMode={this.props.setSortingMode}
        />
      </div>
    );
  }
}
