import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import HeaderBanner from "../../../common/components/headerBanner";
import PartContent from "./partContent";
import { ProfilePart } from "../enums/profilePart.enum";
import UserAPI from "../../../authentication/apis/user.api";
import { Helmet } from 'react-helmet';
import { IApplication } from "../../apiApplications/interfaces/application.interface";
import Loader from "../../../common/components/loader";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}

interface IStates {
  detailsOpened: boolean;
  commercialSubscriptionOpened: boolean;
  usersManagementOpened: boolean;
  applications: IApplication[] | undefined;
  showApiCredentials: boolean;
  isLoading: boolean;
}

class ClientSettings extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      detailsOpened: false,
      commercialSubscriptionOpened: false,
      usersManagementOpened: false,
      applications: undefined,
      showApiCredentials: false,
      isLoading: true
    };
  }

  componentDidMount() {
    UserAPI.getApplicationsByClientId().then((applications) =>{
      this.setState({ applications });
      const hasOAuth2Credentials = applications.some((app) => app.credentials.auth2Credentials !== undefined);
      this.setState({showApiCredentials: hasOAuth2Credentials, isLoading: false})
    }); 
  }

  render() {
    return (
      <div id="client-settings" className={this.props.screenType}>
        <Helmet>
          <title>Client Settings - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="clientSettings.title" />
        </HeaderBanner>
        <div className="profile-content App-page">
      {this.state.isLoading ?
        <Loader className="center-loader"/>
        :
          <div className="App-content">
            {/* <PartContent namePart={ProfilePart.DETAILS} /> */}
            {/* <PartContent namePart={ProfilePart.COMMERCIAL_SUBSCRIPTION} /> */}
            <PartContent namePart={ProfilePart.USERS_MANAGEMENT} />
            { this.state.showApiCredentials && this.props.user?.main && <PartContent namePart={ProfilePart.API_CREDENTIALS} applications={this.state.applications}/> }
          </div>}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user:state.user,
  screenType: state.screenType,
});

const connector = connect(mapState, {});
export default connector(ClientSettings);
