import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import TermsAndConditionsLink from "../../../common/components/termsAndConditionsLink";
import HeaderBanner from "../../../common/components/headerBanner";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import GuideAPI from "../../../common/apis/guide.api"; 
import { Helmet } from 'react-helmet';
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps { }
interface IStates {guideUrl: string;}
class CredentialsGuide extends Component<IProps,IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      guideUrl: '',
    };
  }

  componentDidMount() {
    GuideAPI.getGuideUrl().then((url) => {
        this.setState({
          guideUrl: url,
        });
      });
  }
  render() {

    const test = true;
    return (
      <div id="credentialsGuide">
        <Helmet>
          <title>Help - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="credentialsGuide.head.title" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content-container">
            <div className="content">
              <div className="category api-usage">
                <div className="header">
                  <FormattedMessage id="credentialsGuide.header1" />
                </div>


                <div className="subcategory">
                  <div className="credentialsGuide">
                    <div className="title">
                      <div className="circle">
                        <div className="number">1</div>
                      </div>
                      <FormattedMessage id="credentialsGuide.message1" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="credentialsGuide.message2" />
                    </div>
                    <div className="message">
                      {!!this.props.user ?
                        (<FormattedMessage
                          id="credentialsGuide.message4"
                          values={{
                            a: (chunks: string) => (
                              <a
                                href={`${this.state.guideUrl}api-applications`}
                              >
                                {chunks}
                              </a>
                            ),
                          }}
                        />) : (
                          <FormattedMessage
                            id="credentialsGuide.message4"
                            values={{
                              a: (chunks: string) => (

                                chunks
                              ),
                            }}
                          />
                        )
                      }
                    </div>
                    <div className="message">
                      <img
                        loading="lazy"
                        width="100%"
                        src="Application.png"
                        alt=""
                      />
                    </div>
                    <div className="title">
                      <div className="circle">
                        <div className="number">2</div>
                      </div>
                      <FormattedMessage id="credentialsGuide.message3" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="credentialsGuide.message5" />
                    </div>
                    <ul>
                      <li className="message">
                        {!!this.props.user ?
                          (<FormattedMessage
                            id="credentialsGuide.message6"
                            values={{
                              a: (chunks: string) => (
                                <a
                                  href={`${this.state.guideUrl}api-applications`}
                                >
                                  {chunks}
                                </a>
                              ),
                            }}
                          />) : (
                            <FormattedMessage
                              id="credentialsGuide.message6"
                              values={{
                                a: (chunks: string) => (

                                  chunks
                                ),
                              }}
                            />
                          )
                        }
                      </li>
                      <li className="message">
                        {!!this.props.user ?
                          (<FormattedMessage
                            id="credentialsGuide.message7"
                            values={{
                              a: (chunks: string) => (
                                <a
                                  href={`${this.state.guideUrl}client-settings`}
                                >
                                  {chunks}
                                </a>
                              ),
                            }}
                          />) : (
                            <FormattedMessage
                              id="credentialsGuide.message7"
                              values={{
                                a: (chunks: string) => (

                                  chunks
                                ),
                              }}
                            />
                          )
                        }
                      </li>
                    </ul>
                    <div className="message">
                      {!!this.props.user ?
                        (<FormattedMessage
                          id="credentialsGuide.message9"
                          values={{
                            a: (chunks: string) => (
                              <a
                                href={`${this.state.guideUrl}contact-us/report-issue`}
                              >
                                {chunks}
                              </a>
                            ),
                          }}
                        />) : (
                          <FormattedMessage
                            id="credentialsGuide.message9"
                            values={{
                              a: (chunks: string) => (

                                chunks
                              ),
                            }}
                          />
                        )
                      }
                    </div>
                    <div className="message">
                      <img
                        loading="lazy"
                        width="100%"
                        src="HO.png"
                        alt=""
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const mapDispatch = {
  disconnectUser: () => ({
    type: ReducerAction.USER_LOGGED_OUT,
  }),
};
const connector = connect(mapState, mapDispatch);
export default withRouter(connector(CredentialsGuide));
