import HttpUtils from "../../../../utils/http.utils";
import { IPartnerWithCount } from "../interfaces/business-partners.interface";
import { PartnerPost } from "../models/business-partners.model";
import { SortingModes } from "../../../common/enums/sorting-mode.enum";
import { IGetFile } from "../../../common/interfaces/common.interface";

export default class PartnersAPI {
  private static getSortParam(sortingModes: SortingModes) {
    return sortingModes === SortingModes.ASCEND ? "name:asc" : "name:desc";
  }

  public static getActivePartners(
    page: number,
    nbPerPage = 50,
    search: string,
    sort: SortingModes = SortingModes.ASCEND
  ): Promise<IPartnerWithCount> {
    const params = HttpUtils.formatUrlQueryParams({
      range: HttpUtils.getRange(page, nbPerPage),
      search,
      sort: PartnersAPI.getSortParam(sort),
    });

    return HttpUtils.get<IPartnerWithCount>(`/partners${params}`);
  }

  public static getOpenRequestPartners(
    page: number,
    nbPerPage = 50
  ): Promise<IPartnerWithCount> {
    const params = HttpUtils.formatUrlQueryParams({
      range: HttpUtils.getRange(page, nbPerPage),
    });

    return HttpUtils.get<IPartnerWithCount>(`/partners/openRequests${params}`);
  }

  public static getOrphanPartners(
    page: number,
    nbPerPage: number,
    search: string,
    sort: SortingModes
  ): Promise<IPartnerWithCount> {
    const params = HttpUtils.formatUrlQueryParams({
      range: HttpUtils.getRange(page, nbPerPage),
      search,
      sort: PartnersAPI.getSortParam(sort),
    });

    return HttpUtils.get<IPartnerWithCount>(`/partners/orphans${params}`);
  }

  public static getPartnerByCode(
    code: string,
    page: number,
    nbPerPage: number,
    firstPageOffset: number,
    search: string,
    sort: SortingModes
  ): Promise<IPartnerWithCount> {
    const params = HttpUtils.formatUrlQueryParams({
      range: HttpUtils.getRange(page, nbPerPage, firstPageOffset),
      search,
      sort: PartnersAPI.getSortParam(sort),
    });

    return HttpUtils.get<IPartnerWithCount>(`/partners/${code}${params}`);
  }

  public static postPartner(body: PartnerPost) {
    return HttpUtils.post("/partners", { ...body });
  }

  public static exportCsv(inBehalfOf?: string): Promise<IGetFile | void> {
    const inBehalfOfParam = !!inBehalfOf ? `?inBehalfOf=${inBehalfOf}` : "";
    return HttpUtils.getFile(`/partners/export-csv${inBehalfOfParam}`);
  }
}
