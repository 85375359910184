import "./styles.scss";

import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";

import { ELoaderSize } from "../../enums/loader.enum";

interface IProps {
  size?: ELoaderSize;
  className?: string;
}

class Loader extends Component<IProps> {
  render() {
    return (
      <div className={this.props.className !== undefined? this.props.className : "loader"}>
        <CircularProgress
          size={this.props.size === ELoaderSize.SMALL ? 24 : 40}
        />
      </div>
    );
  }
}

export default Loader;
