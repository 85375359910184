import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Snackbar } from "@material-ui/core";

import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import { ReducerAction } from "../../../../reducer/enums/reducer-action.enum";
import ToastAlert from "./toastAlert";

type PropsFromRedux = ConnectedProps<typeof connector>;

class Toast extends Component<PropsFromRedux> {
  constructor(props: PropsFromRedux) {
    super(props);
  }

  private handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    this.props.hideToast();
  };

  render() {
    if (!!this.props.toastType && !!this.props.toastMessage) {
      return (
        <Snackbar open={true} onClose={this.handleClose} className="toast">
          <ToastAlert
            toastType={this.props.toastType}
            toastMessage={this.props.toastMessage}
            hideToast={this.handleClose}
          />
        </Snackbar>
      );
    }

    return null;
  }
}

const mapState = (state: IReducerState) => ({
  toastType: state.toastType,
  toastMessage: state.toastMessage,
});

const mapDispatch = {
  hideToast: () => ({
    type: ReducerAction.TOAST,
    payload: { toastType: undefined, toastMessage: undefined },
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(Toast);
