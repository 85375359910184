import { ProductFamily, ProductType } from "../enums/product-family.enum";
import { Release } from "./api-summary.model";

export class Event {
  public id: string;
  public type = ProductType.EVENT;
  public description: string;
  public title: string;
  public virtualizedVersion: string;
  public publicationDate: Date;
  public lastUpdatedDate: Date;
  public productFamily: ProductFamily;
  public availableEvents: Events | undefined;
  public isDcsa: boolean;
  public isDeprecated: boolean;
  public isOptimizing: boolean;
  public isPilot: boolean;
  public release: Release[];

  constructor(body: any) {
    this.id = body.eventId;
    this.description = body.description;
    this.title = body.title;
    this.virtualizedVersion = body.version;
    this.publicationDate = new Date(body.publicationDate);
    this.lastUpdatedDate = new Date(body.lastUpdatedDate);
    this.productFamily = body.productFamily;
    this.release = body.releaseNotes;
    this.availableEvents = !!body.availableEvents
      ? new Events(body.availableEvents)
      : undefined;
    this.isDcsa = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "dcsa"
    );
    this.isDeprecated = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "deprecated"
    );
    this.isOptimizing = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "optimizing"
    );
    this.isPilot = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "pilot"
    );
    if (body.releaseNotes && body.releaseNotes.length > 0) {
      this.release = body.releaseNotes.sort( (a: Release, b: Release) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.publicationDate = this.release[this.release.length - 1].date;
      this.lastUpdatedDate = this.release[0].date;
    } else {
      this.release = [];
      this.publicationDate = body.publicationDate;
      this.lastUpdatedDate = body.lastUpdatedDate;
    }
  }
}

export class Events {
  public equipment: EventRow[] = [];
  public shipment: EventRow[]= [];
  public transport: EventRow[]= [];
  public document:EventRow[]= [];
  public schedule:EventRow[]= [];

  [key: string]: EventRow[]; 
 
  constructor(body: any) {
    for (let key in body)
      this[key] = body[key].map((event: any) => new EventRow(event));
      
    // this.equipment = body.equipment.map((event: any) => new EventRow(event));
    // this.shipment = body.shipment.map((event: any) => new EventRow(event));
    // this.transport = body.transport.map((event: any) => new EventRow(event));
    // this.document = body.document.map((event: any) => new EventRow(event));
    // this.schedule = body.schedule.map((event: any) => new EventRow(event));
  }
}

export class EventRow {
  public family: string;

  public dcsaEventType: string;
  public dscaEventClassifier: string;

  public dscaEmptyIndicator?: string;

  public eventDomain?: string;
  public dscaDocument?: string;

  public visibility?: string;

  public relatedEvents: string[];

  constructor(body: any) {
    this.family = body.family;

    this.dcsaEventType = body.dcsaEventType;
    this.dscaEventClassifier = body.dscaEventClassifier;

    if (body.dscaEmptyIndicator)
      this.dscaEmptyIndicator = body.dscaEmptyIndicator;

    if (body.eventDomain) this.eventDomain = body.eventDomain;
    if (body.dscaDocument) this.dscaDocument = body.dscaDocument;

    if (body.visibility) this.visibility = body.visibility;

    this.relatedEvents = body.relatedEvents;
  }
}

export class AvailableEvent {
  public description: string;
  public classifier: string;
  public dcsaType: string;
  public document: string | undefined;
  public emptyIndicatorCode: string | undefined;
  public family: string;
  public group: string;
  public color?: string;

  constructor(body: any, color?: string) {
    this.description = body.description;
    this.classifier = body.classifier;
    this.dcsaType = body.dcsaType;
    this.document = body.document;
    this.emptyIndicatorCode = body.emptyIndicatorCode;
    this.family = body.family;
    this.group = body.group;
    if (color) this.color = color;
  }
}
