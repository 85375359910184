import HttpUtils from "../../../utils/http.utils";
import { Event, Events } from "../models/event.model";
import { IProductSwagger } from "../interfaces/product-swagger.interface";

export default class EventAPI {
  public static async getEventSummary(eventId: string): Promise<Event> {
    return HttpUtils.get(`/events/${eventId}`).then((res) => new Event(res));
  }

  public static async getEventSummaries(
    productFamily?: string,
    nameContains?: string
  ): Promise<Event[]> {
    return HttpUtils.get(
      `/events${HttpUtils.formatUrlQueryParams({
        productFamily,
        nameContains,
      })}`
    ).then((res: any) => res.map((data: Object[]) => new Event(data)));
  }

  public static async getEventSwagger(
    eventId: string
  ): Promise<IProductSwagger> {
    return HttpUtils.get<IProductSwagger>(`/events/${eventId}/swagger`);
  }

  public static async getAvailableEvents(): Promise<Events> {
    return HttpUtils.get<Events>(`/events/available`);
  }

  public static async getSubscribedEvents(): Promise<Events> {
    return HttpUtils.get<Events>(`/events/subscribed`);
  }
}
