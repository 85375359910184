import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import HeaderBanner from "../../../common/components/headerBanner";
import ChecklyAPI from "../../../common/apis/checkly.api"; 
import { Helmet } from 'react-helmet';
interface IProps {}
interface IStates {checklyUrl: string;}

class ApiStatus extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      checklyUrl: '',
    };
  }

  componentDidMount() {
    ChecklyAPI.getChecklyUrl().then((url) => {
        this.setState({
          checklyUrl: url,
        });
      });
  }
  render() {
    const formattedMessageValues = {
      b: (child: string) => <b>{child}</b>,
    };

    return (
      <div id="api-status">
        <Helmet>
          <title>Help - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="apiStatus.title" />
        </HeaderBanner>

        <iframe
          id="status-iframe"
          src={this.state.checklyUrl}
        />
      </div>
    );
  }
}

export default ApiStatus;
