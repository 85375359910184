import { customerTypes } from "../../crm/constants/crm.constant";
import {
  AddNewUser,
  FormSelectValue,
  GettingStartedInputId,
} from "../../common/submodules/form/enums/form.enum";
import { Form } from "../../common/submodules/form/models/form.model";

export class User {
  email;
  givenName;
  familyName;
  clientRequestName;
  main;
  clientId;
  clientName: string;
  clientAddress?;
  clientBusinessProfile?;
  clientRepresentativeName;
  clientRepresentativeEmail;
  clientCreationDate: Date;
  clientDeactivateDate: Date;
  clientTrialEndDate: Date;
  clientTypologies: string[];
  clientStatus;
  directoryId;
  onBoardingToken;
  status;
  letterOfAgreement;

  constructor(body: any) {
    this.email = body.email;
    this.givenName = body.givenName;
    this.familyName = body.familyName;
    this.clientRequestName = body.clientRequestName;
    this.clientId = body.clientId;
    this.clientName = body.clientName;
    if (body.clientAddress) this.clientAddress = body.clientAddress;
    if (body.clientBusinessProfile)
      this.clientBusinessProfile = body.clientBusinessProfile;
    this.clientRepresentativeName = body.clientRepresentativeName;
    this.clientRepresentativeEmail = body.clientRepresentativeEmail;
    this.directoryId = body.directoryId;
    this.onBoardingToken = body.onBoardingToken;
    this.status = body.status;
    this.clientTypologies = body.clientTypologies;
    this.clientStatus = body.clientStatus;
    this.clientCreationDate = new Date(body.clientCreationDate);
    this.clientTrialEndDate = new Date(body.clientTrialEndDate);
    this.clientDeactivateDate = new Date(body.clientDeactivateDate);
    this.letterOfAgreement = body.letterOfAgreement;
    this.main=body.main;
  }
}
export class UserPostCreation{
  givenName: string;
  familyName: string;
  email: string;
  clientRequestName:string | undefined;
  clientId : string | undefined;
  clientRepresentativeName : string | undefined;
  status : string | undefined;
  main : boolean;
  constructor(form: Form,clientRequestName:string | undefined,clientRepresentativeName:string | undefined,clientId:string|undefined, status:string|undefined) {
    this.givenName = form.getFormField(AddNewUser.GIVEN_NAME)
      ?.value as string;
    this.familyName = form.getFormField(AddNewUser.FAMILY_NAME)
      ?.value as string;
    this.email = form.getFormField(AddNewUser.Email_Address)
      ?.value as string;
    this.clientRequestName = clientRequestName;
    this.clientRepresentativeName=clientRepresentativeName;
    this.status = status;
    this.clientId = clientId;
    

    this.main = form.getFormField(AddNewUser.isPrimaryUser)?.value===true?true:false;
  }
}
export class UserPost {
  clientId?: string;
  givenName: string;
  familyName: string;
  email: string;
  companyName?: string;
  companyType?: string;
  representativeName?: string;
  representativeEmail?: string;
  address?: AddressUser;

  constructor(form: Form) {
    this.givenName = form.getFormField(GettingStartedInputId.GIVEN_NAME)
      ?.value as string;
    this.familyName = form.getFormField(GettingStartedInputId.FAMILY_NAME)
      ?.value as string;
    this.email = form.getFormField(GettingStartedInputId.EMAIL)
      ?.value as string;

    const clientId = form.getFormField(GettingStartedInputId.CLIENT_ID)?.value;
    if (!!clientId) {
      this.clientId = clientId as string;
    } else {
      this.companyName = form.getFormField(GettingStartedInputId.COMPANY_NAME)
        ?.value as string;

      const customerType = customerTypes.find(
        (type) =>
          type.value ===
          (form.getFormField(GettingStartedInputId.COMPANY_TYPE)
            ?.value as string)
      );
      this.companyType = customerType
        ? customerType.name
        : FormSelectValue.NONE;

      this.representativeName = form.getFormField(
        GettingStartedInputId.REPRESENTATIVE_NAME
      )?.value as string;

      const representativeEmail = form.getFormField(
        GettingStartedInputId.REPRESENTATIVE_EMAIL
      )?.value as string;
      if (representativeEmail !== "")
        this.representativeEmail = representativeEmail;

      this.address = new AddressUser(
        form.getFormField(GettingStartedInputId.ADDRESS)?.value as string,
        form.getFormField(GettingStartedInputId.MORE_ADDRESS)?.value as string,
        form.getFormField(GettingStartedInputId.CITY)?.value as string,
        form.getFormField(GettingStartedInputId.COUNTRY_NAME)?.value as string,
        form.getFormField(GettingStartedInputId.COUNTRY_CODE)?.value as string,
        form.getFormField(GettingStartedInputId.ZIP_CODE)?.value as string
      );
    }
  }
}

class AddressUser {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  countryCode: string;
  zipCode: string;

  constructor(
    addressLine1: string,
    addressLine2: string,
    city: string,
    countryName: string,
    countryCode: string,
    zipCode: string
  ) {
    this.addressLine1 = addressLine1;
    if (addressLine2 !== "") this.addressLine2 = addressLine2;
    this.city = city;
    this.country = countryName;
    this.countryCode = countryCode;
    this.zipCode = zipCode;
  }
}

export class UserRegisteringGet {
  email: string;
  onBoardingToken: string;
  givenName: string;
  familyName: string;

  constructor(body: any) {
    this.email = body.email;
    this.onBoardingToken = body.onBoardingToken;
    this.givenName = body.givenName;
    this.familyName = body.familyName;
  }
}

export class UserForGettingStarted {
  email: string;
  givenName: string;
  familyName: string;

  constructor(body: any) {
    this.email = body.email;
    this.givenName = body.givenName;
    this.familyName = body.familyName;
  }
}
