import * as EmailValidator from "email-validator";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";

import {
  EFieldType,
  EFormError,
  FormSelectValue,
  TFormInputId,
  TFormValue,
} from "../enums/form.enum";
import FormUtils from "../../../../../utils/form.utils";
import { InputOptions } from "./input-options.model";
import { FormFieldError } from "./form-field-error.model";
import { IInputAdditionalParams } from "../interfaces/form.interface";
import { SelectItem } from "../../../interfaces/common.interface";

export class FormField {
  id: TFormInputId;
  value: TFormValue;
  options: InputOptions;
  type: EFieldType;

  constructor(
    id: TFormInputId,
    value: TFormValue,
    options: InputOptions = {},
    type = EFieldType.TEXT
  ) {
    this.id = id;
    this.value = value;
    this.options = new InputOptions(options);
    this.type = type;
  }

  testRegexp(pattern: string, content: string) {
    return new RegExp(pattern, "g").test(content);
  }

  private generateError(message: EFormError) {
    return {
      id: this.id,
      message,
    };
  }

  private getErrorSelect() {
    if (
      this.options.isMandatory &&
      (this.value === FormSelectValue.PLACEHOLDER ||
        this.value === FormSelectValue.NONE)
    ) {
      return this.generateError(EFormError.FIELD_REQUIRED);
    }

    return undefined;
  }

  private getErrorEmail() {
    const getErrorText = this.getErrorText();
    if (getErrorText) return getErrorText;

    if (
      this.testRegexp("<|>", this.value as string)
    ) {
      return this.generateError(EFormError.INJECTION);
    }

    if (
      !FormUtils.isValueEmpty(this.value as string) &&
      !EmailValidator.validate(this.value as string)
    ) {
      return this.generateError(EFormError.EMAIL_INVALID);
    }

    return undefined;
  }

  private getErrorPhone(phoneCountryCode?: string) {
    const getErrorText = this.getErrorText();
    if (getErrorText) return getErrorText;

    if (
      !phoneCountryCode ||
      !isValidPhoneNumber(
        this.value as string,
        phoneCountryCode.toUpperCase() as CountryCode
      )
    ) {
      return this.generateError(EFormError.PHONE_INVALID);
    }

    return undefined;
  }

  private getErrorCheckbox() {
    if (this.options.isMandatory && !this.value) {
      return this.generateError(EFormError.NOT_CHECKED);
    }

    return undefined;
  }

  private getAutoCompleteError(selectItems?: SelectItem[]) {
    if (
      this.value !== FormSelectValue.NONE &&
      !selectItems?.some((item) => item.value === this.value)
    ) {
      return this.generateError(EFormError.NO_OPTION_SELECTED);
    }

    return undefined;
  }

  private getErrorInjection() {
    if (
      this.options.isMandatory &&
      this.testRegexp("<|>", this.value as string)
    ) {
      return this.generateError(EFormError.INJECTION);
    }
     return this.getErrorText();
  }

  private getErrorText() {
    if (
      this.options.isMandatory &&
      FormUtils.isValueEmpty(this.value as string)
    ) {
      return this.generateError(EFormError.FIELD_REQUIRED);
    }
    else if(this.options.isLatinCharacters && !this.testRegexp("^(?=\\s*\\S.{1,}\\S\\s*$)(?=[a-zA-ZÀ-ÿ0-9\\s'&()._-]*$).*"
    ,this.value as string)){
      return this.generateError(EFormError.LATIN_CHARACTERS);
    }
    return undefined;
  }

  public getFieldError(
    additionalParams: IInputAdditionalParams
  ): FormFieldError | undefined {
    switch (this.type) {
      case EFieldType.SELECT:
      case EFieldType.COUNTRY:
        return this.getErrorSelect();

      case EFieldType.EMAIL:
        return this.getErrorEmail();

      case EFieldType.PHONE:
        return this.getErrorPhone(additionalParams.phoneCountryCode);

      case EFieldType.CHECKBOX:
        return this.getErrorCheckbox();

      case EFieldType.AUTOCOMPLETE:
        return this.getAutoCompleteError(additionalParams.selectItems);

      case EFieldType.TEXTAREA:
      case EFieldType.TEXT:
        return this.getErrorInjection();

      default:
        return this.getErrorText();
    }
  }

  public setFieldError(error: FormFieldError | undefined) {
    this.options.error = error;
  }
}
