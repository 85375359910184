export enum Routes {
  HOME = "/",

  // Product Catalog
  PRODUCTS = "/products",

  // Commercial Offers
  COMMERCIAL_OFFERS = "/commercial-offers",
  SUBSCRIPTION_REQUEST = "/commercial-offers/subscription-request",
  REQUEST_SUBMITTED = "/commercial-offers/request-submitted",
  MY_SELECTION = "/commercial-offers/my-selection",
  PACKAGE = "/commercial-offers/package",

  // My Organization
  API_APPLICATIONS = "/api-applications",
  EVENT_APPLICATIONS = "/event-applications",
  BUSINESS_PARTNERS = "/business-partners",
  CLIENT_SETTINGS = "/client-settings",

  // Contact Us
  CONTACT_US = "/contact-us",
  BUSINESS_REQUEST = "/contact-us/business-request",
  REPORT_ISSUE = "/contact-us/report-issue",

  // Help
  STARTING_GUIDE = "/starting-guide",
  USAGE_GUIDE = "/usage-guide",
  CREDENTIALS_GUIDE="/credentials-guide",
  API_STATUS = "/api-status",
  ROADMAP = "/roadmap",
  RELEASE_NOTES = "/release-notes",

  // Faq
  FAQ = "/faq",

  // Getting Started
  GETTING_STARTED = "/getting-started",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",

  ELIGIBLE_ENTITY = "/business-partners/eligible-entity",
  NEW_ENTITY = "/business-partners/new-entity",

  PASSWORD_FORM = "/set-password",

  // Footer
  SITE_MAP = "/site-map",
  PRIVACY_NOTICE = "/privacy-notice",
  LEGAL_TERMS = "/legal-terms",

  // Authentication
  SIGN_UP = "/sign-up",
}
