import "./styles.scss";

import React, { Component } from "react";
import { Alert } from "@material-ui/lab";

import { ToastType } from "../../enums/toast-type.enum";

interface IProps {
  toastType: ToastType;
  toastMessage: JSX.Element;
  hideToast(): void;
  className?:string;
}

class ToastAlert extends Component<IProps> {
  render() {
    return (
      <Alert
        className={`toast-alert ${this.props.className || ''}`}
        severity={this.props.toastType}
        onClose={this.props.hideToast}
      >
        {this.props.toastMessage}
      </Alert>
    );
  }
}

export default ToastAlert;
