import "./styles.scss";

import React from "react";

import { IHeaderSubMenuSection } from "../../../interfaces/header.interface";
import TransitionContent from "../../../../common/components/transitionContent";
import Section from "./section";

interface IProps {
  subMenuSections: IHeaderSubMenuSection[];
  isVisible: boolean;
  shouldDisplayOnLeft: boolean;
  onRouteClick(route: string): void;
}

class SubMenu extends React.Component<IProps> {
  render() {
    return (
      <div
        className={`header-sub-menu ${
          this.props.shouldDisplayOnLeft ? "left" : "right"
        }`}
      >
        <TransitionContent isExpanded={this.props.isVisible}>
          <div className="sections-container">
            {this.props.subMenuSections.map((subMenuSection, index) => (
              <Section
                key={`submenu-section-${index}`}
                subMenuSection={subMenuSection}
                onRouteClick={this.props.onRouteClick}
              />
            ))}
          </div>
        </TransitionContent>
      </div>
    );
  }
}

export default SubMenu;
