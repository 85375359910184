import "./styles.scss";

import React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";

import { Routes } from "../../enums/routes.enum";
import { ScreenType } from "../../enums/screen-type.enum";
import TermsAndConditionsAPI from "../../../gettingStarted/apis/terms-and-conditions.api";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}
interface IStates {
  pdfDocument: string | null;
}

class TermsAndConditionsLink extends React.Component<IProps, IStates> {
  public constructor(props: IProps) {
    super(props);
    this.state = { pdfDocument: null };
  }

  public downloadTerms = () => {
    const doc = this.state.pdfDocument;
    if (doc) return doc;
    TermsAndConditionsAPI.getTermsAndConditionsPdf().then((file) => {
      if (file && file.url) {
        this.setState({ pdfDocument: file.url });
        return file.url;
      }
    });
    return "";
  };

  render() {
    return (
      <span className="terms-conditions">
        {this.props.screenType == ScreenType.COMPUTER ? (
          <a
            href={Routes.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="termsAndConditions" />
          </a>
        ) : (
          <a href={this.downloadTerms()} download="termsAndConditionsPDF">
            <FormattedMessage id="termsAndConditions" />
          </a>
        )}
      </span>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default connector(TermsAndConditionsLink);
