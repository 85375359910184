import "./styles.scss";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { injectIntl, WrappedComponentProps } from "react-intl";

import { Routes } from "../../../../common/enums/routes.enum";
import { apiFamilies } from "../../../constants/api-family.constant";
import { ScreenSize } from "../../../../common/enums/screen-type.enum";
import SearchInput from "../../../../common/submodules/form/components/searchInput";

interface IRouteProps {}

interface IProps
  extends RouteComponentProps<IRouteProps>,
    WrappedComponentProps {
  onSearch?(productFamily?: string, search?: string): void;
  isCalledFromHomePage?: boolean;
}

class Search extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  private handleResize = () => {
    if (
      window.location.pathname.includes("search") &&
      window.innerWidth < ScreenSize.TABLET
    ) {
      this.props.history.push(`${Routes.PRODUCTS}/${apiFamilies[0].id}`);
    }
  };

  private onSearch = (searchValue: string) => {
    if (searchValue.length > 2) {
      this.props.history.push(`${Routes.PRODUCTS}/search?s=${searchValue}`);

      if (!!this.props.onSearch) this.props.onSearch(undefined, searchValue);
    }
  };

  render() {
    let defaultSearchValue = "";

    if (window.location.pathname.includes("search")) {
      const urlParams = new URLSearchParams(window.location.search);
      const param = urlParams.get("s");
      if (param) defaultSearchValue = param;
    }

    const searchInputPlaceholder = this.props.intl.formatMessage({
      id: "apiCatalog.search",
    });

    return (
      <div
        id="search"
        className={this.props.isCalledFromHomePage ? "from-home-page" : ""}
      >
        <SearchInput
          isAsynchronous={true}
          defaultSearchValue={defaultSearchValue}
          isInputDefaultHidden={this.props.isCalledFromHomePage}
          searchLabel={searchInputPlaceholder}
          onSearch={this.onSearch}
        />
      </div>
    );
  }
}

export default withRouter(injectIntl(Search));
