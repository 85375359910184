import {
  ApiCredentialsPart,
  ClickType,
  StatusCheck,
} from "../../../enums/profilePart.enum";
import { connect, ConnectedProps } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import CopyIcon from "@material-ui/icons/FilterNone";
import React, { Component } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { IApplication } from "../../../../apiApplications/interfaces/application.interface";
import ClientSettingsAPI from "../../../apis/clientSettings.api";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import { ClientSecretRequest } from "../../../models/clientSecretRequest.model";
import { AxiosError } from "axios";
import ToastAlert from "../../../../../common/submodules/toast/components/toastAlert";
import { ToastType } from "../../../../../common/submodules/toast/enums/toast-type.enum";
import { IToastAlert } from "../../../../../common/submodules/toast/interfaces/toast-alert.interface";
import { defaultToastAlert } from "../../../../../common/submodules/toast/constants/default-toast-alert.constant";
import { Input } from "@material-ui/core";
import { HttpCode } from "../../../../../common/enums/http-code.enum";
import { onlyNumericalChars } from "../../../constants/regularExpression";
import Loader from "../../../../../common/components/loader";
import { ELoaderSize } from "../../../../../common/enums/loader.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, WrappedComponentProps {
  application: IApplication;
  clientSecretRequest: ClientSecretRequest;
}

interface IStates {
  apiCodeState: ApiCredentialsPart;
  apiCodeStateInput: string;
  apiSecret: string;
  clickType: string;
  isFailed: boolean;
  toast: IToastAlert;
  clientSecretRequest: ClientSecretRequest;
  isDisabled: Boolean;
}

class ApiCredentialsContent extends Component<IProps, IStates> {
  clientId: string;
  userEmail: string;
  constructor(props: IProps) {
    super(props);
    this.state = {
      apiCodeState:
        this.props.clientSecretRequest?.status || ApiCredentialsPart.NONE,
      apiCodeStateInput: "",
      apiSecret: "",
      clickType: ClickType.Send,
      isFailed: false,
      toast: defaultToastAlert,
      clientSecretRequest: this.props.clientSecretRequest,
      isDisabled: false,
    };
    (this.clientId =
      this.props.application?.credentials?.auth2Credentials || ""),
      (this.userEmail = this.props.user?.email);
  }

  private retrieveActivationCode() {
    this.setState({ isDisabled: true });
    if (this.clientId === "") {
      this.setState({
        isDisabled: false,
        toast: {
          isDisplayed: true,
          type: ToastType.ERROR,
          message: <FormattedMessage id="clientSettings.emptyClientId.label" />,
        },
      });
      return;
    }
    if (this.statusChecker(StatusCheck.VALIDATED)) {
      const clientSecretRequest = new ClientSecretRequest({
        clientId: this.clientId,
      });
      ClientSettingsAPI.resetClientSecretRequest(clientSecretRequest).then(
        (res) => this.setState({ isDisabled: false, apiCodeState: res.status })
      );
    } else {
      const clientSecretRequest = new ClientSecretRequest({
        clientId: this.clientId,
      });
      this.setState({
        toast: {
          isDisplayed: true,
          type: ToastType.SUCCESS,
          message: (
            <FormattedMessage id="clientSettings.confirmationCodeInputCorrect.label" />
          ),
        },
      });
      ClientSettingsAPI.initiateClientSecretRequest(clientSecretRequest)
        .then((res) => {
          this.setState({
            isDisabled: false,
            apiCodeState: res.status,
            clientSecretRequest: {
              ...clientSecretRequest,
              clientSecretRequestId: res.clientSecretRequestId,
            },
          });
        })
        .catch((error: AxiosError) => {
          this.setState({
            isDisabled: false,
            apiCodeState: ApiCredentialsPart.NONE,
            toast: {
              isDisplayed: true,
              type: ToastType.ERROR,
              message: <FormattedMessage id={error.message} />,
            },
          });
        });
    }
  }

  private retrieveClientSecret = (validationCode: string) => {
    this.setState({ isDisabled: true });
    if (
      validationCode.length === 6 &&
      onlyNumericalChars.test(validationCode)
    ) {
      if (this.clientId === "") {
        this.setState({
          isDisabled: false,
          toast: {
            isDisplayed: true,
            type: ToastType.ERROR,
            message: (
              <FormattedMessage id="clientSettings.emptyClientId.label" />
            ),
          },
        });
        return;
      }
      const clientSecretRequest = new ClientSecretRequest({
        clientId: this.clientId,
        validationCode,
      });
      this.setState({ apiCodeState: ApiCredentialsPart.INITIATED });
      ClientSettingsAPI.getClientSecretRequest(clientSecretRequest)
        .then((res) => {
          this.setState({
            isDisabled: false,
            clickType: ClickType.Check,
            apiSecret: res.clientSecret,
          });
          setTimeout(() => this.setState({ apiSecret: "" }), 5 * 60 * 1000);
        })
        .catch((error: AxiosError) => {
          switch (error.response?.status) {
            case HttpCode.BAD_REQUEST:
              this.setState({
                isDisabled: false,
                clickType: ClickType.Close,
                toast: {
                  isDisplayed: true,
                  type: ToastType.ERROR,
                  message: (
                    <FormattedMessage id="clientSettings.confirmationCodeInputIncorrect.label" />
                  ),
                },
              });
              break;
            case HttpCode.TOO_MANY_REQUEST:
              this.setState({
                isDisabled: false,
                isFailed: true,
                toast: {
                  isDisplayed: true,
                  type: ToastType.ERROR,
                  message: (
                    <FormattedMessage id="clientSettings.confirmationCodeInputIncorrect.label" />
                  ),
                },
              });
              break;
            case HttpCode.UNPROCESSABLE_ENTITY:
              this.setState({ apiCodeState: ApiCredentialsPart.EXPIRED });
              const clientSecretRequest = new ClientSecretRequest({
                clientId: this.clientId,
              });
              ClientSettingsAPI.resetClientSecretActivationCode(
                clientSecretRequest
              ).then(() => this.setState({ isDisabled: false }));
              break;
            default:
              break;
          }
        });
    } else
      this.setState({
        isDisabled: false,
        toast: {
          isDisplayed: true,
          type: ToastType.WARNING,
          message: (
            <FormattedMessage id="clientSettings.validationCodeDigits.label" />
          ),
        },
      });
  };
  private handleInputSecret = (e: any) => {
    const inputValue = e.target.value;
    if (/^\d{0,6}$/.test(inputValue)) {
      // If less than 6 digits are entered, update state
      this.setState({
        apiCodeStateInput: inputValue,
        clickType: ClickType.Send,
      });
      // If 6 digits are entered, trigger your function
      if (inputValue.length === 6) {
        this.retrieveClientSecret(inputValue);
      }
    }
  };
  private buttonDisplayer = () => {
    switch (this.state.clickType) {
      case ClickType.Send:
        return (
          <div className="clickableButton">
            <SendIcon
              onClick={() =>
                this.retrieveClientSecret(this.state.apiCodeStateInput)
              }
            />
          </div>
        );
      case ClickType.Check:
        return <CheckIcon />;
      case ClickType.Close:
        return <CloseIcon />;
    }
  };

  private labelDisplayer = () => {
    if (this.statusChecker(StatusCheck.EXPIRED))
      return "clientSettings.newValidationCode.label";
    else if (!!this.state.apiSecret)
      return "clientSettings.confirmationCodeMessageWithSecret.label";
    else return "clientSettings.confirmationCodeMessage.label";
  };

  private statusChecker = (status: string) => {
    switch (status) {
      case StatusCheck.VALIDATED:
        return this.state.apiCodeState === ApiCredentialsPart.VALIDATED;
      case StatusCheck.EXPIRED:
        return this.state.apiCodeState === ApiCredentialsPart.EXPIRED;
      case StatusCheck.NONE_OR_VALIDATED:
        return (
          this.state.apiCodeState === ApiCredentialsPart.NONE ||
          this.state.apiCodeState === ApiCredentialsPart.VALIDATED
        );
      case StatusCheck.EXPIRED_OR_INITIATED:
        return (
          this.state.apiCodeState === ApiCredentialsPart.EXPIRED ||
          this.state.apiCodeState === ApiCredentialsPart.INITIATED
        );
      case StatusCheck.RESETING_OR_PENDING:
        return (
          this.state.apiCodeState === ApiCredentialsPart.RESETING ||
          this.state.apiCodeState === ApiCredentialsPart.PENDING
        );
      case StatusCheck.REJECTED:
        return this.state.apiCodeState === ApiCredentialsPart.REJECTED;
      case StatusCheck.REJECTED2:
        return (
          this.state.clientSecretRequest.status === ApiCredentialsPart.REJECTED
        );
      case StatusCheck.CSS:
        return (
          this.state.apiCodeState === ApiCredentialsPart.EXPIRED ||
          this.state.apiCodeState === ApiCredentialsPart.INITIATED ||
          this.state.apiCodeState === ApiCredentialsPart.RESETING ||
          this.state.apiCodeState === ApiCredentialsPart.PENDING
        );
    }
  };

  private renderConfirmationCodeLabel() {
    const labelClass = [
      "confirmationCodeInputLabel",
      "confirmationCodeDiv",
      !!this.state.apiSecret ? "greenLabel" : "blueLabel",
    ].join(" ");
    const messageId = this.labelDisplayer();

    return (
      <span className={labelClass}>
        <FormattedMessage id={messageId} />
        {this.state.isDisabled && (
          <Loader size={ELoaderSize.SMALL} className="waiting-loader" />
        )}
      </span>
    );
  }

  render() {
    const placeholder = this.props.intl.formatMessage({
      id: "clientSettings.codeReceived.label",
    });
    const applicationName = this.props.application.name;
    return (
      <div className="apiCredentialsContent">
        <div className="title">{applicationName}</div>

        {!this.state.isFailed && (
          <>
            {this.statusChecker(StatusCheck.NONE_OR_VALIDATED) && (
              <div
                className="danger-zone"
                onClick={() => {
                  this.retrieveActivationCode();
                }}
              >
                <span className="retrieveApiSecretText">
                  <FormattedMessage id="clientSettings.retrieveApiSecret" />
                  {this.state.isDisabled && (
                    <Loader
                      size={ELoaderSize.SMALL}
                      className="waiting-loader"
                    />
                  )}
                </span>
              </div>
            )}
            {this.statusChecker(StatusCheck.EXPIRED_OR_INITIATED) && (
              <>
                <div>{this.renderConfirmationCodeLabel()}</div>
                <br />
                <div className="confirmationCodeContainer">
                  {this.state.apiSecret !== "" ? (
                    <div className="apiSecretLabel">
                      <div className="apiSecretValue">
                        <span id="secret" contentEditable="true" tabIndex={1}>
                          {this.state.apiSecret}
                        </span>
                        <CopyIcon
                          className="copy-icon"
                          onClick={() =>
                            navigator.clipboard.writeText(this.state.apiSecret)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="confirmationCodeInputDiv">
                      <div className="codeInputContainer">
                        <Input
                          className="confirmationCodeInput"
                          placeholder={placeholder}
                          value={this.state.apiCodeStateInput}
                          onChange={(e) => this.handleInputSecret(e)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {this.statusChecker(StatusCheck.RESETING_OR_PENDING) && (
              <div>
                <div className="confirmationCodeDiv">
                  <FormattedMessage id="clientSettings.resettingRequestMessage.label" />
                </div>
                <br />
                <div
                  className="danger-zone"
                  onClick={() => {
                    this.setState({
                      apiCodeState: ApiCredentialsPart.INITIATED,
                    });
                  }}
                >
                  <span className="retrieveApiSecretText">
                    <FormattedMessage id="clientSettings.receiveConfirmationCode" />
                  </span>
                </div>
              </div>
            )}
            {this.statusChecker(StatusCheck.REJECTED) && (
              <div>
                <span
                  className="confirmationCodeInputLabel confirmationCodeDiv"
                  style={{ color: "#B3B3B3" }}
                >
                  <FormattedMessage id="clientSettings.rejectedRequestMessage.label" />
                </span>
              </div>
            )}{" "}
          </>
        )}

        {this.state.isFailed && (
          <div>
            <span
              className={[
                "confirmationCodeInputLabel",
                "confirmationCodeDiv",
                "redLabel",
              ].join(" ")}
            >
              <FormattedMessage id="clientSettings.tooManyErrorRequests.label" />
            </span>
          </div>
        )}
        {this.state.toast.isDisplayed && (
          <div className="toast">
            <ToastAlert
              toastType={this.state.toast.type}
              toastMessage={this.state.toast.message}
              hideToast={() =>
                this.setState({
                  toast: { ...this.state.toast, isDisplayed: false },
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});
const connector = connect(mapState, {});
export default connector(injectIntl(ApiCredentialsContent));
