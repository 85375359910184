import "./styles.scss";

import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Input } from "@material-ui/core";

import InputLabel from "../inputLabel";
import { EFieldType, TFormInputId, TFormValue } from "../../enums/form.enum";
import { InputOptions } from "../../models/input-options.model";

interface IProps extends WrappedComponentProps {
  id: TFormInputId;
  value: TFormValue;
  type: EFieldType;
  options: InputOptions;
  disabled?: boolean;

  onChange(value: string): void;
  onBlur(): void;
}

class TextInput extends React.Component<IProps> {
  render() {
    const placeholder = this.props.intl.formatMessage({
      id: this.props.options.placeholder,
    });

    return (
      <div className="form-input text-input">
        <InputLabel options={this.props.options} />

        <Input
          id={this.props.id}
          placeholder={placeholder}
          defaultValue={this.props.value}
          error={!!this.props.options.error}
          type={this.props.type === EFieldType.EMAIL ? "email" : "text"}
          disabled={this.props.disabled ?? false}
          onChange={(event) => this.props.onChange(event.target.value)}
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default injectIntl(TextInput);
