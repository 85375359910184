import { User } from "./user.model";

export class FreeTrial {
  isCommunity: boolean = false;
  isEnded: boolean = false;
  isStatusEnded: boolean = false;
  nbDays: number = 0;
  initials: string = "";

  constructor(user: User | undefined) {
    if (user) {
      this.isCommunity = user.clientTypologies?.some(
        (typology: string) => typology.toLowerCase() === "community"
      );
      this.initials = `${user.givenName[0]}${user.familyName[0]}`;

        this.nbDays = Math.round(
          (user.clientTrialEndDate.getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        );
        
        this.isEnded = this.nbDays < 0;

      if (
        user.clientStatus &&
        (user.clientStatus.toLowerCase() === "suspended" ||
          user.clientStatus.toLowerCase() === "deactivated")
      ) {
        this.isEnded = true;
        this.isStatusEnded = true;
      }
    }
  }
}
