import React from "react";

import { ToastType } from "../enums/toast-type.enum";
import { IToastAlert } from "../interfaces/toast-alert.interface";

export const defaultToastAlert: IToastAlert = {
  isDisplayed: false,
  type: ToastType.SUCCESS,
  message: <span />,
};
