import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";

import ApiTile from "./apiFamilyTile";
import EventTile from "./eventFamilyTile";
import { RedTiretIcon } from "../../../../assets/icons";
import { apiFamilies } from "../../../productCatalog/constants/api-family.constant";
import { eventFamilies } from "../../../productCatalog/constants/event-family.constant";
import Search from "../../../productCatalog/components/catalog/search";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}

class Catalog extends Component<IProps> {
  render() {
    return (
      <div className={`App-content ${this.props.screenType}`}>
        <div className="catalog-title">
          <RedTiretIcon />
          <span className="title">
            <FormattedMessage id="productCatalog" />
          </span>
          {this.props.screenType == ScreenType.COMPUTER && (
            <Search isCalledFromHomePage={true} />
          )}
        </div>
        <div className="details sub-content">
          <i>
            <FormattedMessage id="details.catalog.head1" />
          </i>
          <br />
          <FormattedMessage id="details.catalog.head2" />
          <br />
          <FormattedMessage
            id="details.catalog.head3"
            values={{
              a: (link: any) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dcsa.org/"
                >
                  {link}
                </a>
              ),
            }}
          />
        </div>
        <div className="api-catalog sub-content">
          <div className="api-catalog-title">
            <span className="title-brace">{"{"}</span>
            <FormattedMessage id="exploreApis" />
          </div>
          <div className="details">
            <FormattedMessage id="details.catalog.api1" />
            <br />
            <FormattedMessage id="details.catalog.api2" />
          </div>
          <div className={`api-catalog-content ${this.props.screenType}`}>
            {apiFamilies.map((api, index) => (
              <ApiTile api={api} key={`apiCatalog${index}`} />
            ))}
          </div>
        </div>
        <div className="event-catalog sub-content">
          <div className="event-catalog-title">
            <span className="title-brace">{"{"}</span>
            <FormattedMessage id="exploreEvents" />
          </div>
          <div className="details">
            <FormattedMessage id="details.catalog.event" />
          </div>
          <div className={`event-catalog-content ${this.props.screenType}`}>
            {eventFamilies.map((event, index) => (
              <EventTile event={event} key={`eventCatalog${index}`} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default connector(Catalog);
