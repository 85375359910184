import HttpUtils from "../../../utils/http.utils";
import { ApiSummary } from "../models/api-summary.model";
import { ApiEndpoint } from "../models/api-endpoint.model";
import { IProductSwagger } from "../interfaces/product-swagger.interface";

export default class ApiDescriptionAPI {
  public static getApiSummary(apiId: string): Promise<ApiSummary> {
    return HttpUtils.get(`/apisummaries/${apiId}`).then(
      (res) => new ApiSummary(res)
    );
  }

  public static getApiSummaries(
    productFamily?: string,
    nameContains?: string
  ): Promise<ApiSummary[]> {
    return HttpUtils.get(
      `/apisummaries${HttpUtils.formatUrlQueryParams({
        productFamily,
        nameContains,
      })}`
    ).then((res: any) => res.map((data: Object[]) => new ApiSummary(data)));
  }

  public static getApiEndpoints(apiId: string): Promise<ApiEndpoint[]> {
    return HttpUtils.get(`/apis/${apiId}/endpoints`).then((res: any) =>
      res.map((data: Object[]) => new ApiEndpoint(data))
    );
  }

  public static getApiSwagger(apiId: string): Promise<IProductSwagger> {
    return HttpUtils.get<IProductSwagger>(`/apis/${apiId}/swagger`);
  }

  public static getApisByClientId(): Promise<ApiSummary[]> {
    return HttpUtils.get(`/apis`);
  }
}
