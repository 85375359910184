import "./styles.scss";

import React from "react";
import { FormattedMessage } from "react-intl";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

import CustomTooltip from "../customTooltip";
import { SortingModes } from "../../enums/sorting-mode.enum";

interface IProps {
  sortingMode: SortingModes;
  displayLabelInline?: boolean;
  isSortLoading?: boolean;
  setSortingMode(sortingMode: SortingModes): void;
}

export default class SortAlphabetically extends React.Component<IProps> {
  private onSort = () => {
    if (!this.props.isSortLoading) {
      this.props.setSortingMode(
        this.props.sortingMode === SortingModes.ASCEND
          ? SortingModes.DESCEND
          : SortingModes.ASCEND
      );
    }
  };

  private getSortTooltip = () => {
    return (
      <>
        <FormattedMessage id="sort" />
        &nbsp;
        {this.props.sortingMode === SortingModes.ASCEND ? (
          <FormattedMessage id="sort.ascend" />
        ) : (
          <FormattedMessage id="sort.descend" />
        )}
      </>
    );
  };

  private getAZContainer = () => {
    if (this.props.sortingMode === SortingModes.ASCEND) {
      return (
        <div className="az-container">
          <span>A</span>
          <span>Z</span>
        </div>
      );
    }

    return (
      <div className="az-container">
        <span>Z</span>
        <span>A</span>
      </div>
    );
  };

  render() {
    const tooltip = (
      <CustomTooltip title={this.getSortTooltip()} hideOnClick={true}>
        <div
          className={`icon ${!!this.props.isSortLoading ? "disabled" : ""}`}
          onClick={this.onSort}
        >
          <TrendingFlatIcon />

          {this.getAZContainer()}
        </div>
      </CustomTooltip>
    );

    return (
      <div
        className="sort-alphabetically"
        onClick={(event) => event.stopPropagation()}
      >
        {!!this.props.displayLabelInline ? (
          <div className="label-inline">
            <FormattedMessage id="sort" />:{tooltip}
          </div>
        ) : (
          tooltip
        )}
      </div>
    );
  }
}
