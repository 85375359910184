import { ReCaptchaAction } from "../modules/common/enums/re-captcha-action.enum";

export default class ReCaptchaUtils {
  public static verifyReCaptcha = (
    reCaptchaSecret: string,
    callback: (token: string) => void
  ): void => {
    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise
        .execute(reCaptchaSecret, {
          action: ReCaptchaAction.GETTING_STARTED,
        })
        .then(callback);
    });
  };

  public static setRecaptchaScript = (reCaptchaSecret: string) => {
    const script = document.createElement("script");
    script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${reCaptchaSecret}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };
}
