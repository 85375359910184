import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";

import Utils from "../../../../utils";
import { UserIcon } from "../../../../assets/icons";

class SignInButton extends Component {
  render() {
    return (
      <Button className="sign-in-button" onClick={Utils.login}>
        <UserIcon />
        <span className="sign-in">
          <FormattedMessage id="signIn" />
        </span>
      </Button>
    );
  }
}

export default SignInButton;
