import "./styles.scss";

import React, { Component, createRef } from "react";

interface IProps {
  children: React.ReactNode;
  isExpanded: boolean;
}

class TransitionContent extends Component<IProps> {
  private visibleContentRef = createRef<HTMLDivElement>();
  private placeholderContentRef = createRef<HTMLDivElement>();

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  private handleResize = () => {
    const visibleContent = this.visibleContentRef.current;
    const placeholderContent = this.placeholderContentRef.current;

    if (visibleContent && placeholderContent) {
      const contentHeight = placeholderContent.scrollHeight;

      visibleContent.style.height = this.props.isExpanded
        ? `${contentHeight}px`
        : "0";
    }
  };

  render() {
    return (
      <div
        className={`transition-content ${
          this.props.isExpanded ? "expanded" : ""
        }`}
      >
        <div ref={this.visibleContentRef} className="visible-content">
          <div className="content">{this.props.children}</div>
        </div>
        <div ref={this.placeholderContentRef} className="placeholder-content">
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default TransitionContent;
