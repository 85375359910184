import { UserRegisteringGet } from "./user.model";

export class PasswordRequirements {
  notContainingLogin: boolean;
  correctLength: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumeric: boolean;
  hasSpecialChar: boolean;

  constructor(password: string, user: UserRegisteringGet) {
    const MIN_LENGTH = 8;

    if (password.length === 0) {
      this.notContainingLogin = false;
      this.correctLength = false;
      this.hasUppercase = false;
      this.hasLowercase = false;
      this.hasNumeric = false;
      this.hasSpecialChar = false;
    } else {
      this.notContainingLogin =
        !this.containsSubstring(password, user.givenName) &&
        !this.containsSubstring(password, user.familyName) &&
        !this.containsSubstring(password, user.email);
      this.correctLength = password.length >= MIN_LENGTH;
      this.hasUppercase = this.testRegexp("[A-Z]+", password);
      this.hasLowercase = this.testRegexp("[a-z]+", password);
      this.hasNumeric = this.testRegexp("[0-9]+", password);
      this.hasSpecialChar = this.testRegexp("[!@#$%^&*]+", password);
    }
  }

  testRegexp(pattern: string, password: string) {
    return new RegExp(pattern, "g").test(password);
  }

  isValid(): boolean {
    return (
      this.notContainingLogin &&
      this.correctLength &&
      this.hasUppercase &&
      this.hasLowercase &&
      this.hasNumeric &&
      this.hasSpecialChar
    );
  }

  containsSubstring(value: string, substr: string): boolean {
    return value.toLowerCase().includes(substr.toLowerCase());
  }
}
