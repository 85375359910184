import "./App.scss";

import React, { Component, Suspense, lazy } from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import WebFont from "webfontloader";

import { Routes } from "./modules/common/enums/routes.enum";
import HomePage from "./modules/homePage/components";
import Header from "./modules/header/components";
import Footer from "./modules/footer/components";
import Products from "./modules/productCatalog/components";
import ProductsRoadmap from "./modules/productRoadmap/components";
import EventApplications from "./modules/myOrganization/eventApplications/components";
import TermsAndConditions from "./modules/gettingStarted/components/termsAndConditions";
import Toast from "./modules/common/submodules/toast/components";
import { ToastType } from "./modules/common/submodules/toast/enums/toast-type.enum";
import ContactUs from "./modules/contactUs/components";
import UserAPI from "./modules/authentication/apis/user.api";
import { User } from "./modules/authentication/models/user.model";
import { Language, ReducerAction } from "./modules/reducer/enums/reducer-action.enum";
import PrivateRoute from "./modules/authentication/components/privateRoute";
import PasswordForm from "./modules/authentication/components/passwordForm";
import StartingGuide from "./modules/help/startingGuide/components";
import CredentialsGuide from "./modules/help/credentialsGuide/components";
import UsageGuide from "./modules/help/usageGuide/components";
import ReleaseNotes from "./modules/help/releaseNotes/components";
import ApiStatus from "./modules/help/apiStatus/components";
import LegalTerms from "./modules/footer/components/legalTerms";
import CommercialOffers from "./modules/crm/components";
import GettingStarted from "./modules/gettingStarted/components";
import BusinessPartners from "./modules/myOrganization/businessPartners/components";
import ClientSettings from "./modules/myOrganization/clientSettings/components";
import CustomScrollBars from "./modules/common/components/customScrollBars";
import Utils from "./utils";
import PrivacyNotice from "./modules/footer/components/privacyNotice";
import TopMenu from "./modules/header/components/topMenu";
import SiteMap from "./modules/footer/components/siteMap";
import Loader from "./modules/common/components/loader";
import SuspenseFallback from "./modules/common/components/suspenseFallback";
import faq from "./modules/help/faq/components";
import { IReducerState } from "./modules/reducer/interfaces/reducer.interface";

const messagesEn = require("./translations/en.json");
const messagesFr = require("./translations/fr.json");
const ApiApplications = lazy(
  () => import("./modules/myOrganization/apiApplications/components")
);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteComponentProps, PropsFromRedux {}

interface IStates {
  isUserLoading: boolean;
}

class App extends Component<IProps, IStates> {
  private previousLocation: string = Routes.HOME;

  constructor(props: IProps) {
    super(props);

    const url = new URL(window.location.href);
    const error = url.searchParams.get("error");
    const redirectUrl = url.searchParams.get("redirectUrl");

    if (redirectUrl) this.previousLocation = redirectUrl as string;
    if (!!error) this.displayError(error);

    if (url.pathname === Routes.HOME) this.props.history.replace(Routes.HOME);

    this.state = {
      isUserLoading: true,
    };

    WebFont.load({
      custom: {
        families: [
          "Antonio-Bold",
          "Roboto-Bold",
          "Roboto-Medium",
          "RobotoCondensed-Bold",
        ],
      },
    });
  }

  componentDidMount() {
    if (!this.props.user) {
      UserAPI.getUser()
        .then((res) => {
          this.props.logUser(new User(res));
          this.goToPreviousLocation();
          this.setState({ isUserLoading: false });
        })
        .catch(() => {
          this.setState({ isUserLoading: false });
        });
    }
    this.props.handleResize();
    window.addEventListener("resize", this.props.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>) {
    const urlParams = new URLSearchParams(window.location.search);
    const isSessionExpired = urlParams.get("isSessionExpired");

    if (isSessionExpired) {
      this.props.displayToast(
        <FormattedMessage id="sessionExpired" />,
        ToastType.ERROR
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  goToPreviousLocation = () => {
    if (
      this.previousLocation !== Routes.HOME &&
      Utils.canRedirect(this.previousLocation)
    ) {
      this.props.history.push(this.previousLocation);
      this.previousLocation = Routes.HOME;
    }
  };

  displayError = (error: string) => {
    if (error == "accountError") {
      this.props.displayToast(
        <FormattedMessage id="error.accountError" />,
        ToastType.WARNING
      );
    } else if (error == "loginError") {
      this.props.disconnectUser();
      this.props.displayToast(
        <FormattedMessage
          id="error.loginError"
          values={{
            a: (link: any) => <a href={Routes.REPORT_ISSUE}>{link}</a>,
          }}
        />,
        ToastType.ERROR
      );
    } else {
      this.props.displayToast(
        <FormattedMessage id="error.serverUnavailableError" />,
        ToastType.ERROR
      );
      window.history.pushState({}, document.title, Routes.HOME);
    }
  };

  render() {
    const messages = this.props.locale === Language.FR ? messagesFr : messagesEn;
    return (
      <IntlProvider locale={this.props.locale} defaultLocale="en" messages={messages}>
        <CustomScrollBars className="main-scrollbars">
          <div className="App">
            <TopMenu />

            {this.state.isUserLoading ? (
              <div className="loader-container App-page">
                <Loader />
              </div>
            ) : (
              <>
                <Header />

                <Suspense fallback={<SuspenseFallback />}>
                  <Switch>
                    {/*My Organization*/}
                    <PrivateRoute
                      path={Routes.API_APPLICATIONS}
                      component={ApiApplications}
                    />
                    <PrivateRoute
                      path={Routes.EVENT_APPLICATIONS}
                      component={EventApplications}
                    />

                    <PrivateRoute
                      path={Routes.BUSINESS_PARTNERS}
                      component={BusinessPartners}
                    />

                    <PrivateRoute
                      path={Routes.CLIENT_SETTINGS}
                      component={ClientSettings}
                    />

                    {/*Commercial Offers*/}
                    <PrivateRoute
                      path={Routes.COMMERCIAL_OFFERS}
                      component={CommercialOffers}
                    />

                    <PrivateRoute
                      path={Routes.API_STATUS}
                      component={ApiStatus}
                    />

                    {/*Product Catalog*/}
                    <Route path={Routes.PRODUCTS} component={Products} />

                    {/*Products Roadmap*/}
                    <Route path={Routes.ROADMAP} component={ProductsRoadmap} />

                    {/*Contact Us*/}
                    <Route path={Routes.CONTACT_US} component={ContactUs} />

                    {/*Help*/}
                    <Route
                      path={Routes.STARTING_GUIDE}
                      component={StartingGuide}
                    />
                    <Route path={Routes.USAGE_GUIDE} component={UsageGuide} />
                    <Route path={Routes.CREDENTIALS_GUIDE} component={CredentialsGuide} />
                    <Route
                      path={Routes.RELEASE_NOTES}
                      component={ReleaseNotes}
                    />

                    {/*Faq*/}
                    <Route path={Routes.FAQ} component={faq} />

                    {/*Getting Started*/}
                    <Route
                      path={Routes.GETTING_STARTED}
                      component={GettingStarted}
                    />
                    <Route
                      path={Routes.TERMS_AND_CONDITIONS}
                      component={TermsAndConditions}
                    />
                    <Route
                      path={Routes.PASSWORD_FORM}
                      component={PasswordForm}
                    />

                    {/*Footer*/}
                    <Route path={Routes.SITE_MAP} component={SiteMap} />
                    <Route
                      path={Routes.PRIVACY_NOTICE}
                      component={PrivacyNotice}
                    />
                    <Route path={Routes.LEGAL_TERMS} component={LegalTerms} />

                    {/*Authentication*/}
                    <Route path={Routes.SIGN_UP} component={GettingStarted} />

                    <Route path={Routes.HOME} component={HomePage} />
                  </Switch>
                </Suspense>
              </>
            )}
            <Footer />
            <Toast />
          </div>
        </CustomScrollBars>
      </IntlProvider>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  locale: state.locale,
});

const mapDispatch = {
  handleResize: () => ({
    type: ReducerAction.RESIZE_HANDLE,
  }),
  logUser: (user: User) => ({
    type: ReducerAction.USER_LOGGED_IN,
    payload: user,
  }),
  disconnectUser: () => ({
    type: ReducerAction.USER_LOGGED_OUT,
  }),
  displayToast: (toastMessage: JSX.Element, toastType = ToastType.SUCCESS) => ({
    type: ReducerAction.TOAST,
    payload: { toastMessage, toastType },
  }),
};

const connector = connect(mapState, mapDispatch);
export default withRouter(connector(App));
