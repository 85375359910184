import React from "react";
import { FormattedMessage } from "react-intl";
import { MenuItem } from "@material-ui/core";
import * as EmailValidator from "email-validator";

import { SelectItem } from "../modules/common/interfaces/common.interface";
import { FormSelectValue } from "../modules/common/submodules/form/enums/form.enum";
import { Routes } from "../modules/common/enums/routes.enum";
import { config } from "../config";

export default class Utils {
  public static login = () => {
    let redirectUrl = "";

    // Set redirectUrl only if we are not on one of the blacklisted redirect URLs
    if (Utils.canRedirect()) {
      const urlParams = new URLSearchParams(window.location.search);
      redirectUrl = `?redirectUrl=${
        urlParams.get("redirectUrl") || location.pathname
      }`;
    }

    window.location.replace(`${config.loginUrl}${redirectUrl}`);
  };

  public static canRedirect(redirectUrl = location.pathname) {
    const redirectUrlsBlackList = [
      Routes.GETTING_STARTED,
      Routes.PASSWORD_FORM,
    ];

    return redirectUrlsBlackList.every((url) => !redirectUrl.includes(url));
  }

  public static stripHtml(html: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  public static percentage = (total: number, part: number) => {
    const percentage = parseInt(((part / total) * 100).toFixed());
    return isNaN(percentage) ? undefined : percentage;
  };

  public static shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }

  public static isValueValid(
    value: string,
    ignoreEmpty = false,
    key?: string
  ): boolean {
    const isEmpty = value === "";

    if (key === "email" && !isEmpty && !EmailValidator.validate(value))
      return false;

    if (ignoreEmpty) return true;

    return !isEmpty;
  }

  public static doesStringIncludesSubstring(
    fullString: string,
    subString: string
  ): boolean {
    const normalizeString = (str: string) =>
      str
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();

    return normalizeString(fullString).includes(subString);
  }

  public static formatLargeNumber(x: number, useCommas = true) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, useCommas ? "," : " ");
  }

  public static getMenuItems = (
    items: SelectItem[],
    placeholder: JSX.Element | string,
    allowNoneValue = false
  ) => {
    const allItems = [
      <MenuItem
        value={FormSelectValue.PLACEHOLDER}
        key="menu-item-none"
        disabled={true}
      >
        <span className="disabled">{placeholder}</span>
      </MenuItem>,
    ];

    if (allowNoneValue) {
      allItems.push(
        <MenuItem value={FormSelectValue.NONE} key="menu-item-none">
          <FormattedMessage id="none" />
        </MenuItem>
      );
    }

    return [
      ...allItems,
      ...items.map((item) => (
        <MenuItem
          value={item.value}
          key={`menu-item-${item.name}`}
          disabled={item.value === "none" || !!item.disabled}
        >
          {item.name}
        </MenuItem>
      )),
    ];
  };

  public static stringToDashCase(str: string) {
    return str
      .toLowerCase()
      .replaceAll(" ", "-")
      .replace(/[^-0-9a-z]/gi, "");
  }

  public static bytesToHumanReadable(bytes: number, decimalNumber = 1) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) return `${bytes}B`;

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** decimalNumber;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    const finalBytes =
      parseInt(bytes.toFixed(decimalNumber).split(".")[1]) === 0
        ? Math.round(bytes)
        : bytes.toFixed(decimalNumber);

    return `${finalBytes} ${units[u]}`;
  }

  public static isValueInEnum(instance: Object, value: string): boolean {
    return Object.values(instance).includes(
      value as unknown as typeof instance
    );
  }
}
