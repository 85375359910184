import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { Routes } from "../../../common/enums/routes.enum";
import TermsAndConditionsLink from "../../../common/components/termsAndConditionsLink";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import {
  Language,
  ReducerAction,
} from "../../../reducer/enums/reducer-action.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps {}

class TopMenu extends Component<IProps> {
  private redirectTo = (route: string): void => {
    this.props.history.push(route);
  };

  render() {
    return (
      <div id="top-menu" className={this.props.screenType}>
        <a
          href="https://www.cma-cgm.com/about/the-group"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="topMenu.aboutUs" />
        </a>

        <span onClick={() => this.redirectTo(Routes.BUSINESS_REQUEST)}>
          <FormattedMessage id="contactUs" />
        </span>

        <TermsAndConditionsLink />

        {/* {this.props.locale === Language.FR ? (
          <img
            className="flag"
            onClick={this.props.changeEnLanguage}
            src={`https://flagcdn.com/w20/fr.png`}
            srcSet={`https://flagcdn.com/w40/fr.png 2x`}
            alt=""
          />
        ) : (
          <img
            className="flag"
            onClick={this.props.changeFrLanguage}
            src={`https://flagcdn.com/w20/us.png`}
            srcSet={`https://flagcdn.com/w40/us.png 2x`}
            alt=""
          />
        )} */}

        {/*<a*/}
        {/*  href="https://www.cma-cgm.com"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  <FormattedMessage id="topMenu.corporate" />*/}
        {/*</a>*/}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
  locale: state.locale,
});

const mapDispatch = {
  changeEnLanguage: () => ({
    type: ReducerAction.EN_LANGUAGE,
  }),
  changeFrLanguage: () => ({
    type: ReducerAction.FR_LANGUAGE,
  }),
};

const connector = connect(mapState, mapDispatch);
export default withRouter(connector(TopMenu));
