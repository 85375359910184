import { OfferSelection } from "../enums/crm.enum";
import { IPackageSelection } from "../interfaces/package.interface";

export class PackageSelection {
  packages: IPackageSelection[];

  constructor() {
    this.packages = [];
  }

  public addPackage(name: string, description: string) {
    if (!this.packages.some((el) => el.name === name)) {
      this.packages.push({
        name,
        description,
        offerId: OfferSelection.NONE,
        offerName: OfferSelection.NONE,
      });
    }
  }

  public changePackageSelectedOffer(
    name: string,
    offerId: OfferSelection | string,
    offerName: OfferSelection | string,
    customOfferMessage?: string
  ) {
    const packageIndex = this.packages.findIndex((el) => el.name === name);

    if (packageIndex !== -1) {
      this.packages[packageIndex].offerId = offerId;
      this.packages[packageIndex].offerName = offerName;

      if (customOfferMessage && customOfferMessage !== "") {
        this.packages[packageIndex].customOfferMessage = customOfferMessage;
      } else {
        delete this.packages[packageIndex].customOfferMessage;
      }
    }
  }
}
