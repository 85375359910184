import "./styles.scss";

import React, { Component, Suspense, lazy } from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";

import ApiDescriptionAPI from "../apis/api-description.api";
import EventAPI from "../apis/events.api";
import { ApiSummary } from "../models/api-summary.model";
import { Event } from "../models/event.model";
import { Routes } from "../../common/enums/routes.enum";
import Catalog from "./catalog";
import { Helmet } from 'react-helmet';
import DescriptionContainer from "./description";
import { ProductType } from "../enums/product-family.enum";
import SuspenseFallback from "../../common/components/suspenseFallback";
import { EndpointSearchResult } from "../models/endpoints-search.model";
import EndpointSearchApi from "../apis/endpoint-search.api";

const Swagger = lazy(() => import("./description/swagger"));

interface IRouteProps {}

interface IProps extends RouteComponentProps<IRouteProps> {}

interface IStates {
  apiSummaries: ApiSummary[] | null;
  eventSummaries: Event[] | null;
  isLoading: boolean;
  endpointSearchResults: EndpointSearchResult[];
  isDeepSearchLoading: boolean;
}

class Products extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      apiSummaries: null,
      eventSummaries: null,
      isLoading: false,
      endpointSearchResults: [],
      isDeepSearchLoading: false,
    };
  }

  private getApiSummaries = (
    productFamily?: string,
    search?: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.setState({ isLoading: true, isDeepSearchLoading: true }, () => {
        ApiDescriptionAPI.getApiSummaries(productFamily, search)
          .then((apiSummaries) => {
            this.setState({ apiSummaries, isLoading: false });
            resolve();
          })
          .catch(() => {
            this.setState({ apiSummaries: [], isLoading: false });
            reject();
          });
      });
    });
  };
  
  private getEventSummaries = (
    productFamily?: string,
    search?: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.setState({ isLoading: true, isDeepSearchLoading: true }, () => {
        EventAPI.getEventSummaries(productFamily, search)
          .then((eventSummaries) => {
            this.setState({ eventSummaries, isLoading: false });

            resolve();
          })
          .catch(() => {
            this.setState({ eventSummaries: [], isLoading: false });
            reject();
          });
      });
    });
  };

  private getDeepSearch = (search: string) => {
    EndpointSearchApi.getEndpointSearch(search)
      .then((endpointSearchResults) =>
        this.setState({ endpointSearchResults, isDeepSearchLoading: false })
      )
      .catch(() =>
        this.setState({ endpointSearchResults: [], isDeepSearchLoading: false })
      );
  };

  render() {
    return (
      <div id="products" className="App-page">
        <Helmet>
          <title>Product Catalog - API Portal - CMA CGM</title>
        </Helmet>
        <Suspense fallback={<SuspenseFallback />}>
          <Switch>
            <Route
              path={`${Routes.PRODUCTS}/api/:productId/swagger`}
              render={(props) => (
                <Swagger {...props} productType={ProductType.API} />
              )}
            />
            <Route
              path={`${Routes.PRODUCTS}/event/:productId/swagger`}
              render={(props) => (
                <Swagger {...props} productType={ProductType.EVENT} />
              )}
            />

            <Route
              path={`${Routes.PRODUCTS}/api/:productId`}
              render={(props) => (
                <DescriptionContainer
                  {...props}
                  productType={ProductType.API}
                />
              )}
            />
            <Route
              path={`${Routes.PRODUCTS}/event/:productId`}
              render={(props) => (
                <DescriptionContainer
                  {...props}
                  productType={ProductType.EVENT}
                />
              )}
            />

            <Route
              path={`${Routes.PRODUCTS}/:familyId`}
              render={() => (
                <Catalog
                  apiSummaries={this.state.apiSummaries as ApiSummary[]}
                  eventSummaries={this.state.eventSummaries as Event[]}
                  endpointSearchResults={this.state.endpointSearchResults}
                  isLoading={this.state.isLoading}
                  isDeepSearchLoading={this.state.isDeepSearchLoading}
                  getApiSummaries={this.getApiSummaries}
                  getEventSummaries={this.getEventSummaries}
                  getDeepSearch={this.getDeepSearch}
                />
              )}
            />
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default withRouter(Products);
