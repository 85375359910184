import HttpUtils from "../../../utils/http.utils";
import { Country } from "../interfaces/country.interface";

export default class LocationAPI {
  public static getCountries(): Promise<Country[]> {
    return HttpUtils.get(`/location/countries`);
  }

  public static getCountry(countryCode: string): Promise<Country> {
    const params = HttpUtils.formatUrlQueryParams({
      countryCode: countryCode,
    });
    return HttpUtils.get(`/location/countries/countryCode${params}`);
  }
}
