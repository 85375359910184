import "./styles.scss";

import React, { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import NewEntity from "./newEntity";
import BracedTitle from "../../../../../common/components/bracedTitle";
import { IPartner } from "../../../interfaces/business-partners.interface";
import CustomScrollBars from "../../../../../common/components/customScrollBars";
import { DocumentCheckIcon } from "../../../../../../assets/icons";
import { PartnerStatus } from "../../../enums/business-partners.enum";
import { ReducerAction } from "../../../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  isOpened: boolean;
  enrichPartner?: IPartner;
  closeNewBp: () => void;
}

interface IStates {
  isBpCreationSuccess: boolean;
  numberOfFailures: number;
  clientRepresentativeEmail: String;
}

class CreationModal extends Component<IProps, IStates> {
  private defaultState = {
    isBpCreationSuccess: false,
    numberOfFailures: 0,
    clientRepresentativeEmail: ""
  };

  constructor(props: IProps) {
    super(props);

    this.state = { ...this.defaultState };
  }

  private addFailure = (): number => {
    const numberOfFailures = this.state.numberOfFailures + 1;
    this.setState({ numberOfFailures });
    return numberOfFailures;
  };

  private onModalClick = (event?: any) => {
    event.stopPropagation();
  };

  private closeNewBp = (event?: any) => {
    if (event) event.stopPropagation();
    this.setState({ ...this.defaultState });
    this.props.closeNewBp();
  };

  private onCreationSuccess = (clientRepresentativeEmail: String) => {
    this.setState({ isBpCreationSuccess: true, numberOfFailures: 0, clientRepresentativeEmail });
    this.props.addedOpenRequestPartner(true);
  };

  render() {
    let title = <FormattedMessage id="partner.registerNewCustomer" />;

    if (this.props.enrichPartner) {
      let name = this.props.enrichPartner.name;
      if (
        this.props.enrichPartner.status !== PartnerStatus.ACTIVE &&
        this.props.enrichPartner.applicativeBpName
      ) {
        name = this.props.enrichPartner.applicativeBpName;
      }

      title = (
        <span>
          <FormattedMessage id="partner.enrichmentOf" />
          &nbsp;
          {name}
        </span>
      );
    }

    const content = this.state.isBpCreationSuccess ? (
      <div id="new-bp" className="success App-content">
        <div className="title">
          <FormattedMessage id="partner.registerNewCustomer" />
        </div>
        <div className="content">
          <DocumentCheckIcon />
          <div className="content-text">
            <b>
              <FormattedMessage id="partner.successSubmit.1" />
            </b>

            <span>
              <FormattedMessage id="partner.successSubmit.2" />
              {this.state.clientRepresentativeEmail}
              <FormattedMessage id="partner.successSubmit.3" />
            </span>
          </div>
        </div>

        <div className="button-container">
          <Button className="button inverted" onClick={this.closeNewBp}>
            <FormattedMessage id="close" />
          </Button>
        </div>
      </div>
    ) : (
      <div
        id="new-bp"
        className={`form App-content ${this.props.screenType} ${
          this.props.enrichPartner ? "enriching-partner" : ""
        }`}
      >
        <BracedTitle text={title} size="large" />

        <div className="content-container">
          <div className="title">
            <div className="info">
              <InfoOutlined />
              {this.props.enrichPartner ? (
                <FormattedMessage id="partner.enrichment.existing" />
              ) : (
                <FormattedMessage id="partner.enrichment.other" />
              )}
            </div>
          </div>
          <CustomScrollBars>
            <div className="content">
              <NewEntity
                enrichPartner={this.props.enrichPartner}
                closeNewBp={this.closeNewBp}
                onCreationSuccess={this.onCreationSuccess}
                addFailure={this.addFailure}
              />
            </div>
          </CustomScrollBars>
        </div>
      </div>
    );

    return (
      <Modal open={this.props.isOpened} onClick={this.onModalClick}>
        {content}
      </Modal>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const mapDispatch = {
  addedOpenRequestPartner: (hasOpenRequestPartnerBeenAdded: boolean) => ({
    type: ReducerAction.ADDED_OPEN_REQUEST_PARTNER,
    payload: hasOpenRequestPartnerBeenAdded,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(CreationModal);
