import "./styles.scss";

import React, { createRef } from "react";
import { FormattedMessage } from "react-intl";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import PeriodFilter from "../../submodules/form/components/periodFilter";
import Loader from "../loader";
import NotFound from "../notFound";
import { ISeriesMetric } from "../../../myOrganization/apiApplications/interfaces/metrics.interface";
import { IChartOptions } from "../../interfaces/metricsChart.interface";
import DateUtils from "../../../../utils/date.utils";

interface IProps {
  chartId: string;
  areMetricsVisible: boolean;
  isMetricListLoading: boolean;
  chartOptions: IChartOptions;
  series: ISeriesMetric[];
  onPeriodSelected(periodStart: Date, periodEnd: Date): void;
}

interface IStates {
  options: ApexOptions;
}

export default class MetricsChart extends React.Component<IProps, IStates> {
  private chartRef = createRef<ReactApexChart>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      options: this.chartOptions,
    };
  }

  componentDidMount() {
    this.updateOptions();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.chartOptions.shouldUpdateChart !==
      this.props.chartOptions.shouldUpdateChart
    ) {
      this.updateOptions();
    }
  }

  private updateOptions = () => {
    const options = { ...this.chartOptions };

    if (options.xaxis) {
      options.xaxis.min = this.props.chartOptions.periodStart.getTime();
      options.xaxis.max = this.props.chartOptions.periodEnd.getTime();
    }

    if (options.legend) {
      options.legend.show =
        this.props.chartOptions.displayLegend === undefined
          ? true
          : this.props.chartOptions.displayLegend;
    }

    this.setState({ options });

    if (this.chartRef && this.chartRef.current) {
      ApexCharts.exec(this.props.chartId, "updateOptions", options);
    }
  };

  private beforeZoom = (chartContext: any, options?: any) => {
    this.props.onPeriodSelected(
      new Date(options.xaxis.min),
      new Date(options.xaxis.max)
    );

    return {
      xaxis: {
        min: options.xaxis.min,
        max: options.xaxis.max,
      },
    };
  };

  private readonly chartOptions: ApexOptions = {
    chart: {
      id: this.props.chartId,
      type: "area",
      height: 350,
      stacked: true,
      events: {
        beforeZoom: this.beforeZoom,
      },
      toolbar: {
        tools: {
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            headerCategory: "DATE",
            columnDelimiter: ";",
            dateFormatter: (timestamp) =>
              !!timestamp
                ? DateUtils.formatDate(new Date(timestamp), false, true)
                : "Invalid date",
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        opacityFrom: 0.7,
        opacityTo: 1,
        shadeIntensity: 0.8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
      min: PeriodFilter.getDefaultPeriodStart().getTime(),
      max: new Date().getTime(),
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.floor(val).toString();
        },
      },
    },
    tooltip: {
      inverseOrder: true,
      x: {
        format: "dd MMMM yyyy - HH:mm",
      },
    },
  };

  private getChartOverlay = () => {
    if (this.props.isMetricListLoading) {
      return (
        <div className="chart-overlay">
          <Loader />
        </div>
      );
    }

    if (this.props.series.length === 0) {
      return (
        <div className="chart-overlay">
          <NotFound text={<FormattedMessage id="noMetricsFound" />} />
        </div>
      );
    }

    return null;
  };

  render() {
    if (this.props.areMetricsVisible) {
      return (
        <div className="metrics-chart">
          <ReactApexChart
            ref={this.chartRef}
            options={this.state.options}
            series={this.props.series}
            type="area"
            height={350}
          />
          {this.getChartOverlay()}
        </div>
      );
    }

    return null;
  }
}
