import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { ProductType } from "../../enums/product-family.enum";
import ApiDescription from "./apiDescription";
import EventDescription from "./eventDescription";

interface IRouteProps {
  productId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  productType: ProductType;
}

class DescriptionContainer extends Component<IProps> {
  render() {
    return this.props.productType === ProductType.API ? (
      <ApiDescription apiId={this.props.match.params.productId} />
    ) : (
      <EventDescription eventId={this.props.match.params.productId} />
    );
  }
}

export default withRouter(DescriptionContainer);
