import React from "react";

import { IRegardingFaq } from "../interfaces/faq.interface";

const shippingBookinkRequestV1 = "shipping.bookingrequest.v1";
const shippingShipmentV3 = "shipping.shipment.v3";
const shippingShipmentShippingInstructionV1 = "shipping.shipment.shippinginstruction.v1";
const shippingShipmentShippingInstructionV2 = "shipping.shipment.shippinginstruction.v2";
const pricingCommercialQuotationV2 = "pricing.commercial.quotation.v2";
const referentialGoodsCodificationV2 = "referential.goodscodification.v2";
const operationTrackandtraceV1="operation.trackandtrace.v1";
const referentialPackagingV2="referential.packaging.v2";
const shipmentEvent="shipmentEvent";
const transportEvent = "transportEvent";
const eventhubSubscriptionV1="eventhub.subscription.v1";
const getLink = (href: string, title: string) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );
};

export const regardingFaq: IRegardingFaq[] = [
  {
    family: "Booking Request",
    questions: [
      {
        id: shippingBookinkRequestV1,
        title:
          "Can I provide only CMA Business Partner code without name, addresses and contact in the payload when calling Booking Request API?",
        answer: (
          <>
            <p>
              It is strongly recommended to always provide name, addresses and contact of booking parties in the payload no matter CMA Business Partner Code is provided or not. Also, you are particularly suggested to provide email address for booking party. 
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentV3,
        title: "Can I retrieve my booking data through API?",
        answer: (
          <>
            <p>
              Yes, you can get your data by booking reference using the&nbsp;
              {getLink(
                "/products/api/shipping.shipment.v3",
                "shipping.shipment.v3"
              )}
              &nbsp;endpoint.
            </p>
            <p>
              Using this API you will also be able to get required data to
              submit your shipping instruction.
            </p>
            <p>
              For more information about Shipment API, please contact your
              regional officer.
            </p>
          </>
        ),
      },
      {
        id: shippingBookinkRequestV1,
        title:
          "Can I be notified when booking request is confirmed or cancelled?",
        answer: (
          <>
            <p>
              Using&nbsp;
              {getLink("/products/event/shipmentEvent", "Shipment Event")}:
            </p>

            <ul>
              <li>
                Need to prepare your webhook to receive shipment events pushed
                by CMA CGM.
              </li>
              <li>
                Once the booking is confirmed / cancelled, a Shipment event will
                be pushed to your webhook.
              </li>
            </ul>

            <p>Using CMA CGM group web channel:</p>
            <ul>
              <li>
                If you have a CMA CGM web account, you can receive notifications
                on CMA CGM group web channel as well (“Booking follow up”
                notification).
              </li>
            </ul>
          </>
        ),
      },
      {
        id: shippingBookinkRequestV1,
        title: "What are the covered contracts/rates?",
        answer: (
          <>
            <p>
              The NVOCC rates, FAK rates and BCO contracts are covered by this
              product.
            </p>
            <p>The Spot rates is not covered at this moment.</p>
          </>
        ),
      },
      {
        title:
          "How can I get a full list of commodity codes or container size types?",
        answer: (
          <>
            <p>Please refer to Referential API’s FAQ for detail.</p>
          </>
        ),
      },
      {
        title:
          "How can I obtain the list of party codes available for booking party?",
        answer: (
          <>
          Please follow below procedures to get the list of booking parties on API Portal:-
            <ol type="1">
              <li>Login API Portal</li>
              <li>
                Select &nbsp;
                {getLink("/business-partner", "My Organization > Business Partners")}&nbsp;
              </li>

              <li>
                The business parties are shown under Active.
              </li>
              <li>
                Click tiny “i” icon at the beginning of each line to display the corresponding partner code.
              </li>
            </ol>
          </>
        ),
      },
    ],
  },
  {
    family: "Shipment",
    questions: [
      {
        id: shippingShipmentV3,
        title:
          "Can I find a “not yet confirmed” or “cancelled” booking in the Shipment API?",
        answer: (
          <>
            <p>
              Shipment API returns “confirmed”/”cancelled” bookings but not “not
              yet confirmed” bookings. In addition, you can filter the
              “cancelled” bookings by their status (i.e. <i>shipmentStatus</i>).
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentV3,
        title: "Can I have access to all bookings?",
        answer: (
          <>
            <p>
              No, you can only access if you are one of the parties in the
              booking (i.e. consignee, deciding party, freight forwarder, notify
              party, second notify party, requester, shipper and 3rd party
              booking agent).
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentV3,
        title: "What should I do if I fail to call Shipment API?",
        answer: (
          <>
            <ol type="1">
              <li>
                You can simply report the issues via&nbsp;
                {getLink("/contact-us/report-issue", "Issue Report")}&nbsp;in
                API Portal.
              </li>

              <li>
                Make sure you register API Portal and login before submission.
              </li>
            </ol>
          </>
        ),
      },
      {
        id: shippingShipmentV3,
        title: "Is UAT required for Shipment API?",
        answer: (
          <>
            <p>
              There is no need for UAT as Shipment API for getting not submitting data.
            </p>
          </>
        ),
      },
    ],
  },
  {
    family: "Track and Trace",
    questions: [
      {
        id: operationTrackandtraceV1,
        title:
          "What is the maximum number of events returned in one API call?  ",
        answer: (
          <>
            <p>
            API returns maximum 100 events by default.  
            Setting the query parameter <i>limit</i> can increase the maximum number of events to return in one
            API call (maximum 500).<br/><br/> In case there are over 500 events, 
            multiple API calls are required to retrieve all events. Firstly, 
            call API to get the first 500 events. Repeat API call by setting the query parameter <i>cursor</i>
             equals to the value of header next-page in the last API call. Repeat until there is no header 
            &nbsp;<i>next-page</i> returned.
            </p>
          </>
        ),
      }
    ]
  },
  {
    family: "Shipping Instruction",
    questions: [
      {
        id: shippingShipmentShippingInstructionV2,
        title: "Does API support SPLIT/MERGE BL?",
        answer: (
          <p>
              Yes for SPLIT. New end-point customers PUT/shippinginstructions/splitBooking.<br/><br/> 
              No for MERGE. MERGE function is planned for the 2nd half of 2024. Please consider using
              CMA CGM WEB or other Markeplaces as alternative.</p>
          
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title: "Does API support House BL and Part-Load BL?",
        answer: (
          <>
            <p>No. Please consider using CMA CGM WEB or other Marketplaces as alternative.</p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title: "Does API support VGM submission?",
        answer: (
          <>
            <p>No at this moment. Please consider using CMA CGM WEB or other Marketplaces as alternative.</p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "What should I do if “Marks” or “Description” are in multiple lines?",
        answer: (
          <>
            <p>
              Use <b>\n</b> as line delimiter
              <li>
                e.g. “marks”: “LINE1<b>\n</b>LINE2<b>\n</b>LINE3”
              </li>
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "Why there is no cargo information show on the BL?",
        answer: (
          <>
            <p>
            In order to show cargo information on the BL, the cargo information should be put in “marks” and “descriptions” within the request payload.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "What should I do if name of party in shipping instruction exceed the length of the field, i.e. 35 characters?",
        answer: (
          <>
            <p>
              You can continue the <i>name</i> in the <i>addressLine1</i> field.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "Which one is the mandatory business party when submit Shipping Instruction via API?",
        answer: (
          <>
            <p>
            Shipping Instruction Requestor (REQ) is mandatory and should be provided with a valid partner code and email address.&nbsp;
            &nbsp;&nbsp;For US bookings, the Forwarder party (FOR) is also mandatory.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "Which business party is not allowed when submit Shipping Instruction via API?",
        answer: (
          <>
            <p>
            Booking party (BKG) is not allowed.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "Can I use “sameAsConsignee” for all party roles?",
        answer: (
          <>
            <p>
            No. “sameAsConsignee” can be used for Notify (NOT), Notify 2 (NO2) and payer (PAY) roles only. And “sameAsConsignee” is not allowed when Consignee is “toOrderAsBlank”.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "There are 3 containers in the booking, but I obtained only 1 container number up to this moment. Can I submit shipping instruction now or I must wait until I have all 3 container numbers?",
        answer: (
          <>
            <p>
            “containerNumber” is mandatory. You must wait until you have all 3 container numbers to submit the shipping instruction via API.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "During the submission of SI, I got a 400 error with message “Unknown bookingReference” via API, why?",
        answer: (
          <>
            <p>
            Most likely the booking reference does not exist in our system, or the booking has not been confirmed yet.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "I have provided a valid partner code and role with the address, but I got a 400 error with message “Invalid party definition” via API, why?",
        answer: (
          <>
            <p>
            Most likely you are missing some fields for that party. If you provide any data other than partner code and role, you must provide all mandatory fields: “name”, “addressLine1”, “addressLine2” and “country” <span>&gt;</span> “code”
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "How to submit SI with parties over 6 lines?",
        answer: (
          <>
            <p>
             You can continue the details in “additionalInformation” <span>&gt;</span> “clauseDescription” by adding a symbol (*) to denote additional wording. If you need additional wording for more than one party, please use different symbol (**) or more to denote. The “additionalInformation” will be shown at the end of the description of packages in the BL.
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "Do we support UN LOCODE for the ports?",
        answer: (
          <>
            <p>
              	
Yes. You can either use “internalCode” or “locationCodifications” for the ports.<br/> 
<ul>
<li>“internalCode” is for the Port code in CMA CGM referential (see CMA CGM Location API)</li>

<li>“locationCodifications” is for External location codification such as UN LOCOD</li>
</ul>
            </p>
          </>
        ),
      },
      {
        id: shippingShipmentShippingInstructionV2,
        title:
          "What code can I use in UAT to submit Shipping Instruction?",
        answer: (
          <>
            <p>
            The testing partner code is 0007188714. In case you are 3rd party customer and need “behalfOf” function, the testing partner code of “behalfOf” is API0009533.
            </p>
          </>
        ),
      },
    ],
  },



  {
    family: "Pricing Contracts",
    questions: [
      {
        id: pricingCommercialQuotationV2,
        title:
          "Which contracts are returned from the API?",
        answer: (
          <>
            <p>
              The contracts returned depend on scope selected while signing the API contract:
              <ul>
                <li>If you are a direct customer with multiple office locations, you are asked to select offices (business partner code in CMA CGM terminology) that are in scope. All applicable contracts where such a selected office is a deciding party (Contract Owner) will be returned. </li>
                <li>If you are a platform, while our mutual customer signs Client Mandate/LoA, you are asked to select which offices of the end customer covered by the LoA are in scope. All applicable contracts where such a selected office is a deciding party will be returned.</li>
              </ul>
            </p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title: "Can I retrieve FMC & Non- FMC Contracts from the API response?",
        answer: (
          <>
            <p>
              If applicable for provided search criteria, both FMC & Non-FMC contracts could be retrieved using the API in the same API response.
            </p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title: "What does 200OK response with empty array means?",
        answer: (
          <>
            <p>The API returns a 200OK response containing an empty array when a valid quotation is not found for the provided search criteria. This response is applicable to public quote line searches when the specified search criteria does not correspond to any available service or tariff on the simulation date.</p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title: "What are matchingSurchargesPerEquipment and matchingBlSurcharges?",
        answer: (
          <>
            <p><i>matchingSurchargesPerEquipment</i> are applicable per container.

              <i>matchingBlSurcharges</i> are applicable per BL. For example, if a booking/BL has 4 containers, this charge will be applicable only once.</p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title: "What are chargeCurrency and pivotCurrency ?",
        answer: (
          <>
            <p><i>chargeCurrency</i> is the currency in which the charge has been constructed.

              <i>pivotCurrency</i> follows freight currency. An exchange rate is used for this conversion when they are different.</p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title: "What does it mean when for a charge \"included InBasicFreight\": true? Why is \"amount\": null in this case?",
        answer: (
          <>
            <p><i>{"\"includedInBasicFreight\""}</i>: true means that it is an <i>{"\"all-in\""}</i> rate or that the charge has been rolled into basic freight. In this case, for the rolled in charge, <i>{"\"amount\""}</i>: null because <i>{"\"chargeName\""}</i>: <i>{"\"BASIC FREIGHT\""}</i> already includes this charge.</p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title:
          "What is \"chargeDefinedAsCommentTxt\"? Why is \"amount\": null in this case?",
        answer: (
          <>
            <p>
              The comment only conveys a message on a possible value-added service (VAS) or shipment specific charge which could be dynamically applicable on a booking. The actual amount depends on shipment properties and hence is returned as null by the endpoint.
            </p>
          </>
        ),
      },
      {
        id: pricingCommercialQuotationV2,
        title:
          "Some of my applicable surcharges does not reflects the payment method, what should be considered in this case?",
        answer: (
          <>
            <p>
              In case the surcharge is applicable in the quotation, however the API response reflect <i>{"\"paymentMethod\""}</i>: null , the payment method of such surcharges will follow the method of payment in which Ocean Freight is being charged.
            </p>
          </>
        ),
      },
    ],
  },



  {
    family: "Events",
    questions: [
      {
        title: "What is a Webhook? What are Webhooks used for?",
        answer: (
          <p>
            Please refer to&nbsp;
            {getLink(
              "https://hookdeck.com/webhooks/guides/what-are-webhooks-how-they-work",
              "What Are Webhooks And How They Work - Hookdeck"
            )}
            &nbsp;for details.
          </p>
        ),
      },
      {
        id:operationTrackandtraceV1,
        title: "What is the main different between API and Event Push?",
        answer: (
          <>
            <p>API</p>
            <ul>
              <li>customer calls CMA CGM’s API when it is needed.</li>
            </ul>
            <p>Event Push</p>
            <ul>
              <li>
                CMA CGM call customer’s webhook to send data to customer when
                events happened.
              </li>
            </ul>
          </>
        ),
      },
      {
        title:
          "Which are the possible authentication methods of Webhook for CMA CGM Event Push?",
        answer: (
          <>
            <p>
              Please refer to&nbsp;
              {getLink("/usage-guide#webhook-security", "Webhook Security")}
              &nbsp;for detail information.
            </p>
          </>
        ),
      },
      {
        title: "Is it possible to have different Webhooks for each events?",
        answer: <p>No, one single Webhook for all events.</p>,
      },
      {
        title:
          "Is there any retry policy to deal with downtime of Webhook (HTTP 5XX error)?",
        answer: (
          <p>
            Please refer to &nbsp;
            {getLink("/usage-guide#webhook-policy", "Webhook Retry Policy")}
            &nbsp; for detail information.
          </p>
        ),
      },
      {
        title: "Are all bookings' / containers' events pushed to Webhook?",
        answer: <p>No, only selected roles and parties will be sent.</p>,
      },
      {
        id:shipmentEvent,
        title: "Can I not receive events of all my bookings but only some of them?",
        answer: <p>Yes, Event Subscription API is the solution. You can select which of your bookings that you would like to receive.</p>,
      },
      {
        title: "How many event(s) is/are pushed to my Webhook every time?",
        answer: <p>One event is pushed to Webhook every time.</p>
      },
      {
        title:
          "What is the IP address of the CMA CGM Push server? I need this information to setup the Firewall to allow the incoming traffic from CMA CGM.",
        answer: (
          <p>
            Sorry, IP address of Push server cannot be provided. It is not
            recommended to enable IP filtering on customer’s side.
          </p>
        ),
      },
      {
        id:transportEvent,
        title:
          "What does trigger Planned Vessel Arrival (PVA) / Planned Vessel Departure (PVD) events sent to customer?",
        answer: (
          <>
            <p>
              PVA and PVD are sent when the booking is confirmed. Updates of PVA
              and PVD are sent when there is a change in:
            </p>
            <ul>
              <li>first maritime leg voyage OR</li>
              <li>point of origin OR</li>
              <li>port of discharge, final place of delivery</li>
            </ul>
          </>
        ),
      },
      {
        id:transportEvent,
        title:
          "What does trigger Estimated Vessel Arrival (EVA) / Estimated Vessel Departure (EVD) events sent to customer?",
        answer: (
          <>
            <p>
              EVA and EVD are sent whenever a container is assigned to the
              booking.
            </p>
            <p>
              Updates of ETA and ETD are sent whenever there is a significant
              update.
            </p>
          </>
        ),
      },
    ],
  },
  {
    family: "Event Subscription",
    questions: [
      {
        id:eventhubSubscriptionV1,
        title:
          "Will the past events be pushed after calling Event Subscription API?",
        answer: (
          <p>
            No. It is highly recommended to call the Event Subscription API once
            you get the booking reference returned by Booking Request API to
            avoid missing events.
          </p>
        ),
      },
      {
        id:eventhubSubscriptionV1,
        title:
          "If I call the Event Subscription PUSH API with equipmentReference, shipmentReference and contractReference in the request payload, does it mean only events that meet all 3 references will be pushed?",
        answer: (
          <p>
            No, events that meet any one of these references will be pushed.
          </p>
        ),
      },
      {
        id:eventhubSubscriptionV1,
        title:
          "Will all the existing validation rules be overwritten when I call the Event Subscription PUSH API again?",
        answer: (
          <p>
            Only the validation rules with same equipmentReference, shipmentReference and contractReference will be overwritten. For the rest, new validation rules will be added.
          </p>
        ),
      },
      {
        id:eventhubSubscriptionV1,
        title:
          "Will the Event Subscription PUSH API return error if the equipmentReference, shipmentReference or contractReference specified in the request payload does not exist?",
        answer: (
          <p>
            No error will be returned.</p>
        ),
      },
    ],
  },
  {
    family: "Referential",
    questions: [
      {
        id: referentialGoodsCodificationV2,
        title: "How can I get a full list of HS commodity codes?",
        answer: (
          <p>
            You can check these out through&nbsp;
            {getLink(
              "/products/api/referential.goodscodification.v2",
              "Goods codification referential API"
            )}
            . Even though there are many different commodity codes lists you can
            find on the internet, e.g.&nbsp;
            {getLink(
              "https://www.censtatd.gov.hk/en/EIndexbySubject.html?pcode=B2XX0023&scode=230",
              "C&SD: Hong Kong Harmonized System (HKHS) Codes (censtatd.gov.hk),"
            )}
            &nbsp;you should always refer to our referential API.
          </p>
        ),
      },
      {
        id: referentialPackagingV2,
        title: "How can I get a full list of Container codes",
        answer: (
          <>
            <p>
              You can check these out through&nbsp;
              {getLink(
                "/products/api/referential.packaging.v2",
                "Packaging referential API"
              )}
              . &nbsp;Even though there are many container type and codes lists
              you can find on the internet, e.g. &nbsp;
              {getLink("https://en.wikipedia.org/wiki/ISO_6346", "ISO 6346")},
              you should always refer to our referential API.
            </p>
          </>
        ),
      },
    ],
  },
  {
    family: "User Acceptance Test",
    questions: [
      {
        id: shippingShipmentV3,
        title: "Is UAT required before Shipment API goes Live?",
        answer: (
          <p>
            No, there is no need of UAT as Shipment API is for getting not
            submitting data.
          </p>
        ),
      },
      {
        id: shippingBookinkRequestV1,
        title:
          "Which partner code(s) can be used when submitting booking request in UAT environment?",
        answer: (
          <p>
            You can use dummy partner code 0007188714 (effective Aug 19, 2022)
            when submitting booking request in UAT environment.
          </p>
        ),
      },
      {
        title:
          "In UAT environment, what are the port pairs (i.e. load → discharge) covered in the dummy contract reference TA-CROSS-CMA setup?",
        answer: (
          <>
            <p>
              In UAT environment, the following port pairs [load → discharge]
              are covered:
            </p>
            <ul>
              <li>DEHAM → HKHKG</li>
              <li>DEHAM → USLGB</li>
              <li>DEHAM → USNYC</li>
              <li>FRLEH → CNSHA</li>
              <li>NLRTM → USNYC</li>
              <li>NLRTM → HKHKG</li>
              <li>NLRTM → SGSIN</li>
            </ul>
          </>
        ),
      },
      {
        title:
          "How to get the credentials for UAT environment?",
        answer: (
          <>
            <p>
            Follow the below steps to raise a request:-
            </p>
            <ol type="1">
              <li>Login {getLink("/", "API Portal")}&nbsp;</li>
              <li>Navigate to <i>Contact Us</i> {">"} <i>Report Issue</i> or via URL {getLink("/contact-us/report-issue", "Issue Report")}&nbsp;</li>
              <li>
                Complete the Form
                <ol type="a">
                  <li>Select <b>API Portal</b> in Product family</li>
                  <li>Put <b>Resent UAT credentials</b> in <i>Title</i></li>
                  <li>In <i>Description</i>, put <b>Resend UAT credential to {"{your email address}"}</b></li>
                </ol>  
              </li>
              <li>Click [Send] button to submit your request.</li>
            </ol>
          </>
        ),
      },
      {
        title:
          "Is UAT environment available 24 x 7?",
        answer: (
          <>
            <p>
            	Schedule maintenance on UAT environment is usually held on weekends and sometimes Monday as well. HTTP error 502 will be returned when you call API during the maintenance period.
            </p>
          </>
        ),
      },
    ],
  },
  {
    family: "LOA",
    questions: [
      {
        title: "Why do we need an LOA?",
        answer: (
          <p>
            As a customer of CMA CGM, you are owner of data stored in our
            systems. Data being extremely sensitive information know a day, CMA
            CGM wants to make sure that you have given us the authorization to
            share your data with someone else. This LOA serves this purpose.
            Within the LOA, you will authorize CMA CGM and mandate a 3rd party
            to receive your data. Without this formal, written consent, we
            cannot send your data to any other entity.
          </p>
        ),
      },
      {
        title: "Who needs to sign an LOA?",
        answer: (
          <p>
            All CMA CGM’s client that request CMA CGM to share their data with
            another 3rd party that is not party to the booking will have sign an
            LOA.
          </p>
        ),
      },
      {
        title: "Why is the client mandate required?",
        answer: (
          <p>
            CMA CGM is concerned with protecting your data and goes beyond the
            GDPR requirements. For this reason, we consider even tracking data
            as a sensitive data that we would not share without your consent.
            This client mandates helps us identify to who and under what scope
            should your data be shared with other parties.
          </p>
        ),
      },
      {
        title: "What information should I enter in which field?",
        answer: (
          <ul>
            <li className="main">
              <b>Company Name:</b>
              <p>Enter name of the company</p>
            </li>

            <li className="main">
              <b>Legal status:</b>
              <p>
                Legal status is the status or position held by an entity as
                determined by the law.
              </p>
              <ul>
                <li>
                  Examples:
                  <ul>
                    <li>
                      US: sole proprietorship, general partnership, limited
                      partnership, LLC, C corporation or S corporation in the
                      USD.
                    </li>
                    <li>
                      France: Limited Liability Companies (S.A.R.L.), Simplified
                      Incorporated Companies (S.A.S.), and Incorporated
                      Companies (S.A).
                    </li>
                    <li>
                      India: Private Limited Company, Public Company, Sole
                      Proprietorship, One Person Company, Partnership, Limited
                      Liability Partnership (LLP).
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="main">
              <b>Official registration No:</b>
              <p>
                It is a unique combination of numbers and letters (depending on
                the country) and that is used to identify a company.
              </p>
              <ul>
                <li>
                  Examples:
                  <ul>
                    <li>USA: EIN Number</li>
                    <li>Germany: RegisterNumme</li>
                    <li>India: CRN</li>
                    <li>France: CRN</li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="main">
              <b>Full official address:</b>
              <p>Full address of the company.</p>
            </li>

            <li className="main">
              <b>Country:</b>
              <p>The country of the company.</p>
            </li>
          </ul>
        ),
      },
      {
        title: "What is a parent company?",
        answer: (
          <p>
            A parent company is a single company that has a controlling interest
            in another company or companies.
          </p>
        ),
      },
      {
        title:
          "Why client’s mandate shall be governed by and construed in accordance with French law?",
        answer: (
          <p>
            The API data feed covers all global Ocean Freight Contracts. Those
            contracts which might be under different jurisdictions; therefore,
            the API service cannot be aligned with the Ocean Freight Contracts.
            Furthermore, the jurisdiction applied must be aligned with the place
            where the service is given. In case of CMA CGM, all API services are
            delivered from Marseille France, hence that client’s mandate shall
            be governed by and construed in accordance with French law.
          </p>
        ),
      },
      {
        title: "What tool is used for electronic signature?",
        answer: <p>We use DocuSign for all electronic signatures.</p>,
      },
      {
        title: "What is client’s mapping?",
        answer: (
          <p>
            To share your data with any other entity, we first need to identify
            your data within our system. We identify you in our system through
            the name of your company/entity but also the address, the partner
            code you have at CMA CGM but also Ocean Freight Contract or Bill of
            lading number. The more information you give us the easier and
            faster it is for us to find the correct entity and allow the data to
            be shared. The client mapping consists in identifying in our systems
            which entity allows to share which data with whom.
          </p>
        ),
      },
      {
        title: "What do you mean by “entities”?",
        answer: (
          <p>
            A legal entity is any company or organization that has legal rights
            and responsibilities, including tax filings.
          </p>
        ),
      },
      {
        title: "Why is the Representative’s email needed?",
        answer: (
          <p>
            Sometimes there exists small differences between the information we
            have in our system and the ones that you provide. Your email address
            thus allows us to contact you to clarify the information if need be.
          </p>
        ),
      },
    ],
  },
  {
    family: "Invoices",
    questions: [
      {
        title: "How am I billed for API usage?",
        answer: (
          <>
            <p>
              I am charged of succeed API calls or when the error comes from my
              side. Here is the detailed list of return messages:
            </p>

            <p>
              200 - Basic success code - (Chargeable)
              <br />
              201 - Created - (Chargeable)
              <br />
              204 - Fulfilled - (Chargeable)
              <br />
              206 - Incomplete resource - (Chargeable)
              <br />
            </p>

            <p>
              400 - Request cannot be processed - (Chargeable)
              <br />
              401 - Client is unknown - (Non-Chargeable)
              <br />
              403 - No sufficient rights to perform the request -
              (Non-Chargeable)
              <br />
              404 - The resource doesn&apos;t exist - (Chargeable)
              <br />
              429 - Too Many Requests - (Non-Chargeable)
              <br />
            </p>

            <p>
              500 - Internal Server Error - (Non-Chargeable)
              <br />
              502 - Bad Gateway Error - (Non-Chargeable)
              <br />
              503 - Service Unavailable Error - (Non-Chargeable)
              <br />
              504 - Gateway Timeout Error - (Non-Chargeable)
              <br />
            </p>
          </>
        ),
      },
    ],
  },
];
