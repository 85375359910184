import { ProductFamily, ProductType } from "../enums/product-family.enum";

export class ApiSummary {
  public id: string;
  public type = ProductType.API;
  public name: string;
  public description: string;
  public title: string;
  public virtualizedVersion: string;
  public publicationDate: Date;
  public lastUpdatedDate: Date;
  public productFamily: ProductFamily;
  public isDcsa: boolean;
  public isDeprecated: boolean;
  public isOptimizing: boolean;
  public apiSecurity: string | undefined;
  public isUserSubscribed: boolean | undefined;
  public release: Release[];
  public isPilot: boolean;

  constructor(body: any) {
    this.id = body.id;
    this.name = body.name;
    this.description = body.description;
    this.title = body.title;
    this.virtualizedVersion = body.virtualizedVersion;
    this.publicationDate = new Date(body.publicationDate);
    this.lastUpdatedDate = new Date(body.lastUpdatedDate);
    this.productFamily = body.productFamily;
    this.isDcsa = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "dcsa"
    );
    this.isDeprecated = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "deprecated"
    );
    this.isOptimizing = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "optimizing"
    );
    this.isPilot = body.apiTags.some(
      (tag: string) => tag.toLowerCase() === "pilot"
    );
    this.apiSecurity = body.apiSecurity;
    this.isUserSubscribed = body.isUserSubscribed;
    this.release = body.release;
  }
}

export class Release {
  public version: string;
  public date: Date;
  public changes: string[];

  constructor(body: any) {
    this.version = body.version;
    this.date = body.date;
    this.changes = body.changes;
  }
}
