import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import HeaderBanner from "../../common/components/headerBanner";
import { newProductsFamily } from "../constants/new-products-eta.constant";
import {
  INewProduct,
  INewProductsFamily,
} from "../interfaces/new-product.interface";
import DateUtils from "../../../utils/date.utils";
import { Routes } from "../../common/enums/routes.enum";
import { IReducerState } from "../../reducer/interfaces/reducer.interface";
import { NewProductsETA } from "../enums/date-new-products.enum";
import { Select } from "@material-ui/core";
import { SortRoadmap } from "../enums/sort-roadmap.enum";
import { Helmet } from 'react-helmet';
import { sortRoadMapList } from "../constants/sort-roadmap.constant";
import Utils from "../../../utils";
import ApiDescriptionAPI from "../apis/apiLive.api";
import { ApiRecentlyUpdated } from "../model/api-new.model";
import { apiFamilies } from "../../productCatalog/constants/api-family.constant";
import { FindIcon } from "../../../assets/icons";
import CmsAPI from "../apis/apiCms.api";
import { ICms } from "../interfaces/cms.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IRouteProps { }

interface IProps extends PropsFromRedux, RouteComponentProps<IRouteProps> { }

interface IStates {
  sortValue: string;
  listApiRecentlyUpdated: ApiRecentlyUpdated[] | undefined;
  listCms: ICms | undefined;
}

class ProductsRoadmap extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sortValue: "family",
      listApiRecentlyUpdated: undefined,
      listCms: undefined
    };
  }

  componentDidMount() {
    ApiDescriptionAPI.getApiRecentlyUpdated().then((res) => {
      this.setState({ listApiRecentlyUpdated: res });
    });

    CmsAPI.getRoadmapCms().then((res) => {
      this.setState({ listCms: res });
    })
  }

  private onChange = (value: string) => {
    this.setState({ sortValue: value });
  };

  private displayProductsByFamily = (
    product: INewProductsFamily,
    index: number
  ) => {
    return (
      <div key={product.id} className="family">
        <div className="block-title">
          <div className="family-title">
            <span className="title-brace">{"{"}</span>
            {product.name}
          </div>
          {/* {index === 0 && this.displaySortBlock()} */}
        </div>
        <div className={`list ${this.props.screenType}`}>
          {product.products &&
            product.products.map((productElt) =>
              this.displayTile(productElt, "", false)
            )}
        </div>
      </div>
    );
  };

  private displaySortBlock = () => {
    return (
      <div>
        <FormattedMessage id="roadmap.sortedBy" />
        &nbsp;
        <Select
          value={this.state.sortValue}
          onChange={(event) => this.onChange(event.target.value as string)}
        >
          {Utils.getMenuItems(
            sortRoadMapList,
            <FormattedMessage id="roadmap.sortedBy" />
          )}
        </Select>
      </div>
    );
  };

  private isInCatalog = (productElt: INewProduct, recentlyUpdated: boolean) => {
    if (recentlyUpdated) {
      if (productElt.id?.includes("Event")) {
        return (
          <a
            href={`/products/event/${productElt.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <FindIcon />
          </a>
        );
      }
      else {
        return (
          <a
            href={`/products/api/${productElt.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <FindIcon />
          </a>
        );
      }
    }

    if (
      this.state.listCms?.content?.some((family) =>
        family.products.some(
          (product) => product.id && product.id === productElt.id
        )
      )
    ) {
      let baseUrl =
        productElt.id?.includes("Event") ?
          'event' : 'api';
      return (
        <a
          href={`${Routes.PRODUCTS}/${baseUrl}/${productElt.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <FindIcon />
        </a>
      );
    }
  };

  private checkIfHasProperty(productElt: INewProduct, property: string) {
    const propertyValue = productElt[property as keyof INewProduct];
    return productElt.hasOwnProperty(property) && 
           typeof propertyValue === 'number' && 
           propertyValue > 0;
}
  private displayTile = (
    productElt: INewProduct,
    family: string,
    recentlyUpdated: boolean
  ) => {
    let productETA = this.checkIfHasProperty(productElt,"semester")
      ? NewProductsETA.COMING_SOON
      : recentlyUpdated
        ? NewProductsETA.RECENTLY_UPDATED
        : DateUtils.getProductETA(productElt);
    return (
      <React.Fragment key={`roadmap-tile-${productElt.name}`}>
        {productElt.name !== "NOT FOUND !" && (
          <div className="card">
            <div className="top">
              {productElt.id || recentlyUpdated ? (
                this.isInCatalog(productElt, recentlyUpdated)
              ) : (
                <div />
              )}
              <div className={`date ${productETA}`}>
                {this.state.sortValue == SortRoadmap.FAMILY ? (
                  <>
                    {[NewProductsETA.PLANNED, NewProductsETA.PILOT].includes(productETA) ? (
                      <FormattedMessage id={productETA} />
                    ) : (
                      this.checkIfHasProperty(productElt,"semester") ? (
                        `S${productElt.semester} ${productElt.year}`
                      ) : (
                        `Q${productElt.quarter} ${productElt.year}`
                      )
                    )}
                  </>
                ) : (
                  <>
                    {recentlyUpdated
                      ? apiFamilies[
                        apiFamilies.findIndex(
                          (apiFamily) => apiFamily.productFamily === family
                        )
                      ]?.name
                      : family}
                  </>
                )}
              </div>
            </div>
            <div className="title">{productElt.name}</div>
            <div className="description">
              {Utils.stripHtml(productElt.description)}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  private displayDateCategory = (key: string) => {
    switch (key) {
      case NewProductsETA.RECENTLY_UPDATED:
        return <FormattedMessage id="roadmap.recentlyUpdated" />;
      case NewProductsETA.COMING_SOON:
        return <FormattedMessage id="roadmap.comingSoon" />;
      case NewProductsETA.IMMINENT:
        return <FormattedMessage id="roadmap.imminent" />;
      case NewProductsETA.PLANNED:
        return <FormattedMessage id="roadmap.planned" />;
      case NewProductsETA.PILOT:
        return <FormattedMessage id="roadmap.pilot" />;
    }
  };

  private displayProductsByDate = (key: string, index: number) => {
    return (
      <div key={key} className="family">
        <div className="block-title">
          <div className="family-title">
            <span className="title-brace">{"{"}</span>
            {this.displayDateCategory(key)}
          </div>
          {/* {index === 0 && this.displaySortBlock()} */}
        </div>
        <div className={`list ${this.props.screenType}`}>
          {key === NewProductsETA.RECENTLY_UPDATED
            ? this.state.listApiRecentlyUpdated!.map((product) =>
              this.displayTile(product, product.family, true)
            )
            : this.state.listCms?.content?.map((family) =>
              family.products.map(
                (product) =>
                  DateUtils.getProductETA(product) == key.toLowerCase() &&
                  this.displayTile(product, family.name, false)
              )
            )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="productsRoadmap">
        <Helmet>
          <title>Product Roadmap - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="roadmap" />
        </HeaderBanner>
        <div className="content">
          <div className="App-content">
            <div className={`block-contact ${this.props.screenType}`}>
              <div className="grid-container-master content">
                <div>
                  Updated on {window.innerWidth < 1300 && <br/>} {new Date(this.state.listCms?.lastUpdated || "")
                    .toLocaleDateString('en-US', {
                      month: '2-digit', // '2-digit' represents the month as two digits (e.g., 03)
                      day: '2-digit',   // '2-digit' represents the day as two digits (e.g., 08)
                      year: 'numeric'   // 'numeric' represents the year as four digits (e.g., 2024)
                    }).replace(/\//g, '-')
                  }
                </div>
                <div className="grid-container-slave">
                  <div className="MuiTabs-indicator">
                    &nbsp;&nbsp;
                  </div>
                  <div>
                    <FormattedMessage id="roadmap.contact" />
                  </div>

                  <div
                    className="button" style={{marginTop:"0px"}}
                    onClick={() => this.props.history.push(Routes.CONTACT_US)}
                  >
                    <FormattedMessage id="contactUs" />
                  </div>
                </div>
              </div>
            </div>
            {this.state.sortValue == SortRoadmap.FAMILY ? (
              this.state.listCms?.content?.map((product, index: number) =>
                this.displayProductsByFamily(product, index)
              )
            ) : (
              <>
                {Object.values(NewProductsETA).map((key, index: number) =>
                  this.displayProductsByDate(key, index)
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(ProductsRoadmap));
