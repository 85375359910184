import "./styles.scss";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, ClickAwayListener } from "@material-ui/core";

import SignInButton from "../../../common/components/signInButton";
import { Routes } from "../../../common/enums/routes.enum";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import { FreeTrial } from "../../../authentication/models/freeTrial.model";
import { config } from "../../../../config";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps {
  burgerClosed: () => void;
}

interface IStates {
  isProfileVisible: boolean;
}

class Profile extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isProfileVisible: false
    };
  }

  private displayFreeTrialMsgContent = (freeTrial: FreeTrial): JSX.Element => {
    const nbDays = freeTrial.nbDays;

    if (freeTrial.isEnded) {
      return (
        <span>
          <FormattedMessage id="freeTrial.ended" />
        </span>
      );
    }

    if (nbDays == 0) {
      return (
        <span>
          {nbDays}
          <FormattedMessage id="freeTrial.xDayLeft" values={{ nbDays }} />
        </span>
      );
    }

    return (
      <span>
        <FormattedMessage id="freeTrial.xDaysLeft" values={{ nbDays }} />
      </span>
    );
  };

  private onProfileClick = (
    isProfileOpen = !this.state.isProfileVisible
  ): void => {
    this.setState({ isProfileVisible: isProfileOpen });
  };

  private onDisconnectClick = (): void => {
    this.props.disconnectUser();
    window.location.replace(config.logoutUrl);
  };

  render() {
    const freeTrial = new FreeTrial(this.props.user);

    return (
      <div className={`profile-container ${this.props.screenType}`}>
        {!!this.props.user ? (
          <>
            {freeTrial.isCommunity && (
              <div>
                <div
                  className={`trial-version ${
                    freeTrial.isEnded ? "ended" : "ready"
                  }`}
                >
                  <span>
                    <FormattedMessage id="freeTrial.trialVersion" />
                  </span>
                  {this.displayFreeTrialMsgContent(freeTrial)}
                </div>
              </div>
            )}
            <ClickAwayListener onClickAway={() => this.onProfileClick(false)}>
              <div className="profile">
                <div
                  className="profile-button circle-name"
                  onClick={() => this.onProfileClick()}
                >
                  {freeTrial.initials}
                </div>

                {this.state.isProfileVisible && (
                  <div className="profile-popup">
                    <div className="circle-name">{freeTrial.initials}</div>
                    <div className="name">
                      {this.props.user.givenName}&nbsp;
                      {this.props.user.familyName.split("{")[0]}
                    </div>
                    <div>{this.props.user.email}</div>
                    <div>{this.props.user.clientName}</div>
                    <Button
                      className="disconnect"
                      onClick={this.onDisconnectClick}
                    >
                      <FormattedMessage id="disconnect" />
                    </Button>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </>
        ) : (
          <div className="header-bottom-sign">
            {this.props.screenType === ScreenType.COMPUTER && (
              <>
                <Button
                  className="getting-stated-button"
                  onClick={() => this.props.history.push(Routes.GETTING_STARTED)}
                >
                  <span className="sign-up">
                    <FormattedMessage id="gettingStarted" />
                  </span>
                </Button>

                <SignInButton />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const mapDispatch = {
  disconnectUser: () => ({
    type: ReducerAction.USER_LOGGED_OUT,
  }),
};

const connector = connect(mapState, mapDispatch);
export default withRouter(connector(Profile));
