import "./styles.scss";

import React, { Component } from "react";

interface IProps {
  familyName: string;
  blockClicked(familyName: string): void;
}

interface IStates {
  blockActived: boolean;
}

class BlockFamily extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      blockActived: false,
    };
  }

  private clockClicked = () => {
    this.setState({ blockActived: !this.state.blockActived });
    this.props.blockClicked(this.props.familyName);
  };

  render() {
    return (
      <div
        id={this.props.familyName}
        onClick={() => this.clockClicked()}
        className={`tagFilter ${this.state.blockActived ? "selected" : ""}`}
      >
        {this.props.familyName}
      </div>
    );
  }
}
export default BlockFamily;
