import { FormattedMessage } from "react-intl";
import React from "react";

import {
  EquipmentLogo,
  TransportExecutionLogo,
  TransportLogo,
} from "../../../assets/icons";
import { IProductsFamily } from "../interfaces/products-family.interface";
import { ProductFamily } from "../enums/product-family.enum";

export const eventFamilies: IProductsFamily[] = [
  {
    id: "equipment",
    productFamily: ProductFamily.EQUIPMENT,
    name: <FormattedMessage id="eventCatalog.equipment" />,
    image: EquipmentLogo,
  },
  {
    id: "shipment",
    productFamily: ProductFamily.SHIPMENT,
    name: <FormattedMessage id="eventCatalog.shipment" />,
    image: TransportExecutionLogo,
  },
  {
    id: "transport",
    productFamily: ProductFamily.TRANSPORT,
    name: <FormattedMessage id="eventCatalog.transport" />,
    image: TransportLogo,
  },
  {
    id: "schedule",
    productFamily: ProductFamily.SCHEDULE,
    name: <FormattedMessage id="eventCatalog.schedule" />,
    image: TransportLogo,
  },
  {
    id: "document",
    productFamily: ProductFamily.DOCUMENT,
    name: <FormattedMessage id="eventCatalog.document" />,
    image: TransportLogo,
  },
  {
    id:"subscription",
    productFamily:ProductFamily.SUBSCRIPTION,
    name:<FormattedMessage id="eventCatalog.subscription"/>,
    image:TransportLogo,
  }
];
