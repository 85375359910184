import "./styles.scss";

import React, { Component } from "react";
// import { CargoEmpty } from "../../../assets/icons";

interface IProps {
  text: JSX.Element;
}

class NotFound extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div className="not-found">
        {/*<CargoEmpty />*/}
        <span>{this.props.text}</span>
      </div>
    );
  }
}

export default NotFound;
