export class Contact {
  name: string;
  email: string;
  role: string;
  primary:boolean;
  constructor(body: any) {
    this.name = body.name;
    this.email = body.email;
    this.role = body.role;
    this.primary = body.primary;
  }
}
