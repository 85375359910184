import "./styles.scss";

import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import InputLabel from "../inputLabel";
import { InputOptions } from "../../models/input-options.model";
import { SelectItem } from "../../../../interfaces/common.interface";
import { TFormInputId } from "../../enums/form.enum";
import Loader from "../../../../components/loader";
import { ELoaderSize } from "../../../../enums/loader.enum";

interface IProps extends WrappedComponentProps {
  id: TFormInputId;
  value: string;
  options: InputOptions;
  items: SelectItem[];
  disabled?: boolean;
  isSearchLoading?: boolean;

  onSearch(value: string): void;
  onChange(value: string): void;
  onBlur(): void;
}

class AutocompleteInput extends Component<IProps> {
  private SEARCH_OPTION = "searchOption";

  private getSelectedItem = (): SelectItem | undefined => {
    if (this.props.value === "") return { name: "", value: "" };

    const selectedItem = this.props.items.find(
      (item: SelectItem) =>
        item.value.toUpperCase() === this.props.value.toUpperCase()
    );

    return selectedItem ?? { name: this.props.value, value: this.props.value };
  };

  render() {
    const placeholder = this.props.options.placeholder
      ? this.props.intl.formatMessage({
          id: this.props.options.placeholder,
        })
      : "";

    const selectedItem = this.getSelectedItem();

    const items = [
      ...this.props.items,
      { name: this.SEARCH_OPTION, value: this.SEARCH_OPTION, disabled: true },
    ];

    return (
      <div className="form-input autocomplete-input">
        <InputLabel options={this.props.options} />

        <Autocomplete
          options={items}
          value={selectedItem ?? null}
          disabled={!!this.props.disabled}
          onChange={(event, value) => this.props.onChange(value?.value ?? "")}
          onBlur={this.props.onBlur}
          onInputChange={(event, value, reason) =>
            reason !== "reset" && this.props.onSearch(value)
          }
          getOptionLabel={(option: SelectItem) => option.name as string}
          getOptionDisabled={(option: SelectItem) => !!option.disabled}
          getOptionSelected={(option: SelectItem, value: SelectItem) => {
            return (
              option.value === value.value ||
              option.value === this.SEARCH_OPTION
            );
          }}
          renderOption={(option: SelectItem) =>
            option.value === this.SEARCH_OPTION ? (
              <Box className="autocomplete-item search-option" />
            ) : (
              <Box
                className={`autocomplete-item ${
                  option.disabled ? "disabled" : ""
                }`}
              >
                {option.disabled && this.props.isSearchLoading && (
                  <Loader size={ELoaderSize.SMALL} />
                )}

                <span>{option.name}</span>
              </Box>
            )
          }
          filterOptions={(options: SelectItem[]) => options}
          renderInput={(params) => (
            <TextField
              className={this.props.options.error ? "error" : ""}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
                placeholder,
              }}
            />
          )}
        />
      </div>
    );
  }
}

export default injectIntl(AutocompleteInput);
