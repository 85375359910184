import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";

import UserAPI from "../../authentication/apis/user.api";
import { UserPost } from "../../authentication/models/user.model";
import { ReducerAction } from "../../reducer/enums/reducer-action.enum";
import { HttpCode } from "../../common/enums/http-code.enum";
import { Routes } from "../../common/enums/routes.enum";
import Loader from "../../common/components/loader";
import ReCaptchaAPI from "../../common/apis/re-captcha.api";
import { CloseIcon, InfoIcon } from "../../../assets/icons";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import {
  EFieldType,
  FormSelectValue,
  GettingStartedInputId,
  TFormInputId,
} from "../../common/submodules/form/enums/form.enum";
import TermsAndConditionsLink from "../../common/components/termsAndConditionsLink";
import ReCaptchaUtils from "../../../utils/re-captcha.utils";
import HeaderBanner from "../../common/components/headerBanner";
import { ToastType } from "../../common/submodules/toast/enums/toast-type.enum";
import ToastAlert from "../../common/submodules/toast/components/toastAlert";
import { IToastAlert } from "../../common/submodules/toast/interfaces/toast-alert.interface";
import { defaultToastAlert } from "../../common/submodules/toast/constants/default-toast-alert.constant";
import { IReducerState } from "../../reducer/interfaces/reducer.interface";
import { Form } from "../../common/submodules/form/models/form.model";
import {
  IField,
  IInputAdditionalParams,
} from "../../common/submodules/form/interfaces/form.interface";
import { FormField } from "../../common/submodules/form/models/form-field.model";
import FormUtils from "../../../utils/form.utils";
import { customerTypes } from "../../crm/constants/crm.constant";
import publicMail from "../constants/public-mail.constant";
import { ELoaderSize } from "../../common/enums/loader.enum";
import { Helmet } from 'react-helmet';
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends RouteComponentProps, PropsFromRedux {}

interface IStates {
  form: Form;
  isUserSaved: boolean;
  areUserBasicInfoEditable: boolean;
  isRegisterLoading: boolean;
  reCaptchaToken: string | undefined;
  isReCaptchaSecretLoading: boolean;
  displayInfo: boolean;
  displayWarning: boolean;
  toast: IToastAlert;
}

class GettingStarted extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      form: new Form(GettingStarted.generateForm()),
      isUserSaved: false,
      areUserBasicInfoEditable: true,
      isRegisterLoading: false,
      reCaptchaToken: undefined,
      isReCaptchaSecretLoading: false,
      displayInfo: true,
      displayWarning: false,
      toast: defaultToastAlert,
    };
  }

  componentDidMount() {
    if (!this.props.reCaptchaSecret) {
      this.setState({ isReCaptchaSecretLoading: true }, () =>
        ReCaptchaAPI.getReCaptchaSecret().then((reCaptchaSecret) => {
          this.props.saveReCaptchaSecret(reCaptchaSecret);
          this.setState({ isReCaptchaSecretLoading: false });
        })
      );
    }

    const form = this.state.form;

    const url = new URL(window.location.href);
    const clientId = {
      id: GettingStartedInputId.CLIENT_ID,
      value: undefined,
    };
    const email = {
      id: GettingStartedInputId.EMAIL,
      value: url.searchParams.get("email"),
    };
    const givenName = {
      id: GettingStartedInputId.GIVEN_NAME,
      value: url.searchParams.get("givenName"),
    };
    const familyName = {
      id: GettingStartedInputId.FAMILY_NAME,
      value: url.searchParams.get("familyName"),
    };

    this.props.history.replace(Routes.GETTING_STARTED);

    let areUserBasicInfoEditable = this.state.areUserBasicInfoEditable;

    let fields: IField[] = [clientId];
    if (email.value && givenName.value && familyName.value) {
      fields = [...fields, email, givenName, familyName];
      areUserBasicInfoEditable = false;
    }

    if (clientId.value) {
      // Set Company fields as non-mandatory if there is a ClientID
      [
        GettingStartedInputId.COMPANY_NAME,
        GettingStartedInputId.COMPANY_TYPE,
        GettingStartedInputId.REPRESENTATIVE_NAME,
        GettingStartedInputId.ADDRESS,
        GettingStartedInputId.COUNTRY_NAME,
        GettingStartedInputId.COUNTRY_CODE,
        GettingStartedInputId.CITY,
        GettingStartedInputId.ZIP_CODE,
      ].forEach((id) => {
        fields.push({ id, options: { isMandatory: false } });
      });
    }

    form.updateFormFields(fields);

    this.setState({
      form,
      areUserBasicInfoEditable,
    });
  }

  private static generateForm(): FormField[] {
    return [
      new FormField(GettingStartedInputId.CLIENT_ID, undefined),
      new FormField(GettingStartedInputId.GIVEN_NAME, "", {
        label: "form.field.firstName",
        placeholder: "form.field.firstName.placeholder",
        isMandatory: true,
      }),
      new FormField(GettingStartedInputId.FAMILY_NAME, "", {
        label: "form.field.lastName",
        placeholder: "form.field.lastName.placeholder",
        isMandatory: true,
      }),
      new FormField(
        GettingStartedInputId.EMAIL,
        "",
        {
          label: "form.field.email",
          placeholder: "form.field.email.placeholder",
          isMandatory: true,
        },
        EFieldType.EMAIL
      ),
      new FormField(GettingStartedInputId.COMPANY_NAME, "", {
        label: "form.field.companyName",
        placeholder: "form.field.companyName.placeholder",
        isMandatory: true,
        isLatinCharacters:true
      }),
      new FormField(
        GettingStartedInputId.COMPANY_TYPE,
        FormSelectValue.PLACEHOLDER,
        {
          label: "form.field.companyType",
          placeholder: "form.field.companyType.placeholder",
          isMandatory: true,
        },
        EFieldType.SELECT
      ),
      new FormField(GettingStartedInputId.REPRESENTATIVE_NAME, "", {
        label: "form.field.businessRepName",
        placeholder: "form.field.businessRepName.placeholder",
        isMandatory: true,
      }),
      new FormField(
        GettingStartedInputId.REPRESENTATIVE_EMAIL,
        "",
        {
          label: "form.field.businessRepEmail",
          placeholder: "form.field.businessRepEmail.placeholder",
        },
        EFieldType.EMAIL
      ),
      new FormField(GettingStartedInputId.ADDRESS, "", {
        label: "form.field.address",
        placeholder: "form.field.address.placeholder",
        isMandatory: true,
      }),
      new FormField(GettingStartedInputId.MORE_ADDRESS, "", {
        label: "form.field.moreAddressInformation",
        placeholder: "form.field.moreAddressInformation.placeholder",
      }),
      new FormField(
        GettingStartedInputId.COUNTRY_NAME,
        FormSelectValue.NONE,
        {
          label: "form.field.country",
          placeholder: "form.field.country.placeholder",
          isMandatory: true,
        },
        EFieldType.COUNTRY
      ),
      new FormField(GettingStartedInputId.COUNTRY_CODE, "", {
        isMandatory: true,
      }),
      new FormField(GettingStartedInputId.CITY, "", {
        label: "form.field.city",
        placeholder: "form.field.city.placeholder",
        isMandatory: true,
      }),
      new FormField(GettingStartedInputId.ZIP_CODE, "", {
        label: "form.field.zipCode",
        placeholder: "form.field.zipCode.placeholder",
        isMandatory: true,
      }),
      new FormField(
        GettingStartedInputId.TERMS_AND_CONDITIONS,
        false,
        {
          label: "gettingStarted.agreed",
          isMandatory: true,
        },
        EFieldType.CHECKBOX
      ),
    ];
  }

  private onConfirmClick = () => {
    if (this.isFormValid() && this.props.reCaptchaSecret) {
      this.setState({ isRegisterLoading: true }, () =>
        ReCaptchaUtils.verifyReCaptcha(
          this.props.reCaptchaSecret as string,
          this.handleVerify
        )
      );
    }
  };

  private isFormValid = (): boolean => {
    const form = this.state.form;
    const isFormValid = form.isFormValid();
    this.setState({ form });
    return isFormValid;
  };

  private verifyField = (
    id: TFormInputId,
    hasPreviousError: boolean,
    isOnChange = false,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    form.updateFormFieldError(
      id,
      hasPreviousError,
      isOnChange,
      additionalParams
    );
    this.setState({ form });
  };

  private onChange = (
    id: TFormInputId,
    value: string | boolean,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    if (id === GettingStartedInputId.EMAIL) {
      this.checkPublicMail(value as string);
    }

    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );
    if (previousFieldState) {
      form.updateFormField({ id, value });

      if (additionalParams.countryCode)
        form.updateFormField({
          id: GettingStartedInputId.COUNTRY_CODE,
          value: additionalParams.countryCode,
        });

      this.setState({ form }, () =>
        this.verifyField(id, !!previousFieldState.options.error, true)
      );
    }
  };

  private onBlur = (id: TFormInputId) => {
    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );

    if (previousFieldState) {
      this.verifyField(id, !!previousFieldState.options.error);
    }
  };

  private handleVerify = (reCaptchaToken: string) => {
    this.setState({ reCaptchaToken }, this.submitForm);
  };

  private submitForm() {
    const userPost = new UserPost(this.state.form);

    UserAPI.postUser(userPost, this.state.reCaptchaToken)
      .then(() => {
        this.setState({
          isUserSaved: true,
          isRegisterLoading: false,
          displayInfo: false,
        });
        this.props.scrollToTop();
      })
      .catch((error) => {
        let type = ToastType.ERROR;
        let message;

        switch (error.response.status) {
          case HttpCode.FORBIDDEN:
            message = <FormattedMessage id="unauthorized" />;
            break;

          case HttpCode.NOT_FOUND:
            message = <FormattedMessage id="clientNotFound" />;
            break;

          case HttpCode.CONFLICT:
            type = ToastType.WARNING;
            message = <FormattedMessage id="signUp.alreadyRegistered" />;
            break;

          default:
            message = <FormattedMessage id="signUp.registrationError" />;
            break;
        }

        this.setState({
          isRegisterLoading: false,
          toast: {
            isDisplayed: true,
            type,
            message,
          },
        });
      });
  }

  private checkPublicMail = (value: string) => {
    if (publicMail.some((elt) => {
      const regex = new RegExp("@" + elt + "$");
      return regex.test(value);
    }))
      this.setState({ displayWarning: true });
    else {
      this.setState({ displayWarning: false });
    }
  };

  private getInput(
    id: TFormInputId,
    additionalParams: IInputAdditionalParams = {}
  ): JSX.Element | null {
    let disabled = false;
    if (
      id === GettingStartedInputId.EMAIL ||
      id === GettingStartedInputId.GIVEN_NAME ||
      id === GettingStartedInputId.FAMILY_NAME
    ) {
      disabled = !this.state.areUserBasicInfoEditable;
    }

    const field = this.state.form.getFormField(id);

    if (field) {
      const countryCode = this.state.form.getFormFieldValue(
        GettingStartedInputId.COUNTRY_CODE
      ) as string;

      return FormUtils.getInput(field, this.onChange, this.onBlur, disabled, {
        ...additionalParams,
        countryCode,
      });
    }

    return null;
  }

  private closeMessage = () => {
    this.setState({ displayInfo: false });
  };

  private closeWarning = () => {
    this.setState({ displayWarning: false });
  };

  private returnToHome = () => this.props.history.push(Routes.HOME);

  private onToastHide = () => {
    this.setState({ toast: defaultToastAlert });
  };

  private getSegmentContent() {
    if (this.state.isReCaptchaSecretLoading) {
      return <Loader size={ELoaderSize.SMALL} />;
    }

    if (!this.props.reCaptchaSecret) {
      return (
        <div className="finalMessage">
          <div>
            <div className="form-title">
              <FormattedMessage id="error.errorOccurred" />
            </div>
            <div className="form-message">
              <FormattedMessage id="error.tryAgainLater" />
            </div>
          </div>
          <div className="button-container">
            <div className="button" onClick={this.returnToHome}>
              <FormattedMessage id="returnToHome" />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.isUserSaved) {
      return (
        <div className="finalMessage">
          <div>
            <div className="form-title">
              <FormattedMessage id="gettingStarted.registrationSent" />
            </div>
            <div className="form-message">
              <FormattedMessage id="gettingStarted.registrationSentMessage" />
            </div>
          </div>
          <div className="button-container">
            <div className="button" onClick={this.returnToHome}>
              <FormattedMessage id="returnToHome" />
            </div>
          </div>
        </div>
      );
    }

    const isClientIdKnown = !!this.state.form.getFormFieldValue(
      GettingStartedInputId.CLIENT_ID
    );

    return (
      <>
        {this.state.displayInfo && !isClientIdKnown && (
          <div className="infoMessage">
            <InfoIcon />
            <div className="contentMessage">
              <span className="bold">
                <FormattedMessage id="gettingStarted.infoMessage1" />
                &nbsp;
              </span>

              <FormattedMessage id="gettingStarted.infoMessage2" />

              <br />

              <span className="italic">
                <FormattedMessage id="gettingStarted.infoMessage3" />
                <TermsAndConditionsLink />.
              </span>
            </div>
            <CloseIcon className="close" onClick={this.closeMessage} />
          </div>
        )}

        {this.state.displayWarning && (
          <div className="infoMessage warning">
            <WarningOutlinedIcon />
            <div className="contentMessage">
              <FormattedMessage id="gettingStarted.warningMessage" />
            </div>
            <CloseIcon className="close" onClick={this.closeWarning} />
          </div>
        )}

        <div className="form-title">
          <FormattedMessage id="userInformation" />
        </div>
        <div className="form-content">
          {this.getInput(GettingStartedInputId.GIVEN_NAME)}
          {this.getInput(GettingStartedInputId.FAMILY_NAME)}
          {this.getInput(GettingStartedInputId.EMAIL)}
        </div>

        {!isClientIdKnown && (
          <>
            <div className="br" />
            <div className="form-title">
              <FormattedMessage id="companyInformation" />
            </div>
            <div className="form-content">
              {this.getInput(GettingStartedInputId.COMPANY_NAME)}
              {this.getInput(GettingStartedInputId.COMPANY_TYPE, {
                selectItems: customerTypes,
              })}
              {this.getInput(GettingStartedInputId.REPRESENTATIVE_NAME)}
              {this.getInput(GettingStartedInputId.REPRESENTATIVE_EMAIL)}
              {this.getInput(GettingStartedInputId.ADDRESS)}
              {this.getInput(GettingStartedInputId.MORE_ADDRESS)}
              {this.getInput(GettingStartedInputId.COUNTRY_NAME)}
              {this.getInput(GettingStartedInputId.CITY)}
              {this.getInput(GettingStartedInputId.ZIP_CODE)}
            </div>
          </>
        )}

        <div className="confirm">
          {this.getInput(GettingStartedInputId.TERMS_AND_CONDITIONS, {
            checkBoxContent: <TermsAndConditionsLink />,
          })}

          <div className="button-container button-sign-up">
            {this.state.toast.isDisplayed && (
              <ToastAlert
                toastType={this.state.toast.type}
                toastMessage={this.state.toast.message}
                hideToast={this.onToastHide}
              />
            )}

            <div
              className={`button${
                this.state.isRegisterLoading ? " disabled" : ""
              }`}
              onClick={this.onConfirmClick}
            >
              {this.state.isRegisterLoading ? (
                <Loader />
              ) : (
                <FormattedMessage id="confirm" />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div id="register-form">
        <Helmet>
          <title>Getting Started - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="gettingStarted" />
        </HeaderBanner>

        <div className="App-content">
          <div className={`contentRegister ${this.props.screenType}`}>
            {this.getSegmentContent()}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  reCaptchaSecret: state.reCaptchaSecret,
  screenType: state.screenType,
});

const mapDispatch = {
  saveReCaptchaSecret: (reCaptchaSecret: string) => ({
    type: ReducerAction.RE_CAPTCHA_TOKEN_FETCH,
    payload: reCaptchaSecret,
  }),
  scrollToTop: () => ({
    type: ReducerAction.SCROLL_TO_TOP,
  }),
};

const connector = connect(mapState, mapDispatch);
export default withRouter(connector(GettingStarted));
