import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect, ConnectedProps } from "react-redux";

import { DeleteIcon } from "../../../../../assets/icons";
import {
  IPackageSelectionForOfferTile,
  Offer,
} from "../../../interfaces/package.interface";
import { OfferSelection } from "../../../enums/crm.enum";
import Utils from "../../../../../utils";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  selectedPackage: IPackageSelectionForOfferTile;
  unitOfMeasure: string;
  deleteChoice(): void;
}

class OfferLine extends Component<IProps> {
  private getOfferInfo = () => {
    const { selectedPackage } = this.props;

    const offer = selectedPackage.offer as Offer;
    const pluralUnit = `${this.props.unitOfMeasure}s`;
    return (
      <div className={`info-package-cell ${offer.name.toLowerCase() === OfferSelection.CUSTOM && "custom-package-cell"}`}>
        <div className="offer-name-cell">
          <span className="cell-content">{offer.name}</span>
        </div>

        <div className="offer-desc-cell">
          <div className="offer-desc cell-content">
            {!!offer.consumptionLimit &&
              !!offer.overconsumptionRate &&
              this.props.screenType != ScreenType.PHONE && (
                <>
                  <div className="info">
                    <FormattedMessage
                      id="offers.consumptionLimit"
                      values={{
                        pluralUnit,
                        consumptionLimit: Utils.formatLargeNumber(
                          offer.consumptionLimit,
                          false
                        ),
                      }}
                    />
                  </div>

                  {!!offer.otherLimits && (
                    <>
                      <div className="separator" />
                      <div>{offer.otherLimits}</div>
                    </>
                  )}

                  <div className="separator" />
                  <div className="info">
                    {offer.name.toLowerCase() !== OfferSelection.CUSTOM ? <FormattedMessage
                      id="offers.overconsumptionRate"
                      values={{
                        unitOfMeasure: this.props.unitOfMeasure,
                        overconsumptionRate: offer.overconsumptionRate,
                      }}
                    /> : 
                    <div>
                    <span >
                      <FormattedMessage id="offers.custom" /> 
                    </span>
                    &nbsp;
                    <span>
                      <FormattedMessage id="offers.prices" />
                    </span>
                    </div>}
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    );
  };

  private getOfferPricing = () => {
    const offer = this.props.selectedPackage.offer as Offer;
    if (offer.name.toLowerCase() !== OfferSelection.CUSTOM && offer.pricing) {
      return (
        <div className="price-cell">
          <div className="price-element cell-content">
            <div className="value">
              <FormattedNumber
                value={+offer.pricing.amount}
                style="currency"
                currency={offer.pricing.currency}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </div>
            <div className="unit">
              <FormattedMessage id="offers.perMonth" />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const offer = this.props.selectedPackage.offer as Offer;
    return (
      <div className={`offer-line ${this.props.screenType}`}>
        <div className="offer-tile">
          <div className="package-name-cell">
            <span className="cell-content">
              {this.props.selectedPackage.packageName}
            </span>
          </div>

          {this.props.screenType == ScreenType.COMPUTER && this.getOfferInfo()}
          {this.props.screenType == ScreenType.TABLET && (
            <div className="info-package-cell">
              <div className="offer-name-cell">
                <span className="cell-content">{offer.name}</span>
              </div>
            </div>
          )}

          {this.props.screenType != ScreenType.PHONE && this.getOfferPricing()}

          <div className="delete-cell">
            <div
              className="delete cell-content"
              onClick={this.props.deleteChoice}
            >
              {this.props.screenType != ScreenType.PHONE && (
                <FormattedMessage id="delete" />
              )}
              <DeleteIcon className="trash" />
            </div>
          </div>
        </div>
        <div className="infos-detail">
          {this.props.screenType != ScreenType.COMPUTER && this.getOfferInfo()}
          {this.props.screenType == ScreenType.PHONE && this.getOfferPricing()}
        </div>
      </div>
    );
  }
}
const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default connector(OfferLine);
