import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { Button, FormControlLabel, Radio } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import { ToastType } from "../../../common/submodules/toast/enums/toast-type.enum";
import { SubscriptionRequestDTO } from "../../models/subscription-request.model";
import { LocalStorageEnum } from "../../../common/enums/storage.enum";
import {
  EFieldType,
  FormSelectValue,
  SubscriptionRequestInputId,
  TFormInputId,
} from "../../../common/submodules/form/enums/form.enum";
import CrmAPI from "../../apis/crm.api";
import Loader from "../../../common/components/loader";
import { customerTypes } from "../../constants/crm.constant";
import { IPackage } from "../../interfaces/package.interface";
import { Routes } from "../../../common/enums/routes.enum";
import ToastAlert from "../../../common/submodules/toast/components/toastAlert";
import { IToastAlert } from "../../../common/submodules/toast/interfaces/toast-alert.interface";
import { defaultToastAlert } from "../../../common/submodules/toast/constants/default-toast-alert.constant";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { FormField } from "../../../common/submodules/form/models/form-field.model";
import { IInputAdditionalParams } from "../../../common/submodules/form/interfaces/form.interface";
import FormUtils from "../../../../utils/form.utils";
import { Form } from "../../../common/submodules/form/models/form.model";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps {
  packages: IPackage[];
  isOnlyCustomOffers: boolean;
  onPreviousClick: () => void;
}

interface IStates {
  form: Form;
  sameAddressSelected: boolean;
  isSubmitLoading: boolean;
  toast: IToastAlert;
}

class Subscribe extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      form: new Form(this.generateForm()),
      sameAddressSelected: true,
      isSubmitLoading: false,
      toast: defaultToastAlert,
    };
  }

  private generateForm(): FormField[] {
    let firstName = "";
    let lastName = "";
    let email = "";
    let companyName = "";
    let companyType: string = FormSelectValue.PLACEHOLDER;
    let businessRepName = "";
    let businessRepEmail = "";
    let address = "";
    let moreAddressInfo = "";
    let countryName: string = FormSelectValue.NONE;
    let countryCode = "";
    let city = "";
    let zipCode = "";

    if (this.props.user) {
      firstName = this.props.user.givenName;
      lastName = this.props.user.familyName;
      email = this.props.user.email;
      companyName = this.props.user.clientRequestName;

      const businessName = this.props.user.clientBusinessProfile;
      const foundCustomerType = customerTypes.find(
        (elt) => elt.name == businessName
      );
      if (foundCustomerType) companyType = foundCustomerType.value;

      businessRepName = this.props.user.clientRepresentativeName;
      businessRepEmail = this.props.user.clientRepresentativeEmail || "";

      if (this.props.user.clientAddress) {
        address = this.props.user.clientAddress.addressLine1;
        moreAddressInfo = this.props.user.clientAddress.addressLine2;
        countryName = this.props.user.clientAddress.country;
        countryCode = this.props.user.clientAddress.countryCode;
        city = this.props.user.clientAddress.city;
        zipCode = this.props.user.clientAddress.zipCode;
      }
    }

    let isBillingRepMandatory = true;
    if (this.props.isOnlyCustomOffers) {
      isBillingRepMandatory = false;
    }

    return [
      new FormField(SubscriptionRequestInputId.FIRST_NAME, firstName, {
        label: "form.field.firstName",
        placeholder: "form.field.firstName.placeholder",
        isMandatory: true,
      }),
      new FormField(SubscriptionRequestInputId.LAST_NAME, lastName, {
        label: "form.field.lastName",
        placeholder: "form.field.lastName.placeholder",
        isMandatory: true,
      }),
      new FormField(
        SubscriptionRequestInputId.EMAIL,
        email,
        {
          label: "form.field.email",
          placeholder: "form.field.email.placeholder",
          isMandatory: true,
        },
        EFieldType.EMAIL
      ),
      new FormField(
        SubscriptionRequestInputId.PHONE,
        "",
        {
          label: "form.field.phone",
          isMandatory: true,
        },
        EFieldType.PHONE
      ),
      new FormField(SubscriptionRequestInputId.PHONE_COUNTRY_CODE, "fr", {
        isMandatory: true,
      }),
      new FormField(SubscriptionRequestInputId.PHONE_COUNTRY_DIAL_CODE, 33, {
        isMandatory: true,
      }),
      new FormField(SubscriptionRequestInputId.COMPANY_NAME, companyName, {
        label: "form.field.companyName",
        placeholder: "form.field.companyName.placeholder",
        isMandatory: true,
      }),
      new FormField(
        SubscriptionRequestInputId.COMPANY_TYPE,
        companyType,
        {
          label: "form.field.companyType",
          placeholder: "form.field.companyType.placeholder",
          isMandatory: true,
        },
        EFieldType.SELECT
      ),
      new FormField(
        SubscriptionRequestInputId.REPRESENTATIVE_NAME,
        businessRepName,
        {
          label: "form.field.businessRepName",
          placeholder: "form.field.businessRepName.placeholder",
          isMandatory: true,
        }
      ),
      new FormField(
        SubscriptionRequestInputId.REPRESENTATIVE_EMAIL,
        businessRepEmail,
        {
          label: "form.field.businessRepEmail",
          placeholder: "form.field.businessRepEmail.placeholder",
        },
        EFieldType.EMAIL
      ),
      new FormField(SubscriptionRequestInputId.ADDRESS, address, {
        label: "form.field.address",
        placeholder: "form.field.address.placeholder",
        isMandatory: true,
      }),
      new FormField(SubscriptionRequestInputId.MORE_ADDRESS, moreAddressInfo, {
        label: "form.field.moreAddressInformation",
        placeholder: "form.field.moreAddressInformation.placeholder",
      }),
      new FormField(
        SubscriptionRequestInputId.COUNTRY_NAME,
        countryName,
        {
          label: "form.field.country",
          placeholder: "form.field.country.placeholder",
          isMandatory: true,
        },
        EFieldType.COUNTRY
      ),
      new FormField(SubscriptionRequestInputId.COUNTRY_CODE, countryCode, {
        isMandatory: true,
      }),
      new FormField(SubscriptionRequestInputId.CITY, city, {
        label: "form.field.city",
        placeholder: "form.field.city.placeholder",
        isMandatory: true,
      }),
      new FormField(SubscriptionRequestInputId.ZIP_CODE, zipCode, {
        label: "form.field.zipCode",
        placeholder: "form.field.zipCode.placeholder",
        isMandatory: true,
      }),
      new FormField(
        SubscriptionRequestInputId.BILLING_REPRESENTATIVE_NAME,
        "",
        {
          label: "subscriptionRequest.field.billingRepName",
          placeholder: "subscriptionRequest.field.billingRepName.placeholder",
          isMandatory: isBillingRepMandatory,
        }
      ),
      new FormField(
        SubscriptionRequestInputId.BILLING_REPRESENTATIVE_EMAIL,
        "",
        {
          label: "subscriptionRequest.field.billingRepEmail",
          placeholder: "subscriptionRequest.field.billingRepEmail.placeholder",
          isMandatory: isBillingRepMandatory,
        },
        EFieldType.EMAIL
      ),
      new FormField(SubscriptionRequestInputId.VAT_NUMBER, "", {
        label: "form.field.vatNumber",
        placeholder: "form.field.vatNumber.placeholder",
      }),
      new FormField(SubscriptionRequestInputId.BILLING_ADDRESS, "", {
        label: "subscriptionRequest.field.billingAddress",
        placeholder: "subscriptionRequest.field.billingAddress.placeholder",
      }),
      new FormField(SubscriptionRequestInputId.BILLING_MORE_ADDRESS, "", {
        label: "form.field.moreAddressInformation",
        placeholder: "form.field.moreAddressInformation.placeholder",
      }),
      new FormField(
        SubscriptionRequestInputId.BILLING_COUNTRY_NAME,
        FormSelectValue.NONE,
        {
          label: "form.field.country",
          placeholder: "form.field.country.placeholder",
        },
        EFieldType.COUNTRY
      ),
      new FormField(
        SubscriptionRequestInputId.BILLING_COUNTRY_CODE,
        countryCode
      ),
      new FormField(SubscriptionRequestInputId.BILLING_CITY, "", {
        label: "form.field.city",
        placeholder: "form.field.city.placeholder",
      }),
      new FormField(SubscriptionRequestInputId.BILLING_ZIP_CODE, "", {
        label: "form.field.zipCode",
        placeholder: "form.field.zipCode.placeholder",
      }),
    ];
  }

  private submitPage = () => {
    if (this.isFormValid()) {
      const subscriptionRequest = new SubscriptionRequestDTO(
        this.state.form,
        this.state.sameAddressSelected,
        this.props.isOnlyCustomOffers,
        this.props.packages,
        JSON.parse(
          localStorage.getItem(LocalStorageEnum.PACKAGE_SELECTION) || "[]"
        )
      );

      this.setState({ isSubmitLoading: true }, () => {
        CrmAPI.postSubscriptionRequest(subscriptionRequest)
          .then(() => {
            this.setState({ isSubmitLoading: false });
            localStorage.removeItem(LocalStorageEnum.PACKAGE_SELECTION);
            this.props.history.push(Routes.REQUEST_SUBMITTED);
          })
          .catch(() => {
            this.setState({
              isSubmitLoading: false,
              toast: {
                isDisplayed: true,
                type: ToastType.ERROR,
                message: <FormattedMessage id="error.errorOccurredFull" />,
              },
            });
          });
      });
    }
  };

  private verifyField = (
    id: TFormInputId,
    hasPreviousError: boolean,
    isOnChange = false,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    form.updateFormFieldError(
      id,
      hasPreviousError,
      isOnChange,
      additionalParams
    );
    this.setState({ form });
  };

  private onChange = (
    id: TFormInputId,
    value: string | boolean,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;

    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );
    if (previousFieldState) {
      form.updateFormField({ id, value });

      if (additionalParams.countryCode)
        form.updateFormField({
          id: SubscriptionRequestInputId.COUNTRY_CODE,
          value: additionalParams.countryCode,
        });

      if (additionalParams.phoneCountryCode)
        form.updateFormField({
          id: SubscriptionRequestInputId.PHONE_COUNTRY_CODE,
          value: additionalParams.phoneCountryCode,
        });

      if (additionalParams.phoneCountryDialCode)
        form.updateFormField({
          id: SubscriptionRequestInputId.PHONE_COUNTRY_DIAL_CODE,
          value: additionalParams.phoneCountryDialCode,
        });

      this.setState({ form }, () =>
        this.verifyField(
          id,
          !!previousFieldState.options.error,
          true,
          additionalParams
        )
      );
    }
  };

  private onBlur = (id: TFormInputId) => {
    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );

    const phoneCountryCode = this.state.form.getFormFieldValue(
      SubscriptionRequestInputId.PHONE_COUNTRY_CODE
    ) as string;

    if (previousFieldState) {
      this.verifyField(id, !!previousFieldState.options.error, false, {
        phoneCountryCode,
      });
    }
  };

  private isFormValid(): boolean {
    const form = this.state.form;

    const phoneCountryCode = this.state.form.getFormFieldValue(
      SubscriptionRequestInputId.PHONE_COUNTRY_CODE
    ) as string;

    const isFormValid = form.isFormValid({ phoneCountryCode });
    this.setState({ form });
    return isFormValid;
  }

  private changeSameAddressSelected = (sameAddressSelected: boolean) => {
    const form = this.state.form;
    const options = { isMandatory: !sameAddressSelected };

    form.updateFormFields([
      { id: SubscriptionRequestInputId.BILLING_ADDRESS, options },
      { id: SubscriptionRequestInputId.BILLING_COUNTRY_NAME, options },
      { id: SubscriptionRequestInputId.BILLING_COUNTRY_CODE, options },
      { id: SubscriptionRequestInputId.BILLING_CITY, options },
      { id: SubscriptionRequestInputId.BILLING_ZIP_CODE, options },
    ]);

    this.setState({ form, sameAddressSelected });
  };

  private getInput(
    id: TFormInputId,
    additionalParams: IInputAdditionalParams = {}
  ): JSX.Element | null {
    const disabled =
      !!this.props.user &&
      (id === SubscriptionRequestInputId.EMAIL ||
        id === SubscriptionRequestInputId.FIRST_NAME ||
        id === SubscriptionRequestInputId.LAST_NAME ||
        id === SubscriptionRequestInputId.COMPANY_NAME);

    const field = this.state.form.getFormField(id);

    if (field) {
      const countryCode = this.state.form.getFormFieldValue(
        SubscriptionRequestInputId.COUNTRY_CODE
      ) as string;

      const phoneCountryCode = this.state.form.getFormFieldValue(
        SubscriptionRequestInputId.PHONE_COUNTRY_CODE
      ) as string;

      const phoneCountryDialCode = this.state.form.getFormFieldValue(
        SubscriptionRequestInputId.PHONE_COUNTRY_DIAL_CODE
      ) as number;

      return FormUtils.getInput(field, this.onChange, this.onBlur, disabled, {
        ...additionalParams,
        countryCode,
        phoneCountryCode,
        phoneCountryDialCode,
      });
    }

    return null;
  }

  private onToastHide = () => {
    this.setState({ toast: defaultToastAlert });
  };

  render() {
    return (
      <div id="subscription-request" className={this.props.screenType}>
        <div className="block">
          <div className="title">
            <FormattedMessage id="userInformation" />
          </div>

          <div className="block-content">
            {this.getInput(SubscriptionRequestInputId.FIRST_NAME)}
            {this.getInput(SubscriptionRequestInputId.LAST_NAME)}
            {this.getInput(SubscriptionRequestInputId.EMAIL)}
            {this.getInput(SubscriptionRequestInputId.PHONE)}
          </div>
        </div>

        <div className="block">
          <div className="title">
            <FormattedMessage id="companyInformation" />
          </div>

          <div className="block-content">
            {this.getInput(SubscriptionRequestInputId.COMPANY_NAME)}
            {this.getInput(SubscriptionRequestInputId.COMPANY_TYPE, {
              selectItems: customerTypes,
            })}
            {this.getInput(SubscriptionRequestInputId.REPRESENTATIVE_NAME)}
            {this.getInput(SubscriptionRequestInputId.REPRESENTATIVE_EMAIL)}
            {this.getInput(SubscriptionRequestInputId.ADDRESS)}
            {this.getInput(SubscriptionRequestInputId.MORE_ADDRESS)}
            {this.getInput(SubscriptionRequestInputId.COUNTRY_NAME)}
            {this.getInput(SubscriptionRequestInputId.CITY)}
            {this.getInput(SubscriptionRequestInputId.ZIP_CODE)}
          </div>
        </div>

        {!this.props.isOnlyCustomOffers && (
          <div className="block">
            <div className="title">
              <FormattedMessage id="subscriptionRequest.billingInformation" />
            </div>

            <div className="block-content">
              {this.getInput(
                SubscriptionRequestInputId.BILLING_REPRESENTATIVE_NAME
              )}
              {this.getInput(
                SubscriptionRequestInputId.BILLING_REPRESENTATIVE_EMAIL
              )}
              {this.getInput(SubscriptionRequestInputId.VAT_NUMBER)}
            </div>

            <div className="title small">
              <FormattedMessage id="subscriptionRequest.billingAddress" />
            </div>

            <div className="radio-container">
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.sameAddressSelected}
                    onChange={() => this.changeSameAddressSelected(true)}
                  />
                }
                label={
                  <FormattedMessage id="subscriptionRequest.useSameAddress" />
                }
              />

              <FormControlLabel
                control={
                  <Radio
                    checked={!this.state.sameAddressSelected}
                    onChange={() => this.changeSameAddressSelected(false)}
                  />
                }
                label={
                  <FormattedMessage id="subscriptionRequest.useOtherAddress" />
                }
              />
            </div>

            {!this.state.sameAddressSelected && (
              <div className="block-content">
                {this.getInput(SubscriptionRequestInputId.BILLING_ADDRESS)}
                {this.getInput(SubscriptionRequestInputId.BILLING_MORE_ADDRESS)}
                {this.getInput(SubscriptionRequestInputId.BILLING_COUNTRY_NAME)}
                {this.getInput(SubscriptionRequestInputId.BILLING_CITY)}
                {this.getInput(SubscriptionRequestInputId.BILLING_ZIP_CODE)}
              </div>
            )}
          </div>
        )}

        <div className="buttons-container">
          {this.state.toast.isDisplayed && (
            <ToastAlert
              toastType={this.state.toast.type}
              toastMessage={this.state.toast.message}
              hideToast={this.onToastHide}
            />
          )}

          <div className="buttons">
            <Button
              className="button inverted previous"
              onClick={this.props.onPreviousClick}
            >
              <ChevronLeft />
              <span>
                <FormattedMessage id="previous" />
              </span>
            </Button>

            <Button
              className="button confirm"
              onClick={this.submitPage}
              disabled={this.state.isSubmitLoading}
            >
              {this.state.isSubmitLoading ? (
                <Loader />
              ) : (
                <FormattedMessage id="subscriptionRequest.confirm" />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(Subscribe));
