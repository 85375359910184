import "./styles.scss";

import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import { TFormInputId, TFormValue } from "../../enums/form.enum";
import { InputOptions } from "../../models/input-options.model";
import CustomTooltip from "../../../../components/customTooltip";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import FormUtils from "../../../../../../utils/form.utils";

interface IProps extends WrappedComponentProps {
  id: TFormInputId;
  value: TFormValue;
  options: InputOptions;

  onChange(value: boolean): void;
  onBlur(): void;
}

class CheckboxInput extends React.Component<IProps> {
  render() {
    const label = this.props.intl.formatMessage({
      id: this.props.options.label,
    });

    const errorTooltip = !!this.props.options.error
      ? FormUtils.getMessageFromError(this.props.options.error.message)
      : "";

    return (
      <span className="checkbox-input">
        <span>
          <FormControlLabel
            control={
              <Checkbox
                id="terms-conditions-checkbox"
                className={this.props.options.error ? "error" : ""}
                checked={this.props.value as boolean}
                onChange={() => {
                  this.props.onChange(!this.props.value);
                }}
              />
            }
            label={label}
          />

          {this.props.children}
        </span>

        <CustomTooltip title={errorTooltip} error={!!this.props.options.error}>
          <ErrorOutlinedIcon
            className={`error-icon ${
              this.props.options.error ? "visible" : ""
            }`}
          />
        </CustomTooltip>
      </span>
    );
  }
}

export default injectIntl(CheckboxInput);
