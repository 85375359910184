import { EndpointSearchResult } from "../models/endpoints-search.model";
import HttpUtils from "../../../utils/http.utils";

export default class EndpointSearchApi {
  public static getEndpointSearch(
    search: string
  ): Promise<EndpointSearchResult[]> {
    return HttpUtils.get(
      `/endpoint-search${HttpUtils.formatUrlQueryParams({
        search,
      })}`
    ).then((res: any) =>
      res.map((data: Object[]) => new EndpointSearchResult(data))
    );
  }
}
