import { Language, ReducerAction } from "./enums/reducer-action.enum";
import { IReducerState } from "./interfaces/reducer.interface";
import { defaultState } from "./constants/reducer.constant";
import { ScreenSize, ScreenType } from "../common/enums/screen-type.enum";
import ReCaptchaUtils from "../../utils/re-captcha.utils";

function reducer(
  state = defaultState,
  action: { type: ReducerAction; payload: any }
): IReducerState {
  switch (action.type) {
    case ReducerAction.RESIZE_HANDLE:
      const windowWidth = window.innerWidth;
      let screenType = ScreenType.COMPUTER;
      if(windowWidth<ScreenSize.SMALL_PHONE){
        screenType=ScreenType.SMALL_PHONE;
      }
      else if (windowWidth < ScreenSize.PHONE) {
        screenType = ScreenType.PHONE;
      } else if (windowWidth < ScreenSize.TABLET) {
        screenType = ScreenType.TABLET;
      }

      return {
        ...state,
        screenType,
        windowWidth,
      };

    case ReducerAction.EN_LANGUAGE:
      return {
        ...state,
        locale: Language.EN,
      };

    case ReducerAction.FR_LANGUAGE:
      return {
        ...state,
        locale: Language.FR,
      };

    case ReducerAction.USER_LOGGED_IN:
      return {
        ...state,
        user: action.payload,
      };

    case ReducerAction.USER_LOGGED_OUT:
      return {
        ...state,
        user: undefined,
      };

    case ReducerAction.TOAST:
      return {
        ...state,
        toastType: action.payload.toastType,
        toastMessage: action.payload.toastMessage,
      };

    case ReducerAction.RE_CAPTCHA_TOKEN_FETCH:
      ReCaptchaUtils.setRecaptchaScript(action.payload);

      return {
        ...state,
        reCaptchaSecret: action.payload,
      };

    case ReducerAction.ADDED_OPEN_REQUEST_PARTNER:
      return {
        ...state,
        hasOpenRequestPartnerBeenAdded: action.payload,
      };

    case ReducerAction.COUNTRIES_FETCH:
      return {
        ...state,
        countries: action.payload,
      };

    case ReducerAction.OPEN_DESCRIPTION_PANEL:
      const descriptionPanel = {
        id: action.payload.id,
        productType: action.payload.productType,
      };

      return {
        ...state,
        descriptionPanel,
      };

    case ReducerAction.CLOSE_DESCRIPTION_PANEL:
      return {
        ...state,
        descriptionPanel: undefined,
      };

    case ReducerAction.SCROLL_TO_TOP:
      return {
        ...state,
        scrollControl: !state.scrollControl,
      };

    default:
      return state;
  }
}

export default reducer;
