import "./styles.scss";

import React, { Component } from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { Helmet } from 'react-helmet';
import { Tabs, Tab } from "@material-ui/core";
import ReportIssue from "./reportIssue";
import BusinessRequest from "./businessRequest";
import { Routes } from "../../common/enums/routes.enum";
import HeaderBanner from "../../common/components/headerBanner";

enum ActiveTab {
  BUSINESS_REQUEST,
  REPORT_ISSUE,
}

interface IProps extends RouteComponentProps {}
interface IStates {
  activeTab: ActiveTab | boolean;
}

class ContactUs extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes(Routes.REPORT_ISSUE)) {
      this.onTabClick(ActiveTab.REPORT_ISSUE);
    } else {
      this.onTabClick(ActiveTab.BUSINESS_REQUEST);
    }
  }

  private onTabClick = (activeTab: ActiveTab) => {
    if (this.state.activeTab !== activeTab) {
      this.setState({ activeTab });

      this.props.history.push(
        activeTab === ActiveTab.BUSINESS_REQUEST
          ? Routes.BUSINESS_REQUEST
          : Routes.REPORT_ISSUE
      );
    }
  };

  private getTab = (tab: ActiveTab) => {
    const title =
      tab === ActiveTab.REPORT_ISSUE ? (
        <FormattedMessage id="contactUs.reportIssue" />
      ) : (
        <FormattedMessage id="contactUs.businessRequest" />
      );

    return (
      <Tab
        className={`tab ${this.state.activeTab === tab ? "active" : ""}`}
        label={title}
        value={tab}
        onClick={() => this.onTabClick(tab)}
      />
    );
  };

  render() {
    return (
      <div id="contact-us">
        <Helmet>
          <title>Contact Us - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="contactUs" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content-container">
            <div className="title">
              <Tabs value={this.state.activeTab}>
                {this.getTab(ActiveTab.BUSINESS_REQUEST)}
                {this.getTab(ActiveTab.REPORT_ISSUE)}
              </Tabs>
            </div>
            <div className="content">
              <Switch>
                <Route
                  path={Routes.BUSINESS_REQUEST}
                  component={BusinessRequest}
                />
                <Route path={Routes.REPORT_ISSUE} component={ReportIssue} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContactUs);
