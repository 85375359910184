export enum ScreenType {
  SMALL_PHONE="small-phone",
  PHONE = "phone",
  TABLET = "tablet",
  COMPUTER = "computer",
}

export enum ScreenSize {
  SMALL_PHONE = 500,
  PHONE = 800,
  TABLET = 1300,
}
