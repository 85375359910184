import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import TermsAndConditionsLink from "../../../common/components/termsAndConditionsLink";
import HeaderBanner from "../../../common/components/headerBanner";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import GuideAPI from "../../../common/apis/guide.api"; 
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux,RouteComponentProps {}
interface IStates {guideUrl: string;}
class StartingGuide extends Component<IProps,IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      guideUrl: '',
    };
  }

  componentDidMount() {
    GuideAPI.getGuideUrl().then((url) => {
        this.setState({
          guideUrl: url,
        });
      });
  }
  render() {

    const test=true;
    return (
      <div id="startingGuide">
        <Helmet>
          <title>Help - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="startingGuide.head.title" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content-container">
            <div className="content">
              <div className="category api-usage">
                <div className="header">
                  <FormattedMessage id="startingGuide.header1" />
                </div>
                <div className="header large">
                  <FormattedMessage id="startingGuide.header2" />
                </div>

                <div className="subcategory">
                  <div className="startingGuide">
                    <div className="title">
                      <div className="circle">
                        <div className="number">1</div>
                      </div>
                      <FormattedMessage id="startingGuide.guide.message1" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message2" />
                    </div>

                    <div className="title">
                      <div className="circle">
                        <div className="number">2</div>
                      </div>
                      <FormattedMessage id="startingGuide.guide.message3" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message4" />
                    </div>
                    <ul>
                      <li className="message">
                        <FormattedMessage id="startingGuide.guide.message5" />
                      </li>
                      <li className="message">
                        <FormattedMessage id="startingGuide.guide.message6" />
                      </li>
                    </ul>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message7.1" />
                      <TermsAndConditionsLink />
                      <FormattedMessage id="startingGuide.guide.message7.2" />
                    </div>

                    <div className="title ">
                      <div className="circle">
                        <div className="number">3</div>
                      </div>
                      <FormattedMessage id="startingGuide.guide.message8" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message9" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message10" />
                    </div>

                    <div className="title">
                      <div className="circle">
                        <div className="number">4</div>
                      </div>
                      <FormattedMessage id="startingGuide.guide.message11" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message12" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message13" />
                    </div>

                    <div className="title">
                      <div className="circle">
                        <div className="number">5</div>
                      </div>
                      <FormattedMessage id="startingGuide.guide.message14" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message15" />
                    </div>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message16" />
                    </div>
                    <ul>
                      <li className="message">
                        {!!this.props.user?
                        (<FormattedMessage
                          id="startingGuide.guide.message17"
                          values={{
                            a: (chunks: string) => (
                              <a
                                href={`${this.state.guideUrl}api-applications`}
                              >
                                {chunks}
                              </a>
                            ),}}
                        />):(
                          <FormattedMessage
  id="startingGuide.guide.message17"
  values={{
    a: (chunks: string) => (
      
        chunks
    ),}}
/>
                        )  
                      }
                      
                      </li>
                      <li className="message">
                      {!!this.props.user?
                        (<FormattedMessage
                          id="startingGuide.guide.message18"
                          values={{
                            a: (chunks: string) => (
                              <a
                                href={`${this.state.guideUrl}client-settings`}
                              >
                                {chunks}
                              </a>
                            ),}}
                        />):(
                          <FormattedMessage
  id="startingGuide.guide.message18"
  values={{
    a: (chunks: string) => (
      
        chunks
    ),}}
/>
                        )  
                      }
                      </li>
                    </ul>
                    <div className="message">
                      <FormattedMessage id="startingGuide.guide.message19" />
                    </div>
                    <div className="message italic">
                      <FormattedMessage id="startingGuide.guide.message20" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const mapDispatch = {
  disconnectUser: () => ({
    type: ReducerAction.USER_LOGGED_OUT,
  }),
};
const connector = connect(mapState, mapDispatch);
export default withRouter(connector(StartingGuide));
