const chartColors = [
  "#DF9CC7",
  "#0294E9",
  "#D00A07",
  "#005E68",
  "#1f8e17",
  "#cb6a01",
  "#854eb9",
  "#bd55a3",
  "#d3fcb1",
  "#4b83d5",
];

export default chartColors;
