import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { IProductsFamily } from "../../../../productCatalog/interfaces/products-family.interface";

interface IProps extends RouteComponentProps {
  api: IProductsFamily;
}

class ApiFamilyTile extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  private onExploreClick = () =>
    this.props.history.push(`/products/${this.props.api.id}`);

  render() {
    return (
      <div className="api-family-tile" onClick={this.onExploreClick}>
        {this.props.api.image && (
          <div className="image-container">
            <this.props.api.image className="image" />
          </div>
        )}
        <div className="content-tile">
          <div className="name">{this.props.api.name}</div>
          <div className="explore">
            <div className="more">+</div>
            <FormattedMessage id="explore" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ApiFamilyTile);
