import "./styles.scss";

import React, { Component, createRef } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { injectIntl, WrappedComponentProps } from "react-intl";

import { SearchIcon } from "../../../../../../assets/icons";

interface IRouteProps {}

interface IProps
  extends RouteComponentProps<IRouteProps>,
    WrappedComponentProps {
  isAsynchronous: boolean;
  searchLabel?: string;
  defaultSearchValue?: string;
  isInputDefaultHidden?: boolean;
  onSearch(searchValue: string): void;
}

interface IStates {
  searchValue: string;
  isFocussed: boolean;
}

class SearchInput extends Component<IProps, IStates> {
  private timeout: NodeJS.Timeout | null = null;
  private inputRef = createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: this.props.defaultSearchValue ?? "",
      isFocussed: false,
    };
  }

  private onSearch = (searchValue: string) => {
    if (!!this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.setState({ searchValue });

    if (this.props.isAsynchronous) {
      this.timeout = setTimeout(() => this.props.onSearch(searchValue), 800);
    } else {
      this.props.onSearch(searchValue);
    }
  };

  private onIconClick = () => {
    if (this.props.isInputDefaultHidden && !this.state.isFocussed) {
      this.setState({ isFocussed: true });
    }
  };

  render() {
    const searchInputPlaceholder = this.props.intl.formatMessage({
      id: "search",
    });

    return (
      <div
        id="search-input"
        className={`${
          this.props.isInputDefaultHidden ? "is-default-hidden" : ""
        } ${this.state.isFocussed ? "focussed" : ""}`}
      >
        <input
          ref={this.inputRef}
          value={this.state.searchValue}
          placeholder={this.props.searchLabel ?? searchInputPlaceholder}
          onChange={(event) => this.onSearch(event.target.value)}
        />
        <SearchIcon onClick={this.onIconClick} />
      </div>
    );
  }
}

export default withRouter(injectIntl(SearchInput));
