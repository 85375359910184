import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import { Events } from "../../../../models/event.model";
import EventsTable from "../../../../../common/components/eventsTable";
import { IProductsFamily } from "../../../../interfaces/products-family.interface";
import Segment from "../../../../../common/components/segment";
import Section from "../../../../../common/components/section";
import EventAPI from "../../../../apis/events.api";
import { eventFamilies } from "../../../../constants/event-family.constant";
import Loader from "../../../../../common/components/loader";
import { ProductFamily } from "../../../../enums/product-family.enum";

interface IProps {
  isLoading: boolean;
  toggleIsLoading(): void;
  toggleHasData?(hasData: boolean): void;
}

interface IStates {
  events: Events | undefined;
}

class AvailableEvents extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      events: undefined,
    };
  }

  componentDidMount() {
    EventAPI.getAvailableEvents()
      .then((events) => {
        this.setState({ events });
        this.props.toggleIsLoading();

        !!this.props.toggleHasData &&
          this.props.toggleHasData(this.hasData(events));
      })
      .catch(() => this.props.toggleIsLoading());
  }

  private hasData = (events: Events | undefined): boolean => {
    if (!events) return false;

    return (
      events.equipment.length > 0 ||
      events.shipment.length > 0 ||
      events.transport.length > 0
    );
  };

  private getSection = (eventFamily: IProductsFamily): JSX.Element | null => {
    let events: any = [];

    if (this.state.events) {
      switch (eventFamily.productFamily) {
        case ProductFamily.EQUIPMENT:
          events = this.state.events.equipment;
          break;

        case ProductFamily.SHIPMENT:
          events = this.state.events.shipment;
          break;

        case ProductFamily.TRANSPORT:
          events = this.state.events.transport;
          break;

        case ProductFamily.DOCUMENTS:
          events = this.state.events.documents;
          break;

        case ProductFamily.SCHEDULES:
          events = this.state.events.schedules;
          break;  
      }

      if (events.length > 0) {
        return (
          <Section
            key={`events-section-${eventFamily.id}`}
            title={eventFamily.name}
          >
            <EventsTable events={events} familyName={eventFamily.id} />
          </Section>
        );
      }
    }

    return null;
  };

  render() {
    if (this.props.isLoading) {
      return <Loader />;
    }

    if (!this.hasData(this.state.events)) {
      return null;
    }

    return (
      <Segment title={<FormattedMessage id="eventDesc.availableEvents" />}>
        {eventFamilies.map(this.getSection)}
      </Segment>
    );
  }
}

export default AvailableEvents;
