import "./styles.scss";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { EndpointSearchResult } from "../../../models/endpoints-search.model";
import BracedTitle from "../../../../common/components/bracedTitle";
import Loader from "../../../../common/components/loader";
import { FindIcon, SearchIcon } from "../../../../../assets/icons";
import TransitionContent from "../../../../common/components/transitionContent";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import { Routes } from "../../../../common/enums/routes.enum";
import { EApiEndpointField } from "../../../enums/api-endpoint.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IRouteProps {}

interface IProps extends PropsFromRedux, RouteComponentProps<IRouteProps> {
  isLoading: boolean;
  endpointSearchResults: EndpointSearchResult[];
}

interface IStates {
  areSectionsExpanded: boolean[];
}

class EndpointSearchResults extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      areSectionsExpanded: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.endpointSearchResults !== this.props.endpointSearchResults) {
      this.setState({
        areSectionsExpanded: this.props.endpointSearchResults.map(() => false),
      });
    }
  }

  getField(field?: EApiEndpointField): JSX.Element | null {
    switch (field) {
      case EApiEndpointField.DESCRIPTION:
        return <FormattedMessage id="apiDesc.endpoint.description" />;

      case EApiEndpointField.PARAMETERS:
        return <FormattedMessage id="apiDesc.endpoint.parameters" />;

      case EApiEndpointField.TAGS:
        return <FormattedMessage id="apiDesc.endpoint.tags" />;

      default:
        return null;
    }
  }

  render() {
    const isListEmpty = this.props.endpointSearchResults.length === 0;

    const toggleExpanded = (index: number) => {
      const areSectionsExpanded = [...this.state.areSectionsExpanded];
      areSectionsExpanded[index] = !areSectionsExpanded[index];
      this.setState({ areSectionsExpanded });
    };

    return (
      <div className="App-content">
        <div className={`api-endpoints-list ${this.props.screenType}`}>
          <div className="list-header">
            <BracedTitle
              text={
                <FormattedMessage id="apiCatalog.apiEndpointSearchResults" />
              }
              size="large"
            />
          </div>

          <div className={`list-container ${isListEmpty ? "empty" : ""}`}>
            {this.props.isLoading ? (
              <Loader />
            ) : isListEmpty ? (
              <div className="notfound">
                <div>
                  <SearchIcon className="image" />
                </div>
                <div className="message">
                  <FormattedMessage id="apiCatalog.noProductFound" />
                </div>
              </div>
            ) : (
              <div className="list">
                {this.props.endpointSearchResults.map(
                  (api: EndpointSearchResult, index: number) => (
                    <div className="item" key={`api-endpoints-${api.id}`}>
                      <div
                        className="item-header-container"
                        onClick={() => toggleExpanded(index)}
                      >
                        <div className="title">{api.title}</div>

                        <KeyboardArrowDown
                          className={
                            this.state.areSectionsExpanded[index]
                              ? "opened"
                              : ""
                          }
                        />
                      </div>
                      <TransitionContent
                        isExpanded={this.state.areSectionsExpanded[index]}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="method-cell">
                                  <FormattedMessage id="apiDesc.method" />
                                </TableCell>
                                <TableCell className="name-cell">
                                  <div>
                                    <FormattedMessage id="apiDesc.name" />
                                  </div>
                                </TableCell>

                                <TableCell className="desc-cell">
                                  <div>
                                    <FormattedMessage id="apiDesc.summary" />
                                  </div>
                                </TableCell>

                                <TableCell className="found-in-field-cell">
                                  <div>
                                    <FormattedMessage id="apiDesc.endpoint.foundInField" />
                                  </div>
                                </TableCell>

                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {api.endpoints.map((apiEndpoint) => {
                                return (
                                  <TableRow
                                    key={`api-endpoint-row-${apiEndpoint.endpointName}`}
                                  >
                                    <TableCell>
                                      {apiEndpoint.methodType}
                                    </TableCell>
                                    <TableCell>
                                      <div>{apiEndpoint.endpointName}</div>
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        {apiEndpoint.endpointDescription}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        {this.getField(
                                          apiEndpoint.foundInField
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        href={`${Routes.PRODUCTS}/api/${api.id}/swagger?method=${apiEndpoint.methodType}&endpoint=${apiEndpoint.path}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <FindIcon className="find-icon" />
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TransitionContent>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(EndpointSearchResults));
