import {
  BusinessRequestInputId,
  FormSelectValue,
  ReportIssueInputId,
} from "../../common/submodules/form/enums/form.enum";
import { Form } from "../../common/submodules/form/models/form.model";
import FormUtils from "../../../utils/form.utils";

export class ContactDTO {
  private firstName: string;
  private lastName: string;
  private email: string;
  private phone: string;
  private countryCode: string;
  private companyName: string;
  private customerType: string;
  private requestReason: string;
  private message: string;
  private offer?: string;

  constructor(form: Form) {
    this.firstName = form.getFormField(BusinessRequestInputId.FIRST_NAME)
      ?.value as string;

    this.lastName = form.getFormField(BusinessRequestInputId.LAST_NAME)
      ?.value as string;

    this.email = form.getFormField(BusinessRequestInputId.EMAIL)
      ?.value as string;

    this.phone = form.getFormField(BusinessRequestInputId.PHONE)
      ?.value as string;

    this.countryCode = form.getFormField(BusinessRequestInputId.COUNTRY_CODE)
      ?.value as string;

    this.companyName = form.getFormField(BusinessRequestInputId.COMPANY_NAME)
      ?.value as string;

    this.customerType = form.getFormField(BusinessRequestInputId.COMPANY_TYPE)
      ?.value as string;

    this.requestReason = form.getFormField(
      BusinessRequestInputId.REQUEST_REASON
    )?.value as string;

    this.message = form.getFormField(BusinessRequestInputId.MESSAGE)
      ?.value as string;

    const offer = form.getFormField(BusinessRequestInputId.OFFER)
      ?.value as string;

    if (
      offer &&
      offer !== FormSelectValue.PLACEHOLDER &&
      offer !== FormSelectValue.NONE
    )
      this.offer = offer;
  }
}

export class ReportIssueDTO {
  private readonly email: string;
  private readonly client: string;
  private readonly product: string;
  private readonly title: string;
  private readonly description: string;
  private readonly files: FileList;

  private readonly requestUrl?: string;
  private readonly responseCode?: string;
  private readonly correlationId?: string;

  constructor(form: Form, files: FileList) {
    this.email = form.getFormField(ReportIssueInputId.EMAIL)?.value as string;
    
    this.client = form.getFormField(ReportIssueInputId.CLIENT)?.value as string;

    this.product = form.getFormField(ReportIssueInputId.PRODUCT)
      ?.value as string;

    this.title = form.getFormField(ReportIssueInputId.TITLE)?.value as string;

    this.description = form.getFormField(ReportIssueInputId.DESCRIPTION)
      ?.value as string;

    const requestUrl = form.getFormField(ReportIssueInputId.REQUEST_URL)
      ?.value as string;

    const responseCode = form.getFormField(ReportIssueInputId.RESPONSE_CODE)
      ?.value as string;

    const correlationId = form.getFormField(ReportIssueInputId.CORRELATION_ID)
      ?.value as string;

    if (!FormUtils.isValueEmpty(requestUrl)) this.requestUrl = requestUrl;

    if (
      !FormUtils.isValueEmpty(responseCode) &&
      responseCode !== FormSelectValue.PLACEHOLDER
    )
      this.responseCode = responseCode;

    if (!FormUtils.isValueEmpty(correlationId))
      this.correlationId = correlationId;

    this.files = files;
  }

  getFormData(): FormData {
    const formData = new FormData();

    formData.append("email", this.email);
    formData.append("client", this.client);
    formData.append("product", this.product);
    formData.append("title", this.title);
    formData.append("description", this.description);

    for (let i = 0; i < this.files.length; i++) {
      formData.append("attachments", this.files[i]);
    }

    if (this.requestUrl) formData.append("requestUrl", this.requestUrl);
    if (this.responseCode) formData.append("responseCode", this.responseCode);
    if (this.correlationId)
      formData.append("correlationId", this.correlationId);

    return formData;
  }
}
