import "./styles.scss";

import React from "react";
import { Checkbox } from "@material-ui/core";

import { IProductsFamilyWithColor } from "../../../../../productCatalog/interfaces/products-family.interface";
import { IChartOptions } from "../../../../../common/interfaces/metricsChart.interface";
import { ProductFamily } from "../../../../../productCatalog/enums/product-family.enum";
import MetricsAPI from "../../../../apiApplications/apis/metrics.api";
import {
  IMetrics,
  ISeriesMetric,
} from "../../../../apiApplications/interfaces/metrics.interface";
import MetricsChart from "../../../../../common/components/metricsChart";
import MessagesCount from "../../../../../common/components/messagesCount";
import {
  AssessmentIcon,
  AssessmentInvertedIcon,
} from "../../../../../../assets/icons";

interface IProps {
  eventFamilies: IProductsFamilyWithColor[];
  eventFamily: IProductsFamilyWithColor;
  chartOptions: IChartOptions;
  isChecked: boolean;
  selectedFamily: ProductFamily | undefined;
  totalSuccesses: number;
  totalErrors: number;
  onFamilyCheck(): void;
  onFamilySelected(family: ProductFamily): boolean;
  onPeriodSelected(periodStart: Date, periodEnd: Date): void;
}

interface IStates {
  areMetricsVisible: boolean;
  isMetricListLoading: boolean;
  data: IMetrics | undefined;
  series: ISeriesMetric[];
}

export default class FamilyTile extends React.Component<IProps, IStates> {
  private readonly CHART_ID = `metrics-chart-event-${this.props.eventFamily.id}`;

  constructor(props: IProps) {
    super(props);

    this.state = {
      areMetricsVisible: false,
      isMetricListLoading: false,
      data: undefined,
      series: [],
    };
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    const hasFamilyBeenSelected =
      prevProps.selectedFamily !== this.props.selectedFamily &&
      this.props.selectedFamily === this.props.eventFamily.productFamily;

    const shouldDisplayChart =
      this.state.areMetricsVisible &&
      prevProps.chartOptions.shouldUpdateChart !==
        this.props.chartOptions.shouldUpdateChart;

    if (hasFamilyBeenSelected || shouldDisplayChart) {
      this.getMetrics();
    }
  }

  private onFamilyExpand = (eventFamily = this.props.eventFamily) => {
    const isSelected = this.props.onFamilySelected(eventFamily.productFamily);
    if (isSelected) {
      this.getMetrics();
    } else {
      this.setState({ areMetricsVisible: false });
    }
  };

  private getMetrics() {
    setTimeout(() => {
      this.setState(
        { areMetricsVisible: true, isMetricListLoading: true },
        () => {
          MetricsAPI.getEventMetrics(
            this.props.chartOptions.periodStart,
            this.props.chartOptions.periodEnd,
            this.props.eventFamily.id.toUpperCase()
          )
            .then((data) => {
              this.setState(
                {
                  data,
                  isMetricListLoading: false,
                },
                this.updateChart
              );
            })
            .catch(() => this.setState({ isMetricListLoading: false }));
        }
      );
    }, 300);
  }

  private updateChart = () => {
    let series: ISeriesMetric[] = [];

    if (this.state.data) {
      series = this.state.data.series;
    }

    this.setState({ series });
  };

  private getFamilyNames = (
    isSelected: boolean
  ): JSX.Element | JSX.Element[] => {
    if (!isSelected) {
      return <div className="name">{this.props.eventFamily.name}</div>;
    }

    return this.props.eventFamilies.map((family, index) => (
      <div
        key={`family-name-${index}`}
        className={`name ${
          family.id === this.props.eventFamily.id ? "" : "is-not-selected"
        }`}
        onClick={() => this.onFamilyExpand(family)}
      >
        {family.name}
      </div>
    ));
  };

  render() {
    const isSelected =
      this.props.selectedFamily === this.props.eventFamily.productFamily;

    let familyTileClass = "family-tile";
    if (!this.props.selectedFamily && !this.props.isChecked)
      familyTileClass += " no-checked";
    if (isSelected) familyTileClass += " expanded";
    else if (!!this.props.selectedFamily) familyTileClass += " collapsed";

    const IconComponent = isSelected ? AssessmentInvertedIcon : AssessmentIcon;

    return (
      <div className={familyTileClass}>
        <div className="padding-div">
          <div className="tile-header">
            <div className="families-names">
              {this.getFamilyNames(isSelected)}
            </div>

            <div className="controls">
              <IconComponent
                className="expand-icon"
                onClick={() => this.onFamilyExpand()}
              />

              {!isSelected && (
                <Checkbox
                  checked={this.props.isChecked}
                  disabled={isSelected}
                  onChange={this.props.onFamilyCheck}
                />
              )}
            </div>
          </div>

          {isSelected && this.state.areMetricsVisible && (
            <MetricsChart
              chartId={this.CHART_ID}
              areMetricsVisible={true}
              isMetricListLoading={this.state.isMetricListLoading}
              chartOptions={this.props.chartOptions}
              series={this.state.series}
              onPeriodSelected={this.props.onPeriodSelected}
            />
          )}

          <MessagesCount
            totalSuccesses={this.props.totalSuccesses}
            totalErrors={this.props.totalErrors}
            color={this.props.eventFamily.color}
          />
        </div>
      </div>
    );
  }
}
