import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";

import { IPackage } from "../../interfaces/package.interface";
import Utils from "../../../../utils";
import { Routes } from "../../../common/enums/routes.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

interface IStep {
  value: number;
  title: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteComponentProps, PropsFromRedux {
  packages: IPackage[];
}

interface IStates {
  steps: IStep[];
}

enum StepProgress {
  BEFORE,
  EQUAL,
  AFTER,
}

class Stepper extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      steps: [
        ...this.props.packages.map((currentPackage, index) => ({
          value: index,
          title: currentPackage.name,
        })),
      ],
    };
  }

  private onStepClick = (index: number) => {
    const routes = this.props.packages.map((el) =>
      Utils.stringToDashCase(el.name)
    );

    if (index < this.props.packages.length) {
      if (!window.location.pathname.includes(routes[index]))
        this.props.history.push(routes[index]);
    } else {
      this.props.history.push(Routes.MY_SELECTION);
    }
  };

  private getStepProgress = (
    stepTitle: string,
    index: number
  ): StepProgress => {
    const splitUrl = window.location.pathname.split("/");
    const currentRoute = splitUrl[splitUrl.length - 1];

    if (currentRoute === Utils.stringToDashCase(stepTitle))
      return StepProgress.EQUAL;

    const routes = this.props.packages.map((el) =>
      Utils.stringToDashCase(el.name)
    );

    const packageIndex = routes.findIndex((route) => route === currentRoute);
    if (index < packageIndex) return StepProgress.BEFORE;

    return StepProgress.AFTER;
  };

  render() {
    return (
      <div id="packages-stepper" className={this.props.screenType}>
        <div className="steps-container">
          {this.state.steps.map((step: IStep, index: number) => {
            let stepClass = "step";
            let separatorClass = "separator";

            const stepProgress = this.getStepProgress(step.title, index);
            if (stepProgress === StepProgress.EQUAL)
              stepClass = `${stepClass} active`;
            else if (stepProgress === StepProgress.BEFORE) {
              stepClass = `${stepClass} completed`;
            }

            return (
              <React.Fragment key={`step-${step.value}`}>
                <div className={stepClass}>
                  <div
                    className="badge"
                    onClick={() => this.onStepClick(index)}
                  >
                    {index + 1}
                    <div className="completed-badge">
                      <CheckIcon />
                    </div>
                  </div>
                  <div
                    className="title"
                    onClick={() => this.onStepClick(index)}
                  >
                    {step.title}
                  </div>
                </div>

                {index !== this.state.steps.length - 1 && (
                  <div className={separatorClass} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(Stepper));
