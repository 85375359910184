import { SelectItem } from "../../common/interfaces/common.interface";

export const customerTypes: SelectItem[] = [
  { name: "Third party logistic", value: "10" },
  { name: "Consolidator / Trader", value: "20" },
  { name: "Bank", value: "30" },
  { name: "State agent", value: "40" },
  { name: "Freight forwarder", value: "50" },
  { name: "Non-vessel operating common carrier", value: "60" },
  { name: "Business Cargo Owner", value: "70" },
  { name: "Vendor", value: "90" },
  { name: "Custom", value: "80" },
];

export const roleTypes: SelectItem[] = [
  { name: "Technical", value: "Technical" },
  { name: "Commercial", value: "Commercial" }
];

export const requestReasons: SelectItem[] = [
  { name: "Product information", value: "Product information" },
  { name: "Technical information", value: "Technical information" },
  { name: "Pricing information", value: "Pricing information" },
  { name: "Other", value: "Other" },
];

export const offers: SelectItem[] = [
  { name: "Essential Starter", value: "APIESST" },
  { name: "Essential Power", value: "APIESPO" },
  { name: "Essential Advanced", value: "APIESAD" },
  { name: "Transport Execution Starter", value: "APITES" },
  { name: "Transport Execution Power", value: "APITEP" },
  { name: "Transport Execution Advanced", value: "APITEA" },
  { name: "BL Suite Starter", value: "APIOBST" },
  { name: "BL Suite Power", value: "APIOBPO" },
  { name: "BL Suite Advanced", value: "APIOBAD" },
  { name: "Visibility Starter", value: "APITPST" },
  { name: "Visibility Power", value: "APITPPO" },
  { name: "Visibility Advanced", value: "APITPAD" },
];

export const customConsumptionLimits = [
  { name: "Essential", value: 500000 },
  { name: "Transport Execution", value: 5000 },
  { name: "BL Suite", value: 5000 },
  { name: "Visibility", value: 3500 },
];
