import { EApiEndpointField } from "../enums/api-endpoint.enum";

export class ApiEndpoint {
  public path?: string;
  public endpointName: string;
  public endpointDescription: string;
  public methodType: string;
  public foundInField?: EApiEndpointField;
  public security: string[];

  constructor(body: any) {
    if (body.path) this.path = body.path;
    this.endpointName = body.endpointName;
    this.endpointDescription = body.endpointDescription;
    this.methodType = body.methodType;
    if (body.foundInField) this.foundInField = body.foundInField;
    this.security = body.security;
  }
}
