import { ApiEndpoint } from "./api-endpoint.model";

export class EndpointSearchResult {
  public id: string;
  public title: string;
  public endpoints: ApiEndpoint[];

  constructor(body: any) {
    this.id = body.id;
    this.title = body.title;
    this.endpoints = body.endpoints.map(
      (endpoint: any) => new ApiEndpoint(endpoint)
    );
  }
}
