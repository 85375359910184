export class ApiRecentlyUpdated {
    id: string;
    name: string;
    description: string;
    family: string;

  constructor(body: any) {
    this.id = body.id;
    this.name = body.title;
    this.description = body.description;
    this.family = body.productFamily;
  }
}
