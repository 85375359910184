export enum Requirements {
  LOGIN = "notContainingLogin",
  LENGTH = "correctLength",
  UPPERCASE = "hasUppercase",
  LOWERCASE = "hasLowercase",
  NUMERIC = "hasNumeric",
  SPECIAL_CHAR = "hasSpecialChar",
}

export enum InputId {
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
}
