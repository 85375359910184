import "./styles.scss";

import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { IHeaderSubMenuSection } from "../../../../interfaces/header.interface";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  subMenuSection: IHeaderSubMenuSection;
  onRouteClick(route: string): void;
}

class Section extends React.Component<IProps> {
  private onClick = (
    event: any,
    route: string | undefined,
    isClickable = true
  ) => {
    event.stopPropagation();
    if (route !== undefined && isClickable) this.props.onRouteClick(route);
  };

  render() {
    if (
      !this.props.subMenuSection.title &&
      !!this.props.subMenuSection.items &&
      this.props.subMenuSection.items.every((item) => item.shouldBeConnected) &&
      !this.props.user
    ) {
      return null;
    }

    const hasItems = !!this.props.subMenuSection.items;
    return (
      <ul className="header-sub-menu-section">
        {this.props.subMenuSection.title && (
          <li
            className={`title ${hasItems ? "" : "clickable"}`}
            onClick={(event) =>
              this.onClick(event, this.props.subMenuSection.route, !hasItems)
            }
          >
            {this.props.subMenuSection.title}
          </li>
        )}

        {this.props.subMenuSection.items?.map((item) => (
          <li
            key={`sub-menu-section-item-${item.route}`}
            className="item clickable"
            onClick={(event) => this.onClick(event, item.route)}
          >
            {item.title}
          </li>
        ))}
      </ul>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const connector = connect(mapState);
export default connector(Section);
