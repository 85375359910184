import { IReducerState } from "../interfaces/reducer.interface";
import { ScreenType } from "../../common/enums/screen-type.enum";
import { Language } from "../enums/reducer-action.enum";

export const defaultState: IReducerState = {
  user: undefined,

  locale: Language.EN,

  screenType: ScreenType.COMPUTER,
  windowWidth: window.innerWidth,

  hasOpenRequestPartnerBeenAdded: false,

  toastType: undefined,
  toastMessage: undefined,

  reCaptchaSecret: undefined,

  countries: undefined,

  descriptionPanel: undefined,

  scrollControl: false,
};
