import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";

import { ProductType } from "../../../enums/product-family.enum";
import ApiDescription from "../apiDescription";
import EventDescription from "../eventDescription";
import { ReducerAction } from "../../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}

class DescriptionSidePanel extends Component<IProps> {
  componentWillUnmount() {
    this.props.closePanel();
  }

  private onClickAway = (pointerEvent?: any) => {
    let isApiTile =
      !!pointerEvent &&
      pointerEvent.path.some(
        (el: any) => el.className && el.className.includes("api-tile")
      );

    if (!isApiTile) {
      this.props.closePanel();
    }
  };

  render() {
    if (this.props.descriptionPanel) {
      return (
        <ClickAwayListener onClickAway={this.onClickAway}>
          <div className={`side-panel ${this.props.screenType}`}>
            {this.props.descriptionPanel.productType === ProductType.API ? (
              <ApiDescription
                apiId={this.props.descriptionPanel.id}
                onBackClick={this.onClickAway}
              />
            ) : (
              <EventDescription
                eventId={this.props.descriptionPanel.id}
                onBackClick={this.onClickAway}
              />
            )}
          </div>
        </ClickAwayListener>
      );
    }

    return null;
  }
}

const mapState = (state: IReducerState) => ({
  descriptionPanel: state.descriptionPanel,
  screenType: state.screenType,
});

const mapDispatch = {
  closePanel: () => ({
    type: ReducerAction.CLOSE_DESCRIPTION_PANEL,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(DescriptionSidePanel);
