import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";

import { Routes } from "../../../common/enums/routes.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteProps, PropsFromRedux {}

class PrivateRoute extends Component<IProps> {
  render() {
    if (this.props.user) {
      return <Route component={this.props.component} />;
    }

    return <Redirect to={Routes.HOME} />;
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const connector = connect(mapState);
export default connector(PrivateRoute);
