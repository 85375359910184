import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";

import { Routes } from "../../../common/enums/routes.enum";

class RequestSubmitted extends Component<RouteComponentProps> {
  private onHomeClick = () => {
    this.props.history.push(Routes.HOME);
  };

  render() {
    return (
      <div className="request-submitted">
        <div className="title">
          <FormattedMessage id="subscriptionRequest.success.title" />
        </div>
        <div className="content">
          <FormattedMessage id="subscriptionRequest.success.content" />
        </div>

        <div className="button-container">
          <div className="button" onClick={() => this.onHomeClick()}>
            <FormattedMessage id="backHome" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RequestSubmitted);
