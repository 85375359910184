import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";

import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {
  apiId: string;
  isUserSubscribed: boolean;
}

class UserSubscribed extends Component<IProps> {
  render() {
    return !!this.props.user && this.props.isUserSubscribed ? (
      <div id="user-subscribed">
        <FormattedMessage id="subscribed" />
      </div>
    ) : null;
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const connector = connect(mapState);
export default connector(UserSubscribed);
