import "./styles.scss";

import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";

import { InputOptions } from "../../models/input-options.model";
import CustomTooltip from "../../../../components/customTooltip";
import FormUtils from "../../../../../../utils/form.utils";

interface IProps extends WrappedComponentProps {
  options: InputOptions;
}

class InputLabel extends React.Component<IProps> {
  render() {
    if (!this.props.options.label) {
      return null;
    }

    const label = this.props.intl.formatMessage({
      id: this.props.options.label,
    });

    const tooltip = this.props.options.tooltip
      ? this.props.intl.formatMessage({
          id: this.props.options.tooltip,
        })
      : "";

    const errorTooltip = !!this.props.options.error
      ? FormUtils.getMessageFromError(this.props.options.error.message)
      : "";

    return (
      <div className="input-label">
        <span>{label}</span>

        {this.props.options.isMandatory && <span>*</span>}

        {this.props.options.tooltip && (
          <CustomTooltip title={tooltip}>
            <InfoOutlined />
          </CustomTooltip>
        )}

        <CustomTooltip title={errorTooltip} error={!!this.props.options.error}>
          <ErrorOutlinedIcon
            className={`error-icon ${
              this.props.options.error ? "visible" : ""
            }`}
          />
        </CustomTooltip>
      </div>
    );
  }
}

export default injectIntl(InputLabel);
