import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { FormGroup, Input } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { ToastType } from "../../../common/submodules/toast/enums/toast-type.enum";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { Routes } from "../../../common/enums/routes.enum";
import Loader from "../../../common/components/loader";
import TokenAPI from "../../apis/token.api";
import { UserRegisteringGet } from "../../models/user.model";
import SignInButton from "../../../common/components/signInButton";
import { PasswordRequirements } from "../../models/password-requirements.model";
import { InputId, Requirements } from "../../enum/password-requirements.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { ScreenType } from "../../../common/enums/screen-type.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps
  extends RouteComponentProps,
    WrappedComponentProps,
    PropsFromRedux {}

interface IStates {
  user: UserRegisteringGet | undefined;
  password: string;
  confirmPassword: string;
  isGetLoading: boolean;
  isPatchLoading: boolean;
  isPasswordSaved: boolean;
  isVisibilityIconDisplayed: boolean;
  isPasswordVisible: boolean;
  isValidConditions: boolean;
}

class PasswordForm extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      user: undefined,
      password: "",
      confirmPassword: "",
      isGetLoading: false,
      isPatchLoading: false,
      isPasswordSaved: false,
      isVisibilityIconDisplayed: false,
      isPasswordVisible: false,
      isValidConditions: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (history.replaceState)
      history.replaceState({}, "", Routes.PASSWORD_FORM);

    if (token) {
      this.setState({ isGetLoading: true }, () => {
        TokenAPI.getUserByToken(token)
          .then((user) => {
            this.setState({ user, isGetLoading: false });
          })
          .catch(() => this.setState({ isGetLoading: false }));
      });
    }
  }

  private returnToHome = () => this.props.history.push(Routes.HOME);

  private submitForm() {
    if (this.state.user && this.state.password) {
      this.setState({ isPatchLoading: true }, () => {
        TokenAPI.patchUserPassword(
          this.state.user!.onBoardingToken,
          this.state.password
        )
          .then(() => {
            this.props.displayToast(
              <FormattedMessage id="password.passwordSaved" />
            );
            this.setState({ isPatchLoading: false, isPasswordSaved: true });
          })
          .catch(() => {
            this.props.displayToast(
              <FormattedMessage id="password.passwordError" />,
              ToastType.ERROR
            );
            this.setState({ isPatchLoading: false });
          });
      });
    }
  }

  private setIconVisibility = (isVisibilityIconDisplayed: boolean) => {
    this.setState({ isVisibilityIconDisplayed });
  };

  private setPasswordVisibility = (isPasswordVisible: boolean) => {
    this.setState({ isPasswordVisible });
  };

  private getInput(id: InputId): JSX.Element {
    const state = { ...this.state };

    const onChange = (value: string) => {
      state[id] = value;
      this.setState(state);
    };

    let isValueValid = this.isConfirmPasswordValid();
    let placeholder = this.props.intl.formatMessage({
      id: "password.confirmPassword.placeholder",
    });

    if (id === InputId.PASSWORD) {
      isValueValid = this.isPasswordValid();
      placeholder = this.props.intl.formatMessage({
        id: "password.password.placeholder",
      });
    }

    return (
      <div
        className="input-container"
        onMouseEnter={() =>
          id === InputId.PASSWORD && this.setIconVisibility(true)
        }
        onMouseLeave={() =>
          id === InputId.PASSWORD && this.setIconVisibility(false)
        }
      >
        <Input
          id={id}
          type={this.state.isPasswordVisible ? "text" : "password"}
          placeholder={placeholder}
          error={this.state.password === "" ? false : !isValueValid}
          defaultValue={state[id]}
          onChange={(event) => onChange(event.target.value)}
        />

        {id === InputId.PASSWORD && (
          <div
            className={`visibility-icon ${
              this.state.isVisibilityIconDisplayed ||
              this.state.isPasswordVisible
                ? "visible"
                : ""
            }`}
            onClick={() =>
              this.setPasswordVisibility(!this.state.isPasswordVisible)
            }
          >
            {this.state.isPasswordVisible ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </div>
        )}
      </div>
    );
  }

  private static getRequirement(
    requirement: Requirements,
    passwordRequirements: PasswordRequirements
  ): JSX.Element {
    let message;

    switch (requirement) {
      case Requirements.LOGIN:
        message = <FormattedMessage id="password.doesNotContainsLogin" />;
        break;
      case Requirements.LENGTH:
        message = <FormattedMessage id="password.mustBe8CharLong" />;
        break;
      case Requirements.UPPERCASE:
        message = <FormattedMessage id="password.containsUppercase" />;
        break;
      case Requirements.LOWERCASE:
        message = <FormattedMessage id="password.containsLowercase" />;
        break;
      case Requirements.NUMERIC:
        message = <FormattedMessage id="password.containsNumeric" />;
        break;
      case Requirements.SPECIAL_CHAR:
        message = <FormattedMessage id="password.containsSpecialChar" />;
        break;
    }

    return (
      <div
        className={`requirement ${
          passwordRequirements[requirement] ? "valid" : ""
        }`}
      >
        {message}
      </div>
    );
  }

  private isPasswordValid(): boolean {
    if (this.state.user) {
      const passwordRequirements = new PasswordRequirements(
        this.state.password,
        this.state.user
      );

      if (passwordRequirements.isValid()) {
        if (!this.state.isValidConditions) {
          this.setState({ isValidConditions: true });
        }
        return true;
      }

      if (this.state.isValidConditions) {
        this.setState({ isValidConditions: false });
      }
    }
    return false;
  }

  private isConfirmPasswordValid(): boolean {
    if (
      this.state.password !== "" &&
      this.state.password === this.state.confirmPassword
    )
      return true;
      
    return false;
  }

  private getSegmentContent() {
    if (this.state.isGetLoading) {
      return <Loader />;
    }

    if (this.state.isPasswordSaved) {
      return (
        <>
          <div>
            <div className="form-title">
              <FormattedMessage id="password.passwordSaved" />
            </div>
            <div className="form-message">
              <FormattedMessage id="password.passwordSavedMessage" />
            </div>
          </div>
          <div className="button-container">
            <SignInButton />
          </div>
        </>
      );
    }

    let passwordRequirements;
    if (this.state.user) {
      passwordRequirements = new PasswordRequirements(
        this.state.password,
        this.state.user
      );
    }

    if (this.state.user && passwordRequirements) {
      const isFormValid = this.isConfirmPasswordValid();

      return (
        <>
          <div>
            <div className="form-title">
              <FormattedMessage
                id="password.welcome"
                values={{
                  firstName: this.state.user.givenName,
                  lastName: this.state.user.familyName,
                }}
              />
            </div>
            <div className="form-message">
              <FormattedMessage id="password.setPassword" />
            </div>
            <div className="form-content">
              <FormGroup>
                <div className="field-require">
                  <FormattedMessage id="password.password" />
                </div>
                {this.getInput(InputId.PASSWORD)}
                {this.state.isValidConditions && (
                  <>
                    <div className="field-require">
                      <FormattedMessage id="password.confirmPassword" />
                    </div>
                    {this.getInput(InputId.CONFIRM_PASSWORD)}
                  </>
                )}
              </FormGroup>
            </div>

            <div className="requirements-container">
              {!this.state.isValidConditions && (
                <FormattedMessage id="password.passwordMustRespectTheseRequirements" />
              )}
              {PasswordForm.getRequirement(
                Requirements.LOGIN,
                passwordRequirements
              )}

              {PasswordForm.getRequirement(
                Requirements.LENGTH,
                passwordRequirements
              )}

              {PasswordForm.getRequirement(
                Requirements.UPPERCASE,
                passwordRequirements
              )}

              {PasswordForm.getRequirement(
                Requirements.LOWERCASE,
                passwordRequirements
              )}

              {PasswordForm.getRequirement(
                Requirements.NUMERIC,
                passwordRequirements
              )}

              {PasswordForm.getRequirement(
                Requirements.SPECIAL_CHAR,
                passwordRequirements
              )}
            </div>
          </div>
          <div className="button-container save-button">
            <div
              className={`button ${
                isFormValid && !this.state.isPatchLoading ? "" : "disabled"
              }`}
              onClick={() => isFormValid && this.submitForm()}
            >
              {this.state.isPatchLoading ? (
                <Loader />
              ) : (
                <FormattedMessage id="password.save" />
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          <div className="form-title">
            <FormattedMessage id="password.formInvalid" />
          </div>
          <div className="form-message">
            <FormattedMessage id="password.formInvalidMessage" />
          </div>
        </div>
        <div className="button-container">
          <div className="button" onClick={this.returnToHome}>
            <FormattedMessage id="returnToHome" />
          </div>
        </div>
      </>
    );
  }

  render() {
    const form = <div className="api-form">{this.getSegmentContent()}</div>;

    return (
      <div id="set-password" className={this.props.screenType}>
        {this.props.screenType === ScreenType.COMPUTER ? (
          <div className="api-image">
            <div className="api-portal">
              <div className="api-portal-title">
                <FormattedMessage id="api" />
                <span className="title-brace">{"{"}</span>
                <FormattedMessage id="portal" />
              </div>
              <div className="api-portal-content">
                <FormattedMessage id="apiPortalContent" />
              </div>
            </div>
            {form}
          </div>
        ) : (
          form
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const mapDispatch = {
  displayToast: (toastMessage: JSX.Element, toastType = ToastType.SUCCESS) => ({
    type: ReducerAction.TOAST,
    payload: { toastMessage, toastType },
  }),
};

const connector = connect(mapState, mapDispatch);
export default withRouter(injectIntl(connector(PasswordForm)));
