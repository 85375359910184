import "./styles.scss";

import React, { Component } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckIcon from "@material-ui/icons/Check";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import SynAltIcon from "@material-ui/icons/SyncAlt";

import Segment from "../../../../common/components/segment";
import DateUtils from "../../../../../utils/date.utils";
import { FunctionalRule } from "../../models/event-applications.model";
import EventApplicationsAPI from "../../apis/event-applications.api";
import { SortingModes } from "../../../../common/enums/sorting-mode.enum";
import SearchInput from "../../../../common/submodules/form/components/searchInput";

interface IProps extends WrappedComponentProps {
  isLoading: boolean;
  toggleIsLoading(): void;
  toggleHasData(hasData: boolean): void;
}

interface IStates {
  functionalRules: FunctionalRule[] | undefined;
  isTypesSelectVisible: boolean;
  selectedTypes: string[];
  searchValue: string;
  startDateSorting: SortingModes;
  endDateSorting: SortingModes;
}

class FunctionalRulesSegment extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      functionalRules: undefined,
      isTypesSelectVisible: false,
      selectedTypes: [],
      searchValue: "",
      startDateSorting: SortingModes.DEFAULT,
      endDateSorting: SortingModes.DEFAULT,
    };
  }

  componentDidMount() {
    EventApplicationsAPI.getFunctionalRules()
      .then((functionalRules) => {
        this.setState({ functionalRules: functionalRules });
        this.props.toggleIsLoading();
        this.props.toggleHasData(functionalRules.length > 0);
      })
      .catch(() => this.props.toggleIsLoading());
  }

  private toggleTypesSelectVisibility = (isVisible?: boolean) => {
    const isTypesSelectVisible =
      isVisible === undefined ? !this.state.isTypesSelectVisible : isVisible;
    this.setState({ isTypesSelectVisible });
  };

  private onTypeSelection = (type: string) => {
    const selectedTypes = [...this.state.selectedTypes];
    const typeIndex = selectedTypes.indexOf(type);

    if (typeIndex === -1) {
      selectedTypes.push(type);
    } else {
      selectedTypes.splice(typeIndex, 1);
    }

    this.setState({ selectedTypes });
  };

  private getTypesSelect = () => {
    const types = this.state
      .functionalRules!.map((functionalRule) => functionalRule.type)
      .reduce((acc: string[], cur: string) => {
        if (!acc.includes(cur)) {
          acc.push(cur);
        }
        return acc;
      }, []);

    return (
      <ClickAwayListener
        onClickAway={() => this.toggleTypesSelectVisibility(false)}
      >
        <div className="type-selection-container">
          <FilterListIcon onClick={() => this.toggleTypesSelectVisibility()} />

          <ul className={this.state.isTypesSelectVisible ? "visible" : ""}>
            {types.map((type) => (
              <li
                key={`type-item-${type}`}
                value={type}
                onClick={() => this.onTypeSelection(type)}
              >
                {type}
                <CheckIcon
                  className={
                    this.state.selectedTypes.includes(type) ? "visible" : ""
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      </ClickAwayListener>
    );
  };

  private onSearch = (searchValue: string) => {
    this.setState({ searchValue });
  };

  private cycleSortingModes = (isSortingStartDate: boolean) => {
    let sortingMode = isSortingStartDate
      ? this.state.startDateSorting
      : this.state.endDateSorting;

    if (sortingMode === SortingModes.DESCEND) {
      sortingMode = SortingModes.DEFAULT;
    } else {
      sortingMode++;
    }

    if (isSortingStartDate) {
      this.setState({
        startDateSorting: sortingMode,
        endDateSorting: SortingModes.DEFAULT,
      });
    } else {
      this.setState({
        endDateSorting: sortingMode,
        startDateSorting: SortingModes.DEFAULT,
      });
    }
  };

  private getCurrentSortingModeIcon = (isSortingStartDate = true) => {
    const sortingMode = isSortingStartDate
      ? this.state.startDateSorting
      : this.state.endDateSorting;

    const onClick = () => this.cycleSortingModes(isSortingStartDate);

    switch (sortingMode) {
      case SortingModes.DEFAULT:
        return <SynAltIcon className="up" onClick={onClick} />;

      case SortingModes.ASCEND:
        return <TrendingFlatIcon className="up" onClick={onClick} />;

      case SortingModes.DESCEND:
        return <TrendingFlatIcon className="down" onClick={onClick} />;
    }
  };

  private getFunctionalRuleRow = (functionalRule: FunctionalRule) => {
    let rowClass = "active";
    let statusMessage = (
      <FormattedMessage id="eventApplications.functionalRules.status.active" />
    );

    if (functionalRule.startDate > new Date()) {
      rowClass = "planned";
      statusMessage = (
        <FormattedMessage id="eventApplications.functionalRules.status.notStarted" />
      );
    }

    if (functionalRule.endDate < new Date()) {
      rowClass = "expired";
      statusMessage = (
        <FormattedMessage id="eventApplications.functionalRules.status.ended" />
      );
    }

    return (
      <TableRow
        key={`functional-rule-row-${functionalRule.value}`}
        className={rowClass}
      >
        <TableCell className="type">{functionalRule.type}</TableCell>
        <TableCell className="value">
          <div>{functionalRule.value}</div>
        </TableCell>
        <TableCell className="start-date">
          <div>{DateUtils.formatDate(functionalRule.startDate, true)}</div>
        </TableCell>
        <TableCell className="end-date">
          <div>{DateUtils.formatDate(functionalRule.endDate, true)}</div>
        </TableCell>
        <TableCell className="status">
          <div className="status-content">{statusMessage}</div>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    if (
      this.props.isLoading ||
      !this.state.functionalRules ||
      this.state.functionalRules.length === 0
    ) {
      return null;
    }

    let functionalRules = this.state.functionalRules
      ? this.state.functionalRules.filter((functionalRule) =>
          functionalRule.value
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        )
      : [];

    if (this.state.selectedTypes.length > 0) {
      functionalRules = functionalRules.filter((functionalRule) =>
        this.state.selectedTypes.includes(functionalRule.type)
      );
    }

    if (this.state.startDateSorting !== SortingModes.DEFAULT) {
      functionalRules.sort((a, b) =>
        DateUtils.sortFunction(a, b, "startDate", this.state.startDateSorting)
      );
    } else if (this.state.endDateSorting !== SortingModes.DEFAULT) {
      functionalRules.sort((a, b) =>
        DateUtils.sortFunction(a, b, "endDate", this.state.endDateSorting)
      );
    }

    return (
      <div id="functional-rules">
        <Segment
          title={<FormattedMessage id="eventApplications.functionalRules" />}
        >
          <TableContainer className="functional-rules-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="type-cell">
                    <div className="type-cell-content">
                      <FormattedMessage id="eventApplications.functionalRules.referenceType" />

                      {this.getTypesSelect()}
                    </div>
                  </TableCell>
                  <TableCell className="value-cell">
                    <div>
                      <FormattedMessage id="eventApplications.functionalRules.referenceValue" />
                      <SearchInput
                        isAsynchronous={false}
                        onSearch={this.onSearch}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="date-cell">
                    <div>
                      <FormattedMessage id="eventApplications.functionalRules.startDate" />
                      {this.getCurrentSortingModeIcon()}
                    </div>
                  </TableCell>
                  <TableCell className="date-cell">
                    <div>
                      <FormattedMessage id="eventApplications.functionalRules.endDate" />
                      {this.getCurrentSortingModeIcon(false)}
                    </div>
                  </TableCell>
                  <TableCell className="status-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {functionalRules.length > 0 ? (
                  functionalRules.map(this.getFunctionalRuleRow)
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="no-rules">
                      <FormattedMessage id="eventApplications.functionalRules.noFunctionalRules" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Segment>
      </div>
    );
  }
}

export default injectIntl(FunctionalRulesSegment);
