import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

import HeaderBanner from "../../../common/components/headerBanner";
import { Helmet } from 'react-helmet';
import { regardingFaq } from "../constants/faq.constant";
import BracedTitle from "../../../common/components/bracedTitle";
import QuestionFaq from "./questionFaq";
import { IQuestionFaq, IRegardingFaq } from "../interfaces/faq.interface";
import SearchInput from "../../../common/submodules/form/components/searchInput";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import BlockFamily from "./blockFamily";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps
  extends RouteComponentProps,
    WrappedComponentProps,
    PropsFromRedux {}

interface IStates {
  searchValue: string;
  blocksActivated: string[];
}

class Faq extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: "",
      blocksActivated: [],
    };
  }

  private displayFamily = (family: IRegardingFaq, index: number) => {
    let questionsFamily = family.questions;
    if (this.state.searchValue !== "") {
      questionsFamily = questionsFamily.filter((question: IQuestionFaq) =>
        question.title
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase())
      );
    }

    if (
      this.state.blocksActivated.length == 0 ||
      this.state.blocksActivated.includes(family.family)
    ) {
      return (
        questionsFamily.length > 0 && (
          <div key={`faq-family-${index}`} className="questions">
            <BracedTitle text={family.family} />
            {questionsFamily.map((question: IQuestionFaq) => (
              <QuestionFaq key={question.title} question={question} />
            ))}
          </div>
        )
      );
    }
  };
  
  private onSearch = (searchValue: string) => {
    this.setState({ searchValue });
  };

  private blockClicked = (familyName: string) => {
    const blocksActivated = this.state.blocksActivated;
    if (blocksActivated.includes(familyName)) {
      blocksActivated.splice(blocksActivated.indexOf(familyName), 1);
    } else {
      blocksActivated.push(familyName);
    }
    this.setState({ blocksActivated });
  };

  render() {
    return (
      <div id="faq" className={this.props.screenType}>
        <Helmet>
          <title>Faq - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <div className="header-banner-content">
            <span>
              <FormattedMessage id="faq.head.title" />
            </span>
            <SearchInput isAsynchronous={false} onSearch={this.onSearch} />
          </div>
        </HeaderBanner>
        <div className="App-content">
          <div className="summary">
            {regardingFaq.map((family) => (
              <BlockFamily
                key={family.family}
                familyName={family.family}
                blockClicked={(familyName) => this.blockClicked(familyName)}
              />
            ))}
          </div>
          {regardingFaq.map(this.displayFamily)}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(injectIntl(connector(Faq)));
