export enum PartnerType {
  OPEN_REQUEST,
  ACTIVE,
  MY_ENTITIES,
}

export enum PartnerStatus {
  IN_PROGRESS = "pending",
  ACTIVE = "active",
  LOA_REQUIRED = "loarequired",
  REJECTED = "rejected",
}
