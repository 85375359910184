import "./styles.scss";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ClickAwayListener } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { IHeaderMenuTab } from "../../interfaces/header.interface";
import SubMenu from "./subMenu";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import { TabValues } from "../../enums/header.enum";
import { FreeTrial } from "../../../authentication/models/freeTrial.model";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteComponentProps, PropsFromRedux {
  id: string;
  menuTab: IHeaderMenuTab;
  isActive: boolean;
  onRouteClick(route: string): void;
}

interface IStates {
  isSubMenuVisible: boolean;
}

class MenuTab extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isSubMenuVisible: false,
    };
  }

  private onRouteClick = (route: string): void => {
    this.props.onRouteClick(route);
    this.setSubMenuVisibility(false);
  };

  private onClick = (): void => {
    if (!!this.props.menuTab.route) {
      this.onRouteClick(this.props.menuTab.route);
    } else {
      this.setSubMenuVisibility(!this.state.isSubMenuVisible);
    }
  };

  private setSubMenuVisibility = (isSubMenuVisible: boolean): void => {
    this.setState({ isSubMenuVisible });
  };

  private displayFreeTrialMsgHead = (freeTrial: FreeTrial): JSX.Element => {
    return freeTrial.isEnded ? (
      <span className="free-trial ended">
        <FormattedMessage id="freeTrial.head.ended" />
      </span>
    ) : (
      <span className="free-trial">
        <FormattedMessage id="freeTrial.head" />
      </span>
    );
  };

  render() {
    const hasSubMenu = !!this.props.menuTab.subMenuSections;

    let headerTabClass = `header-tab ${this.props.screenType}`;
    if (hasSubMenu) headerTabClass = `${headerTabClass} clickable`;
    if (this.props.isActive) headerTabClass = `${headerTabClass} active`;

    // Handle subMenu popup going outside of screen on small computers screens
    const shouldDisplayOnLeft =
      !!this.props.user &&
      this.props.menuTab.tabValue === TabValues.HELP &&
      this.props.windowWidth < 1550;

    const freeTrial: FreeTrial = new FreeTrial(this.props.user);

    return (
      <ClickAwayListener onClickAway={() => this.setSubMenuVisibility(false)}>
        <div
          id={this.props.id}
          className={headerTabClass}
          onClick={this.onClick}
        >
          <div className="placeholder" />

          <div className="tab-title">
            <div>
              {this.props.menuTab.title}

              {this.props.menuTab.tabValue === TabValues.MY_ORGANIZATION &&
                freeTrial.isCommunity &&
                this.displayFreeTrialMsgHead(freeTrial)}
            </div>

            {this.props.screenType !== ScreenType.COMPUTER && hasSubMenu && (
              <KeyboardArrowDown
                className={`chevron ${
                  this.state.isSubMenuVisible ? "opened" : ""
                }`}
              />
            )}
          </div>

          {hasSubMenu && (
            <SubMenu
              subMenuSections={this.props.menuTab.subMenuSections!}
              isVisible={this.state.isSubMenuVisible}
              shouldDisplayOnLeft={shouldDisplayOnLeft}
              onRouteClick={this.onRouteClick}
            />
          )}

          <div className="active-under" />
        </div>
      </ClickAwayListener>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
  windowWidth: state.windowWidth,
});

const connector = connect(mapState);
export default withRouter(connector(MenuTab));
