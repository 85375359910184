import HttpUtils from "../../../../utils/http.utils";
import { FunctionalRule } from "../models/event-applications.model";

export default class EventApplicationsAPI {
  public static async getFunctionalRules(): Promise<FunctionalRule[]> {
    return HttpUtils.get<FunctionalRule[]>(`/events/functional-rules`).then(
      (functionalRules) =>
        functionalRules.map(
          (functionalRule) => new FunctionalRule(functionalRule)
        )
    );
  }
}
