import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";

import { ApiSummary } from "../../../../models/api-summary.model";
import DateUtils from "../../../../../../utils/date.utils";
import Utils from "../../../../../../utils";
import { Event } from "../../../../models/event.model";
import { ProductType } from "../../../../enums/product-family.enum";
import { Tooltip } from "@material-ui/core";

interface IProps extends RouteComponentProps {
  summary: ApiSummary | Event;
  onDetailsClick(): void;
}

interface IStates {
  descLineClamp?: number;
}

class Tile extends Component<IProps, IStates> {
  private readonly nameDivRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      descLineClamp: undefined,
    };

    this.nameDivRef = React.createRef();
  }

  componentDidMount() {
    this.setDescLineClamp();
    setTimeout(this.setDescLineClamp, 0);
    window.addEventListener("resize", this.setDescLineClamp);
  }

  private setDescLineClamp = () => {
    const nameDiv = this.nameDivRef.current;
    if (nameDiv) {
      const TOTAL_HEIGHT = 164;
      const HEADER_HEIGHT = 18;
      const FOOTER_HEIGHT = 18;
      const GAP_HEIGHT = 16 * 3;
      const LINE_HEIGHT = 16;

      const availableHeight =
        TOTAL_HEIGHT -
        (HEADER_HEIGHT + FOOTER_HEIGHT + GAP_HEIGHT + nameDiv.clientHeight);

      this.setState({
        descLineClamp: Math.round(availableHeight / LINE_HEIGHT),
      });
    }
  };

  private tagDisplayer(isApiNew: boolean, isDeprecated: boolean, isDcsa: boolean, isOptimizing: boolean, isPilot: boolean, isApiUpdated: boolean) {
    const tags: JSX.Element[] = [];
    if (isPilot)
      tags.push(
        <Tooltip title="Initial small-scale implementation" placement="top" arrow>
          <div className="label pilot">
            <FormattedMessage id="pilot" />
          </div>
        </Tooltip>);
    else if (isDcsa)
      tags.push(
        <Tooltip title="Fully DCSA compliant" placement="top" arrow>
          <div className="label dcsa">
            <FormattedMessage id="dcsa" />
          </div>
        </Tooltip>);
    else if (isDeprecated)
      tags.push(
        <Tooltip title="Will be removed soon" placement="top" arrow>
          <div className="label deprecated" >
            <FormattedMessage id="deprecated" />
          </div>
        </Tooltip>);
    else if (isOptimizing)
      tags.push(
        <Tooltip title="Currently unavailable for optimization" placement="top" arrow>
          <div className="label optimizing">
            <FormattedMessage id="optimizing" />
          </div>
        </Tooltip>
      );
    else if (isApiNew)
      tags.push(
        <Tooltip title="Added in the last 3 months" placement="top" arrow>
          <div className="label new">
            <FormattedMessage id="new" />
          </div>
        </Tooltip>
      );
    else if (isApiUpdated)
      tags.push(
        <Tooltip title="Improved in the last 3 months" placement="top" arrow>
          <div className="label updated">
            <FormattedMessage id="updated" />
          </div>
        </Tooltip>
      );
    return <>{tags}</>;
  }

  render() {
    const isNew = DateUtils.isNewOrUpdated((this.props.summary as ApiSummary | Event).publicationDate);
    const isDeprecated = (this.props.summary as ApiSummary | Event).isDeprecated;
    const isDcsa = (this.props.summary as ApiSummary | Event).isDcsa;
    const isOptimizing = (this.props.summary as ApiSummary | Event).isOptimizing;
    const isPilot = (this.props.summary as ApiSummary | Event).isPilot;
    const grayBackground = isDeprecated || isOptimizing || isPilot;
    const isUpdated = DateUtils.isNewOrUpdated((this.props.summary as ApiSummary | Event).lastUpdatedDate);
    
    return (
      <div className="tile" style={{ backgroundColor: grayBackground ? "#e6e9f0" : "#ffffff" }} onClick={() => this.props.onDetailsClick()}>
        <div className="content-container">
          <div className="tile-header">
            <div className="label-container">
              {(this.props.summary as ApiSummary).isUserSubscribed &&
                <span style={{ float: "left" }}>
                  <Tooltip title="Subscribed" placement="top" arrow>
                    <div>
                      <span className="circle"></span>
                    </div>
                  </Tooltip></span>}
              <span style={{ float: "right" }}>
                {this.tagDisplayer(isNew, isDeprecated, isDcsa, isOptimizing, isPilot, isUpdated)}</span>
            </div>
          </div>

          <div className="name" ref={this.nameDivRef}>
            {this.props.summary.title}
          </div>

          <div
            className="description"
            style={{
              WebkitLineClamp: this.state.descLineClamp || "none",
            }}
          >
            {Utils.stripHtml(this.props.summary.description)}
          </div>
        </div>

        <div className="tile-footer">
          <div className="left-content">
            {this.props.summary.virtualizedVersion &&
              `v${this.props.summary.virtualizedVersion}`}
          </div>

          <div className="right-content">
            {this.props.summary.type === ProductType.API?(
              DateUtils.formatDate(
                (this.props.summary as ApiSummary).publicationDate
              ))
              :(
                DateUtils.formatDate(
                  (this.props.summary as Event).lastUpdatedDate
                )
              )
                }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Tile);
