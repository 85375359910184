import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { Tab, Tabs } from "@material-ui/core";

import { apiFamilies } from "../../../constants/api-family.constant";
import { Routes } from "../../../../common/enums/routes.enum";
import { eventFamilies } from "../../../constants/event-family.constant";
import { ProductType } from "../../../enums/product-family.enum";

interface IRouteProps {
  familyId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {}

class Menu extends Component<IProps> {
  private onFamilyClick = (familyId?: string) => {
    this.props.history.push(familyId==="subscription"?`${Routes.PRODUCTS}/api/eventhub.subscription.v1`:`${Routes.PRODUCTS}/${familyId}`);
  };

  private getActiveTab = (productType: ProductType): number | boolean => {
    let index;

    if (productType === ProductType.API)
      index = apiFamilies.findIndex(
        (apiFamily) => apiFamily.id === this.props.match.params.familyId
      );
    else
      index = eventFamilies.findIndex(
        (eventFamily) => eventFamily.id === this.props.match.params.familyId
      );

    return index === -1 ? false : index;
  };

  render() {
    return (
      <div id="menu">
        <div className="apiMenu">
          <div className="titleMenu">
            <FormattedMessage id="apis" />
          </div>
          <Tabs
            className="family-menu"
            orientation="vertical"
            value={this.getActiveTab(ProductType.API)}
          >
            {apiFamilies.map((apiFamily, index) => (
              <Tab
                className="tab"
                key={`tab-${apiFamily.id}`}
                onClick={() => this.onFamilyClick(apiFamily.id)}
                label={apiFamily.name}
                value={index}
              />
            ))}
          </Tabs>
        </div>
        <div className="eventMenu">
          <div className="titleMenu">
            <FormattedMessage id="events" />
          </div>
          <Tabs
            className="family-menu"
            orientation="vertical"
            value={this.getActiveTab(ProductType.EVENT)}
          >
            {eventFamilies.map((eventFamily, index) => (
              <Tab
                className="tab"
                key={`tab-${eventFamily.id}`}
                onClick={() => this.onFamilyClick(eventFamily.id)}
                label={eventFamily.name}
                value={index}
              />
            ))}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
