import { FormFieldError } from "./form-field-error.model";

export class InputOptions {
  error?: FormFieldError;
  label?: string;
  placeholder?: string;
  tooltip?: string;
  isMandatory?: boolean;
  isLatinCharacters?:boolean;

  constructor(options: InputOptions) {
    this.error = options.error;
    this.label = options.label;
    this.placeholder = options.placeholder ?? "";
    this.isLatinCharacters = options.isLatinCharacters === undefined ? false:options.isLatinCharacters;
    this.tooltip = options.tooltip;
    this.isMandatory =
      options.isMandatory === undefined ? false : options.isMandatory;
  }
}
