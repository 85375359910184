import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Helmet } from 'react-helmet';
import { Routes } from "../../../common/enums/routes.enum";
import HeaderBanner from "../../../common/components/headerBanner";
import { IReleaseNote } from "../interfaces/release-notes.interface";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteComponentProps, PropsFromRedux {}

class ReleaseNotes extends Component<IProps> {
  private redirectTo = (event: any, route: string) => {
    event.preventDefault();
    this.props.history.push(route);
  };

  private getBoldValue = (tag: string = "b") => ({
    [tag]: (word: any) => <b>{word}</b>,
  });

  private getItalicValue = (tag: string = "i") => ({
    [tag]: (word: any) => <i>{word}</i>,
  });

  private getLinkValue = (route: string, tag: string = "a") => ({
    [tag]: (link: any) => (
      <a href={route} onClick={(event) => this.redirectTo(event, route)}>
        {link}
      </a>
    ),
  });
  
  render() {
    let linkToApiApplications = this.getBoldValue();
    let linkToEventApplications = this.getBoldValue("b1");
    let linkToBusinessPartners = this.getBoldValue();
    let linkToApiStatus = this.getBoldValue();
    let linkToClientSettings = this.getBoldValue();
    let linkToSubscribtion = this.getLinkValue(`${Routes.PRODUCTS}/api/eventhub.subscription.v1`,"b")
    let linkToScheduleEvent = this.getLinkValue(`${Routes.PRODUCTS}/event/scheduleEvent`,"b")
    let linkToDocumentEvent = this.getLinkValue(`${Routes.PRODUCTS}/event/documentEvent`,"b")
    let linkToShipmentEvent = this.getLinkValue(`${Routes.PRODUCTS}/event/shipmentEvent`,"b")
    let linkToRoadmap = this.getLinkValue(Routes.ROADMAP);
    let linkToCatalogSearch = this.getLinkValue(
      `${Routes.PRODUCTS}/search?s=invoice`
    );

    if (!!this.props.user) {
      linkToApiApplications = this.getLinkValue(Routes.API_APPLICATIONS, "b");
      linkToEventApplications = this.getLinkValue(
        Routes.EVENT_APPLICATIONS,
        "b1"
      );
      linkToBusinessPartners = this.getLinkValue(Routes.BUSINESS_PARTNERS, "b");
      linkToApiStatus = this.getLinkValue(Routes.API_STATUS, "b");
      linkToClientSettings = this.getLinkValue(Routes.CLIENT_SETTINGS, "b");
    }

    const releaseNotes: IReleaseNote[] = [
      {
        title:<FormattedMessage id="releaseNotes.version.14.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.14.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.14.category.1.item.1",
                values: linkToSubscribtion,
              },
              {
                id: "releaseNotes.version.14.category.1.item.2",
                values: linkToDocumentEvent,
              }
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.14.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.14.category.2.item.1",
                values: this.getLinkValue(Routes.FAQ),
              },
              
            ],
          },
        ],
      },
      //V13
      {
        title: <FormattedMessage id="releaseNotes.version.13.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.13.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.13.category.1.item.1",
                values: linkToScheduleEvent,
              },
              {
                id: "releaseNotes.version.13.category.1.item.2",
                values: linkToDocumentEvent,
              },
              {
                id: "releaseNotes.version.13.category.1.item.3",
                values: linkToShipmentEvent,
              },
            ],
          },
        
        ],
      },
      //v10
      {
        title: <FormattedMessage id="releaseNotes.version.10.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.10.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.10.category.1.item.1",
                values: linkToBusinessPartners,
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.10.category.2.title" />
            ),
            subTitle: (
              <FormattedMessage id="releaseNotes.version.10.category.2.subtitle" values={linkToClientSettings} />
            ),
            list: [
              {
                id: "releaseNotes.version.10.category.2.item.1",
              },
              {
                id: "releaseNotes.version.10.category.2.item.2",
              },
              {
                id: "releaseNotes.version.10.category.2.item.3",
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.10.category.3.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.10.category.3.item.1",
              }
            ],
          },
        ],
      },
      // v9.1
      {
        title: <FormattedMessage id="releaseNotes.version.9.1.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.9.1.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.9.1.category.1.item.1",
                values: linkToEventApplications,
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.9.1.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.9.1.category.2.item.1",
                values: {
                  ...this.getLinkValue(`${Routes.PRODUCTS}/allocation`),
                  ...this.getLinkValue(
                    `${Routes.PRODUCTS}/api/commercial.allocation.route.v1`,
                    "a1"
                  ),
                },
              },
            ],
          },
        ],
      },

      // v9
      {
        title: <FormattedMessage id="releaseNotes.version.9.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.9.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.9.category.1.item.1",
                values: linkToBusinessPartners,
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.9.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.9.category.2.item.1",
                values: linkToRoadmap,
              },
              {
                id: "releaseNotes.version.9.category.2.item.2",
              },
              {
                id: "releaseNotes.version.9.category.2.item.3",
                values: linkToCatalogSearch,
              },
            ],
          },
        ],
      },

      // V8
      {
        title: <FormattedMessage id="releaseNotes.version.8.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.8.category.1.title" />
            ),
            list: [{ id: "releaseNotes.version.8.category.1.item.1" }],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.8.category.2.title" />
            ),
            list: [
              { id: "releaseNotes.version.8.category.2.item.1" },
              // { id: "releaseNotes.version.8.category.2.item.2" },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.8.category.3.title" />
            ),
            list: [{ id: "releaseNotes.version.8.category.3.item.1" }],
          },
        ],
      },

      // V7.1
      {
        title: <FormattedMessage id="releaseNotes.version.7.1.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.7.1.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.7.1.category.1.item.1",
                values: linkToEventApplications,
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.7.1.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.7.1.category.2.item.1",
                values: linkToRoadmap,
              },
            ],
          },
        ],
      },

      // V7
      {
        title: <FormattedMessage id="releaseNotes.version.7.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.7.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.7.category.1.item.1",
                values: this.getLinkValue(Routes.FAQ),
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.7.category.3.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.7.category.3.item.1",
                values: {
                  ...this.getLinkValue(`${Routes.PRODUCTS}/invoice`),
                  ...this.getLinkValue(
                    `${Routes.PRODUCTS}/api/shipping.invoicing.customer.v1`,
                    "a1"
                  ),
                },
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.7.category.4.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.7.category.4.item.1",
              },
              {
                id: "releaseNotes.version.7.category.4.item.2",
              },
            ],
          },
        ],
      },

      // V6
      {
        title: <FormattedMessage id="releaseNotes.version.6.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.6.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.6.category.1.item.1",
                values: linkToBusinessPartners,
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.6.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.6.category.2.item.1",
                values: {
                  ...linkToEventApplications,
                  ...linkToApiApplications,
                },
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.6.category.3.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.6.category.3.item.1",
                values: linkToRoadmap,
              },
            ],
          },
        ],
      },

      // V5
      {
        title: <FormattedMessage id="releaseNotes.version.5.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.5.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.5.category.1.item.1",
                values: linkToApiStatus,
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.5.category.2.title" />
            ),
            list: [{ id: "releaseNotes.version.5.category.2.item.1" }],
          },
        ],
      },

      // V4.1
      {
        title: <FormattedMessage id="releaseNotes.version.4.1.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.4.1.category.1.title" />
            ),
            subTitle: (
              <FormattedMessage id="releaseNotes.version.4.1.category.1.subtitle" />
            ),
            list: [
              {
                id: "releaseNotes.version.4.1.category.1.item.1",
                values: linkToApiApplications,
              },
              {
                id: "releaseNotes.version.4.1.category.1.item.2",
                values: linkToBusinessPartners,
              },
            ],
          },
        ],
      },

      // V4
      {
        title: <FormattedMessage id="releaseNotes.version.4.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.4.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.4.category.1.item.1",
                values: this.getLinkValue(Routes.PRODUCTS + "/bl-suites"),
              },
              {
                id: "releaseNotes.version.4.category.1.item.2",
                values: this.getLinkValue(
                  Routes.PRODUCTS + "/event/shipmentEvent"
                ),
              },
              { id: "releaseNotes.version.4.category.1.item.3" },
            ],
          },
          {
            title: (
              <FormattedMessage
                id="releaseNotes.version.4.category.2.title"
                values={this.getBoldValue()}
              />
            ),
            list: [],
          },
        ],
      },

      // V3
      {
        title: <FormattedMessage id="releaseNotes.version.3.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.3.category.1.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.3.category.1.item.1",
                values: this.getItalicValue(),
              },
              {
                id: "releaseNotes.version.3.category.1.item.2",
                values: this.getItalicValue(),
              },
              {
                id: "releaseNotes.version.3.category.1.item.3",
                values: {
                  ...this.getItalicValue(),
                  ...this.getLinkValue(
                    Routes.PRODUCTS + "/event/equipmentEvent"
                  ),
                  ...this.getLinkValue(
                    Routes.PRODUCTS + "/event/transportEvent",
                    "a1"
                  ),
                },
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.3.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.3.category.2.item.1",
                values: this.getItalicValue(),
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.3.category.3.title" />
            ),
            list: [{ id: "releaseNotes.version.3.category.3.item.1" }],
          },

          {
            title: (
              <FormattedMessage id="releaseNotes.version.3.category.4.title" />
            ),
            list: [{ id: "releaseNotes.version.3.category.4.item.1" }],
          },
        ],
      },

      // V2
      {
        title: <FormattedMessage id="releaseNotes.version.2.title" />,
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.2.category.1.title" />
            ),
            list: [
              { id: "releaseNotes.version.2.category.1.item.1" },
              { id: "releaseNotes.version.2.category.1.item.2" },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.2.category.2.title" />
            ),
            list: [
              {
                id: "releaseNotes.version.2.category.2.item.1",
                values: this.getItalicValue(),
              },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.2.category.3.title" />
            ),
            list: [{ id: "releaseNotes.version.2.category.3.item.1" }],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.2.category.4.title" />
            ),
            list: [{ id: "releaseNotes.version.2.category.4.item.1" }],
          },
        ],
      },

      // V1
      {
        title: (
          <FormattedMessage
            id="releaseNotes.version.1.title"
            values={this.getItalicValue()}
          />
        ),
        sections: [
          {
            title: (
              <FormattedMessage id="releaseNotes.version.1.category.1.title" />
            ),
            list: [{ id: "releaseNotes.version.1.category.1.item.1" }],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.1.category.2.title" />
            ),
            list: [
              { id: "releaseNotes.version.1.category.2.item.1" },
              { id: "releaseNotes.version.1.category.2.item.2" },
            ],
          },
          {
            title: (
              <FormattedMessage id="releaseNotes.version.1.category.3.title" />
            ),
            list: [{ id: "releaseNotes.version.1.category.3.item.1" }],
          },
        ],
      },
    ];

    return (
      <div id="release-notes">
        <Helmet>
          <title>Help - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="releaseNotes.title" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content-container">
            <div className="content">
              {releaseNotes.map((releaseNote, i) => (
                <div key={`category-${i}`} className="category">
                  <div className="header large">{releaseNote.title}</div>

                  <div className="category-content">
                    {releaseNote.sections.map((section, j) => (
                      <React.Fragment key={`category-${i}-section-${j}`}>
                        <div className="header">
                          <div className="title">{section.title}</div>
                        </div>

                        {!!section.subTitle && (
                          <div className="subtitle">{section.subTitle}</div>
                        )}

                        {section.list.length > 0 && (
                          <ul>
                            {section.list.map((item, k) => (
                              <li key={`category-${i}-section-${j}-item-${k}`}>
                                <FormattedMessage
                                  id={item.id}
                                  values={item.values}
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const connector = connect(mapState);
export default connector(ReleaseNotes);
