import { TFormInputId, TFormValue } from "../enums/form.enum";
import { FormField } from "./form-field.model";
import { FormFieldError } from "./form-field-error.model";
import { IField, IInputAdditionalParams } from "../interfaces/form.interface";

export class Form {
  fields: FormField[];

  constructor(fields: FormField[]) {
    this.fields = fields;
  }

  public getFormField(id: TFormInputId): FormField | undefined {
    const index = this.fields.findIndex((field) => field.id === id);
    if (index > -1) return this.fields[index];
    return undefined;
  }

  public getFormFieldValue(id: TFormInputId): TFormValue {
    const index = this.fields.findIndex((field) => field.id === id);
    if (index > -1) return this.fields[index].value;
    return undefined;
  }

  public isFormValid(additionalParams: IInputAdditionalParams = {}): boolean {
    const formErrors: FormFieldError[] = this.fields
      .map((el) => el.getFieldError(additionalParams))
      .filter((el) => !!el) as FormFieldError[];

    let isFormValid = true;

    this.fields.forEach((field) => {
      const error = field.getFieldError(additionalParams);
      field.setFieldError(error);
      if (!!error) isFormValid = false;
    });

    return formErrors.length === 0;
  }

  public updateFormFieldError(
    id: TFormInputId,
    hasPreviousError: boolean,
    isOnChange = false,
    additionalParams: IInputAdditionalParams
  ) {
    const field = this.fields.find((el) => el.id === id);
    if (field) {
      const currentError = field.getFieldError(additionalParams);

      if (isOnChange) {
        // If it's when editing the value,
        // remove the error only if the current value is valid
        if (hasPreviousError && !currentError) field.setFieldError(undefined);
      } else {
        // Else, when on focus lost, handle the error normally
        field.setFieldError(currentError);
      }
    }
  }

  public updateFormField(field: IField) {
    const fieldToUpdate = this.fields.find((el) => el.id === field.id);
    if (fieldToUpdate) {
      if ("value" in field) fieldToUpdate.value = field.value;

      if ("options" in field) {
        fieldToUpdate.options = {
          ...fieldToUpdate.options,
          ...field.options,
        };
      }
    }
  }

  public updateFormFields(fields: IField[]) {
    fields.forEach((field) => {
      this.updateFormField(field);
    });
  }
}
