export enum ReducerAction {
  EN_LANGUAGE = "enLanguage",
  FR_LANGUAGE = "frLanguage",
  USER_LOGGED_IN = "userLoggedIn",
  USER_LOGGED_OUT = "userLoggedOut",
  TOAST = "toast",
  RE_CAPTCHA_TOKEN_FETCH = "reCaptchaTokenFetch",
  COUNTRIES_FETCH = "countriesFetch",
  OPEN_DESCRIPTION_PANEL = "openDescriptionPanel",
  CLOSE_DESCRIPTION_PANEL = "closeDescriptionPanel",
  ADDED_OPEN_REQUEST_PARTNER = "addedOpenRequestPartner",
  RESIZE_HANDLE = "resizeHandle",
  SCROLL_TO_TOP = "scrollToTop",
}

export enum Language {
  FR = "fr",
  EN = "en",
}
