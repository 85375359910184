import React, { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AddIcon from "@material-ui/icons/Add";

import CreationModal from "../creationModal";
import { IPartner } from "../../../interfaces/business-partners.interface";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import { ScreenType } from "../../../../../common/enums/screen-type.enum";
import { Button } from "@material-ui/core";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  enrichPartner?: IPartner;
  className?:string;
}

interface IStates {
  isNewBpModalOpened: boolean;
}

class CreationButton extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isNewBpModalOpened: false,
    };
  }

  private displayNewBpModal = (event: any, isNewBpModalOpened = true) => {
    if (event) event.stopPropagation();
    this.setState({ isNewBpModalOpened });
  };

  render() {
    if (!this.props.user || !this.props.user.letterOfAgreement) {
      return null;
    }

    return (
      <>
        {!this.props.enrichPartner && this.props.user?.main && <Button title="Add New Customer"
          className={`button ${this.props.className || ""}`} style={{backgroundColor:"#E20101"}}
          onClick={(event) => this.displayNewBpModal(event)}
        >
          <FormattedMessage id="partner.newCustomer" />
          {/* // : !!this.props.enrichPartner ? (
          //   <FormattedMessage id="partner.enrichCustomer" />
          // ) : (
          //   <FormattedMessage id="partner.newCustomer" />
          // ) */}
        </Button>}

      

        <CreationModal
          isOpened={this.state.isNewBpModalOpened}
          closeNewBp={() => this.displayNewBpModal(null, false)}
          enrichPartner={this.props.enrichPartner}
        />
      </>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
  windowWidth: state.windowWidth,
});

const connector = connect(mapState);
export default connector(CreationButton);
