import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { FormGroup } from "@material-ui/core";

import Loader from "../../../common/components/loader";
import CrmAPI from "../../../crm/apis/crm.api";
import { ToastType } from "../../../common/submodules/toast/enums/toast-type.enum";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import {
  customerTypes,
  offers,
  requestReasons,
} from "../../../crm/constants/crm.constant";
import ReCaptchaAPI from "../../../common/apis/re-captcha.api";
import { ContactDTO } from "../../models/contact.model";
import {
  BusinessRequestInputId,
  EFieldType,
  FormSelectValue,
  TFormInputId,
} from "../../../common/submodules/form/enums/form.enum";
import { HttpCode } from "../../../common/enums/http-code.enum";
import ReCaptchaUtils from "../../../../utils/re-captcha.utils";
import { IToastAlert } from "../../../common/submodules/toast/interfaces/toast-alert.interface";
import { defaultToastAlert } from "../../../common/submodules/toast/constants/default-toast-alert.constant";
import ToastAlert from "../../../common/submodules/toast/components/toastAlert";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { FormField } from "../../../common/submodules/form/models/form-field.model";
import { Form } from "../../../common/submodules/form/models/form.model";
import FormUtils from "../../../../utils/form.utils";
import {
  IField,
  IInputAdditionalParams,
} from "../../../common/submodules/form/interfaces/form.interface";
import { ELoaderSize } from "../../../common/enums/loader.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {}

interface IStates {
  form: Form;
  isSubmitLoading: boolean;
  reCaptchaToken: string | undefined;
  isReCaptchaSecretLoading: boolean;
  toast: IToastAlert;
}

class BusinessRequest extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      form: this.updateFormWithUserProps(
        new Form(BusinessRequest.generateForm())
      ),
      isSubmitLoading: false,
      reCaptchaToken: undefined,
      isReCaptchaSecretLoading: true,
      toast: defaultToastAlert,
    };
  }

  componentDidMount() {
    if (!this.props.reCaptchaSecret) {
      ReCaptchaAPI.getReCaptchaSecret().then((reCaptchaSecret) => {
        this.props.saveReCaptchaSecret(reCaptchaSecret);
      });
    }
    this.setState({
      isReCaptchaSecretLoading: false,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<PropsFromRedux>,
    prevState: Readonly<IStates>
  ) {
    if (prevProps.user !== this.props.user) {
      this.setState({ form: this.updateFormWithUserProps() });
    }
  }

  private static generateForm(): FormField[] {
    return [
      new FormField(BusinessRequestInputId.FIRST_NAME, "", {
        label: "form.field.firstName",
        placeholder: "form.field.firstName.placeholder",
        isMandatory: true,
      }),
      new FormField(BusinessRequestInputId.LAST_NAME, "", {
        label: "form.field.lastName",
        placeholder: "form.field.lastName.placeholder",
        isMandatory: true,
      }),
      new FormField(
        BusinessRequestInputId.EMAIL,
        "",
        {
          label: "form.field.email",
          placeholder: "form.field.email.placeholder",
          isMandatory: true,
        },
        EFieldType.EMAIL
      ),
      new FormField(
        BusinessRequestInputId.PHONE,
        "",
        {
          label: "form.field.phone",
          isMandatory: true,
        },
        EFieldType.PHONE
      ),
      new FormField(BusinessRequestInputId.PHONE_COUNTRY_CODE, "fr", {
        isMandatory: true,
      }),
      new FormField(BusinessRequestInputId.PHONE_COUNTRY_DIAL_CODE, 33, {
        isMandatory: true,
      }),
      new FormField(
        BusinessRequestInputId.COUNTRY_NAME,
        FormSelectValue.NONE,
        {
          label: "form.field.country",
          placeholder: "form.field.country.placeholder",
          isMandatory: true,
        },
        EFieldType.COUNTRY
      ),
      new FormField(BusinessRequestInputId.COUNTRY_CODE, "", {
        isMandatory: true,
      }),
      new FormField(BusinessRequestInputId.COMPANY_NAME, "", {
        label: "form.field.companyName",
        placeholder: "form.field.companyName.placeholder",
        isMandatory: true,
      }),
      new FormField(
        BusinessRequestInputId.COMPANY_TYPE,
        FormSelectValue.PLACEHOLDER,
        {
          label: "form.field.companyType",
          placeholder: "form.field.companyType.placeholder",
          isMandatory: true,
        },
        EFieldType.SELECT
      ),
      new FormField(
        BusinessRequestInputId.REQUEST_REASON,
        FormSelectValue.PLACEHOLDER,
        {
          label: "contactUs.businessRequest.requestReason",
          placeholder: "contactUs.businessRequest.requestReasonSelect",
          isMandatory: true,
        },
        EFieldType.SELECT
      ),
      new FormField(
        BusinessRequestInputId.OFFER,
        FormSelectValue.PLACEHOLDER,
        {
          label: "contactUs.businessRequest.offer",
          placeholder: "contactUs.businessRequest.offerSelect",
        },
        EFieldType.SELECT
      ),
      new FormField(
        BusinessRequestInputId.MESSAGE,
        "",
        {
          label: "contactUs.businessRequest.message",
          placeholder: "contactUs.businessRequest.message.placeholder",
        },
        EFieldType.TEXTAREA
      ),
    ];
  }

  private updateFormWithUserProps = (form = this.state.form): Form => {
    if (this.props.user) {
      let fields: IField[] = [
        {
          id: BusinessRequestInputId.FIRST_NAME,
          value: this.props.user.givenName,
        },
        {
          id: BusinessRequestInputId.LAST_NAME,
          value: this.props.user.familyName,
        },
        { id: BusinessRequestInputId.EMAIL, value: this.props.user.email },
        {
          id: BusinessRequestInputId.COMPANY_NAME,
          value: this.props.user.clientRequestName,
        },
        {
          id: BusinessRequestInputId.OFFER,
          value: FormSelectValue.PLACEHOLDER,
        },
      ];

      if (this.props.user.clientAddress) {
        fields = [
          ...fields,

          {
            id: BusinessRequestInputId.COUNTRY_NAME,
            value: this.props.user.clientAddress.country,
          },
          {
            id: BusinessRequestInputId.COUNTRY_CODE,
            value: this.props.user.clientAddress.countryCode,
          },
        ];
      }

      const foundCustomerType = customerTypes.find(
        (elt) => elt.name == this.props.user?.clientBusinessProfile
      );

      if (foundCustomerType) {
        fields.push({
          id: BusinessRequestInputId.COMPANY_TYPE,
          value: foundCustomerType.value,
        });
      }

      form.updateFormFields(fields);
    }

    return form;
  };

  private getInput(
    id: TFormInputId,
    additionalParams: IInputAdditionalParams = {}
  ): JSX.Element | null {
    const disabled =
      !!this.props.user &&
      (id === BusinessRequestInputId.EMAIL ||
        id === BusinessRequestInputId.FIRST_NAME ||
        id === BusinessRequestInputId.LAST_NAME ||
        id === BusinessRequestInputId.COMPANY_NAME);

    const field = this.state.form.getFormField(id);

    if (field) {
      const countryCode = this.state.form.getFormFieldValue(
        BusinessRequestInputId.COUNTRY_CODE
      ) as string;

      const phoneCountryCode = this.state.form.getFormFieldValue(
        BusinessRequestInputId.PHONE_COUNTRY_CODE
      ) as string;

      const phoneCountryDialCode = this.state.form.getFormFieldValue(
        BusinessRequestInputId.PHONE_COUNTRY_DIAL_CODE
      ) as number;

      return FormUtils.getInput(field, this.onChange, this.onBlur, disabled, {
        ...additionalParams,
        countryCode,
        phoneCountryCode,
        phoneCountryDialCode,
      });
    }

    return null;
  }

  private handleVerify = (reCaptchaToken: string) => {
    this.setState({ reCaptchaToken }, this.submitForm);
  };

  private onSendClick = () => {
    if (this.isFormValid() && this.props.reCaptchaSecret) {
      this.setState({ isSubmitLoading: true }, () =>
        ReCaptchaUtils.verifyReCaptcha(
          this.props.reCaptchaSecret as string,
          this.handleVerify
        )
      );
    }
  };

  private verifyField = (
    id: TFormInputId,
    hasPreviousError: boolean,
    isOnChange = false,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    form.updateFormFieldError(
      id,
      hasPreviousError,
      isOnChange,
      additionalParams
    );
    this.setState({ form });
  };

  private onChange = (
    id: TFormInputId,
    value: string | boolean,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;

    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );
    if (previousFieldState) {
      form.updateFormField({ id, value });

      if (additionalParams.countryCode)
        form.updateFormField({
          id: BusinessRequestInputId.COUNTRY_CODE,
          value: additionalParams.countryCode,
        });

      if (additionalParams.phoneCountryCode)
        form.updateFormField({
          id: BusinessRequestInputId.PHONE_COUNTRY_CODE,
          value: additionalParams.phoneCountryCode,
        });

      if (additionalParams.phoneCountryDialCode)
        form.updateFormField({
          id: BusinessRequestInputId.PHONE_COUNTRY_DIAL_CODE,
          value: additionalParams.phoneCountryDialCode,
        });

      this.setState({ form }, () =>
        this.verifyField(
          id,
          !!previousFieldState.options.error,
          true,
          additionalParams
        )
      );
    }
  };

  private onBlur = (id: TFormInputId) => {
    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );

    const phoneCountryCode = this.state.form.getFormFieldValue(
      BusinessRequestInputId.PHONE_COUNTRY_CODE
    ) as string;

    if (previousFieldState) {
      this.verifyField(id, !!previousFieldState.options.error, false, {
        phoneCountryCode,
      });
    }
  };

  private isFormValid(): boolean {
    const form = this.state.form;

    const phoneCountryCode = this.state.form.getFormFieldValue(
      BusinessRequestInputId.PHONE_COUNTRY_CODE
    ) as string;

    const isFormValid = form.isFormValid({ phoneCountryCode });
    this.setState({ form });
    return isFormValid;
  }

  private submitForm() {
    CrmAPI.postContact(
      new ContactDTO(this.state.form),
      this.state.reCaptchaToken
    )
      .then(() => {
        this.setState({
          isSubmitLoading: false,
          form: this.updateFormWithUserProps(
            new Form(BusinessRequest.generateForm())
          ),
          toast: {
            isDisplayed: true,
            type: ToastType.SUCCESS,
            message: (
              <FormattedMessage id="contactUs.businessRequest.requestSent" />
            ),
          },
        });
      })
      .catch((error) => {
        const message =
          error.response.status === HttpCode.FORBIDDEN ? (
            <FormattedMessage id="unauthorized" />
          ) : (
            <FormattedMessage id="error.errorOccurredFull" />
          );

        this.setState({
          isSubmitLoading: false,
          toast: {
            isDisplayed: true,
            type: ToastType.ERROR,
            message,
          },
        });
      });
  }

  private onToastHide = () => {
    this.setState({ toast: defaultToastAlert });
  };

  private getSegmentContent() {
    if (this.state.isReCaptchaSecretLoading) {
      return <Loader />;
    }

    if (!this.props.reCaptchaSecret) {
      return (
        <div className="finalMessage">
          <div>
            <div className="form-title">
              <FormattedMessage id="error.errorOccurred" />
            </div>
            <div className="form-message">
              <FormattedMessage id="error.tryAgainLater" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="form-content">
        <FormGroup>
          <div className={`inputs-container ${this.props.screenType}`}>
            {this.getInput(BusinessRequestInputId.FIRST_NAME)}
            {this.getInput(BusinessRequestInputId.LAST_NAME)}
            {this.getInput(BusinessRequestInputId.EMAIL)}

            {this.getInput(BusinessRequestInputId.PHONE)}

            {this.getInput(BusinessRequestInputId.COUNTRY_NAME)}
            {this.getInput(BusinessRequestInputId.COMPANY_NAME)}

            {this.getInput(BusinessRequestInputId.COMPANY_TYPE, {
              selectItems: customerTypes,
            })}

            {this.getInput(BusinessRequestInputId.REQUEST_REASON, {
              selectItems: requestReasons,
            })}

            {this.props.user &&
              this.getInput(BusinessRequestInputId.OFFER, {
                selectItems: offers,
              })}
          </div>

          <div>{this.getInput(BusinessRequestInputId.MESSAGE)}</div>
        </FormGroup>

        <div className="button-container">
          <div
            className={`button${this.state.isSubmitLoading ? " disabled" : ""}`}
            onClick={this.onSendClick}
          >
            {this.state.isSubmitLoading ? (
              <Loader size={ELoaderSize.SMALL} />
            ) : (
              <FormattedMessage id="contactUs.send" />
            )}
          </div>

          {this.state.toast.isDisplayed && (
            <ToastAlert
              toastType={this.state.toast.type}
              toastMessage={this.state.toast.message}
              hideToast={this.onToastHide}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="business-request-container">
        <FormattedMessage id="contactUs.businessRequest.content" />
        {this.getSegmentContent()}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  reCaptchaSecret: state.reCaptchaSecret,
  screenType: state.screenType,
});

const mapDispatch = {
  saveReCaptchaSecret: (reCaptchaSecret: string) => ({
    type: ReducerAction.RE_CAPTCHA_TOKEN_FETCH,
    payload: reCaptchaSecret,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(BusinessRequest);
