import "./styles.scss";

import React from "react";
import { FormattedMessage } from "react-intl";

import Utils from "../../../../utils";

interface IProps {
  totalSuccesses: number;
  totalErrors: number;
  color: string;
}

export default class MessagesCount extends React.Component<IProps> {
  render() {
    const total = this.props.totalSuccesses + this.props.totalErrors;
    const successesPercentage = Utils.percentage(
      total,
      this.props.totalSuccesses
    );
    const errorsPercentage = Utils.percentage(total, this.props.totalErrors);

    return (
      <div className="messages-count">
        <div className="count-container">
          <div className="title">
            <FormattedMessage id="messages" />
          </div>
          <div className="count">
            <p>{total}</p>
          </div>
        </div>

        <div className="count-container">
          <div className="title">
            <FormattedMessage id="successes" />
          </div>
          <div className="count">
            <p>
              {this.props.totalSuccesses}
              {successesPercentage !== undefined && (
                <>&nbsp;({successesPercentage}%)</>
              )}
            </p>
          </div>
        </div>
        <div className="progress-bar-custom">
          <div
            className="progress"
            style={{
              backgroundColor: this.props.color,
              width:
                (successesPercentage ? successesPercentage : 0).toString() +
                "%",
            }}
          />
        </div>

        <div className="count-container">
          <div className="title">
            <FormattedMessage id="errors" />
          </div>
          <div className="count">
            <p>
              {this.props.totalErrors}
              {errorsPercentage !== undefined && (
                <>&nbsp;({errorsPercentage}%)</>
              )}
            </p>
          </div>
        </div>
        <div className="progress-bar-custom">
          <div
            className="progress"
            style={{
              backgroundColor: this.props.color,
              width: (errorsPercentage ? errorsPercentage : 0).toString() + "%",
            }}
          />
        </div>
      </div>
    );
  }
}
