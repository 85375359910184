import "./styles.scss";

import React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { Switch } from "@material-ui/core";

import MetricsChart from "../../../../common/components/metricsChart";
import {
  IMetrics,
  ISeriesMetric,
} from "../../../apiApplications/interfaces/metrics.interface";
import MetricsAPI from "../../../apiApplications/apis/metrics.api";
import PeriodFilter from "../../../../common/submodules/form/components/periodFilter";
import { eventFamilies } from "../../../../productCatalog/constants/event-family.constant";
import chartColors from "../../../../common/constants/chart-colors.constant";
import FamilyTile from "./familyTile";
import { IChartOptions } from "../../../../common/interfaces/metricsChart.interface";
import { IProductsFamilyWithColor } from "../../../../productCatalog/interfaces/products-family.interface";
import { ProductFamily } from "../../../../productCatalog/enums/product-family.enum";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}

interface IStates {
  areMetricsVisible: boolean;
  isMetricListLoading: boolean;
  data: IMetrics | undefined;
  series: ISeriesMetric[];
  chartOptions: IChartOptions;
  checkedItems: ProductFamily[];
  selectedFamily: ProductFamily | undefined;
}

class EventMetrics extends React.Component<IProps, IStates> {
  private readonly CHART_ID = "metrics-chart-event";
  private eventFamiliesWithColors: IProductsFamilyWithColor[] =
    eventFamilies.map((family, index) => ({
      ...family,
      color: chartColors[index],
    }));

  constructor(props: IProps) {
    super(props);

    this.state = {
      areMetricsVisible: false,
      isMetricListLoading: false,
      data: undefined,
      series: [],
      chartOptions: {
        periodStart: PeriodFilter.getDefaultPeriodStart(),
        periodEnd: new Date(),
        colors: [],
        shouldUpdateChart: false,
      },
      checkedItems: this.eventFamiliesWithColors.map(
        (family) => family.productFamily
      ),
      selectedFamily: undefined,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    if (this.state.data === undefined && !prevState.areMetricsVisible) {
      this.getMetrics();
    }
  }

  private getMetrics() {
    if (this.state.areMetricsVisible) {
      this.setState({ isMetricListLoading: true }, () => {
        MetricsAPI.getEventMetrics(
          this.state.chartOptions.periodStart,
          this.state.chartOptions.periodEnd
        )
          .then((data) => {
            this.setState(
              {
                data,
                isMetricListLoading: false,
              },
              this.updateChart
            );
          })
          .catch(() => this.setState({ isMetricListLoading: false }));
      });
    }
  }

  private updateChart = () => {
    let series: ISeriesMetric[] = [];

    if (this.state.data) {
      series = this.state.data.series
        .filter((el) =>
          this.state.checkedItems.includes(el.name as ProductFamily)
        )
        .map((el) => {
          const matchingFamily = this.eventFamiliesWithColors.find(
            (family) => el.name === family.id.toUpperCase()
          );

          const color = matchingFamily ? matchingFamily.color : "";

          return {
            ...el,
            color,
          };
        });
    }

    const chartOptions = { ...this.state.chartOptions };

    chartOptions.colors = this.eventFamiliesWithColors
      .filter((family) =>
        series.some((el) => el.name === family.id.toUpperCase())
      )
      .map((family) => family.color);

    chartOptions.shouldUpdateChart = !chartOptions.shouldUpdateChart;

    this.setState({ series, chartOptions });
  };

  private onViewMetricsToggle = () => {
    this.setState(
      { areMetricsVisible: !this.state.areMetricsVisible },
      () => this.state.areMetricsVisible && this.updateChart()
    );
  };

  private onPeriodSelected = (periodStart: Date, periodEnd: Date) => {
    const chartOptions = { ...this.state.chartOptions };
    chartOptions.periodStart = periodStart;
    chartOptions.periodEnd = periodEnd;

    this.setState({ chartOptions }, () => {
      this.getMetrics();
    });
  };

  private onFamilyCheck = (family: ProductFamily) => {
    const checkedItems = [...this.state.checkedItems];
    const index = checkedItems.findIndex((el) => el === family);

    if (index === -1) checkedItems.push(family);
    else checkedItems.splice(index, 1);

    this.setState({ checkedItems }, this.updateChart);
  };

  private onFamilySelection = (family: ProductFamily): boolean => {
    let selectedFamily = undefined;

    if (this.state.selectedFamily !== family) {
      selectedFamily = family;
    }

    this.setState({ selectedFamily });

    return !!selectedFamily;
  };

  private getFamilyCard = (
    eventFamily: IProductsFamilyWithColor
  ): JSX.Element => {
    let totalSuccesses = 0;
    let totalErrors = 0;

    if (this.state.data) {
      const familyMetric = this.state.data.items.find(
        (item) => item.name === eventFamily.id.toUpperCase()
      );

      if (familyMetric) {
        totalSuccesses = familyMetric.messages.successes.total;
        totalErrors = familyMetric.messages.errors.total;
      }
    }

    const isChecked = this.state.checkedItems.includes(
      eventFamily.productFamily
    );

    return (
      <FamilyTile
        key={`family-tile-${eventFamily.id}`}
        eventFamilies={this.eventFamiliesWithColors}
        eventFamily={eventFamily}
        chartOptions={this.state.chartOptions}
        totalSuccesses={totalSuccesses}
        totalErrors={totalErrors}
        isChecked={isChecked}
        selectedFamily={this.state.selectedFamily}
        onFamilyCheck={() => this.onFamilyCheck(eventFamily.productFamily)}
        onFamilySelected={this.onFamilySelection}
        onPeriodSelected={this.onPeriodSelected}
      />
    );
  };

  private getFamilyCards = (): JSX.Element | null => {
    if (!this.state.areMetricsVisible) {
      return null;
    }

    return (
      <div
        className={`family-tiles-container ${
          !!this.state.selectedFamily ? "family-selected" : ""
        }`}
      >
        {this.eventFamiliesWithColors.map(this.getFamilyCard)}
      </div>
    );
  };

  render() {
    return (
      <div className={`event-metrics ${this.props.screenType}`}>
        <div className="controls-container">
          {this.state.areMetricsVisible && (
            <PeriodFilter onPeriodSelected={this.onPeriodSelected} />
          )}

          <div className="switch-container">
            <Switch
              color="primary"
              checked={this.state.areMetricsVisible}
              onChange={this.onViewMetricsToggle}
            />
            <FormattedMessage id="viewMetrics" />
          </div>
        </div><br/>

        <MetricsChart
          chartId={this.CHART_ID}
          areMetricsVisible={this.state.areMetricsVisible}
          isMetricListLoading={this.state.isMetricListLoading}
          chartOptions={this.state.chartOptions}
          series={this.state.series}
          onPeriodSelected={this.onPeriodSelected}
        />

        {this.getFamilyCards()}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState, {});
export default connector(EventMetrics);
