import HttpUtils from "../../../utils/http.utils";
import { UserRegisteringGet } from "../models/user.model";

export default class TokenAPI {
  public static getUserByToken(token: string): Promise<UserRegisteringGet> {
    return HttpUtils.get(`/token/${token}`).then(
      (res: any) => new UserRegisteringGet(res)
    );
  }

  public static patchUserPassword(token: string, password: string) {
    return HttpUtils.patch(`/token/${token}`, { password });
  }
}
