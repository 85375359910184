import { INewProductsFamily } from "../interfaces/new-product.interface";

const shippingBlSuite = "shipping.shipment.billoflading.v1"

const shippingBookingRequest = "shipping.bookingrequest.v1"

const shippingInstruction = "shipping.shipment.shippinginstruction.v1"

const operationTrackAndTrace = "operation.trackandtrace.v1"

const commercialShippingDocument = "commercial.shippingdocument.v1"

const shippingInvoiceCustomer = "shipping.invoicing.customer.v1"

const vesselOperationVoyage = "vesseloperation.voyage.v2"

const pricingCommercialQuotation = "pricing.commercial.quotation.v2"

const documentEvent = "documentEvent"

export const newProductsFamily: INewProductsFamily[] = [
  {
    id: "schedules",
    name: "Schedules",
    products: [
      {
        id: vesselOperationVoyage,
        name: "Commercial vessel schedules",
        description:
          "Receive events linked with maritime services when some changes are done in the CMA CGM voyages.",
          quarter:4,
          year: 2023
      }
    ],
  },

  {
    id: "pricing",
    name: "Pricing",
    products: [
      {
        name: "Instant quote",
        description: "Get your SPOT ON reference to submit booking request."
      },
      {
        id:pricingCommercialQuotation,
        name: "Rating alert",
        description: "Receive event related to the update of your contract.",
        quarter:2,
        year: 2024
      }
    ],
  },

  {
    id: "allocation",
    name: "Allocation",
    products: [
      {
        name: "Customer allocation per trade",
        description: "Get your customer allocation allowed and remaining.",
        quarter:2,
        year: 2024,
      }
    ],
  },

  {
    id: "booking-request",
    name: "Booking Request",
    products: [
      {
        id: shippingBookingRequest,
        name: "Booking process",
        description:
          "Plan to upgrade our sales and deployment process to deploy the product"
      },
      {
        id: shippingBookingRequest,
        name: "Allocation check",
        description:
          "Include the real time vessel allocation visibility at booking stage.",
        quarter:1,
        year: 2024,
      },
      {
        id:shippingBookingRequest,
        name: "DCSA version",
        description: "Submit your booking request using the DCSA format."
      },
      {
        id: shippingBookingRequest,
        name: "Non Operating Reefer (NOR)",
        description: "Manage equipment NOR at booking stage.",
        quarter:1,
        year:2024
      }
    ],
  },

  {
    id: "shipping-instruction",
    name: "Shipping Instruction",
    products: [
      {
        id:shippingInstruction,
        name: "Verified Gross Mass (VGM)",
        description: "Submit VGM data related to your shipment.",
        quarter:3,
        year: 2024
      },
      {
        id:shippingInstruction,
        name: "DCSA version",
        description:
          "Submit your Shipping instructions request using the DCSA format."
      },
      {
        id:shippingInstruction,
        name: "Shipping instruction process",
        description:"Plan to upgrade our sales and deployment process to deploy the product"
      },
      {
        id:shippingInstruction,
        name: "Merge request",
        description:"Submit a merge request.",
        quarter:2,
        semester:2,
        year:2024
      },
    ],
  },

  {
    id: "visibility",
    name: "Visibility",
    products: [
      
      {
        id: operationTrackAndTrace,
        name: "DCSA T&T 3.0",
        description:
          "Update the current version of the visibility API CMA CGM after publication by DCSA",
          semester:2,
          quarter:4,
        year: 2024
      }
    ],
  },

  {
    id: "invoice",
    name: "Invoice",
    products: [
      {
        id:shippingInvoiceCustomer,
        name: "Invoice data",
        description: "List your available invoices and get your invoice data.",
        pilot: true
      },
      {
        name: "Payment confirmation",
        description: "Submit your payment confirmation to the accounting department CMA CGM.",
        pilot: true
      }
    ],
  },

  {
    id: "shipping-documents",
    name: "Shipping Documents",
    products: [
      {
        id:documentEvent,
        name: "Documents upload",
        description:
          "Upload shipping documents such as Letter of Credit, Packing List, etc.",
        quarter: 2,
        semester:1,
        year: 2024
      },
      {
        id:documentEvent,
        name: "Arrival notice",
        description: "Receive your pre-arrival notice and cargo available data (US scope).",
        quarter:4,
        year: 2023
      },
      {
        id:documentEvent,
        name: "Arrival notice worldwide",
        description: "Receive your standard and other arrival notice data.",
        quarter:2,
        year:2024
      },
      {
        id:documentEvent,
        name: "Delivery order",
        description: "Receive your delivery order data.",
        semester:2,
        quarter:4,
        year:2024
      },
    ],
  },

  {
    id: "bl-suites",
    name: "BL Suite",
    products: [
      {
        id:shippingBlSuite,
        name: "Bill of lading draft approval",
        description: "Submit your approval for the final version of the draft",
        quarter:1,
        year:2024
      },
      {
        id:shippingBlSuite,
        name: "Bill of Lading draft review",
        description: "Manage the exchange back and forth between the customers and CMA and enable to get an approval",
        quarter:2,
        year:2024
      }
    ],
  },
];
