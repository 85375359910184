import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ChevronLeft } from "@material-ui/icons";

import {
  IPartner,
  PartnerSelection,
} from "../interfaces/business-partners.interface";
import { PartnerType } from "../enums/business-partners.enum";
import ParentPartnerList from "./parentPartnerList";
import ChildPartnerList from "./childPartnerList";
import { Helmet } from 'react-helmet';
import HeaderBanner from "../../../common/components/headerBanner";
import { PartnerConfig } from "../models/business-partners.model";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { Button } from "@material-ui/core";
import { FreeTrial } from "../../../authentication/models/freeTrial.model";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import CreationButton from "./businessPartnerCreation/creationButton";

enum ActivePage {
  PARENT_PARTNERS,
  OPEN_REQUEST_CHILDREN,
  ACTIVE_CHILDREN,
  MY_ENTITIES,
}

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}

interface IStates {
  activePage: ActivePage;

  openRequestPartnerConfig: PartnerConfig;
  activePartnerConfig: PartnerConfig;

  selectedChildPartner: PartnerSelection | null;
  openEnrichmentSuccess: boolean;
  coveredProductsToDisplay: string[] | undefined;
  isActivePartnersLoaded: Boolean;
}

class BusinessPartners extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activePage: ActivePage.PARENT_PARTNERS,
      openRequestPartnerConfig: new PartnerConfig({
        type: PartnerType.OPEN_REQUEST,
      }),
      activePartnerConfig: new PartnerConfig({ type: PartnerType.ACTIVE }),
      selectedChildPartner: null,
      openEnrichmentSuccess: false,
      coveredProductsToDisplay: undefined,
      isActivePartnersLoaded: false
    };
  }


  async componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    if (!!this.props.hasOpenRequestPartnerBeenAdded) {
      this.props.addedOpenRequestPartner(false);
      const partnerConfig = this.state.openRequestPartnerConfig;
      partnerConfig.setValues({ partners: undefined });
      await this.updatePartnerConfig(partnerConfig);
      this.setState({ activePage: ActivePage.PARENT_PARTNERS });
    }
  }

  private updatePartnerConfig = (partnerConfig: PartnerConfig) => {
    return new Promise<void>((resolve) => {
      let state = {};
      switch (partnerConfig.type) {
        case PartnerType.OPEN_REQUEST:
          state = { openRequestPartnerConfig: partnerConfig };
          break;

        case PartnerType.ACTIVE:
          state = { activePartnerConfig: partnerConfig };
          break;

        default:
          break;
      }

      this.setState(state, resolve);
    });
  };

  private onChildPartnerSelection = (
    partnerType: PartnerType,
    selectedChildPartner: PartnerSelection
  ) => {
    let activePage = ActivePage.PARENT_PARTNERS;

    switch (partnerType) {
      case PartnerType.OPEN_REQUEST:
        activePage = ActivePage.OPEN_REQUEST_CHILDREN;
        break;

      case PartnerType.ACTIVE:
        activePage = ActivePage.ACTIVE_CHILDREN;
        break;

      case PartnerType.MY_ENTITIES:
        activePage = ActivePage.MY_ENTITIES;
        break;

      default:
        break;
    }

    this.props.scrollToTop();
    this.setState({ activePage, selectedChildPartner });
  };

  private setActivePartnerLoaded = (isActivePartnersLoaded?: Boolean)  => {
    if(isActivePartnersLoaded !== undefined)
      this.setState({isActivePartnersLoaded: isActivePartnersLoaded});
    else
      return this.state.isActivePartnersLoaded;
  };

  private getActivePage = () => {
    const freeTrial = new FreeTrial(this.props.user);

    if (freeTrial.isCommunity) {
      return (
        <div className="free-trial-message">
          <FormattedMessage id="freeTrial.message.accessDenied" />
          <br />
          <FormattedMessage id="partner.freeTrial" />
        </div>
      );
    }

    if (this.state.activePage === ActivePage.PARENT_PARTNERS) {
      const getParentPartnerList = (partnerConfig: PartnerConfig) => {
        return (
          <ParentPartnerList
            setActivePartnerLoaded = {this.setActivePartnerLoaded}
            partnerConfig={partnerConfig}
            updatePartnerConfig={this.updatePartnerConfig}
            onChildPartnerSelection={(
              childPartner: IPartner,
              partnerType: PartnerType
            ) => this.onChildPartnerSelection(partnerType, childPartner)}
          />
        );
      };

      return (
        this.props.screenType==ScreenType.COMPUTER ? (
        <div className="column-grid">
          {this.props.user &&
            this.props.user.letterOfAgreement &&
            (<div className="row-grid">
              {this.setActivePartnerLoaded()&&
              <CreationButton
                enrichPartner={this.state.selectedChildPartner as IPartner}
                className="button-full-width"
              />}
              {getParentPartnerList(this.state.openRequestPartnerConfig)}</div>)}
          {getParentPartnerList(this.state.activePartnerConfig)}
        </div>):(<>
          {this.props.user &&
            this.props.user.letterOfAgreement &&
            getParentPartnerList(this.state.openRequestPartnerConfig)}
          {getParentPartnerList(this.state.activePartnerConfig)}
        
        </>)
      );
    }

    if (this.state.selectedChildPartner) {
      let partnerType: PartnerType;
      let openRequestPartners: IPartner[] | undefined = undefined;

      switch (this.state.activePage) {
        case ActivePage.OPEN_REQUEST_CHILDREN:
          partnerType = PartnerType.OPEN_REQUEST;
          openRequestPartners = [this.state.selectedChildPartner as IPartner];

          if (
            (this.state.selectedChildPartner as IPartner).code &&
            this.state.openRequestPartnerConfig.partners
          ) {
            openRequestPartners =
              this.state.openRequestPartnerConfig.partners.filter(
                (partner) =>
                  (this.state.selectedChildPartner as IPartner)?.code ===
                  partner.code
              );
          }
          break;

        case ActivePage.ACTIVE_CHILDREN:
          partnerType = PartnerType.ACTIVE;
          break;

        default:
          partnerType = PartnerType.MY_ENTITIES;
          break;
      }

      return (
        <ChildPartnerList
          partnerType={partnerType}
          setActivePartnerLoaded = {this.setActivePartnerLoaded}
          selectedParentPartner={this.state.selectedChildPartner}
          openRequestPartners={openRequestPartners}
        />
      );
    }

    return null;
  };

  private onBackClick = () => {
    this.setState({ activePage: ActivePage.PARENT_PARTNERS });
    this.props.scrollToTop();
  };

  render() {
    return (
      <div
        id="business-partners"
        className={`${this.props.screenType} App-page`}
      >
        <Helmet>
          <title>Business Partners - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="partner.businessPartners" />
        </HeaderBanner>

        {this.state.activePage !== ActivePage.PARENT_PARTNERS && (
          <div className="back-container">
            <div className="back App-content" onClick={this.onBackClick}>
              <ChevronLeft />
              &nbsp;
              <span>
                <FormattedMessage id="back" />
              </span>
            </div>
          </div>
        )}

        <div className="App-content">
          {(this.props.screenType == ScreenType.SMALL_PHONE || this.props.screenType == ScreenType.PHONE) && this.setActivePartnerLoaded() &&
        
          <CreationButton
                enrichPartner={this.state.selectedChildPartner as IPartner}
                className="button-full-width"
              />
        }
          {this.getActivePage()}
          </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
  hasOpenRequestPartnerBeenAdded: state.hasOpenRequestPartnerBeenAdded,
});

const mapDispatch = {
  addedOpenRequestPartner: (hasOpenRequestPartnerBeenAdded: boolean) => ({
    type: ReducerAction.ADDED_OPEN_REQUEST_PARTNER,
    payload: hasOpenRequestPartnerBeenAdded,
  }),
  scrollToTop: () => ({
    type: ReducerAction.SCROLL_TO_TOP,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(BusinessPartners);
