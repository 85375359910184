import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import HeaderBanner from "../../../common/components/headerBanner";

interface IProps {}

class LegalTerms extends Component<IProps> {
  render() {
    const LINK_OFAC =
      "https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information";
    const email = "ho.ethics@cma-cgm.com";

    return (
      <div id="legal-terms">
        <HeaderBanner>
          <FormattedMessage id="legalTerms.title" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content">
            <p>
              <FormattedMessage id="legalTerms.message1" />
            </p>
            <p>
              <FormattedMessage id="legalTerms.message2" />
            </p>
            <p>
              <FormattedMessage id="legalTerms.message3" />
            </p>
            <p>
              <ul>
                <li>
                  <FormattedMessage id="legalTerms.message4" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message5" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message6" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message7" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message8" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message9" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message10" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message11" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message12" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message13" />
                </li>
                <li>
                  <FormattedMessage id="legalTerms.message14" />
                  <a
                    href={LINK_OFAC}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {LINK_OFAC}
                  </a>
                </li>
              </ul>
            </p>
            <p>
              <FormattedMessage id="legalTerms.message15" />
              :&nbsp;
              <a
                className="link"
                onClick={() => window.open("mailto:" + email)}
              >
                {email}
              </a>
            </p>
            <p>
              <span className="office" />
              <strong>
                <FormattedMessage id="legalTerms.address1" />
              </strong>
              <FormattedMessage id="legalTerms.address2" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalTerms;
