import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";

import Loader from "../../../common/components/loader";
import FunctionalRulesSegment from "./functionalRulesSegment";
import SubscribedEventsSegment from "./suscribedEventsSegment";
import { Helmet } from 'react-helmet';
import NotFound from "../../../common/components/notFound";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { FreeTrial } from "../../../authentication/models/freeTrial.model";
import HeaderBanner from "../../../common/components/headerBanner";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {}

interface IStates {
  areSubscribedEventsLoading: boolean;
  hasSubscribedEventsData: boolean;
  areSubscriptionFiltersLoading: boolean;
  hasSubscriptionFiltersData: boolean;
}

class EventApplications extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      areSubscribedEventsLoading: true,
      hasSubscribedEventsData: false,
      areSubscriptionFiltersLoading: true,
      hasSubscriptionFiltersData: false,
    };
  }
  
  private toggleAreSubscribedEventsLoading = (
    areSubscribedEventsLoading = false
  ) => {
    this.setState({ areSubscribedEventsLoading });
  };

  private toggleHasSubscribedEventsData = (
    hasSubscribedEventsData: boolean
  ) => {
    this.setState({ hasSubscribedEventsData });
  };

  private toggleAreSubscriptionFiltersLoading = (
    areSubscriptionFiltersLoading = false
  ) => {
    this.setState({ areSubscriptionFiltersLoading });
  };

  private toggleHasSubscriptionFiltersData = (
    hasSubscriptionFiltersData: boolean
  ) => {
    this.setState({ hasSubscriptionFiltersData });
  };

  render() {
    const isLoading =
      this.state.areSubscribedEventsLoading ||
      this.state.areSubscriptionFiltersLoading;

    const hasData =
      this.state.hasSubscribedEventsData ||
      this.state.hasSubscriptionFiltersData;

    let content;

    const freeTrial = new FreeTrial(this.props.user);

    if (freeTrial.isCommunity) {
      content = (
        <div className="free-trial-message">
          <FormattedMessage id="freeTrial.message.accessDenied" />
          <br />
          <FormattedMessage id="eventApplications.freeTrial" />
        </div>
      );
    } else {
      content = (
        <>
          <SubscribedEventsSegment
            isLoading={isLoading}
            toggleIsLoading={this.toggleAreSubscribedEventsLoading}
            toggleHasData={this.toggleHasSubscribedEventsData}
          />

          <FunctionalRulesSegment
            isLoading={isLoading}
            toggleIsLoading={this.toggleAreSubscriptionFiltersLoading}
            toggleHasData={this.toggleHasSubscriptionFiltersData}
          />

          {isLoading ? (
            <Loader />
          ) : (
            !hasData && (
              <NotFound
                text={
                  <FormattedMessage id="eventApplications.noSubscriptionOrRuleFound" />
                }
              />
            )
          )}
        </>
      );
    }

    return (
      <div
        id="event-applications"
        className={`App-page ${this.props.screenType}`}
      >
        <Helmet>
          <title>Event Applications - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="myOrganization.eventApplications" />
        </HeaderBanner>

        <div className="App-content">
          <div id="event-content">{content}</div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const connector = connect(mapState, {});
export default connector(EventApplications);
