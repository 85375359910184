import "./styles.scss";

import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Select } from "@material-ui/core";

import InputLabel from "../inputLabel";
import { TFormInputId, TFormValue } from "../../enums/form.enum";
import { InputOptions } from "../../models/input-options.model";
import Utils from "../../../../../../utils";
import { SelectItem } from "../../../../interfaces/common.interface";

interface IProps extends WrappedComponentProps {
  id: TFormInputId;
  value: TFormValue;
  options: InputOptions;
  items: SelectItem[];
  disabled?: boolean;

  onChange(value: string): void;
  onBlur(): void;
}

class SelectInput extends React.Component<IProps> {
  render() {
    const placeholder = this.props.intl.formatMessage({
      id: this.props.options.placeholder,
    });

    return (
      <div className="form-input select-input">
        <InputLabel options={this.props.options} />

        <Select
          id={this.props.id}
          value={this.props.value}
          error={!!this.props.options.error}
          disabled={this.props.disabled ?? false}
          onChange={(event) =>
            this.props.onChange(event.target.value as string)
          }
          onBlur={this.props.onBlur}
        >
          {Utils.getMenuItems(this.props.items, placeholder)}
        </Select>
      </div>
    );
  }
}

export default injectIntl(SelectInput);
