import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import EventAPI from "../../../../productCatalog/apis/events.api";
import { IProductsFamily } from "../../../../productCatalog/interfaces/products-family.interface";
import Segment from "../../../../common/components/segment";
import { eventFamilies } from "../../../../productCatalog/constants/event-family.constant";
import Section from "../../../../common/components/section";
import { Events } from "../../../../productCatalog/models/event.model";
import PeriodFilter from "../../../../common/submodules/form/components/periodFilter";
import { ISectionInfo } from "../../interface/subscribed-events.interface";
import EventsTable from "../../../../common/components/eventsTable";
import { ProductFamily } from "../../../../productCatalog/enums/product-family.enum";
import EventMetrics from "../eventMetrics";

interface IProps {
  isLoading: boolean;
  toggleIsLoading(): void;
  toggleHasData(hasData: boolean): void;
}

interface IStates {
  subscribedEvents: Events | undefined;
  sectionInfo: ISectionInfo[];
}

class SubscribedEventsSegment extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      subscribedEvents: undefined,
      sectionInfo: eventFamilies.map(() => ({
        areMetricsVisible: false,
        periodStart: PeriodFilter.getDefaultPeriodStart(),
        periodEnd: new Date(),
      })),
    };
  }

  componentDidMount() {
    EventAPI.getSubscribedEvents()
      .then((subscribedEvents) => {
        this.setState({ subscribedEvents });
        this.props.toggleIsLoading();
        this.props.toggleHasData(this.hasData(subscribedEvents));
      })
      .catch(() => this.props.toggleIsLoading());
  }

  private hasData = (events: Events | undefined): boolean => {
    if (!events) return false;

    return (
      events.equipment.length > 0 ||
      events.shipment.length > 0 ||
      events.transport.length > 0
    );
  };

  private getSection = (eventFamily: IProductsFamily): JSX.Element | null => {
    let subscribedEvents:any=[];

    if (this.state.subscribedEvents) {
      switch (eventFamily.productFamily) {
        case ProductFamily.EQUIPMENT:
          subscribedEvents = this.state.subscribedEvents.equipment;
          break;

        case ProductFamily.SHIPMENT:
          subscribedEvents = this.state.subscribedEvents.shipment;
          break;
        case ProductFamily.TRANSPORT:
          subscribedEvents = this.state.subscribedEvents.transport;
          break;
        case ProductFamily.DOCUMENTS:
          subscribedEvents = this.state.subscribedEvents.document;
          break;
        case ProductFamily.SCHEDULES:
          subscribedEvents = this.state.subscribedEvents.schedule;
          break;
        
      }

      if (subscribedEvents.length > 0) {
        return (
          <div
            className="section-container"
            key={`subscribed-events-section-${eventFamily.id}`}
          >
            <Section title={eventFamily.name}>
              <EventsTable events={subscribedEvents} familyName={eventFamily.id}/>
            </Section>
          </div>
        );
      }
    }

    return null;
  };

  render() {
    if (this.props.isLoading) {
      return null;
    }

    if (!this.hasData(this.state.subscribedEvents)) {
      return null;
    }

    return (
      <Segment
        title={<FormattedMessage id="eventApplications.subscribedEvents" />}
      ><br/>
        <EventMetrics />

        {eventFamilies.map(this.getSection)}
      </Segment>
    );
  }
}

export default SubscribedEventsSegment;
