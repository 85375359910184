import "./styles.scss";

import React from "react";
import { Tooltip } from "@material-ui/core";
import ClickAwayListener from "react-advanced-click-away";

interface IProps {
  children: React.ReactElement;
  title: JSX.Element | string;
  error?: boolean;
  hideOnClick?: boolean;
}

interface IStates {
  isTooltipVisible: boolean;
}

export default class CustomTooltip extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isTooltipVisible: false,
    };
  }

  private onTooltipClick = (isTooltipVisible: boolean): void => {
    this.setState({ isTooltipVisible });
  };

  render() {
    return (
      <ClickAwayListener onClickAway={() => this.onTooltipClick(false)}>
        <Tooltip
          title={this.props.title}
          placement="right"
          open={this.state.isTooltipVisible}
          classes={this.props.error ? { popper: "error-tooltip" } : {}}
        >
          <div
            onClick={() =>
              this.props.hideOnClick !== undefined &&
              !this.props.hideOnClick &&
              this.onTooltipClick(true)
            }
            onMouseEnter={() => this.onTooltipClick(true)}
            onMouseLeave={() => this.onTooltipClick(false)}
          >
            {this.props.children}
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  }
}
