import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { DocumentsSignLogo } from "../../../../../../assets/icons";
import { IPartner } from "../../../interfaces/business-partners.interface";
import TransitionContent from "../../../../../common/components/transitionContent";
import PartnerCodeTooltip from "../../common/partnerCodeTooltip";
import StatusLabel from "../../common/statusLabel";
import {
  PartnerStatus,
  PartnerType,
} from "../../../enums/business-partners.enum";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  partner: IPartner;
  partnerType: PartnerType;
  index: number;
}

interface IStates {
  isAddressVisible: boolean;
}

class ChildPartnerItem extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isAddressVisible: false,
    };
  }

  private toggleAddressVisibility = () => {
    if (this.props.partner.address) {
      this.setState({
        isAddressVisible: !this.state.isAddressVisible,
      });
    }
  };

  private displayValue = (value?: string): string => {
    if (value) return value.toLowerCase();
    return "";
  };

  private getAddressElement = (title: JSX.Element, content: string) => {
    return (
      <div className="address-item">
        <div className="address-item-title">{title}:</div>
        <div className="address-item-content">{content}</div>
      </div>
    );
  };

  private iconDownloadLOA = () => {
    return (
      this.props.partner.status === PartnerStatus.LOA_REQUIRED && (
        <a
          href={this.props.user!.letterOfAgreement}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton className="loa-button">
            <DocumentsSignLogo />
          </IconButton>
        </a>
      )
    );
  };

  render() {
    let itemClass = "child-partner-item partner-item";

    if (this.props.index % 2 === 0) {
      itemClass = `${itemClass} even`;
    } else {
      itemClass = `${itemClass} odd`;
    }

    if (this.state.isAddressVisible) {
      itemClass = `${itemClass} opened`;
    }

    return (
      <div className={itemClass}>
        <div
          className={`header-container ${
            this.props.partner.address ? "clickable" : ""
          }`}
          onClick={this.toggleAddressVisibility}
        >
          <div className="left-content">
            {this.props.partnerType !== PartnerType.OPEN_REQUEST && (
              <PartnerCodeTooltip partner={this.props.partner} />
            )}

            <div className="name">
              {this.props.partner.name}

              {this.props.partner.address && (
                <span className="address-item-content">
                  &nbsp;-&nbsp;
                  {this.displayValue(this.props.partner.address.city)},&nbsp;
                  {this.displayValue(this.props.partner.address.country)}
                </span>
              )}
            </div>
          </div>
          <div className="right-content">
            <StatusLabel status={this.props.partner.status} />

            {
              //this.iconDownloadLOA()
            }

            {this.props.partner.address && (
              <KeyboardArrowDown
                className={`chevron ${
                  this.state.isAddressVisible ? "opened" : ""
                }`}
              />
            )}
          </div>
        </div>

        {this.props.partner.address && (
          <div className="sub-content-container">
            <TransitionContent isExpanded={this.state.isAddressVisible}>
              <div className="address-container">
                {this.getAddressElement(
                  <FormattedMessage id="partner.address" />,
                  this.displayValue(this.props.partner.address.address)
                )}

                {this.getAddressElement(
                  <FormattedMessage id="partner.city" />,
                  this.displayValue(this.props.partner.address.city)
                )}

                {this.getAddressElement(
                  <FormattedMessage id="partner.zipCode" />,
                  this.displayValue(this.props.partner.address.postCode)
                )}

                {this.getAddressElement(
                  <FormattedMessage id="partner.region" />,
                  this.displayValue(this.props.partner.address.region)
                )}

                {this.getAddressElement(
                  <FormattedMessage id="partner.country" />,
                  this.displayValue(this.props.partner.address.country)
                )}
              </div>
            </TransitionContent>
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const connector = connect(mapState);
export default connector(ChildPartnerItem);
