export class FunctionalRule {
  type: string;
  value: string;
  startDate: Date;
  endDate: Date;

  constructor(body: any) {
    this.type = body.type;
    this.value = body.value;
    this.startDate = new Date(body.startDate);
    this.endDate = new Date(body.endDate);
  }
}
