import HttpUtils from "../../../utils/http.utils";
import { ApiSummary } from "../../productCatalog/models/api-summary.model";
import { IApiRecentlyUpdated } from "../interfaces/new-product.interface";
import { ApiRecentlyUpdated } from "../model/api-new.model";

export default class ApiDescriptionAPI {
  public static async getApiRecentlyUpdated(): Promise<IApiRecentlyUpdated[]> {
    return HttpUtils.get(`/apisummaries/apiNew`).then((res: any) =>
      res.map((data: Object[]) => new ApiRecentlyUpdated(data))
    );
  }
}
