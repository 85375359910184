import "./styles.scss";

import React, { Component } from "react";

interface IProps {
  text: JSX.Element | string;
  size?: "normal" | "large" | "x-large";
}

class BracedTitle extends Component<IProps> {
  render() {
    const size = this.props.size || "normal";
    return (
      <div className={`braced-title ${size}`}>
        <span className="brace">{"{"}</span>
        {this.props.text}
      </div>
    );
  }
}

export default BracedTitle;
