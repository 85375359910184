import "./styles.scss";

import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

interface IProps extends WrappedComponentProps {
  children: React.ReactNode;
  title: JSX.Element;
}

interface IStates {
  isOpened: boolean;
}

class Section extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpened: false,
    };
  }

  private toggleOpen = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  render() {
    const isOpenedClass = this.state.isOpened ? "opened" : "";

    return (
      <div className="section subscription-filters-section">
        <div className="section-header" onClick={() => this.toggleOpen()}>
          <div>
            <div>{this.props.title}</div>
            <KeyboardArrowDownIcon className={isOpenedClass} />
          </div>
        </div>

        <div className={`section-content ${isOpenedClass}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default injectIntl(Section);
