import { AxiosResponse } from "axios";

import HttpUtils from "../../../utils/http.utils";
import { SubscriptionRequestDTO } from "../models/subscription-request.model";
import { ContactDTO } from "../../contactUs/models/contact.model";
import { IPackage } from "../interfaces/package.interface";

export default class CrmAPI {
  public static getPackages(): Promise<IPackage[]> {
    return HttpUtils.get("/crm/packages");
  }

  public static postSubscriptionRequest(
    body: SubscriptionRequestDTO
  ): Promise<AxiosResponse> {
    return HttpUtils.post("/crm/subscription-request", body);
  }

  public static postContact(
    body: ContactDTO,
    reCaptchaToken?: string
  ): Promise<AxiosResponse> {
    return HttpUtils.post("/crm/contact", { ...body, reCaptchaToken });
  }
}
