import "./styles.scss";

import React from "react";
import BracedTitle from "../bracedTitle";

interface IProps {
  children: React.ReactNode;
  title: JSX.Element;
}

export default class Segment extends React.Component<IProps> {
  render() {
    return (
      <div className="segment">
        <BracedTitle text={this.props.title} />

        {this.props.children}
      </div>
    );
  }
}
