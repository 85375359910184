import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Button } from "@material-ui/core";
import { AxiosError } from "axios";

import {
  IPartner,
  IPartnerWithCount,
} from "../../../../interfaces/business-partners.interface";
import { ReducerAction } from "../../../../../../reducer/enums/reducer-action.enum";
import PartnersAPI from "../../../../apis/business-partners.api";
import { HttpCode } from "../../../../../../common/enums/http-code.enum";
import Loader from "../../../../../../common/components/loader";
import {
  EFieldType,
  FormSelectValue,
  NewCustomerInputId,
  TFormInputId,
} from "../../../../../../common/submodules/form/enums/form.enum";
import { ToastType } from "../../../../../../common/submodules/toast/enums/toast-type.enum";
import { IReducerState } from "../../../../../../reducer/interfaces/reducer.interface";
import { FormField } from "../../../../../../common/submodules/form/models/form-field.model";
import { Form } from "../../../../../../common/submodules/form/models/form.model";
import { IInputAdditionalParams } from "../../../../../../common/submodules/form/interfaces/form.interface";
import FormUtils from "../../../../../../../utils/form.utils";
import { PartnerPost } from "../../../../models/business-partners.model";
import { SelectItem } from "../../../../../../common/interfaces/common.interface";
import { Routes } from "../../../../../../common/enums/routes.enum";
import { ELoaderSize } from "../../../../../../common/enums/loader.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps
  extends RouteComponentProps,
    PropsFromRedux,
    WrappedComponentProps {
  enrichPartner?: IPartner;
  closeNewBp(): void;
  onCreationSuccess(clientRepresentativeEmail: String): void;
  addFailure(): number;
}

interface IStates {
  form: Form;
  isSubmitLoading: boolean;
  activePartners: IPartnerWithCount | undefined;
  searchPartnersValue: string;
  isPartnerSearchLoading: boolean;
}

class NewEntity extends Component<IProps, IStates> {
  private timeout: NodeJS.Timeout | null = null;
  private NB_OF_PARTNERS_BY_PAGE = 50;

  constructor(props: IProps) {
    super(props);

    this.state = {
      form: new Form(NewEntity.generateForm()),
      isSubmitLoading: false,
      activePartners: undefined,
      searchPartnersValue: "",
      isPartnerSearchLoading: false,
    };
  }

  private static generateForm(): FormField[] {
    return [
      new FormField(NewCustomerInputId.COMPANY_NAME, "", {
        label: "partner.newCustomer.field.name",
        placeholder: "partner.newCustomer.field.name.placeholder",
        isMandatory: true,
      }),

      new FormField(
        NewCustomerInputId.REPRESENTATIVE_NAME,
        "",
        {
          label: "partner.newCustomer.field.representativeName",
          placeholder:
            "partner.newCustomer.field.representativeName.placeholder",
          isMandatory: true,
        },
        EFieldType.TEXT
      ),

      new FormField(
        NewCustomerInputId.REPRESENTATIVE_EMAIL,
        "",
        {
          label: "partner.newCustomer.field.representativeEmail",
          placeholder:
            "partner.newCustomer.field.representativeEmail.placeholder",
          isMandatory: true,
        },
        EFieldType.EMAIL
      ),

      // new FormField(
      //   NewCustomerInputId.ENRICH_PARTNER,
      //   FormSelectValue.NONE,
      //   {
      //     label: "partner.newCustomer.field.enrichPartner",
      //     placeholder: "partner.newCustomer.field.enrichPartner.placeholder",
      //     isMandatory: true,
      //   },
      //   EFieldType.AUTOCOMPLETE
      // ),
    ];
  }

  private handleFormSubmissionSuccess = (isEnrichingPartner: boolean) => {
    this.setState({ isSubmitLoading: false });
    this.props.displayToast(
      isEnrichingPartner ? (
        <FormattedMessage id="partner.successSubmit.enrichCustomer" />
      ) : (
        <FormattedMessage id="partner.successSubmit.newCustomer" />
      )
    );
    this.props.onCreationSuccess((this.state.form.getFormField(NewCustomerInputId.REPRESENTATIVE_EMAIL)?.value as String).toLowerCase());
    this.props.addedOpenRequestPartner(true);
  };

  private handleFormSubmissionFailure = (
    error: AxiosError,
    isEnrichingPartner: boolean
  ) => {
    this.setState({ isSubmitLoading: false });
    let toastMessage = <FormattedMessage id="error.errorOccurredFull" />;

    if (error.response?.status === HttpCode.BAD_REQUEST) {
      toastMessage = <span>{error.response.data}</span>;
    }

    if (this.props.addFailure() >= 3) {
      toastMessage = isEnrichingPartner ? (
        <FormattedMessage
          id="partner.tooManyRetries.enrichCustomer"
          values={{
            a: (link: any) => <a href={Routes.REPORT_ISSUE}>{link}</a>,
          }}
        />
      ) : (
        <FormattedMessage
          id="partner.tooManyRetries.newCustomer"
          values={{
            a: (link: any) => <a href={Routes.REPORT_ISSUE}>{link}</a>,
          }}
        />
      );
    }

    this.props.displayToast(toastMessage, ToastType.ERROR);
  };

  private submitForm = () => {
    if (this.isFormValid()) {
      this.setState({ isSubmitLoading: true }, () => {
        const enrichPartnerFormField = this.state.form.getFormField(
          NewCustomerInputId.ENRICH_PARTNER
        )?.value as string;

        const enrichPartner =
          this.props.enrichPartner ??
          this.state.activePartners?.partners.find(
            (partner) => partner.name === enrichPartnerFormField
          );

        const partnerPost = new PartnerPost(
          this.state.form,
          this.props.user?.clientName as string,
          enrichPartner
        );

        PartnersAPI.postPartner(partnerPost)
          .then(() => this.handleFormSubmissionSuccess(!!enrichPartner))
          .catch((error: AxiosError) =>
            this.handleFormSubmissionFailure(error, !!enrichPartner)
          );
      });
    }
  };

  private verifyField = (
    id: TFormInputId,
    hasPreviousError: boolean,
    isOnChange = false,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    form.updateFormFieldError(
      id,
      hasPreviousError,
      isOnChange,
      additionalParams
    );
    this.setState({ form });
  };

  private searchBP = (searchValue: string) => {
    PartnersAPI.getActivePartners(
      1,
      this.NB_OF_PARTNERS_BY_PAGE,
      searchValue
    ).then((activePartners) => {
      this.setState({ activePartners, isPartnerSearchLoading: false });
    });
  };

  private onSearch = (searchValue: string) => {
    this.setState({ isPartnerSearchLoading: true }, () => {
      if (!!this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      const form = this.state.form;
      form.updateFormField({
        id: NewCustomerInputId.ENRICH_PARTNER,
        value: searchValue,
      });

      const timeoutDelay = 800;
      this.setState({ form, searchPartnersValue: searchValue }, () => {
        if (searchValue.length >= 3) {
          this.timeout = setTimeout(
            () => this.searchBP(searchValue),
            timeoutDelay
          );
        } else {
          this.setState({ isPartnerSearchLoading: false });
        }
      });
    });
  };

  private onChange = (
    id: TFormInputId,
    value: string | boolean,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    const previousFieldState = form.fields.find((el) => el.id === id);

    if (previousFieldState) {
      form.updateFormField({ id, value });

      this.setState({ form }, () =>
        this.verifyField(
          id,
          !!previousFieldState.options.error,
          true,
          additionalParams
        )
      );
    }
  };

  private onBlur = (id: TFormInputId) => {
    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );

    if (previousFieldState) {
      this.verifyField(id, !!previousFieldState.options.error, false, {
        selectItems: this.getItems(),
      });
    }
  };

  private getInput(
    id: TFormInputId,
    additionalParams: IInputAdditionalParams = {}
  ): JSX.Element | null {
    const field = this.state.form.getFormField(id);

    if (field) {
      return FormUtils.getInput(
        field,
        this.onChange,
        this.onBlur,
        false,
        additionalParams
      );
    }

    return null;
  }

  private isFormValid(): boolean {
    const form = this.state.form;
    const isFormValid = form.isFormValid({ selectItems: this.getItems() });
    this.setState({ form });
    return isFormValid;
  }

  private getItems = (): SelectItem[] => {
    if (!!this.props.enrichPartner) return [];

    const items: SelectItem[] = [];
    items.push({
      name: this.props.intl.formatMessage({
        id: "partner.newCustomer.enrichPartner.newEntity",
      }),
      value: FormSelectValue.NONE,
    });

    if (this.state.activePartners) {
      this.state.activePartners.partners.forEach((partner) => {
        items.push({ name: partner.name, value: partner.name });
      });
    }

    let messageNameId = "";
    let remainingItems;

    if (this.state.isPartnerSearchLoading) {
      messageNameId = "partner.newCustomer.enrichPartner.searching";
    } else {
      if (
        !this.state.activePartners ||
        this.state.activePartners.partnerCount === 0
      ) {
        messageNameId =
          this.state.searchPartnersValue === ""
            ? "partner.newCustomer.enrichPartner.typeToSearch"
            : "partner.newCustomer.enrichPartner.noResult";
      } else {
        remainingItems =
          this.state.activePartners.partnerCount - this.NB_OF_PARTNERS_BY_PAGE;

        if (remainingItems > 0) {
          messageNameId = "partner.newCustomer.enrichPartner.remaining";
        }
      }
    }

    if (messageNameId !== "")
      items.push({
        name: this.props.intl.formatMessage(
          {
            id: messageNameId,
          },
          { remainingItems }
        ),
        value: "disabled",
        disabled: true,
      });

    return items;
  };

  render() {
    const items = this.getItems();

    return (
      <div id="new-entity">
        <div className="two-block">
          {/* {items.length > 0 &&
            this.getInput(NewCustomerInputId.ENRICH_PARTNER, {
              selectItems: items,
              isSearchLoading: this.state.isPartnerSearchLoading,
              onSearch: this.onSearch,
            })} */}

          {this.getInput(NewCustomerInputId.COMPANY_NAME)}
        </div>

        <div className="two-block">
          {this.getInput(NewCustomerInputId.REPRESENTATIVE_NAME)}
          {this.getInput(NewCustomerInputId.REPRESENTATIVE_EMAIL)}
        </div>

        <div className="buttons-container">
          <Button className="button inverted" onClick={this.props.closeNewBp}>
            <FormattedMessage id="partner.cancel" />
          </Button>
          <Button
            className={`button submit-button ${
              this.state.isSubmitLoading ? "disabled" : ""
            }`}
            onClick={this.submitForm}
          >
            {this.state.isSubmitLoading ? (
              <Loader size={ELoaderSize.SMALL} />
            ) : (
              <FormattedMessage id="partner.submit" />
            )}
          </Button>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const mapDispatch = {
  displayToast: (toastMessage: JSX.Element, toastType = ToastType.SUCCESS) => ({
    type: ReducerAction.TOAST,
    payload: { toastMessage, toastType },
  }),
  addedOpenRequestPartner: (hasOpenRequestPartnerBeenAdded: boolean) => ({
    type: ReducerAction.ADDED_OPEN_REQUEST_PARTNER,
    payload: hasOpenRequestPartnerBeenAdded,
  }),
};

const connector = connect(mapState, mapDispatch);
export default withRouter(connector(injectIntl(NewEntity)));
