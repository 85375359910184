import "./styles.scss";

import React, { Component } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { AxiosError } from "axios";
import { FormGroup } from "@material-ui/core";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import Close from "@material-ui/icons/Close";
import Loader from "../../../common/components/loader";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import ReCaptchaAPI from "../../../common/apis/re-captcha.api";
import Utils from "../../../../utils";
import {
  BusinessRequestInputId,
  EFieldType,
  FormSelectValue,
  ReportIssueInputId,
  TFormInputId,
} from "../../../common/submodules/form/enums/form.enum";
import ReCaptchaUtils from "../../../../utils/re-captcha.utils";
import { ReportIssuePost } from "../../interfaces/report-issue.interface";
import { SelectItem } from "../../../common/interfaces/common.interface";
import ApiDescriptionAPI from "../../../productCatalog/apis/api-description.api";
import { ApiSummary } from "../../../productCatalog/models/api-summary.model";
import { FreeTrial } from "../../../authentication/models/freeTrial.model";
import { HttpCode } from "../../../common/enums/http-code.enum";
import { ReportIssueDTO } from "../../models/contact.model";
import ContactAPI from "../../apis/contact.api";
import { ToastType } from "../../../common/submodules/toast/enums/toast-type.enum";
import ToastAlert from "../../../common/submodules/toast/components/toastAlert";
import { IToastAlert } from "../../../common/submodules/toast/interfaces/toast-alert.interface";
import { defaultToastAlert } from "../../../common/submodules/toast/constants/default-toast-alert.constant";
import EventAPI from "../../../productCatalog/apis/events.api";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import InputLabel from "../../../common/submodules/form/components/inputLabel";
import { FormField } from "../../../common/submodules/form/models/form-field.model";
import { Form } from "../../../common/submodules/form/models/form.model";
import {
  IField,
  IInputAdditionalParams,
} from "../../../common/submodules/form/interfaces/form.interface";
import FormUtils from "../../../../utils/form.utils";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import { ProductFamily } from "../../enums/product.family";
import { EventRow } from "../../../productCatalog/models/event.model";
import { ELoaderSize } from "../../../common/enums/loader.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, WrappedComponentProps {}

interface IStates {
  form: Form;
  freeTrial: boolean;
  listApis: SelectItem[];
  listEvents: SelectItem[];
  selectedFiles: File[];
  isSubmitLoading: boolean;
  areApisLoading: boolean;
  areEventsLoading: boolean;
  reCaptchaToken: string | undefined;
  isReCaptchaSecretLoading: boolean;
  toast: IToastAlert;
}

const acceptedFileExtensions = ".png,.jpg,.jpeg,.gif,.pdf,.txt,.log";
const formattedMessageApiPortal = "contactUs.reportIssue.apiPortal";

class ReportIssue extends Component<IProps, IStates> {
  private readonly defaultForm: ReportIssuePost;
  private readonly fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.defaultForm = {
      email: !!this.props.user ? this.props.user.email : "",
      product: ProductFamily.API_PORTAL,
      title: "",
      description: "",
      requestUrl: "",
      responseCode: FormSelectValue.PLACEHOLDER,
      correlationId: "",
    };

    this.state = {
      form: this.updateFormWithUserProps(new Form(this.generateForm())),
      freeTrial: false,
      listApis: [],
      listEvents: [],
      selectedFiles: [],
      isSubmitLoading: false,
      areApisLoading: false,
      areEventsLoading: false,
      reCaptchaToken: undefined,
      isReCaptchaSecretLoading: false,
      toast: defaultToastAlert,
    };

    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.reCaptchaSecret) {
      this.setState({ isReCaptchaSecretLoading: true }, () => {
        ReCaptchaAPI.getReCaptchaSecret().then((reCaptchaSecret) => {
          this.props.saveReCaptchaSecret(reCaptchaSecret);
          this.setState({ isReCaptchaSecretLoading: false });
        });
      });
    }

    this.getApis();
    this.getEvents();

    this.setState({ freeTrial: new FreeTrial(this.props.user).isCommunity });
  }

  componentDidUpdate(
    prevProps: Readonly<PropsFromRedux>,
    prevState: Readonly<IStates>
  ) {
    if (prevProps.user !== this.props.user) {
      this.setState({ form: this.updateFormWithUserProps() });
    }
  }

  private generateForm = (): FormField[] => {
    return [
      new FormField(
        ReportIssueInputId.EMAIL,
        "",
        {
          label: "form.field.email",
          placeholder: "form.field.email.placeholder",
          isMandatory: true,
        },
        EFieldType.EMAIL
      ),
      new FormField(
        ReportIssueInputId.CLIENT,
        "",
        {
          label: "form.field.client",
          placeholder: "form.field.client.placeholder",
          isMandatory: true,
        },
        EFieldType.TEXT
      ),
      new FormField(
        ReportIssueInputId.PRODUCT_FAMILY,
        ProductFamily.API_PORTAL,
        {
          label: "contactUs.reportIssue.productFamily",
          placeholder: "contactUs.reportIssue.productFamily.placeholder",
          tooltip: this.props.user
            ? "contactUs.reportIssue.productFamily.tooltip.connected"
            : "contactUs.reportIssue.productFamily.tooltip",
          isMandatory: true,
        },
        EFieldType.SELECT
      ),
      new FormField(
        ReportIssueInputId.PRODUCT,
        ProductFamily.API_PORTAL,
        {
          label: "contactUs.reportIssue.product",
          placeholder: "contactUs.reportIssue.product.placeholder",
          tooltip: this.props.user
            ? "contactUs.reportIssue.product.tooltip.connected"
            : "contactUs.reportIssue.product.tooltip",
          isMandatory: true,
        },
        EFieldType.SELECT
      ),
      new FormField(ReportIssueInputId.TITLE, "", {
        label: "contactUs.reportIssue.title",
        placeholder: "contactUs.reportIssue.title.placeholder",
        tooltip: "contactUs.reportIssue.title.tooltip",
        isMandatory: true,
      }),
      new FormField(ReportIssueInputId.REQUEST_URL, "", {
        label: "contactUs.reportIssue.url",
        placeholder: "contactUs.reportIssue.url.placeholder",
        tooltip: "contactUs.reportIssue.url.tooltip",
      }),
      new FormField(
        ReportIssueInputId.RESPONSE_CODE,
        FormSelectValue.PLACEHOLDER,
        {
          label: "contactUs.reportIssue.responseCode",
          placeholder: "contactUs.reportIssue.responseCode.placeholder",
          tooltip: "contactUs.reportIssue.responseCode.tooltip",
        },
        EFieldType.SELECT
      ),
      new FormField(ReportIssueInputId.CORRELATION_ID, "", {
        label: "contactUs.reportIssue.correlationId",
        placeholder: "contactUs.reportIssue.correlationId.placeholder",
        tooltip: "contactUs.reportIssue.correlationId.tooltip",
      }),
      new FormField(
        ReportIssueInputId.DESCRIPTION,
        "",
        {
          label: "contactUs.reportIssue.description",
          placeholder: "contactUs.reportIssue.description.placeholder",
          tooltip: "contactUs.reportIssue.description.tooltip",
          isMandatory: true,
        },
        EFieldType.TEXTAREA
      ),
    ];
  };

  private updateFormWithUserProps = (form = this.state.form): Form => {
    if (this.props.user) {
      let fields: IField[] = [
        { id: BusinessRequestInputId.EMAIL, value: this.props.user.email },
        { id:BusinessRequestInputId.CLIENT, value: this.props.user.clientName}
      ];
      form.updateFormFields(fields);
    }

    return form;
  };

  private getEvents() {
    if (this.props.user) {
      this.setState({ areEventsLoading: true }, () => {
        EventAPI.getSubscribedEvents()
          .then((events) => {
            const listEvents: SelectItem[] = [];

            const addEventsToList = (event: EventRow) => {
              event.relatedEvents.forEach((relatedEvent) => {
                !listEvents.some(
                  (existingEvent) =>
                    existingEvent.name.toLowerCase() ===
                    relatedEvent.toLowerCase()
                ) &&
                  listEvents.push({
                    name: relatedEvent,
                    value: relatedEvent,
                  });
              });
            };

            events.equipment.forEach(addEventsToList);
            events.shipment.forEach(addEventsToList);
            events.transport.forEach(addEventsToList);

            listEvents.sort((a, b) => a.name.localeCompare(b.name));

            this.setState({ listEvents, areEventsLoading: false });
          })
          .catch(() => {
            this.setState({ listEvents: [], areEventsLoading: false });
          });
      });
    }
  }

  private getApis() {
    if (this.props.user) {
      this.setState({ areApisLoading: true }, () => {
        ApiDescriptionAPI.getApisByClientId().then((apis) => {
          const listApis: SelectItem[] = apis.map((elt: ApiSummary) => ({
            name: elt.title,
            value: elt.title,
          }));

          this.setState({ listApis, areApisLoading: false });
        });
      });
    }
  }

  private getCode = (): SelectItem[] => {
    return Object.values(HttpCode)
      .filter((key) => !isNaN(Number(key)))
      .map((item) => ({ name: item.toString(), value: item.toString() }));
  };

  private getProductFamilies = (): SelectItem[] => {
    let products: SelectItem[] = [
      {
        name: this.props.intl.formatMessage({
          id: formattedMessageApiPortal,
        }),
        value: ProductFamily.API_PORTAL,
      },
    ];

    if (this.props.user) {
      products = [
        ...products,
        {
          name: this.props.intl.formatMessage({
            id: "contactUs.reportIssue.apiProduct",
          }),
          value: ProductFamily.API_PRODUCT,
        },
        {
          name: this.props.intl.formatMessage({
            id: "contactUs.reportIssue.eventProduct",
          }),
          value: ProductFamily.EVENT_PRODUCT,
        },
      ];
    }

    return products;
  };

  private getProducts = (): SelectItem[] => {
    switch (
      this.state.form.getFormFieldValue(ReportIssueInputId.PRODUCT_FAMILY)
    ) {
      case ProductFamily.API_PRODUCT:
        return this.state.listApis;

      case ProductFamily.EVENT_PRODUCT:
        const equipment = this.props.intl.formatMessage({
          id: "eventCatalog.equipment",
        });
        const shipment = this.props.intl.formatMessage({
          id: "eventCatalog.shipment",
        });
        const transport = this.props.intl.formatMessage({
          id: "eventCatalog.transport",
        });

        const listFamilyEvent: SelectItem[] = [
          { value: equipment, name: equipment },
          { value: shipment, name: shipment },
          { value: transport, name: transport },
        ];

        if (this.state.listEvents.length > 0) {
          listFamilyEvent.push({ value: "", name: "---", disabled: true });
        }

        return listFamilyEvent.concat(this.state.listEvents);
      default:
        return [
          {
            name: this.props.intl.formatMessage({
              id: formattedMessageApiPortal,
            }),
            value: ProductFamily.API_PORTAL,
            disabled: true,
          },
        ];
    }
  };

  private getInput(
    id: TFormInputId,
    additionalParams: IInputAdditionalParams = {}
  ): JSX.Element | null {
    const field = this.state.form.getFormField(id);

    if (field) {
      const disabled =
        (!!this.props.user && id === ReportIssueInputId.EMAIL) ||
        (!!this.props.user && id === ReportIssueInputId.CLIENT) ||
        (id === ReportIssueInputId.PRODUCT &&
          field.value === ProductFamily.API_PORTAL);

      return FormUtils.getInput(field, this.onChange, this.onBlur, disabled, {
        ...additionalParams,
      });
    }

    return null;
  }

  private openFileInput = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  private onFileChange = () => {
    if (this.fileInputRef.current && this.fileInputRef.current.files) {
      const MAX_SIZE = 1000000; // 1MB
      const selectedFiles = [...this.state.selectedFiles];
      let totalFileSize = 0;

      selectedFiles.forEach((file) => (totalFileSize += file.size));

      for (const file of Array.from(this.fileInputRef.current.files)) {
        if (file.size > MAX_SIZE) {
          this.props.displayToast(
            <FormattedMessage
              id="contactUs.fileUpload.tooLarge"
              values={{ fileName: file.name }}
            />,
            ToastType.ERROR
          );
          continue;
        }

        if (
          !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
        ) {
          totalFileSize += file.size;

          if (totalFileSize > MAX_SIZE) {
            this.props.displayToast(
              <FormattedMessage id="contactUs.reportIssue.fileUpload.totalFileSizeTooLarge" />,
              ToastType.ERROR
            );
            break;
          }

          selectedFiles.push(file);
        }
      }

      this.setState({ selectedFiles });
    }
  };

  private removeFile = (fileName: string) => {
    if (
      this.state.selectedFiles &&
      this.fileInputRef.current &&
      this.fileInputRef.current.files
    ) {
      const selectedFiles = this.state.selectedFiles.filter(
        (file) => file.name !== fileName
      );

      this.setState({ selectedFiles });
      this.fileInputRef.current.files = this.arrayToFileList(selectedFiles);
    }
  };

  private getFiles = () => {
    if (this.state.selectedFiles) {
      return this.state.selectedFiles.map((file) => (
        <div className="selected-file" key={`file-${file.name}`}>
          {file.name}
          <span className="size">
            ({Utils.bytesToHumanReadable(file.size)})
          </span>
          <Close
            className="remove-icon"
            onClick={() => this.removeFile(file.name)}
          />
        </div>
      ));
    }

    return null;
  };

  private arrayToFileList = (files: File[]): FileList => {
    const fileList = new ClipboardEvent("").clipboardData || new DataTransfer();
    files.forEach((file) => fileList.items.add(file));
    return fileList.files;
  };

  private handleVerify = (reCaptchaToken: string) => {
    this.setState({ reCaptchaToken }, this.submitForm);
  };

  private onSendClick = () => {
    if (this.isFormValid() && this.props.reCaptchaSecret) {
      this.setState({ isSubmitLoading: true }, () =>
        ReCaptchaUtils.verifyReCaptcha(
          this.props.reCaptchaSecret as string,
          this.handleVerify
        )
      );
    }
  };

  private verifyField = (
    id: TFormInputId,
    hasPreviousError: boolean,
    isOnChange = false,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;
    form.updateFormFieldError(
      id,
      hasPreviousError,
      isOnChange,
      additionalParams
    );
    this.setState({ form });
  };

  private getContentPlaceholder = (value: any) => {
    if (value === ProductFamily.API_PRODUCT)
      return "contactUs.reportIssue.product.placeholder.api";
    return "contactUs.reportIssue.product.placeholder.event";
  };

  private onChange = (
    id: TFormInputId,
    value: string | boolean,
    additionalParams: IInputAdditionalParams = {}
  ) => {
    const form = this.state.form;

    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );
    if (previousFieldState) {
      const fields: IField[] = [{ id, value }];

      if (id === ReportIssueInputId.PRODUCT_FAMILY) {
        fields.push({
          id: ReportIssueInputId.PRODUCT,
          value:
            value === ProductFamily.API_PORTAL
              ? ProductFamily.API_PORTAL
              : FormSelectValue.PLACEHOLDER,
          options: { placeholder: this.getContentPlaceholder(value) },
        });
      }

      form.updateFormFields(fields);

      this.setState({ form }, () =>
        this.verifyField(
          id,
          !!previousFieldState.options.error,
          true,
          additionalParams
        )
      );
    }
  };

  private onBlur = (id: TFormInputId) => {
    const previousFieldState = this.state.form.fields.find(
      (el) => el.id === id
    );

    if (previousFieldState) {
      this.verifyField(id, !!previousFieldState.options.error, false);
    }
  };

  private isFormValid(): boolean {
    const form = this.state.form;
    const isFormValid = form.isFormValid();
    this.setState({ form });
    return isFormValid;
  }

  private submitForm() {
    const reportIssue = new ReportIssueDTO(
      this.state.form,
      this.arrayToFileList(this.state.selectedFiles)
      );

    ContactAPI.postReportIssue(reportIssue.getFormData())
      .then(() => {
        this.setState({
          isSubmitLoading: false,
          form: this.updateFormWithUserProps(new Form(this.generateForm())),
          toast: {
            isDisplayed: true,
            type: ToastType.SUCCESS,
            message: (
              <FormattedMessage id="contactUs.reportIssue.issueReported" />
            ),
          },
        });
      })
      .catch((error: AxiosError) => {
        let toastMessage = <FormattedMessage id="error.errorOccurredFull" />;

        if (
          error.response?.status === HttpCode.PAYLOAD_TOO_LARGE ||
          error.response?.status === HttpCode.UNSUPPORTED_MEDIA_TYPE
        ) {
          toastMessage = <span>{error.response.data}</span>;
        }

        this.setState({
          isSubmitLoading: false,
          toast: {
            isDisplayed: true,
            type: ToastType.ERROR,
            message: toastMessage,
          },
        });
      });
  }

  private onToastHide = () => {
    this.setState({ toast: defaultToastAlert });
  };

  private getSegmentContent() {
    if (
      this.state.isReCaptchaSecretLoading ||
      this.state.areApisLoading ||
      this.state.areEventsLoading
    ) {
      return <Loader />;
    }

    if (!this.props.reCaptchaSecret) {
      return (
        <div className="finalMessage">
          <div>
            <div className="form-title">
              <FormattedMessage id="error.errorOccurred" />
            </div>
            <div className="form-message">
              <FormattedMessage id="error.tryAgainLater" />
            </div>
          </div>
        </div>
      );
    }

    const emptyFieldPlaceholder =
      this.props.screenType === ScreenType.COMPUTER ? <div /> : null;

    return (
      <div className="form-content">
        <FormGroup>
          <div className={`inputs-container ${this.props.screenType}`}>
            {this.getInput(ReportIssueInputId.EMAIL)}
            {this.getInput(ReportIssueInputId.CLIENT)}
            {this.getInput(ReportIssueInputId.PRODUCT_FAMILY, {
              selectItems: this.getProductFamilies(),
            })}

            {!!this.props.user
              ? this.getInput(ReportIssueInputId.PRODUCT, {
                  selectItems: this.getProducts(),
                })
              : emptyFieldPlaceholder}

            {this.getInput(ReportIssueInputId.TITLE)}
            {this.getInput(ReportIssueInputId.REQUEST_URL)}
            {this.getInput(ReportIssueInputId.RESPONSE_CODE, {
              selectItems: this.getCode(),
            })}
            {this.getInput(ReportIssueInputId.CORRELATION_ID)}
          </div>

          <div className="text-field-container">
            <div>{this.getInput(ReportIssueInputId.DESCRIPTION)}</div>
          </div>

          <div className="file-upload-container">
            <div>
              <InputLabel
                options={{
                  label: "contactUs.reportIssue.fileUpload",
                  error: undefined,
                  tooltip: "contactUs.reportIssue.fileUpload.tooltip",
                }}
              />

              <div className="file-upload">
                <div
                  className="upload-button custom-button"
                  onClick={this.openFileInput}
                >
                  <FormattedMessage id="contactUs.reportIssue.fileUpload.placeholder" />
                  <VerticalAlignTopIcon />
                </div>
                <input
                  ref={this.fileInputRef}
                  type="file"
                  accept={acceptedFileExtensions}
                  hidden={true}
                  multiple={true}
                  onChange={this.onFileChange}
                />

                <div className="selected-files-container">
                  {this.getFiles()}
                </div>
              </div>
            </div>
          </div>
        </FormGroup>

        <div className="button-container">
          <div
            className={`button ${this.state.isSubmitLoading ? "disabled" : ""}`}
            onClick={this.onSendClick}
          >
            {this.state.isSubmitLoading ? (
              <Loader size={ELoaderSize.SMALL} />
            ) : (
              <FormattedMessage id="contactUs.send" />
            )}
          </div>

          {this.state.toast.isDisplayed && (
            <ToastAlert
              toastType={this.state.toast.type}
              toastMessage={this.state.toast.message}
              hideToast={this.onToastHide}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="report-issue-container">
        <FormattedMessage id="contactUs.reportIssue.reportIssue.content1" />
        <div className="br" />
        <FormattedMessage id="contactUs.reportIssue.reportIssue.content2" />
        {this.getSegmentContent()}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  reCaptchaSecret: state.reCaptchaSecret,
  screenType: state.screenType,
});

const mapDispatch = {
  displayToast: (toastMessage: JSX.Element, toastType = ToastType.SUCCESS) => ({
    type: ReducerAction.TOAST,
    payload: { toastMessage, toastType },
  }),
  saveReCaptchaSecret: (reCaptchaSecret: string) => ({
    type: ReducerAction.RE_CAPTCHA_TOKEN_FETCH,
    payload: reCaptchaSecret,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(injectIntl(ReportIssue));
