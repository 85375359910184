import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Pagination } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Loader from "../../../../common/components/loader";
import NotFound from "../../../../common/components/notFound";
import {
  IMyEntities,
  IPartner,
  IPartnerWithCount,
  PartnerSelection,
} from "../../interfaces/business-partners.interface";
import PartnersAPI from "../../apis/business-partners.api";
import BracedTitle from "../../../../common/components/bracedTitle";
import { ReducerAction } from "../../../../reducer/enums/reducer-action.enum";
import { PartnerStatus, PartnerType } from "../../enums/business-partners.enum";
import ParentPartnerItem from "./parentPartnerItem";
import CreationButton from "../businessPartnerCreation/creationButton";
import { PartnerConfig } from "../../models/business-partners.model";
import StatusLabel from "../common/statusLabel";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import { SortingModes } from "../../../../common/enums/sorting-mode.enum";
import PartnerSearchSort from "../partnerSearchSort";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, WrappedComponentProps {
  partnerConfig: PartnerConfig;
  updatePartnerConfig(partnerConfig: PartnerConfig): Promise<void>;
  onChildPartnerSelection(
    childPartner: PartnerSelection,
    partnerType: PartnerType
  ): void;
  setActivePartnerLoaded(isActivePartnersLoaded?: Boolean) : Boolean | void;
}

class ParentPartnerList extends Component<IProps> {
  private NB_OF_PARTNERS_BY_PAGE =
    this.props.partnerConfig.type === PartnerType.OPEN_REQUEST ? 50 : 20;

  componentDidMount() {
    if (this.props.partnerConfig.partners === undefined) {
      this.getPartners();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      this.props.partnerConfig.type === PartnerType.OPEN_REQUEST &&
      !!this.props.hasOpenRequestPartnerBeenAdded
    ) {
      this.getPartners();
      this.props.addedOpenRequestPartner(false);
    }
  }

  private toggleIsOpened = () => {
    const partnerConfig = this.props.partnerConfig;
    partnerConfig.setValues({
      isCollapsed: !this.props.partnerConfig.isCollapsed,
    });
    this.props.updatePartnerConfig(partnerConfig);
  };

  private getPartners = () => {
    const request =
      this.props.partnerConfig.type === PartnerType.OPEN_REQUEST
        ? PartnersAPI.getOpenRequestPartners
        : PartnersAPI.getActivePartners;

    const partnerConfig = this.props.partnerConfig;
    partnerConfig.setValues({ isLoading: true });

    this.props.updatePartnerConfig(partnerConfig).then(() => {
      request(
        this.props.partnerConfig.currentPage,
        this.NB_OF_PARTNERS_BY_PAGE,
        this.props.partnerConfig.searchValue,
        this.props.partnerConfig.sortingMode
      )
        .then((partnersWithCount: IPartnerWithCount) => {
          if(this.props.partnerConfig.type === PartnerType.ACTIVE && partnersWithCount?.partners?.length > 0)
            this.props.setActivePartnerLoaded(true);
          const totalPages =
            partnersWithCount.partnerCount > 0
              ? Math.ceil(
                  partnersWithCount.partnerCount / this.NB_OF_PARTNERS_BY_PAGE
                )
              : 0;

          let count = partnersWithCount.partnerCount;

          if (partnersWithCount.orphanPartners?.hasOrphans) {
            count += 1;
          }

          partnerConfig.setValues({
            partners: partnersWithCount.partners,
            count,
            totalPages,
            isLoading: false,
            orphanPartners: partnersWithCount.orphanPartners,
            isCollapsed: count === 0,
          });
          return this.props.updatePartnerConfig(partnerConfig);
        })
        .catch(() => {
          partnerConfig.setValues({ isLoading: false });
          return this.props.updatePartnerConfig(partnerConfig);
        });
    });
  };

  private onSearch = (searchValue: string): Promise<void> => {
    const partnerConfig = this.props.partnerConfig;
    partnerConfig.setValues({ searchValue });
    return this.props.updatePartnerConfig(partnerConfig).then(() => {
      if (
        this.props.partnerConfig.searchValue === "" ||
        this.props.partnerConfig.searchValue.length >= 3
      ) {
        this.getPartners();
      }
    });
  };

  private setSortingMode = (sortingMode: SortingModes): void => {
    const partnerConfig = this.props.partnerConfig;
    partnerConfig.setValues({ sortingMode });
    this.props.updatePartnerConfig(partnerConfig).then(this.getPartners);
  };

  private onPageChange = (currentPage: number) => {
    if (this.props.partnerConfig.currentPage !== currentPage) {
      const partnerConfig = this.props.partnerConfig;
      partnerConfig.setValues({ currentPage });
      this.props.updatePartnerConfig(partnerConfig).then(this.getPartners);
      this.props.scrollToTop();
    }
  };

  private getFilteredPartners = (): IPartner[] => {
    const partners: IPartner[] = [];

    if (this.props.partnerConfig.partners) {
      for (const partner of this.props.partnerConfig.partners) {
        if (!partner.hasOwnProperty("code")) {
          partners.push({ ...partner });
          continue;
        }
      
        const partnerIndex = partners.findIndex((p) => p.code === partner.code);
      
        if (partnerIndex === -1) {
          partners.push({ ...partner });
        } else if (partner.status === PartnerStatus.LOA_REQUIRED) {
          partners[partnerIndex].status = partner.status;
        }
      }
    }

    return partners;
  };

  private getHeader = (label: JSX.Element | undefined) => {
    const title =
      this.props.partnerConfig.type === PartnerType.OPEN_REQUEST ? (
        <FormattedMessage id="partner.openRequests" />
      ) : (
        <FormattedMessage id="partner.active" />
      );

    return (
      <div className="list-header" onClick={this.toggleIsOpened}>
        <div className="left-container">
          <BracedTitle text={title} />

          {(this.props.screenType === ScreenType.TABLET || this.props.screenType === ScreenType.COMPUTER)  && label}
        </div>

        <div className="right-container">
          {this.props.screenType === ScreenType.SMALL_PHONE && label}
          {this.props.partnerConfig.type === PartnerType.OPEN_REQUEST && this.props.setActivePartnerLoaded() && this.props.screenType === ScreenType.TABLET && (
            <CreationButton />
          )}

          {this.props.screenType!==ScreenType.COMPUTER&&<KeyboardArrowDownIcon
            className={`chevron ${
              this.props.partnerConfig.isCollapsed ? "" : "opened"
            }`}
          />
  }
        </div>
      </div>
    );
  };

  private getContent = (partners: IPartner[]) => {
    if (this.props.partnerConfig.isLoading) {
      return <Loader />;
    }

    if (
      (this.props.partnerConfig.partners === undefined ||
        this.props.partnerConfig.partners.length === 0) &&
      !this.props.partnerConfig.orphanPartners?.hasOrphans
    ) {
      return this.props.partnerConfig.type === PartnerType.OPEN_REQUEST ? (
        <NotFound
          text={<FormattedMessage id="partner.notFound.openRequest" />}
        />
      ) : (
        <NotFound text={<FormattedMessage id="partner.notFound.active" />} />
      );
    }

    let myEntitiesRow: JSX.Element | null = null;
    let indexOffset = 0;

    if (this.props.partnerConfig.orphanPartners?.hasOrphans) {
      const myEntitiesPartner: IMyEntities = {
        name: this.props.intl.formatMessage({
          id: "partner.myEntities",
        }),
        code: this.props.partnerConfig.orphanPartners.code,
        status: PartnerStatus.ACTIVE,
        products: this.props.partnerConfig.orphanPartners.products,
      };

      myEntitiesRow = (
        <ParentPartnerItem
          key={`partner-my-entities`}
          partnerType={PartnerType.MY_ENTITIES}
          partner={myEntitiesPartner}
          index={0}
          onChildPartnerSelection={() =>
            this.props.onChildPartnerSelection(
              myEntitiesPartner,
              PartnerType.MY_ENTITIES
            )
          }
        />
      );

      indexOffset = 1;
    }
    
    return (
      <div
        className={`branch-list ${
          this.props.partnerConfig.type === PartnerType.OPEN_REQUEST
            ? "open-request"
            : "active"
        }`}
      >
        {myEntitiesRow}

        {partners.map((partner, index) => (
          <ParentPartnerItem
            key={`partner-${index}-${partner.name}`}
            partnerType={this.props.partnerConfig.type}
            partner={partner}
            index={index + indexOffset}
            onChildPartnerSelection={() =>
              this.props.onChildPartnerSelection(
                partner,
                this.props.partnerConfig.type
              )
            }
          />
        ))}
      </div>
    );
  };

  render() {
    const partners = this.getFilteredPartners();
    let label;

    if (this.props.partnerConfig.type === PartnerType.OPEN_REQUEST) {
      const getLabel = (status: PartnerStatus) => {
        if (!partners) {
          return null;
        }

        const count = partners.filter(
          (partner) => partner.status === status
        ).length;
        return <StatusLabel status={status} count={count} />;
      };

      label = (
        <div className="label-container">
          {getLabel(PartnerStatus.IN_PROGRESS)}
          {getLabel(PartnerStatus.LOA_REQUIRED)}
          {getLabel(PartnerStatus.REJECTED)}
        </div>
      );
    } else {
      if (
        this.props.partnerConfig.partners &&
        this.props.partnerConfig.partners.length > 0
      ) {
        label = (
          <div className={`label-container ${this.props.screenType}`}>
            <StatusLabel
              status={PartnerStatus.ACTIVE}
              count={this.props.partnerConfig.count}
            />
          </div>
        );
      }
    }

    return (
      <div className="partner-parent-list partner-list">
        {this.getHeader(label)}

        {this.props.screenType === ScreenType.PHONE && label}

        <div
          className={`list-content ${this.props.screenType==ScreenType.COMPUTER?"opened":
            this.props.partnerConfig.isCollapsed ? "" : "opened"
          }`}
        >
          {this.props.partnerConfig.type === PartnerType.ACTIVE &&
            this.props.partnerConfig.partners && (
              <PartnerSearchSort
                partnerConfig={this.props.partnerConfig}
                onSearch={this.onSearch}
                setSortingMode={this.setSortingMode}
              />
            )}

          {this.getContent(partners)}

          {this.props.partnerConfig.partners &&
            this.props.partnerConfig.totalPages > 1 && (
              <div className="pagination-container">
                <Pagination
                  count={this.props.partnerConfig.totalPages}
                  page={this.props.partnerConfig.currentPage}
                  onChange={(event, value) => this.onPageChange(value)}
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
  hasOpenRequestPartnerBeenAdded: state.hasOpenRequestPartnerBeenAdded,
});

const mapDispatch = {
  addedOpenRequestPartner: (hasOpenRequestPartnerBeenAdded: boolean) => ({
    type: ReducerAction.ADDED_OPEN_REQUEST_PARTNER,
    payload: hasOpenRequestPartnerBeenAdded,
  }),
  scrollToTop: () => ({
    type: ReducerAction.SCROLL_TO_TOP,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(injectIntl(ParentPartnerList));
