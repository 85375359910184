import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";

import { BetterWayIcon } from "../../../assets/icons";
import { Routes } from "../../common/enums/routes.enum";
import { IReducerState } from "../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteComponentProps, PropsFromRedux {}

class Footer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div id="footer">
        <div className={`footer-content ${this.props.screenType}`}>
          <div className="global-footer">
            <div className="App-content">
              <div className="footer">
                <div className="left-part">
                  <BetterWayIcon className="better-way-icon" />
                </div>

                <div className="center-part">
                  <div
                    className="link"
                    onClick={() =>
                      this.props.history.push(`${Routes.PRODUCTS}/schedules`)
                    }
                  >
                    <FormattedMessage id="productCatalog" />
                  </div>
                  <div
                    className="link"
                    onClick={() =>
                      this.props.history.push(Routes.BUSINESS_REQUEST)
                    }
                  >
                    <FormattedMessage id="contactUs" />
                  </div>
                  <div
                    className="link"
                    onClick={() =>
                      this.props.history.push(Routes.STARTING_GUIDE)
                    }
                  >
                    <FormattedMessage id="help" />
                  </div>

                  <div className="tiret" />

                  <a
                    className="about-us link"
                    href="https://www.cma-cgm.com/about/the-group"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="topMenu.aboutUs" />
                  </a>
                </div>

                <div className="right-part">
                  <FormattedMessage id="followUs" />
                  <div className="logo">
                    <a
                      href="https://www.facebook.com/cmacgm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/cma-cgm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedIn />
                    </a>
                    <a
                      href="https://twitter.com/CMACGM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter />
                    </a>
                    <a
                      href="https://www.instagram.com/cmacgm/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="classic-footer">
            <ul>
              <li>
                <a href={Routes.BUSINESS_REQUEST} className="button">
                  <FormattedMessage id="footer.contact" />
                </a>
              </li>
              <li>
                <a href={Routes.SITE_MAP} className="button">
                  <FormattedMessage id="siteMap" />
                </a>
              </li>
              <li>
                {/*OneTrust Cookies Settings*/}
                <div id="ot-sdk-btn" className="ot-sdk-show-settings button">
                  <FormattedMessage id="footer.cookiesSettings" />
                </div>
              </li>
              <li>
                <a href={Routes.PRIVACY_NOTICE} className="button">
                  <FormattedMessage id="privacyNotice" />
                </a>
              </li>
              <li>
                <a href={Routes.LEGAL_TERMS} className="button">
                  <FormattedMessage id="footer.legalTerms" />
                </a>
              </li>
            </ul>
            <div className="original">
              <FormattedMessage id="original" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(Footer));
