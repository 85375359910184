import HttpUtils from "../../../utils/http.utils";
import { UserPost,UserPostCreation,User } from "../models/user.model";
import { IApplication } from "../../myOrganization/apiApplications/interfaces/application.interface";
import { AxiosResponse } from "axios";

export default class UserAPI {
  public static getUser(): Promise<AxiosResponse> {
    return HttpUtils.get("/user");
  }

  public static postUser(body: UserPost, reCaptchaToken?: string) {
    return HttpUtils.post("/user", { ...body, reCaptchaToken });
  }

  public static postUserCreation(body:UserPostCreation){
    return HttpUtils.post("/user/createUser", { ...body });
  }

  public static deleteUser(): Promise<AxiosResponse> {
    return HttpUtils.delete("/user");
  }

  public static getApplicationsByClientId(): Promise<IApplication[]> {
    return HttpUtils.get(`/applications`);
  }
  public static updateUser(body:User):Promise<AxiosResponse>{
    return HttpUtils.put("/user/updateUser",{...body});
  }
}
