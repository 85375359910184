import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";

import { RedTiretIcon } from "../../../assets/icons";
import Catalog from "./catalog";
import HeaderBanner from "../../common/components/headerBanner";
import { Routes } from "../../common/enums/routes.enum";
import { IReducerState } from "../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps {}

class HomePage extends Component<IProps> {
  render() {
    return (
      <div id="home-page">
        <HeaderBanner isHomePage={true}>
          <div className={`api-portal-title ${this.props.screenType}`}>
            <FormattedMessage id="api" />
            <span className="title-brace">{"{"}</span>
            <FormattedMessage id="portal" />
          </div>
          <div className={`api-portal-content ${this.props.screenType}`}>
            <FormattedMessage id="apiPortalContent" />
          </div>
        </HeaderBanner>

        {!this.props.user && (
          <div className="helpStart">
            <div className="App-content">
              <FormattedMessage id="landingStarted" />
              <div className="lane">
                <div className={`landingStartedLane ${this.props.screenType}`}>
                  <div className="block">
                    <div className="tiretNumber">1</div>
                    <FormattedMessage id="landingStarted1" />
                  </div>
                  <div className="block">
                    <div className="tiretNumber">2</div>
                    <FormattedMessage id="landingStarted2" />
                  </div>
                  <div className="block">
                    <div className="tiretNumber">3</div>
                    <FormattedMessage id="landingStarted3" />
                  </div>
                  <div className="block">
                    <div className="tiretNumber">4</div>
                    <FormattedMessage id="landingStarted4" />
                  </div>
                  <div className="block">
                    <div className="tiretNumber">
                      5
                      <div className="tiret" />
                    </div>
                    <FormattedMessage id="landingStarted5" />
                  </div>
                </div>
                <div
                  className="learnMore"
                  onClick={() => this.props.history.push(Routes.STARTING_GUIDE)}
                >
                  <FormattedMessage id="apiUsage.learnMore" />
                </div>
              </div>
            </div>
          </div>
        )}

        <Catalog />

        <div className="products-roadmap-container">
          <div className="App-content">
            <span className="roadmap-title">
              <RedTiretIcon />
              <FormattedMessage id="roadmap" />
            </span>
            <div className={`products-roadmap ${this.props.screenType}`}>
              <div className="content">
                <div className="italic">
                  <FormattedMessage id="roadmap.landing1" />
                </div>
                <div>
                  <FormattedMessage
                    id="roadmap.landing2"
                    values={{
                      a: (link: any) => (
                        <a
                          href={Routes.BUSINESS_REQUEST}
                          onClick={() =>
                            this.props.history.push(Routes.BUSINESS_REQUEST)
                          }
                        >
                          {link}
                        </a>
                      ),
                    }}
                  />
                </div>
              </div>
              <div
                className="button"
                onClick={() => this.props.history.push(Routes.ROADMAP)}
              >
                <FormattedMessage id="roadmap.discover" />
              </div>
            </div>
          </div>
        </div>

        <div className="api-price">
          <div className="api-price-title">
            <RedTiretIcon />
            <FormattedMessage id="apiPriceTitle" />
          </div>
          <div className="api-price-content">
            <FormattedMessage id="apiPriceContent" />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(HomePage));
