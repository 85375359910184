import "./styles.scss";

import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { Box, Input, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Country } from "../../../../interfaces/country.interface";
import { ReducerAction } from "../../../../../reducer/enums/reducer-action.enum";
import LocationAPI from "../../../../apis/location.api";
import { FormSelectValue, TFormInputId } from "../../enums/form.enum";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import InputLabel from "../inputLabel";
import { InputOptions } from "../../models/input-options.model";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, WrappedComponentProps {
  id: TFormInputId;
  countryName: string;
  countryCode: string;
  options: InputOptions;
  disabled?: boolean;

  onChange(countryName: string, countryCode: string): void;
  onBlur(): void;
}

class CountryInput extends Component<IProps> {
  componentDidMount() {
    if (!this.props.countries) {
      LocationAPI.getCountries().then((countries) => {
        this.props.saveCountries(countries);
      });
    }
  }

  render() {
    const placeholder = this.props.options.placeholder
      ? this.props.intl.formatMessage({
          id: this.props.options.placeholder,
        })
      : "";

    let content = <Input placeholder={placeholder} disabled={true} />;

    if (this.props.countries && this.props.countryName !== "") {
      const selectedCountry = this.props.countries.find(
        (country: Country) =>
          country.name.toUpperCase() === this.props.countryName.toUpperCase()
      );

      content = (
        <Autocomplete
          options={this.props.countries!}
          value={selectedCountry || null}
          disabled={!!this.props.disabled}
          onChange={(event, value) =>
            this.props.onChange(
              value?.name || FormSelectValue.NONE,
              value?.code || FormSelectValue.NONE
            )
          }
          onBlur={this.props.onBlur}
          getOptionLabel={(option) => option.fullName as string}
          renderOption={(option) => (
            <Box className="autocomplete-item" component="li">
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.fullName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              className={this.props.options.error ? "error" : ""}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
                placeholder,
              }}
            />
          )}
        />
      );
    }

    return (
      <div className="form-input country-input">
        <InputLabel options={this.props.options} />

        {content}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  countries: state.countries,
});

const mapDispatch = {
  saveCountries: (countries: Country[]) => ({
    type: ReducerAction.COUNTRIES_FETCH,
    payload: countries,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(injectIntl(CountryInput));
