import {
  IOrphanPartners,
  IPartner,
} from "../interfaces/business-partners.interface";
import { PartnerStatus, PartnerType } from "../enums/business-partners.enum";
import { Form } from "../../../common/submodules/form/models/form.model";
import { NewCustomerInputId } from "../../../common/submodules/form/enums/form.enum";
import { SortingModes } from "../../../common/enums/sorting-mode.enum";

interface IPartnerConfig {
  partners?: IPartner[];
  count?: number;
  type?: PartnerType;
  isLoading?: boolean;
  currentPage?: number;
  totalPages?: number;
  searchValue?: string;
  sortingMode?: SortingModes;
  isCollapsed?: boolean;
  orphanPartners?: IOrphanPartners;
}

export class PartnerConfig {
  partners: IPartner[] | undefined;
  count: number;
  type: PartnerType;
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  searchValue: string;
  sortingMode: SortingModes;
  isCollapsed: boolean;
  orphanPartners?: IOrphanPartners;

  constructor(config: IPartnerConfig) {
    this.partners = config.partners;
    this.count = config.count ?? 0;
    this.type = config.type ?? PartnerType.OPEN_REQUEST;
    this.isLoading = config.isLoading !== undefined ? config.isLoading : false;
    this.currentPage = config.currentPage ?? 1;
    this.totalPages = config.totalPages ?? 1;
    this.searchValue = config.searchValue ?? "";
    this.sortingMode = config.sortingMode ?? SortingModes.ASCEND;
    this.isCollapsed =
      config.isCollapsed !== undefined ? config.isCollapsed : true;
    this.orphanPartners = config.orphanPartners ?? undefined;
  }

  public setValues(config: IPartnerConfig) {
    if ("partners" in config) this.partners = config.partners;
    if (config.count !== undefined) this.count = config.count;
    if (config.type !== undefined) this.type = config.type;
    if (config.isLoading !== undefined) this.isLoading = config.isLoading;
    if (config.currentPage !== undefined) this.currentPage = config.currentPage;
    if (config.totalPages !== undefined) this.totalPages = config.totalPages;
    if (config.searchValue !== undefined) this.searchValue = config.searchValue;
    if (config.sortingMode !== undefined) this.sortingMode = config.sortingMode;
    if (config.isCollapsed !== undefined) this.isCollapsed = config.isCollapsed;
    if ("orphanPartners" in config) this.orphanPartners = config.orphanPartners;
  }
}

export class PartnerPost {
  companyName: string;
  representativeName: string;
  representativeEmail: string;
  customerName: string;
  customerCode?: string;

  constructor(form: Form, clientName: string, enrichPartner?: IPartner) {
    this.companyName = form.getFormField(NewCustomerInputId.COMPANY_NAME)
      ?.value as string;

    this.representativeName = form.getFormField(
      NewCustomerInputId.REPRESENTATIVE_NAME
    )?.value as string;

    this.representativeEmail = (form.getFormField(
      NewCustomerInputId.REPRESENTATIVE_EMAIL
    )?.value as string).toLowerCase();

    let customerName = `${clientName}_${this.companyName}_API APP`;
    let customerCode;

    if (enrichPartner) {
      customerName = enrichPartner.name;
      if (
        enrichPartner.status !== PartnerStatus.ACTIVE &&
        enrichPartner.applicativeBpName
      ) {
        customerName = enrichPartner.applicativeBpName;
      }

      customerCode = enrichPartner.code;
    }

    this.customerName = customerName;
    this.customerCode = customerCode;
  }
}
