import "./styles.scss";

import * as React from "react";
import { FormattedMessage } from "react-intl";

import TermsAndConditionsAPI from "../../apis/terms-and-conditions.api";
import Loader from "../../../common/components/loader";
import NotFound from "../../../common/components/notFound";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";

interface IProps {}
interface IStates {
  pdfDocument: string | null;
  isPdfLoading: boolean;
}

class TermsAndConditions extends React.Component<IProps, IStates> {
  public constructor(props: IProps) {
    super(props);
    this.state = { pdfDocument: null, isPdfLoading: true };
  }

  public componentDidMount() {
    TermsAndConditionsAPI.getTermsAndConditionsPdf()
      .then((file) => {
        if (file && file.url) {
          this.setState({ pdfDocument: file.url, isPdfLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isPdfLoading: false });
      });
  }

  private onPdfDownloadClick = () => {
    if (this.state.pdfDocument !== null || this.state.pdfDocument === "") {
      const link = document.createElement("a");
      link.href = this.state.pdfDocument;
      link.download = "terms-and-conditions.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  public render() {
    if (this.state.isPdfLoading) {
      return <Loader />;
    }

    return (
      <>
        <div id="terms-and-conditions-container" className="App-page">
          {this.state.pdfDocument !== null || this.state.pdfDocument === "" ? (
            <object
              id="pdf-container"
              data={`${this.state.pdfDocument}#page=1&zoom=125`}
              type="application/pdf"
              width="100%"
            >
              <div className="no-file">
                <span>
                  <FormattedMessage id="termsAndConditions.pdfNotSupported" />
                </span>

                <div
                  className="download custom-button"
                  onClick={this.onPdfDownloadClick}
                >
                  <FormattedMessage id="termsAndConditions.downloadPdf" />
                  <VerticalAlignBottomIcon />
                </div>
              </div>
            </object>
          ) : (
            <div className="no-file">
              <NotFound
                text={
                  <FormattedMessage id="termsAndConditions.noPdfDocument" />
                }
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
