import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import ClickAwayListener from "react-advanced-click-away";

import { IPartner } from "../../../interfaces/business-partners.interface";
import { CopyIcon } from "../../../../../../assets/icons";

interface IProps {
  partner: IPartner;
}

interface IStates {
  isTooltipVisible: boolean;
  isCodeCopied: boolean;
}

class PartnerCodeTooltip extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isTooltipVisible: false,
      isCodeCopied: false,
    };
  }

  private copyPartnerCode = (event: any): void => {
    event.stopPropagation();

    if (this.props.partner.code) {
      navigator.clipboard.writeText(this.props.partner.code).then(() => {
        this.setState({ isCodeCopied: true }, () => {
          setTimeout(() => {
            this.setState({ isCodeCopied: false });
          }, 2000);
        });
      });
    }
  };

  private showTooltip = (event: any, isTooltipVisible = true) => {
    event.stopPropagation();
    this.setState({ isTooltipVisible });
  };

  render() {
    let titleContent = <FormattedMessage id="partner.noCodeYet" />;

    if (this.props.partner.code) {
      titleContent = (
        <div>
          {this.props.partner.code} - {this.props.partner.name}
        </div>
      );
    }

    if (this.state.isCodeCopied) {
      titleContent = <FormattedMessage id="partner.codeCopied" />;
    }

    return (
      <ClickAwayListener
        onClickAway={(event: MouseEvent | TouchEvent) => this.showTooltip(event, false)}
      >
        <div className="partner-tooltip-container">
          <IconButton
            onClick={(event) =>
              this.showTooltip(event, !this.state.isTooltipVisible)
            }
          >
            <InfoOutlined />
          </IconButton>

          <div
            className={`partner-tooltip-container-popup ${
              this.state.isTooltipVisible ? "visible" : ""
            }`}
          >
            {this.props.partner.code && (
              <CopyIcon onClick={this.copyPartnerCode} />
            )}
            {titleContent}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

export default PartnerCodeTooltip;
