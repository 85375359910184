import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect, ConnectedProps } from "react-redux";

import {
  IPackage,
  IPackageSelectionForOfferTile,
  Offer,
  OfferId,
} from "../../interfaces/package.interface";
import OfferLine from "./offerLine";
import { PackageSelection } from "../../models/crm.model";
import { OfferSelection } from "../../enums/crm.enum";
import Utils from "../../../../utils";
import NotFound from "../../../common/components/notFound";
import { InfoIcon } from "../../../../assets/icons";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  packages: IPackage[];
  packageSelection: PackageSelection;
  deleteChoice(packageName: string): void;
}

class MySelection extends Component<IProps> {
  private getOfferLine = (selectedPackage: IPackageSelectionForOfferTile) => {
    const fullPackage = this.props.packages.find(
      (el) => el.name === selectedPackage.packageName
    );

    if (fullPackage) {
      return (
        <OfferLine
          key={`offer-tile-${selectedPackage.offer.id}`}
          selectedPackage={selectedPackage}
          unitOfMeasure={fullPackage.unitOfMeasure}
          deleteChoice={() =>
            this.props.deleteChoice(
              Utils.stringToDashCase(selectedPackage.packageName)
            )
          }
        />
      );
    }
  };

  render() {
    const pricedPackages: IPackageSelectionForOfferTile[] = [];
    const customPackages: IPackageSelectionForOfferTile[] = [];

    this.props.packageSelection.packages.forEach((packageSelection) => {
      if (packageSelection.offerId !== OfferSelection.NONE) {
        const matchingPackage = this.props.packages.find(
          (el) => el.name === packageSelection.name
        );

        let offer: Offer | OfferId = {
          id: packageSelection.offerId,
        };

        if (matchingPackage) {
          const offerInMatchingPackage = matchingPackage.offers.find(
            (offer) => offer.id === packageSelection.offerId
          );

          if (offerInMatchingPackage) offer = offerInMatchingPackage;
        }

        const newPackage = {
          offer,
          packageName: packageSelection.name,
          customOfferMessage: packageSelection.customOfferMessage,
        };

        if (packageSelection.offerName.toLowerCase() === OfferSelection.CUSTOM) {
          customPackages.push(newPackage);
        } else {
          pricedPackages.push(newPackage);
        }
      }
    });

    return (
      <div id="my-selection">
        <div className="title">
          <FormattedMessage id="mySelection" />
        </div>

        <div className={`offers ${this.props.screenType}`}>
          {pricedPackages.length === 0 && customPackages.length === 0 && (
            <NotFound text={<FormattedMessage id="offers.noOfferSelected" />} />
          )}
          {pricedPackages.length > 0 && (
            <>
              <div className="offers-type-title">
                <FormattedMessage id="offers.pricedOffers" />
              </div>

              <div className="info-message">
                <InfoIcon />
                <FormattedMessage id="offers.pricedOffersInfo" />
              </div>

              <div className="offers-tile priced-offers">
                {pricedPackages.map(this.getOfferLine)}

                <div className="total-price">
                  <span className="total">
                    <FormattedMessage id="total" />:
                  </span>

                  <span>
                    <span className="price">
                      <FormattedNumber
                        value={pricedPackages.reduce(
                          (prevValue, currentPackage) =>
                            prevValue +
                            (currentPackage.offer as Offer).pricing.amount,
                          0
                        )}
                        style="currency"
                        currency={"USD"}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </span>
                    &nbsp;
                    <FormattedMessage id="offers.perMonth" />
                  </span>
                </div>
              </div>
            </>
          )}

          {customPackages.length > 0 && (
            <>
              <div className="offers-type-title">
                <FormattedMessage id="offers.customOffers" />
              </div>

              <div className="info-message">
                <InfoIcon />
                <FormattedMessage id="offers.customOffersInfo" />
              </div>
              <div className="offers-tile custom-offers">
                {customPackages.map(this.getOfferLine)}
                
              <div className="total-message">
                <span className="total">
                  <FormattedMessage id="message" />:
                </span>

                  <span className="message">
                    {customPackages.map((customPackage) => customPackage.customOfferMessage)}
                  </span>
              </div>

              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default connector(MySelection);
