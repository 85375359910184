import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@material-ui/core";
import { Helmet } from 'react-helmet';
import BracedTitle from "../../../common/components/bracedTitle";
import { InfoIcon, WarningIcon } from "../../../../assets/icons";
import CustomScrollBars from "../../../common/components/customScrollBars";
import HeaderBanner from "../../../common/components/headerBanner";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps { }

interface IStates {
  apiResponseCodes: IApiResponseCode[];
  activeTab: TabValues;
}

interface IApiResponseCode {
  code: number;
  description: any;
  chargeable: string;
}

enum TabValues {
  HEADER_API = "header-api",
  ACCESS = "access",
  AUTHENTICATION = "authentication",
  CACHING = "caching",
  RATE_LIMIT = "rate-limit",
  PAGINATION = "pagination",
  CORS = "cors",
  HEADER_EVENT = "header-event",
  WEBHOOK_URL = "webhook-url",
  WEBHOOK_SECURITY = "webhook-security",
  WEBHOOK_THROTTLING = "webhook-throttling",
  WEBHOOK_POLICY = "webhook-policy",
  API_RESPONSE_CODE="api-response-code"
}

class UsageGuide extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: this.getActiveTab(),
      apiResponseCodes: [
        { code: 200, description: <FormattedMessage id="usageGuide.apiResponseCodes.message5" />, chargeable: 'YES' },
        { code: 201, description: <FormattedMessage id="usageGuide.apiResponseCodes.message6" />, chargeable: 'YES' },
        { code: 204, description: <FormattedMessage id="usageGuide.apiResponseCodes.message7" />, chargeable: 'YES' },
        { code: 206, description: <FormattedMessage id="usageGuide.apiResponseCodes.message8" />, chargeable: 'YES' },
        { code: 400, description: <FormattedMessage id="usageGuide.apiResponseCodes.message9" />, chargeable: 'YES' },
        { code: 401, description: <FormattedMessage id="usageGuide.apiResponseCodes.message10" />, chargeable: 'NO' },
        { code: 403, description: <FormattedMessage id="usageGuide.apiResponseCodes.message11" />, chargeable: 'NO' },
        { code: 429, description: <FormattedMessage id="usageGuide.apiResponseCodes.message12" />, chargeable: 'NO' },
        { code: 500, description: <FormattedMessage id="usageGuide.apiResponseCodes.message13" />, chargeable: 'NO' },
        { code: 502, description: <FormattedMessage id="usageGuide.apiResponseCodes.message14" />, chargeable: 'NO' },
        { code: 503, description: <FormattedMessage id="usageGuide.apiResponseCodes.message15" />, chargeable: 'NO' },
        { code: 504, description: <FormattedMessage id="usageGuide.apiResponseCodes.message16" />, chargeable: 'NO' },
      ]
    };
  }

  componentDidMount() {
    if (window.location.hash) {
      const tabValue = Object.values(TabValues)
        .filter((key) => isNaN(Number(key)))
        .find((tab) => tab === window.location.hash.replace("#", ""));

      if (tabValue) {
        this.onTabClick(tabValue);
      }
    } else {
      this.onTabClick(TabValues.HEADER_API);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    if (prevState.activeTab !== this.state.activeTab) {
      const element = document.getElementById(this.state.activeTab);
      if (element) element.scrollIntoView();
    }
  }

  private getActiveTab = () => {
    return this.state ? this.state.activeTab : TabValues.HEADER_API;
  };

  private getActiveTabIndex = (tabLink = this.state.activeTab) => {
    return Object.values(TabValues)
      .filter((key) => isNaN(Number(key)))
      .findIndex((tab) => tab === tabLink);
  };

  private onTabClick = (activeTab: TabValues) => this.setState({ activeTab });

  private getTab = (
    tabLink: TabValues,
    label: JSX.Element,
    isTitle = false
  ) => {
    return (
      <a href={`#${tabLink}`} onClick={() => this.onTabClick(tabLink)}>
        <Tab
          className={`tab ${tabLink === this.state.activeTab ? "active" : ""} ${isTitle ? "title" : ""
            }`}
          label={label}
          value={this.getActiveTabIndex()}
        />
      </a>
    );
  };

  render() {
    const numberListPostResponse = "1\n2\n3\n4\n5";
    const numberListPostExample = "1\n2\n3\n4\n5\n6";
    const codeContentPostResponse =
      "{\n« access_token »: « <token_value> »,\n« token_type »: « Bearer »,\n« expires_in »: 299\n}";
    const codeContentPostExample =
      "curl --request POST --url https://auth.cma-cgm.com/as/token.oauth2\n--header ‘Content-Type: application/x-www-form-urlencoded’\n--data grant_type=client_credentials\n--data client_id=<your_client_Id>\n--data client_secret=<your_client_secret>\n--data ‘scope=scope1 scope 2’";

    return (
      <div id="usageGuide">
        <Helmet>
          <title>Help - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <FormattedMessage id="usageGuide.head.title" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content-container">
            <CustomScrollBars>
              <div className="content">
                <div id={TabValues.HEADER_API} className="category header-api">
                  <div className="title-header">
                    <BracedTitle
                      text={<FormattedMessage id="api" />}
                      size="x-large"
                    />
                  </div>
                </div>

                <div id={TabValues.ACCESS} className="category api-access">
                  <div id="access" className="title">
                    <FormattedMessage id="usageGuide.apiAccess.title" />
                  </div>
                  <div className="message">
                    <FormattedMessage id="usageGuide.apiAccess.message" />
                    <span className="api-url">https://apis.cma-cgm.net</span>
                  </div>
                </div>

                <div
                  id={TabValues.AUTHENTICATION}
                  className="category api-authorization"
                >
                  <div id="authentication" className="title">
                    <FormattedMessage id="usageGuide.apiAuthorization.title" />
                  </div>
                  <div className="message">
                    <FormattedMessage id="usageGuide.apiAuthorization.message" />
                  </div>

                  <div className="subcategory">
                    <BracedTitle
                      text={<FormattedMessage id="usageGuide.apiKey.title" />}
                      size="large"
                    />
                    <div className="message">
                      <FormattedMessage id="usageGuide.apiKey.message1.1" />
                      <strong>
                        <FormattedMessage id="usageGuide.apiKey.message1.2" />
                      </strong>
                      <FormattedMessage id="usageGuide.apiKey.message1.3" />
                    </div>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="first-cell">
                              <FormattedMessage id="usageGuide.table.requestHeader" />
                            </TableCell>
                            <TableCell>
                              <div>
                                <FormattedMessage id="usageGuide.table.value" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <FormattedMessage id="usageGuide.table.keyId" />
                            </TableCell>
                            <TableCell>
                              <div>
                                <FormattedMessage id="usageGuide.table.keyValue" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="message">
                      <p>
                        <FormattedMessage id="usageGuide.apiKey.message2" />
                      </p>
                    </div>

                    <div className="message">
                      <p>
                        <FormattedMessage id="usageGuide.apiKey.message3" />
                      </p>
                    </div>

                    <div className="message">
                      <p>
                        <FormattedMessage id="usageGuide.apiKey.message4" />
                      </p>
                    </div>
                  </div>
                  <div className="subcategory">
                    <BracedTitle
                      text={<FormattedMessage id="usageGuide.oauth2.title" />}
                      size="large"
                    />
                    <div className="message">
                      <FormattedMessage id="usageGuide.oauth2.message1" />
                    </div>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="first-cell">
                              <FormattedMessage id="usageGuide.table.authorizationHeader" />
                            </TableCell>
                            <TableCell>
                              <div>
                                <FormattedMessage id="usageGuide.table.value" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <FormattedMessage id="usageGuide.table.authorization" />
                            </TableCell>
                            <TableCell>
                              <div>
                                <FormattedMessage id="usageGuide.table.tokenValue" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="message">
                      <div className="message">
                        <FormattedMessage
                          id="usageGuide.oauth2.message2.1"
                          values={{
                            a: (link: any) => (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://oauth.net/2"
                              >
                                {link}
                              </a>
                            ),
                            a1: (link: any) => (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://oauth.net/2/grant-types/client-credentials"
                              >
                                {link}
                              </a>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="howGenerate">
                      <div className="subTitle">
                        <FormattedMessage id="usageGuide.howGenerate.title" />
                      </div>
                      <div className="message">
                        <FormattedMessage id="usageGuide.howGenerate.message1" />
                        <a
                          className="link"
                          href="https://auth.cma-cgm.com/as/token.oauth2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://auth.cma-cgm.com/as/token.oauth2
                        </a>
                      </div>
                      <div className="message">
                        <FormattedMessage id="usageGuide.howGenerate.message2" />
                      </div>

                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="first-cell">
                                <FormattedMessage id="usageGuide.table.parameter" />
                              </TableCell>
                              <TableCell>
                                <div>
                                  <FormattedMessage id="usageGuide.table.description" />
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <FormattedMessage id="usageGuide.table.grant" />
                              </TableCell>
                              <TableCell>
                                <div>
                                  <FormattedMessage id="usageGuide.table.grantDesc" />
                                </div>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <FormattedMessage id="usageGuide.table.clientId" />
                              </TableCell>
                              <TableCell>
                                <div>
                                  <FormattedMessage id="usageGuide.table.clientIdDesc" />
                                </div>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <FormattedMessage id="usageGuide.table.clientSecret" />
                              </TableCell>
                              <TableCell>
                                <div>
                                  <FormattedMessage id="usageGuide.table.clientSecretDesc" />
                                </div>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <FormattedMessage id="usageGuide.table.scope" />
                              </TableCell>
                              <TableCell>
                                <div>
                                  <FormattedMessage id="usageGuide.table.scopeDesc1" />
                                  <br />
                                  <i>
                                    <FormattedMessage id="usageGuide.table.scopeDesc2" />
                                  </i>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="postExemple">
                      <div className="subTitle">
                        <FormattedMessage id="usageGuide.postExample.title" />
                      </div>
                      <div className="code">
                        <div className="number">
                          <div className="number-content">
                            {numberListPostExample}
                          </div>
                        </div>
                        <div className="code-content">
                          {codeContentPostExample}
                        </div>
                      </div>
                    </div>
                    <div className="postResponse">
                      <div className="subTitle">
                        <FormattedMessage id="usageGuide.postResponse.title" />
                      </div>
                      <div className="code">
                        <div className="number">
                          <div className="number-content">
                            {numberListPostResponse}
                          </div>
                        </div>
                        <div className="code-content">
                          {codeContentPostResponse}
                        </div>
                      </div>
                    </div>
                    <div className="message">
                      <FormattedMessage id="usageGuide.note.message1" />
                      <i>
                        <FormattedMessage id="usageGuide.note.message2" />
                      </i>
                      <FormattedMessage id="usageGuide.note.message3" />
                    </div>

                    <div className="note">
                      <WarningIcon />
                      <FormattedMessage id="usageGuide.note" />
                    </div>
                  </div>
                </div>

                <div id={TabValues.CACHING} className="category api-caching">
                  <div id="caching" className="title">
                    <FormattedMessage id="usageGuide.apiCaching.title" />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.apiCaching.message1" />
                    </p>
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.apiCaching.message2" />
                    </p>
                  </div>

                  <TableContainer className="one-row">
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-cell">
                            <FormattedMessage id="usageGuide.table.cacheControl" />
                          </TableCell>
                          <TableCell>
                            <div>
                              <FormattedMessage id="usageGuide.table.maxAge" />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>

                <div
                  id={TabValues.RATE_LIMIT}
                  className="category api-rate-limit"
                >
                  <div id="rate-limit" className="title">
                    <FormattedMessage id="usageGuide.rateLimit.title" />
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.rateLimit.message1" />
                    </p>
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.rateLimit.message2.1" />
                      <a className="errorCode">429</a>
                      <FormattedMessage id="usageGuide.rateLimit.message2.2" />
                      <strong>
                        <FormattedMessage id="usageGuide.rateLimit.message2.3" />
                      </strong>
                      <FormattedMessage id="usageGuide.rateLimit.message2.4" />
                    </p>
                  </div>

                  <ul>
                    <li className="message">
                      <FormattedMessage id="usageGuide.rateLimit.message3" />
                    </li>
                    <li className="message">
                      <FormattedMessage id="usageGuide.rateLimit.message4" />
                    </li>
                  </ul>

                  <TableContainer className="one-row">
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-cell">
                            <FormattedMessage id="usageGuide.table.retryAfter" />
                          </TableCell>
                          <TableCell>
                            <div>
                              <FormattedMessage id="usageGuide.table.time" />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="message">
                    <FormattedMessage id="usageGuide.rateLimit.message5" />
                  </div>
                </div>

                <div
                  id={TabValues.PAGINATION}
                  className="category api-pagination"
                >
                  <div id="pagination" className="title">
                    <FormattedMessage id="usageGuide.pagination.title1" />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message1" />
                    </p>
                  </div>

                  <div className="subcategory">
                    <BracedTitle
                      text={<FormattedMessage id="usageGuide.pagination.title2" />}
                      size="large"
                    />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message2.1" />
                      <a className="successCode">206</a>
                      <FormattedMessage id="usageGuide.pagination.message2.2" />
                      <strong>
                        <FormattedMessage id="usageGuide.pagination.message2.3" />
                      </strong>
                      <FormattedMessage id="usageGuide.pagination.message2.4" />
                    </p>
                  </div>

                  <TableContainer className="one-row">
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-cell">
                            <FormattedMessage id="usageGuide.table.rangeResult" />
                          </TableCell>
                          <TableCell>
                            <div>
                              <FormattedMessage id="usageGuide.table.rangeResultSize" />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="message">
                    <FormattedMessage id="usageGuide.pagination.message3" />
                  </div>

                  <TableContainer className="one-row">
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-cell">
                            <FormattedMessage id="usageGuide.table.rangeRequest" />
                          </TableCell>
                          <TableCell>
                            <div>
                              <FormattedMessage id="usageGuide.table.rangeRequestSize" />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="subcategory">
                    <BracedTitle
                      text={<FormattedMessage id="usageGuide.pagination.title3" />}
                      size="large"
                    />
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message4" />
                    </p>
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message5.1" />
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message5.2" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message5.3" />
                    </p>
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message6" />
                      <a className="successCode">200</a>.
                    </p>
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message7" />
                    </p>
                  </div>

                  <ul>
                    <li className="message">
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message7.1.1" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message7.1.2" />
                    </li>
                    <li className="message">
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message7.2.1" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message7.2.2" />
                    </li>
                    <li className="message">
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message7.3.1" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message7.3.2" />
                    </li>
                    <li className="message">
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message7.4.1" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message7.4.2" />
                    </li>
                    <li className="message">
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message7.5.1" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message7.5.2" />
                    </li>
                  </ul>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message8.1" />
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message8.2" />
                      </span>
                    </p>
                  </div>

                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.pagination.message9.1" />
                      <span className="grey-background">
                        <FormattedMessage id="usageGuide.pagination.message9.2" />
                      </span>
                      <FormattedMessage id="usageGuide.pagination.message9.3" />
                    </p>
                  </div>

                </div>
                <div id={TabValues.CORS} className="category api-cross">
                  <div id="cors" className="title">
                    <FormattedMessage id="usageGuide.cross.title" />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.cross.message1.1" />

                      <a
                        className="link"
                        href="https://fetch.spec.whatwg.org/#http-cors-protocol"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="usageGuide.cross.message1.2" />
                      </a>

                      <FormattedMessage id="usageGuide.cross.message1.3" />
                    </p>
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="usageGuide.cross.message2" />
                    </p>
                  </div>
                </div>
                <div
                  id={TabValues.API_RESPONSE_CODE}
                  className="category"
                >
                    <div id="api-response" className="title"><FormattedMessage id="usageGuide.apiResponseCodes.message1" /></div>
                    <TableContainer style={{ width: '100%' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell><FormattedMessage id="usageGuide.apiResponseCodes.message2" /></TableCell>
                            <TableCell><FormattedMessage id="usageGuide.apiResponseCodes.message3" /></TableCell>
                            <TableCell><FormattedMessage id="usageGuide.apiResponseCodes.message4" /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.apiResponseCodes.map((codeData, index) => (
                            <TableRow key={index}>
                              <TableCell>{codeData.code}</TableCell>
                              <TableCell>{codeData.description}</TableCell>
                              <TableCell>{codeData.chargeable}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                <div
                  id={TabValues.HEADER_EVENT}
                  className="category header-event"
                >
                  <div className="title-header">
                    <BracedTitle
                      text={<FormattedMessage id="event" />}
                      size="x-large"
                    />
                  </div>
                </div>
                <div className="message-head">
                  <FormattedMessage id="eventGuide.head.message" />
                </div>

                <div id={TabValues.WEBHOOK_URL} className="category webhookUrl">
                  <div id="webhookUrl" className="title">
                    <FormattedMessage id="eventGuide.webhookUrl.title" />
                  </div>
                  <div className="message">
                    <FormattedMessage id="eventGuide.webhookUrl.message" />
                  </div>
                </div>

                <div
                  id={TabValues.WEBHOOK_SECURITY}
                  className="category webhookSecurity"
                >
                  <div id="webhookSecurity" className="title">
                    <FormattedMessage id="eventGuide.webhookSecurity.title" />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="eventGuide.webhookSecurity.message1" />
                    </p>
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="eventGuide.webhookSecurity.message2" />
                    </p>
                  </div>

                  <ul>
                    <li className="message">
                      <FormattedMessage id="eventGuide.webhookSecurity.message3" />
                    </li>
                    <li className="message">
                      <FormattedMessage id="eventGuide.webhookSecurity.message4" />
                    </li>
                  </ul>

                  <div className="message">
                    <p>
                      <FormattedMessage id="eventGuide.webhookSecurity.message5" />
                    </p>
                  </div>
                  <div className="message">
                    <p>
                      <a
                        className="link"
                        href="https://oauth.net/2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="eventGuide.webhookSecurity.message6" />
                      </a>
                    </p>
                    <p>
                      <a
                        className="link"
                        href="https://datatracker.ietf.org/doc/html/rfc6749"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="eventGuide.webhookSecurity.message7" />
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  id={TabValues.WEBHOOK_THROTTLING}
                  className="category webhookThrottling"
                >
                  <div id="webhookThrottling" className="title">
                    <FormattedMessage id="eventGuide.webhookThrottling.title" />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="eventGuide.webhookThrottling.message1" />
                    </p>
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="eventGuide.webhookThrottling.message2" />
                      <a className="errorCode">429</a>
                      <FormattedMessage id="eventGuide.webhookThrottling.message3" />
                      <strong>
                        <FormattedMessage id="eventGuide.webhookThrottling.message4" />
                      </strong>
                      <FormattedMessage id="eventGuide.webhookThrottling.message5" />
                    </p>
                  </div>
                </div>

                <div
                  id={TabValues.WEBHOOK_POLICY}
                  className="category webhookPolicy"
                >
                  <div id="webhookPolicy" className="title">
                    <FormattedMessage id="eventGuide.webhookPolicy.title" />
                  </div>
                  <div className="message">
                    <p>
                      <FormattedMessage id="eventGuide.webhookPolicy.message1" />
                      <a className="errorCode">5XX</a>
                      <FormattedMessage id="eventGuide.webhookPolicy.message2" />
                    </p>
                  </div>
                  <div className="message">
                    <ul>
                      <li>
                        <strong>
                          <FormattedMessage id="eventGuide.webhookPolicy.message3" />
                        </strong>
                        <FormattedMessage id="eventGuide.webhookPolicy.message4" />
                        <em>
                          <FormattedMessage id="eventGuide.webhookPolicy.message5" />
                        </em>
                      </li>
                    </ul>
                  </div>
                  <div className="message">
                    <ul>
                      <li>
                        <strong>
                          <FormattedMessage id="eventGuide.webhookPolicy.message6" />
                        </strong>
                        <FormattedMessage id="eventGuide.webhookPolicy.message7" />
                        <ul>
                          <li>
                            <FormattedMessage id="eventGuide.webhookPolicy.message8" />
                          </li>
                          <li>
                            <FormattedMessage id="eventGuide.webhookPolicy.message9" />
                            <ul>
                              <li>
                                <FormattedMessage id="eventGuide.webhookPolicy.message10" />
                              </li>
                              <li>
                                <FormattedMessage id="eventGuide.webhookPolicy.message11" />
                              </li>
                              <li>...</li>
                              <li>
                                <FormattedMessage id="eventGuide.webhookPolicy.message12" />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="tile-message">
                    <InfoIcon />
                    <div className="contentMessage">
                      <FormattedMessage id="eventGuide.webhookPolicy.message13" />
                    </div>
                  </div>
                </div>
              </div>
            </CustomScrollBars>

            {this.props.screenType != ScreenType.PHONE && (
              <div className="navigation">
                <Tabs
                  className="family-menu"
                  orientation="vertical"
                  value={this.getActiveTabIndex()}
                >
                  {this.getTab(
                    TabValues.HEADER_API,
                    <FormattedMessage id="api" />,
                    true
                  )}
                  {this.getTab(
                    TabValues.ACCESS,
                    <FormattedMessage id="usageGuide.apiAccess.title" />
                  )}
                  {this.getTab(
                    TabValues.AUTHENTICATION,
                    <FormattedMessage id="usageGuide.apiAuthorization.title" />
                  )}
                  {this.getTab(
                    TabValues.CACHING,
                    <FormattedMessage id="usageGuide.apiCaching.title" />
                  )}
                  {this.getTab(
                    TabValues.RATE_LIMIT,
                    <FormattedMessage id="usageGuide.rateLimit.title" />
                  )}
                  {this.getTab(
                    TabValues.PAGINATION,
                    <FormattedMessage id="usageGuide.pagination.title1" />
                  )}
                  {this.getTab(
                    TabValues.CORS,
                    <FormattedMessage id="usageGuide.cross.title" />
                  )}
                  {this.getTab(
                    TabValues.API_RESPONSE_CODE,
                    <FormattedMessage id="usageGuide.apiResponseCode.title" />
                  )}
                  {this.getTab(
                    TabValues.HEADER_EVENT,
                    <FormattedMessage id="event" />,
                    true
                  )}
                  {this.getTab(
                    TabValues.WEBHOOK_URL,
                    <FormattedMessage id="eventGuide.webhookUrl.title" />
                  )}
                  {this.getTab(
                    TabValues.WEBHOOK_SECURITY,
                    <FormattedMessage id="eventGuide.webhookSecurity.title" />
                  )}
                  {this.getTab(
                    TabValues.WEBHOOK_THROTTLING,
                    <FormattedMessage id="eventGuide.webhookThrottling.title" />
                  )}
                  {this.getTab(
                    TabValues.WEBHOOK_POLICY,
                    <FormattedMessage id="eventGuide.webhookPolicy.title" />
                  )}
                </Tabs>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(UsageGuide));
