import React, { createRef, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars-2";
import { useLocation } from "react-router";

import reducer from "../../../reducer";
import { defaultState } from "../../../reducer/constants/reducer.constant";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

interface IProps extends ScrollbarProps {}

const CustomScrollBars = (props: IProps) => {
  const scrollbarsRef = createRef<Scrollbars>();
  const location = useLocation();

  useReducer(reducer, defaultState);
  const scrollControl = useSelector(
    (state: IReducerState) => state.scrollControl
  );

  useEffect(() => {
    if (scrollbarsRef && scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(0);
    }
  }, [location, scrollControl]);

  return (
    <Scrollbars
      id={props.id}
      className={`custom-scrollbars ${props.className ? props.className : ""}`}
      style={props.style}
      ref={scrollbarsRef}
      renderTrackHorizontal={(props) => (
        <div {...props} className="track-horizontal" />
      )}
      renderTrackVertical={(props) => (
        <div {...props} className="track-vertical" />
      )}
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderView={(props) => <div {...props} className="view" />}
      // Add here more ScrollbarProps if needed
      autoHeight={props.autoHeight}
      autoHeightMax={props.autoHeightMax}
      onScrollFrame={props.onScrollFrame}
    >
      {props.children}
    </Scrollbars>
  );
};

export default CustomScrollBars;
