import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { config } from "../config";
import { HttpCode } from "../modules/common/enums/http-code.enum";
import Utils from "./index";
import { Routes } from "../modules/common/enums/routes.enum";
import { IGetFile } from "../modules/common/interfaces/common.interface";

export default class HttpUtils {
  private static config: AxiosRequestConfig = HttpUtils.isFrontModeStart()
    ? { withCredentials: true }
    : {};

  public static isFrontModeStart() {
    return (
      process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_FRONT_MODE === "start"
    );
  }

  public static get<T>(url: string, rawUrl = false): Promise<T> {
    const fullUrl = rawUrl ? url : `${config.apiUrl}${url}`;
    return axios
      .get(fullUrl, HttpUtils.config)
      .then((res) => res.data)
      .catch((err) => this.handleError(err, url === "/user"));
  }

  public static delete<T>(url: string, rawUrl = false): Promise<T> {
    const fullUrl = rawUrl ? url : `${config.apiUrl}${url}`;
    return axios
      .delete(fullUrl, HttpUtils.config)
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  }

  public static post<T>(url: string, body: object): Promise<T> {
    return axios
      .post(`${config.apiUrl}${url}`, body, HttpUtils.config)
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  }

  public static put<T>(url: string, body: object): Promise<T> {
    return axios
      .put(`${config.apiUrl}${url}`, body, HttpUtils.config)
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  }

  public static patch<T>(url: string, body: object): Promise<T> {
    return axios
      .patch(`${config.apiUrl}${url}`, body, HttpUtils.config)
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  }

  public static getFile(url: string): Promise<IGetFile | void> {
    return axios
      .get(`${config.apiUrl}${url}`, {
        ...HttpUtils.config,
        responseType: "blob",
        validateStatus: (status) => status < HttpCode.BAD_REQUEST,
      })
      .then((response: any) => {
        if (response.status < HttpCode.BAD_REQUEST) {
          return {
            url: URL.createObjectURL(response.data),
            status: response.status,
          };
        }

        throw response;
      })
      .catch((err) => {
        throw err;
      });
  }

  private static handleError(err: AxiosError, isGetUserCall = false) {
    if (err.response?.status === HttpCode.UNAUTHORIZED && !isGetUserCall) {
      Utils.login();
    }

    if (err.response && err.response.status >= HttpCode.BAD_GATEWAY) {
      window.location.replace(`${Routes.HOME}?error=${err.response.status}`);
    }

    throw err;
  }

  /**
   * Format the given object's values to URL query params
   * @param params: Object
   * @returns string
   */
  public static formatUrlQueryParams(params: Object) {
    const urlParams = Object.keys(params)
      // @ts-ignore
      .map((el) => (params[el] !== undefined ? `${el}=${params[el]}` : false))
      .filter((el) => !!el)
      .join("&");

    return urlParams === "" ? "" : `?${urlParams}`;
  }

  /**
   * Setup range param from page number and the count of items per page
   * @param page: number
   * @param nbPerPage: number (default: 50)
   * @param firstPageOffset: number (default: 0)
   * @returns string
   */
  public static getRange(page: number, nbPerPage = 50, firstPageOffset = 0) {
    let minRange = (page - 1) * nbPerPage;
    if (page !== 1) minRange -= firstPageOffset;

    let maxRange = minRange + nbPerPage - 1;
    if (page === 1) maxRange -= firstPageOffset;

    return `${minRange}-${maxRange}`;
  }
}
