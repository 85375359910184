import "./styles.scss";

import React, { Component } from "react";

interface IProps {
  children: React.ReactNode;
  isHomePage?: boolean;
}

class HeaderBanner extends Component<IProps> {
  render() {
    return (
      <div
        className={`header-banner ${!!this.props.isHomePage ? "homepage" : ""}`}
      >
        <div className="header-content App-content">{this.props.children}</div>
      </div>
    );
  }
}

export default HeaderBanner;
