import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";

import Search from "./search";
import Menu from "./menu";
import Grid from "./grid";
import { ApiSummary } from "../../models/api-summary.model";
import { Event } from "../../models/event.model";
import { apiFamilies } from "../../constants/api-family.constant";
import { eventFamilies } from "../../constants/event-family.constant";
import HeaderBanner from "../../../common/components/headerBanner";
import { ScreenType } from "../../../common/enums/screen-type.enum";
import { IProductsFamily } from "../../interfaces/products-family.interface";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { EndpointSearchResult } from "../../models/endpoints-search.model";
import EndpointSearchResults from "./endpointSearchResults";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IRouteProps {
  familyId: string;
}

interface IProps extends PropsFromRedux, RouteComponentProps<IRouteProps> {
  apiSummaries: ApiSummary[];
  eventSummaries: Event[];
  endpointSearchResults: EndpointSearchResult[];
  isLoading: boolean;
  isDeepSearchLoading: boolean;
  getApiSummaries(productFamily?: string, search?: string): Promise<void>;
  getEventSummaries(productFamily?: string, search?: string): Promise<void>;
  getDeepSearch(search: string): void;
}

interface IStates {
  searchValue: string;
}

class Catalog extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: "",
    };
  }

  componentDidMount() {
    this.getSummariesByProductFamily();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, snapshot?: any) {
    if (prevProps.match.params.familyId !== this.props.match.params.familyId) {
      this.getSummariesByProductFamily();
    }
  }

  private getSummariesByProductFamily = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get("s");

    this.setState({ searchValue: searchValue || "" });

    if (searchValue && searchValue !== "") {
      try {
        await this.props.getApiSummaries(undefined, searchValue);
        await this.props.getEventSummaries(undefined, searchValue);
      } finally {
        this.props.getDeepSearch(searchValue);
      }
    }

    const selectedApiFamily = this.getSelectedFamily(apiFamilies);
    if (selectedApiFamily) {
      return this.props.getApiSummaries(selectedApiFamily.productFamily);
    }

    const selectedEventFamily = this.getSelectedFamily(eventFamilies);
    if (selectedEventFamily) {
      return this.props.getEventSummaries(selectedEventFamily.productFamily);
    }
  };

  private onSearch = async (productFamily?: string, searchValue?: string) => {
    try {
      if (!!this.props.getApiSummaries)
        await this.props.getApiSummaries(undefined, searchValue);

      if (!!this.props.getEventSummaries)
        await this.props.getEventSummaries(undefined, searchValue);
    } finally {
      if (searchValue && searchValue !== "")
        this.props.getDeepSearch(searchValue);
    }
  };

  private getSelectedFamily(
    families: IProductsFamily[]
  ): IProductsFamily | undefined {
    if (this.props.match.params.familyId === "search") return undefined;

    return families.find(
      (family: IProductsFamily) =>
        family.id === this.props.match.params.familyId
    );
  }

  private getSummaries = (): (Event | ApiSummary)[] => {
    const apiSummaries = this.props.apiSummaries || [];
    const eventSummaries = this.props.eventSummaries || [];

    if (this.getSelectedFamily(apiFamilies)) return apiSummaries;
    if (this.getSelectedFamily(eventFamilies)) return eventSummaries;

    return [...apiSummaries, ...eventSummaries];
  };

  render() {
    const selectedFamily =
      this.getSelectedFamily(eventFamilies) ||
      this.getSelectedFamily(apiFamilies);

    const summaries = this.getSummaries();

    return (
      <div id="catalog">
        <HeaderBanner>
          <span className="title">
            <FormattedMessage id="productCatalog" />
          </span>
          {this.props.screenType == ScreenType.COMPUTER && (
            <Search onSearch={this.onSearch} />
          )}
        </HeaderBanner>
        <div className="content">
          {this.props.screenType == ScreenType.COMPUTER && <Menu />}

          <div className="grid-container">
            <Grid
              isLoading={this.props.isLoading}
              summaries={summaries}
              selectedFamily={selectedFamily}
            />

            {this.state.searchValue !== "" && (
              <EndpointSearchResults
                isLoading={this.props.isDeepSearchLoading}
                endpointSearchResults={this.props.endpointSearchResults}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(Catalog));
