export const statuses = {
    SUCCESS: "Success",
    ACCEPTED: "Accepted",
    APPROVED: "Approved",
    ACTIVE: "Active",
    REACTIVATED: "Reactivated",
    DEACTIVATED:"Deactivated",
    REJECTED:"Rejected",
    PENDING: "Pending",
    ALL: "All",
    ERROR: "Error",
    REMOVECLIENTID:"RemoveClientId",
    CREATEUSERFROMPORTAL:"CreateUserFromPortal"
};