import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { Button } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { connect, ConnectedProps } from "react-redux";
import { IPackage, IPackageSelection } from "../interfaces/package.interface";
import Loader from "../../common/components/loader";
import { LocalStorageEnum } from "../../common/enums/storage.enum";
import Package, { IPackageRouteProps } from "./package";
import MySelection from "./mySelection";
import Subscribe from "./subscribe";
import { PackageSelection } from "../models/crm.model";
import { OfferSelection } from "../enums/crm.enum";
import { Routes } from "../../common/enums/routes.enum";
import Utils from "../../../utils";
import Stepper from "./stepper";
import CrmAPI from "../apis/crm.api";
import RequestSubmitted from "./requestSubmitted";
import NotFound from "../../common/components/notFound";
import DescriptionSidePanel from "../../productCatalog/components/description/descriptionSidePanel";
import HeaderBanner from "../../common/components/headerBanner";
import { IReducerState } from "../../reducer/interfaces/reducer.interface";
import { Helmet } from 'react-helmet';
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteComponentProps, PropsFromRedux {}
interface IStates {
  packages: IPackage[];
  packageSelection: PackageSelection | undefined;
  routes: string[];
  arePackagesLoading: boolean;
}

class CommercialOffers extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      packages: [],
      packageSelection: undefined,
      routes: [],
      arePackagesLoading: true,
    };
  }

  componentDidMount() {
    CrmAPI.getPackages()
      .then((packages) => {
        const storedSelection = JSON.parse(
          localStorage.getItem(LocalStorageEnum.PACKAGE_SELECTION) || "[]"
        );

        const packageSelection = new PackageSelection();

        packages.forEach((currentPackage: IPackage) => {
          packageSelection.addPackage(
            currentPackage.name,
            currentPackage.description
          );

          const packageInStore: IPackageSelection | undefined =
            storedSelection.find(
              (el: IPackageSelection) => el.name === currentPackage.name
            );
          if (packageInStore) {
            let offerId: OfferSelection | string = OfferSelection.NONE;
            let offerName: OfferSelection | string = OfferSelection.NONE;

            if (currentPackage.offers.some((el) => el.id === packageInStore.offerId && el.name === packageInStore.offerName)) {
              offerId = packageInStore.offerId;
              offerName = packageInStore.offerName;
            }

            packageSelection.changePackageSelectedOffer(
              packageInStore.name,
              offerId,
              offerName,
              packageInStore.customOfferMessage
            );
          }
        });

        this.setState(
          {
            packages,
            packageSelection,
            arePackagesLoading: false,
            routes: [
              ...packages.map(
                (el) => `${Routes.PACKAGE}/${Utils.stringToDashCase(el.name)}`
              ),
              Routes.MY_SELECTION,
              Routes.SUBSCRIPTION_REQUEST,
              Routes.REQUEST_SUBMITTED,
            ],
          },
          this.navigateToFirstPackage
        );
      })
      .catch(() => {
        this.setState({ arePackagesLoading: false });
      });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    this.navigateToFirstPackage();
  }

  private navigateToFirstPackage = () => {
    if (
      this.state.routes.length > 0 &&
      window.location.pathname === Routes.COMMERCIAL_OFFERS
    ) {
      this.props.history.replace(this.state.routes[0]);
    }
  };

  private updateSelection = (packageSelection: PackageSelection) => {
    if (packageSelection.packages.length > 0) {
      localStorage.setItem(
        LocalStorageEnum.PACKAGE_SELECTION,
        JSON.stringify(packageSelection.packages.map((el) => el))
      );
    } else {
      localStorage.removeItem(LocalStorageEnum.PACKAGE_SELECTION);
    }

    this.setState({ packageSelection });
  };

  private onOfferSelection = (
    offerId: OfferSelection | string,
    packageName: string,
    offerName: OfferSelection | string
  ) => {
    if (this.state.packageSelection) {
      const fullPackage = this.findPackageByName(packageName);

      if (fullPackage) {
        let packageSelection = this.state.packageSelection;
        let storedSelection = localStorage.getItem(LocalStorageEnum.PACKAGE_SELECTION);
        let parsedSelection = storedSelection ? JSON.parse(storedSelection) : null;
        let message : string = "";
        parsedSelection?.forEach((selectedPackage : IPackageSelection) => {
          if(selectedPackage.name === fullPackage.name)
            message = selectedPackage.customOfferMessage || "";
        });
        packageSelection.changePackageSelectedOffer(fullPackage.name, offerId, offerName, message);

        this.updateSelection(packageSelection);
      }
    }
  };

  private onCustomOfferMessageChange = (
    offerId: OfferSelection | string,
    offerName: OfferSelection | string,
    message: string,
    packageName: string
  ) => {
    if (this.state.packageSelection) {
      const fullPackage = this.findPackageByName(packageName);

      if (fullPackage) {
        let packageSelection = this.state.packageSelection;

        packageSelection.changePackageSelectedOffer(
          fullPackage.name,
          offerId,
          offerName,
          message
        );

        this.updateSelection(packageSelection);
      }
    }
  };

  private getCurrentRouteIndex = () => {
    return this.state.routes.findIndex((route) =>
      window.location.pathname.includes(route)
    );
  };

  private onPreviousClick = () => {
    this.props.history.push(this.state.routes[this.getCurrentRouteIndex() - 1]);
  };

  private onNextClick = () => {
    this.props.history.push(this.state.routes[this.getCurrentRouteIndex() + 1]);
  };

  private findPackageByName = (name: string): IPackage | undefined => {
    return this.state.packages.find(
      (el) => Utils.stringToDashCase(el.name) === name
    );
  };

  private findSelectedPackageByName = (
    name: string
  ): IPackageSelection | undefined => {
    if (this.state.packageSelection) {
      return this.state.packageSelection.packages.find(
        (el) => Utils.stringToDashCase(el.name) === name
      );
    }
  };

  private getNextButton = () => {
    const { packages, packageSelection, routes } = this.state;

    if (routes.length > 0) {
      const currentRouteIndex = this.getCurrentRouteIndex();
      let disabled = false;

      if (currentRouteIndex > packages.length) {
        return <Button className="button confirm-button-placeholder" />;
      }

      let btnContent;

      if (currentRouteIndex === packages.length) {
        btnContent = <FormattedMessage id="submitRequest" />;
        if (
          !packageSelection ||
          packageSelection.packages.every(
            (el) => el.offerId === OfferSelection.NONE
          )
        ) {
          disabled = true;
        }
      } else {
        let content = <FormattedMessage id="offers.seeMySelection" />;

        const currentPackageIndex = packages.findIndex(
          (el) =>
            routes[currentRouteIndex] &&
            routes[currentRouteIndex].includes(Utils.stringToDashCase(el.name))
        );

        if (
          currentPackageIndex > -1 &&
          currentPackageIndex < packages.length - 1
        ) {
          content = (
            <>
              <FormattedMessage
                id="offers.seePackage"
                values={{ packageName: packages[currentPackageIndex + 1].name }}
              />
            </>
          );
        }

        btnContent = (
          <>
            <span>{content}</span>
            <ChevronRight />
          </>
        );
      }

      return (
        <Button
          className="button next"
          onClick={this.onNextClick}
          disabled={disabled}
        >
          {btnContent}
        </Button>
      );
    }
  };

  getContent = (isSubscriptionRequest: boolean) => {
    const { packages, packageSelection } = this.state;
    const currentRouteIndex = this.getCurrentRouteIndex();

    const hasPackages =
      packageSelection &&
      this.state.routes.some((route) =>
        window.location.pathname.includes(route)
      );

    let content;

    if (this.state.arePackagesLoading) {
      content = <Loader />;
    } else if (hasPackages) {
      content = (
        <>
          {window.location.pathname.includes(Routes.PACKAGE) && (
            <Stepper packages={this.state.packages} />
          )}

          <Switch>
            <Route
              path={Routes.REQUEST_SUBMITTED}
              component={RequestSubmitted}
            />

            <Route
              path={Routes.SUBSCRIPTION_REQUEST}
              component={() => (
                <Subscribe
                  packages={packages}
                  isOnlyCustomOffers={
                    !!this.state.packageSelection &&
                    this.state.packageSelection.packages
                      .filter((el) => el.offerName !== OfferSelection.NONE)
                      .every((el) => el.offerName === OfferSelection.CUSTOM)
                  }
                  onPreviousClick={this.onPreviousClick}
                />
              )}
            />

            <Route
              path={Routes.MY_SELECTION}
              component={() => (
                <MySelection
                  packages={packages}
                  packageSelection={packageSelection!}
                  deleteChoice={(packageName: string) =>
                    this.onOfferSelection(OfferSelection.NONE, packageName, OfferSelection.NONE)
                  }
                />
              )}
            />

            <Route
              {...this.props}
              path={`${Routes.PACKAGE}/:packageName`}
              render={(props: RouteComponentProps<IPackageRouteProps>) => {
                const packageName = props.match.params.packageName;
                const currentPackage = this.findPackageByName(packageName);
                const selectedPackage =
                  this.findSelectedPackageByName(packageName);
                if (currentPackage && selectedPackage) {
                  return (
                    <Package
                      currentPackage={currentPackage}
                      packageSelection={selectedPackage}
                      onOfferCheck={(offerId, offerName) =>
                        this.onOfferSelection(offerId, packageName, offerName)
                      }
                      onCustomOfferMessageChange={(offerId, offerName, message) =>
                        this.onCustomOfferMessageChange(
                          offerId,
                          offerName,
                          message,
                          packageName
                        )
                      }
                    />
                  );
                }

                return null;
              }}
            />
          </Switch>

          {!isSubscriptionRequest && (
            <div className="buttons-container">
              <div className={`buttons ${this.props.screenType}`}>
                {currentRouteIndex > 0 && (
                  <Button
                    className="button inverted previous"
                    onClick={this.onPreviousClick}
                  >
                    <ChevronLeft />
                    <span>
                      <FormattedMessage id="previous" />
                    </span>
                  </Button>
                )}

                {this.getNextButton()}
              </div>
            </div>
          )}
        </>
      );
    } else {
      content = (
        <NotFound text={<FormattedMessage id="offers.notPackageFound" />} />
      );
    }

    return content;
  };

  render() {
    const isSubscriptionRequest =
      window.location.pathname === Routes.SUBSCRIPTION_REQUEST ||
      window.location.pathname === Routes.REQUEST_SUBMITTED;

    return (
      <div id="commercial-offers" className="App-page">
        <Helmet>
          <title>Commercial Offer - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          {isSubscriptionRequest ? (
            <FormattedMessage id="subscriptionRequest" />
          ) : (
            <FormattedMessage id="commercialOffers" />
          )}
        </HeaderBanner>

        <div className="App-content">
          <div id="commercial-offers-content">
            {this.getContent(isSubscriptionRequest)}
          </div>
        </div>

        <DescriptionSidePanel />
      </div>
    );
  }
}


const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(CommercialOffers));
