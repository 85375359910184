import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { MenuItem, Select } from "@material-ui/core";

import Tile from "./tile";
import BracedTitle from "../../../../common/components/bracedTitle";
import Loader from "../../../../common/components/loader";
import { SearchIcon } from "../../../../../assets/icons";
import { Routes } from "../../../../common/enums/routes.enum";
import { ApiSummary } from "../../../models/api-summary.model";
import { Event } from "../../../models/event.model";
import { apiFamilies } from "../../../constants/api-family.constant";
import { eventFamilies } from "../../../constants/event-family.constant";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import { IProductsFamily } from "../../../interfaces/products-family.interface";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import { ProductType } from "../../../enums/product-family.enum";

type Summary = Event | ApiSummary;
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IRouteProps {}

interface IProps extends PropsFromRedux, RouteComponentProps<IRouteProps> {
  isLoading: boolean;
  summaries: Summary[];
  selectedFamily?: IProductsFamily;
}

interface IStates {
  actualBlock: number;
}

class Grid extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      actualBlock: 1,
    };
  }

  private onDetailsClick = (summary: Summary) => {
    this.props.history.push(
      summary.type === ProductType.API
        ? `${Routes.PRODUCTS}/api/${summary.id}`
        : `${Routes.PRODUCTS}/event/${summary.id}`
    );
  };

  private getSortedApiList(): Summary[] {
    if (!this.props.summaries) return [];

    const summaries = [...this.props.summaries];

    summaries.sort((summaryA: Summary, summaryB: Summary) =>
      summaryA.title < summaryB.title
        ? -1
        : summaryA.title > summaryB.title
        ? 1
        : 0
    );

    return summaries;
  }

  private onFamilyClick = (familyId?: string) => {
    this.props.history.push(`${Routes.PRODUCTS}/${familyId}`);
  };

  render() {
    const FamilyImage = this.props.selectedFamily
      ? this.props.selectedFamily.image
      : SearchIcon;

    const summaries = this.getSortedApiList();
    const isListEmpty = summaries.length === 0;
    const allFamily = [...apiFamilies, ...eventFamilies];

    let title = <FormattedMessage id="apiCatalog.searchResults" />;
    if (this.props.selectedFamily) title = this.props.selectedFamily.name;

    return (
        <div className={`api-grid ${this.props.screenType}`}>
          {this.props.screenType === ScreenType.COMPUTER ? (
            <div className="list-header">
              <BracedTitle text={title} size="large" />
            </div>
          ) : (
            <div className="category">
              <div className="list-header">
                <BracedTitle text={title} size="large" />
              </div>
              <Select
                value={
                  this.props.selectedFamily
                    ? this.props.selectedFamily.id
                    : apiFamilies[0].id
                }
                onChange={(event) =>
                  this.onFamilyClick(event.target.value as string)
                }
              >
                {allFamily.map((elt) => (
                  <MenuItem value={elt.id} key={`key-${elt.id}`}>
                    {elt.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <div className={`list-container ${isListEmpty ? "empty" : ""}`}>
            {this.props.isLoading ? (
              <Loader />
            ) : isListEmpty ? (
              <div className="notfound">
                <div>{FamilyImage && <FamilyImage className="image" />}</div>
                <div className="message">
                  <FormattedMessage id="apiCatalog.noProductFound" />
                </div>
              </div>
            ) : (
              <>
                <div className="list">
                  {summaries.map((summary: Summary, index: number) => (
                    <Tile
                      key={`api-summary-${summary.id}-${index}`}
                      summary={summary}
                      onDetailsClick={() => this.onDetailsClick(summary)}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(Grid));
