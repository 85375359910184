import HttpUtils from "../../../../utils/http.utils";
import { IMetrics } from "../interfaces/metrics.interface";

export default class MetricsAPI {
  public static getApiMetrics(
    clientApps: string,
    periodStart: Date,
    periodEnd: Date
  ): Promise<IMetrics> {
    const params = HttpUtils.formatUrlQueryParams({
      clientApps,
      dateFrom: periodStart.toISOString(),
      dateTo: periodEnd.toISOString(),
    });

    return HttpUtils.get(`/metrics/api${params}`);
  }

  public static getEventMetrics(
    periodStart: Date,
    periodEnd: Date,
    filter = "ALL"
  ): Promise<IMetrics> {
    const params = HttpUtils.formatUrlQueryParams({
      dateFrom: periodStart.toISOString(),
      dateTo: periodEnd.toISOString(),
      filter,
    });

    return HttpUtils.get(`/metrics/event${params}`);
  }
}
