import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { AccountTreeOutlined, KeyboardArrowDown } from "@material-ui/icons";

import {
  IMyEntities,
  IPartner,
} from "../../../interfaces/business-partners.interface";
import { PartnerType } from "../../../enums/business-partners.enum";
import TransitionContent from "../../../../../common/components/transitionContent";
import PartnerCodeTooltip from "../../common/partnerCodeTooltip";
import StatusLabel from "../../common/statusLabel";
import { IconButton } from "@material-ui/core";

interface IProps {
  partnerType: PartnerType;
  partner: IPartner | IMyEntities;
  index: number;
  onChildPartnerSelection(): void;
}

interface IStates {
  arePartnerInformationVisible: boolean;
}

class ParentPartnerItem extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      arePartnerInformationVisible: false,
    };
  }

  private togglePartnerInformationVisibility = () => {
    if (this.props.partner.products.length > 0)
      this.setState({
        arePartnerInformationVisible: !this.state.arePartnerInformationVisible,
      });
  };

  render() {
    let name = this.props.partner.name;

    if (
      this.props.partnerType === PartnerType.OPEN_REQUEST &&
      (this.props.partner as IPartner).applicativeBpName
    ) {
      name = (this.props.partner as IPartner).applicativeBpName as string;
    }

    let itemClass = "parent-partner-item partner-item";

    if (this.props.index % 2 === 0) {
      itemClass = `${itemClass} even`;
    } else {
      itemClass = `${itemClass} odd`;
    }

    if (this.state.arePartnerInformationVisible) {
      itemClass = `${itemClass} opened`;
    }

    const hasProducts = this.props.partner.products.length > 0;

    return (
      <div className={itemClass}>
        <div
          className={`header-container ${hasProducts ? "clickable" : ""}`}
          onClick={this.togglePartnerInformationVisibility}
        >
          <div className="left-content">
            <PartnerCodeTooltip partner={this.props.partner as IPartner} />

            <div className="name">{name}</div>
          </div>

          <div className="right-content">
            <StatusLabel status={this.props.partner.status} />

            <IconButton
              className="see-children"
              onClick={this.props.onChildPartnerSelection}
            >
              <AccountTreeOutlined />
            </IconButton>

            {hasProducts && (
              <IconButton
                className={`chevron ${
                  this.state.arePartnerInformationVisible ? "opened" : ""
                }`}
              >
                <KeyboardArrowDown />
              </IconButton>
            )}
          </div>
        </div>

        <div className="sub-content-container">
          <TransitionContent
            isExpanded={this.state.arePartnerInformationVisible}
          >
            <div className="covered-products-container">
              <span className="covered-products-title">
                <FormattedMessage id="partner.coveredProducts" />
              </span>

              <ul>
                {this.props.partner.products.map((product, index) => (
                  <li key={`covered-product-${index}-${product}`}>
                    <div className="covered-product">
                      {product}
                      {index !== this.props.partner.products.length - 1 && (
                        <div className="separator" />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </TransitionContent>
        </div>
      </div>
    );
  }
}

export default ParentPartnerItem;
