export enum ProfilePart {
  DETAILS = "details",
  COMMERCIAL_SUBSCRIPTION = "commercialSubscription",
  USERS_MANAGEMENT = "usersManagement",
  API_CREDENTIALS = "apiCredentials"
}


export enum ApiCredentialsPart {
  VALIDATED = "VALIDATED",
  INITIATED = "INITIATED",
  RESETING = "RESETING",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
  NONE = ""
}

export enum ClickType {
  Send="Send",
  Check="Check",
  Close="Close"
}

export enum StatusCheck{
  VALIDATED = "isValidated",
  EXPIRED = "isExpired",
  NONE_OR_VALIDATED = "isNoneOrValidated",
  EXPIRED_OR_INITIATED = "isExpiredOrInitiated",
  RESETING_OR_PENDING = "isResetingOrPending",
  REJECTED = "isRejected",
  REJECTED2 = "isRejected2",
  CSS="css"
}