import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@material-ui/lab";

import Loader from "../../../../common/components/loader";
import NotFound from "../../../../common/components/notFound";
import {
  IPartner,
  IPartnerWithCount,
  PartnerSelection,
} from "../../interfaces/business-partners.interface";
import PartnersAPI from "../../apis/business-partners.api";
import BracedTitle from "../../../../common/components/bracedTitle";
import { PartnerStatus, PartnerType } from "../../enums/business-partners.enum";
import ChildPartnerItem from "./childPartnerItem";
import CreationButton from "../businessPartnerCreation/creationButton";
import StatusLabel from "../common/statusLabel";
import { PartnerConfig } from "../../models/business-partners.model";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import { ReducerAction } from "../../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import CsvExport from "../common/csvExport";
import { SortingModes } from "../../../../common/enums/sorting-mode.enum";
import PartnerSearchSort from "../partnerSearchSort";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  partnerType: PartnerType;
  selectedParentPartner: PartnerSelection;
  setActivePartnerLoaded(isActivePartnersLoaded?: Boolean) : Boolean | void;
  openRequestPartners?: IPartner[];
}

interface IStates {
  partnerConfig: PartnerConfig;
  isNewBpModalOpened: boolean;
}

class ChildPartnerList extends Component<IProps, IStates> {
  private NB_OF_PARTNERS_BY_PAGE = 20;
  private offset = this.props.openRequestPartners
    ? this.props.openRequestPartners.length
    : 0;

  constructor(props: IProps) {
    super(props);

    this.state = {
      partnerConfig: new PartnerConfig({ type: this.props.partnerType }),
      isNewBpModalOpened: false,
    };
  }

  componentDidMount() {
    this.getPartners();
  }

  private updatePartnerConfig = (partnerConfig: PartnerConfig) => {
    return new Promise<void>((resolve) => {
      this.setState({ partnerConfig }, resolve);
    });
  };

  private getPartners = () => {
    const partnerConfig = this.state.partnerConfig;
    partnerConfig.setValues({ isLoading: true });

    this.updatePartnerConfig(partnerConfig).then(async () => {
      const partners =
        this.props.openRequestPartners &&
        this.state.partnerConfig.currentPage === 1
          ? this.props.openRequestPartners
          : [];

      let request;

      if (this.props.partnerType === PartnerType.MY_ENTITIES) {
        request = PartnersAPI.getOrphanPartners(
          this.state.partnerConfig.currentPage,
          this.NB_OF_PARTNERS_BY_PAGE,
          this.state.partnerConfig.searchValue,
          this.state.partnerConfig.sortingMode
        );
      } else if (this.props.partnerType === PartnerType.ACTIVE) {
        request = PartnersAPI.getPartnerByCode(
          (this.props.selectedParentPartner as IPartner).code,
          this.state.partnerConfig.currentPage,
          this.NB_OF_PARTNERS_BY_PAGE,
          this.offset,
          this.state.partnerConfig.searchValue,
          this.state.partnerConfig.sortingMode
        );
      } else {
        partnerConfig.setValues({
          partners,
          totalPages: 1,
          isLoading: false,
        });

        await this.updatePartnerConfig(partnerConfig);
      }

      if (request) {
        request
          .then(async (partnersWithCount: IPartnerWithCount) => {
            const totalPages = Math.ceil(
              partnersWithCount.partnerCount / this.NB_OF_PARTNERS_BY_PAGE
            );

            partnerConfig.setValues({
              partners: partners.concat(partnersWithCount.partners),
              count: partnersWithCount.partnerCount,
              totalPages,
              isLoading: false,
            });

            await this.updatePartnerConfig(partnerConfig);
          })
          .catch(() => {
            partnerConfig.setValues({ isLoading: false });
            return this.updatePartnerConfig(partnerConfig);
          });
      }
    });
  };

  private onSearch = (searchValue: string): Promise<void> => {
    const partnerConfig = this.state.partnerConfig;
    partnerConfig.setValues({ searchValue });
    return this.updatePartnerConfig(partnerConfig).then(() => {
      if (
        this.state.partnerConfig.searchValue === "" ||
        this.state.partnerConfig.searchValue.length >= 3
      ) {
        this.getPartners();
      }
    });
  };

  private setSortingMode = (sortingMode: SortingModes): void => {
    const partnerConfig = this.state.partnerConfig;
    partnerConfig.setValues({ sortingMode });
    this.updatePartnerConfig(partnerConfig).then(this.getPartners);
  };

  private onPageChange = (currentPage: number) => {
    if (this.state.partnerConfig.currentPage !== currentPage) {
      const partnerConfig = this.state.partnerConfig;
      partnerConfig.setValues({ currentPage });
      this.updatePartnerConfig(partnerConfig).then(this.getPartners);
      this.props.scrollToTop();
    }
  };

  private getHeader = (title: string, label: JSX.Element | undefined) => {
    return (
      <div className="list-header">
        <div className="left-container">
          <BracedTitle text={title} />

          {this.props.screenType !== ScreenType.PHONE && label}
        </div>

        <div className="right-container">
          {this.props.selectedParentPartner.code &&
            this.props.partnerType !== PartnerType.MY_ENTITIES && this.props.screenType === ScreenType.TABLET&&this.props.setActivePartnerLoaded()&& (
              <CreationButton
                enrichPartner={this.props.selectedParentPartner as IPartner}
              />
            )}

          {this.props.partnerType !== PartnerType.OPEN_REQUEST && (
            <CsvExport
              inBehalfOf={
                this.props.partnerType === PartnerType.MY_ENTITIES
                  ? undefined
                  : this.props.selectedParentPartner.code
              }
            />
          )}
        </div>
      </div>
    );
  };

  private getContent = () => {
    if (this.state.partnerConfig.isLoading) {
      return <Loader />;
    }

    if (
      this.state.partnerConfig.partners === undefined ||
      this.state.partnerConfig.partners.length === 0
    ) {
      return this.state.partnerConfig.type === PartnerType.OPEN_REQUEST ? (
        <NotFound
          text={<FormattedMessage id="partner.notFound.openRequest" />}
        />
      ) : (
        <NotFound text={<FormattedMessage id="partner.notFound.active" />} />
      );
    }

    return (
      <div
        className={`branch-list  ${
          this.state.partnerConfig.type === PartnerType.OPEN_REQUEST
            ? "open-request"
            : "active"
        }`}
      >
        {this.state.partnerConfig.partners.map((partner, index) => (
          <ChildPartnerItem
            partner={partner}
            partnerType={this.props.partnerType}
            index={index}
            key={`partner-${index}-${partner.name}`}
          />
        ))}
      </div>
    );
  };

  private getLabelAndTitle = () => {
    let title = this.props.selectedParentPartner.name;
    let label;

    if (this.props.partnerType === PartnerType.OPEN_REQUEST) {
      if ((this.props.selectedParentPartner as IPartner).applicativeBpName) {
        title = (this.props.selectedParentPartner as IPartner)
          .applicativeBpName as string;
      }

      const getLabel = (status: PartnerStatus) => {
        if (!this.props.openRequestPartners) {
          return null;
        }

        const count = this.props.openRequestPartners.filter(
          (partner) => partner.status === status
        ).length;
        return <StatusLabel status={status} count={count} />;
      };

      label = (
        <div className={`label-container ${this.props.screenType}`}>
          {getLabel(PartnerStatus.IN_PROGRESS)}
          {getLabel(PartnerStatus.LOA_REQUIRED)}
          {getLabel(PartnerStatus.REJECTED)}
        </div>
      );
    } else {
      label = (
        <div className={`label-container ${this.props.screenType}`}>
          <StatusLabel
            status={PartnerStatus.ACTIVE}
            count={this.state.partnerConfig.count}
          />
        </div>
      );
    }

    return { title, label };
  };

  render() {
    const { title, label } = this.getLabelAndTitle();

    return (
      <div className="child-partner-list partner-list">
        {this.getHeader(title, label)}

        {this.props.screenType === ScreenType.PHONE && label}

        {this.props.selectedParentPartner.products.length > 0 && (
          <div className="covered-products-container">
            <span className="covered-products-title">
              <FormattedMessage id="partner.coveredProducts" />
            </span>

            <ul>
              {this.props.selectedParentPartner.products.map(
                (product, index) => (
                  <li key={`covered-product-${index}-${product}`}>
                    <div className="covered-product">
                      {product}
                      {index !==
                        this.props.selectedParentPartner.products.length -
                          1 && <div className="separator" />}
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        )}

        <div className="list-content">
          {this.props.partnerType !== PartnerType.OPEN_REQUEST &&
            this.state.partnerConfig.partners && (
              <PartnerSearchSort
                partnerConfig={this.state.partnerConfig}
                onSearch={this.onSearch}
                setSortingMode={this.setSortingMode}
              />
            )}

          {this.getContent()}

          {this.state.partnerConfig.totalPages > 1 && (
            <div className="pagination-container">
              <Pagination
                count={this.state.partnerConfig.totalPages}
                page={this.state.partnerConfig.currentPage}
                onChange={(event, value) => this.onPageChange(value)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const mapDispatch = {
  scrollToTop: () => ({
    type: ReducerAction.SCROLL_TO_TOP,
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(ChildPartnerList);
