import "./styles.scss";

import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { TextField } from "@material-ui/core";

import InputLabel from "../inputLabel";
import { TFormInputId, TFormValue } from "../../enums/form.enum";
import { InputOptions } from "../../models/input-options.model";

interface IProps extends WrappedComponentProps {
  id: TFormInputId;
  value: TFormValue;
  options: InputOptions;

  onChange(value: string): void;
  onBlur(): void;
}

class TextareaInput extends React.Component<IProps> {
  render() {
    const placeholder = this.props.intl.formatMessage({
      id: this.props.options.placeholder,
    });

    return (
      <div className="textarea-input">
        <InputLabel options={this.props.options} />

        <TextField
          id={this.props.id}
          multiline
          rows={4}
          value={this.props.value}
          error={!!this.props.options.error}
          placeholder={placeholder}
          onChange={(event) => this.props.onChange(event.target.value)}
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default injectIntl(TextareaInput);
