import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";

import { PartnerStatus } from "../../../enums/business-partners.enum";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import { ScreenType } from "../../../../../common/enums/screen-type.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, WrappedComponentProps {
  status: PartnerStatus;
  count?: number;
}

class StatusLabel extends Component<IProps> {
  render() {
    if (this.props.count === 0) {
      return null;
    }

    let status = "";

    switch (this.props.status) {
      case PartnerStatus.IN_PROGRESS:
        status = this.props.intl.formatMessage({
          id: "partner.status.inProgress",
        });
        break;

      case PartnerStatus.LOA_REQUIRED:
        status = this.props.intl.formatMessage({
          id: "partner.status.loaRequired",
        });
        break;

      case PartnerStatus.REJECTED:
        status = this.props.intl.formatMessage({
          id: "partner.status.rejected",
        });
        break;

      case PartnerStatus.ACTIVE:
        status = this.props.intl.formatMessage({
          id:
            this.props.count && this.props.count > 1
              ? "partner.status.actives"
              : "partner.status.active",
        });
        break;

      default:
        break;
    }

    const isChip =
      this.props.windowWidth < 800 && this.props.count === undefined;
    const responsiveClass = isChip ? "small-phone" : this.props.screenType===ScreenType.SMALL_PHONE?ScreenType.PHONE:this.props.screenType;

    return (
      <div className={`status-label ${this.props.status} ${responsiveClass}`}>
        {this.props.count !== undefined && <span>{this.props.count}</span>}
        {!isChip && this.props.screenType!== ScreenType.SMALL_PHONE && <span>{status}</span>}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
  windowWidth: state.windowWidth,
});

const connector = connect(mapState);
export default connector(injectIntl(StatusLabel));
