import "./styles.scss";

import React, { Component } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { connect, ConnectedProps } from "react-redux";
import { EventRow } from "../../../productCatalog/models/event.model";
import { SortingModes } from "../../enums/sorting-mode.enum";
import SynAltIcon from "@material-ui/icons/SyncAlt";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import SearchInput from "../../submodules/form/components/searchInput";
import { ToastType } from "../../submodules/toast/enums/toast-type.enum";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { DownloadIcon } from "../../../../assets/icons";

enum EColumn {
  DCSA_EVENT_TYPE,
  DCSA_EVENT_CLASSIFIER,
  DCSA_EMPTY_INDICATOR,
  DCSA_EVENT_DOMAIN,
  DCSA_DOCUMENT_TYPE,
  VISIBILITY,
}

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends WrappedComponentProps, PropsFromRedux {
  events: EventRow[];
  familyName: string;
  headers?: string[];
}

interface IStates {
  searchValue: string;
  sortingModes: SortingModes[];
  isLoading: boolean;
}

class EventsTable extends Component<IProps, IStates> {
  private readonly shouldDisplayDcsaEmptyIndicator: boolean;
  private readonly shouldDisplayEventDomain: boolean;
  private readonly shouldDisplayDcsaDocument: boolean;
  private readonly shouldDisplayVisibility: boolean;

  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: "",
      sortingModes: this.getDefaultSortingModesList(),
      isLoading: false,
    };

    this.shouldDisplayDcsaEmptyIndicator = this.props.events.some(
      (event) => !!event.dscaEmptyIndicator
    );

    this.shouldDisplayEventDomain = this.props.events.some(
      (event) => !!event.eventDomain
    );

    this.shouldDisplayDcsaDocument = this.props.events.some(
      (event) => !!event.dscaDocument
    );

    this.shouldDisplayVisibility = this.props.events.some(
      (event) => !!event.visibility
    );
  }

  private getDefaultSortingModesList = (): SortingModes[] => {
    return Object.keys(EColumn)
      .filter((key) => isNaN(Number(key)))
      .map(() => SortingModes.DEFAULT);
  };

  private onSearch = (searchValue: string) => {
    this.setState({ searchValue });
  };

  private isEventMatchingSearch = (event: EventRow): boolean => {
    return event.relatedEvents
      .join(" ")
      .toLowerCase()
      .includes(this.state.searchValue.toLowerCase());
  };

  private getRelatedEventCellContent = (
    relatedEvents: string[],
    index: number
  ): JSX.Element => {
    if (relatedEvents.length === 0) {
      return <div className="separator">N/A</div>;
    }

    return (
      <div className="separator">
        {relatedEvents.map((relatedEvent) => (
          <React.Fragment key={`related-event-${index}-${relatedEvent}`}>
            {relatedEvent}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  };

  private convertToCSV = (data: EventRow[]) => {
    const header = [
      "family",
      "dcsaEventType",
      "dscaEventClassifier",
      "dscaEmptyIndicator",
      "eventDomain",
      "dscaDocument",
      "visibility",
      "relatedEvents",
    ];
    const rows = data.map((row) => [
      this.props.familyName,
      row.dcsaEventType,
      row.dscaEventClassifier,
      row.dscaEmptyIndicator || "",
      row.eventDomain || "",
      row.dscaDocument || "",
      row.visibility || "",
      row.relatedEvents.join(","),
    ]);
    return [header, ...rows].map((r) => r.join(";")).join("\n");
  };

  private onClick = (events: any) => {
    this.setState({ isLoading: true });
    const csv = this.convertToCSV(events);
    const file = `${this.props.familyName}-all.csv`;
    const csvContent = "data:text/csv;charset=utf-8," + csv;
    const data = encodeURI(csvContent);

    let link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({ isLoading: false });
  };

  private getEventRow = (event: EventRow, index: number) => {
    return (
      <TableRow key={`api-endpoint-row-${index}`}>
        <TableCell>{event.dscaEventClassifier}</TableCell>

        {this.shouldDisplayDcsaEmptyIndicator && (
          <TableCell>
            <div className="separator capitalize">
              {event.dscaEmptyIndicator
                ? event.dscaEmptyIndicator.toLowerCase()
                : "N/A"}
            </div>
          </TableCell>
        )}

        <TableCell>
          <div className="separator">{event.dcsaEventType}</div>
        </TableCell>

        {this.shouldDisplayDcsaDocument && (
          <TableCell>
            <div className="separator">
              {event.dscaDocument ? event.dscaDocument : "N/A"}
            </div>
          </TableCell>
        )}

        {this.shouldDisplayEventDomain && (
          <TableCell>
            <div className="separator">
              {event.eventDomain ? event.eventDomain : "N/A"}
            </div>
          </TableCell>
        )}

        {this.shouldDisplayVisibility && (
          <TableCell>
            <div className="separator">
              {event.visibility ? event.visibility : "N/A"}
            </div>
          </TableCell>
        )}

        <TableCell>
            {this.getRelatedEventCellContent(event.relatedEvents, index)}
        </TableCell>
        <TableCell />
      </TableRow>
    );
  };

  private getColSpan = (): number => {
    let colSpan = 4;

    if (this.shouldDisplayDcsaEmptyIndicator) colSpan++;
    if (this.shouldDisplayEventDomain) colSpan++;
    if (this.shouldDisplayDcsaDocument) colSpan++;
    if (this.shouldDisplayVisibility) colSpan++;

    return colSpan;
  };

  private cycleSortingModes = (column: EColumn) => {
    const currentSortingMode = this.state.sortingModes[column];
    const sortingModes = this.getDefaultSortingModesList();

    if (currentSortingMode === SortingModes.DESCEND) {
      sortingModes[column] = SortingModes.DEFAULT;
    } else {
      sortingModes[column] = currentSortingMode + 1;
    }

    this.setState({ sortingModes });
  };

  private getCurrentSortingModeIcon = (column: EColumn) => {
    const onClick = () => this.cycleSortingModes(column);

    switch (this.state.sortingModes[column]) {
      case SortingModes.DEFAULT:
        return <SynAltIcon className="up" onClick={onClick} />;

      case SortingModes.ASCEND:
        return <TrendingFlatIcon className="down" onClick={onClick} />;

      case SortingModes.DESCEND:
        return <TrendingFlatIcon className="up" onClick={onClick} />;
    }
  };

  private getSortedEvent = (events: EventRow[]): EventRow[] => {
    const sortingModeIndex = this.state.sortingModes.findIndex(
      (sortingMode) => sortingMode !== SortingModes.DEFAULT
    );

    if (sortingModeIndex === -1) return events;

    const sortingMode = this.state.sortingModes[sortingModeIndex];
    const sortDirection = sortingMode === SortingModes.DESCEND ? -1 : 1;

    const sortValue = (a: string | undefined, b: string | undefined) => {
      if (!!a && !!b) {
        return sortingMode === SortingModes.DESCEND
          ? b.localeCompare(a)
          : a.localeCompare(b);
      }

      if (a === undefined && !!b) return sortDirection;
      if (!!a && b === undefined) return sortDirection * -1;

      return 0;
    };

    switch (sortingModeIndex) {
      case EColumn.DCSA_EVENT_TYPE:
        return events.sort((a, b) =>
          sortValue(a.dcsaEventType, b.dcsaEventType)
        );

      case EColumn.DCSA_EVENT_CLASSIFIER:
        return events.sort((a, b) =>
          sortValue(a.dscaEventClassifier, b.dscaEventClassifier)
        );

      case EColumn.DCSA_EMPTY_INDICATOR:
        return events.sort((a, b) =>
          sortValue(a.dscaEmptyIndicator, b.dscaEmptyIndicator)
        );

      case EColumn.DCSA_EVENT_DOMAIN:
        return events.sort((a, b) => sortValue(a.eventDomain, b.eventDomain));

      case EColumn.DCSA_DOCUMENT_TYPE:
        return events.sort((a, b) => sortValue(a.dscaDocument, b.dscaDocument));

      case EColumn.VISIBILITY:
        return events.sort((a, b) => sortValue(a.visibility, b.visibility));

      default:
        return events;
    }
  };

  render() {
    let events = this.props.events
      ? this.props.events.filter(this.isEventMatchingSearch)
      : [];

    events = this.getSortedEvent(events);

    return (
      <div className="events-table-container">
        {this.props.events.length === 0 ? (
          <div className="no-event-found">
            <FormattedMessage id="eventDesc.noAvailableEvents" />
          </div>
        ) : (
          <TableContainer className="events-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="classifier-cell">
                    {this.props.headers?
                    (<FormattedMessage id={this.props.headers[0]} />)
                    :(<FormattedMessage id="eventDesc.classifier" />)}
                    {this.getCurrentSortingModeIcon(
                      EColumn.DCSA_EVENT_CLASSIFIER
                    )}
                  </TableCell>

                  {this.shouldDisplayDcsaEmptyIndicator && (
                    <TableCell className="empty-indicator-cell">
                      <div className="separator">
                        <FormattedMessage id="eventDesc.emptyIndicator" />
                        {this.getCurrentSortingModeIcon(
                          EColumn.DCSA_EMPTY_INDICATOR
                        )}
                      </div>
                    </TableCell>
                  )}
                  <TableCell className="dsca-type-cell">
                    <div className="separator">
                      {this.props.headers?
                      (<FormattedMessage id={this.props.headers[1]} />):
                      (<FormattedMessage id="eventDesc.dcsaType" />)}
                      {this.getCurrentSortingModeIcon(EColumn.DCSA_EVENT_TYPE)}
                    </div>
                  </TableCell>

                  {this.shouldDisplayDcsaDocument && (
                    <TableCell className="document-type-cell">
                      <div className="separator">
                        {this.props.headers?
                        (<FormattedMessage id={this.props.headers[2]} />):
                        (<FormattedMessage id="eventDesc.document" />)}
                        {this.getCurrentSortingModeIcon(
                          EColumn.DCSA_DOCUMENT_TYPE
                        )}
                      </div>
                    </TableCell>
                  )}

                  {this.shouldDisplayEventDomain && (
                    <TableCell className="event-domain-cell">
                      <div className="separator">
                        <FormattedMessage id="eventDesc.eventDomain" />
                        {this.getCurrentSortingModeIcon(
                          EColumn.DCSA_EVENT_DOMAIN
                        )}
                      </div>
                    </TableCell>
                  )}

                  {this.shouldDisplayVisibility && (
                    <TableCell className="visibility-cell">
                      <div className="separator">
                        <FormattedMessage id="eventDesc.eventVisibility" />
                        {this.getCurrentSortingModeIcon(EColumn.VISIBILITY)}
                      </div>
                    </TableCell>
                  )}

                  <TableCell className="related-events-cell">
                    <div className="separator">
                      <FormattedMessage id="eventDesc.relatedEvents" />
                      <SearchInput
                        isAsynchronous={false}
                        onSearch={this.onSearch}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <DownloadIcon
                      onClick={() => this.onClick(this.props.events)}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.length > 0 ? (
                  events.map(this.getEventRow)
                ) : (
                  <TableRow>
                    <TableCell colSpan={this.getColSpan()}>
                      <FormattedMessage id="eventDesc.noAvailableEvents" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const mapDispatch = {
  displayToast: (toastMessage: JSX.Element, toastType = ToastType.SUCCESS) => ({
    type: ReducerAction.TOAST,
    payload: { toastMessage, toastType },
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(injectIntl(EventsTable));
