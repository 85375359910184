import "./styles.scss";

import React, { Component } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Release } from "../../../../models/api-summary.model";
import DateUtils from "../../../../../../utils/date.utils";

interface IProps {
  release: Release;
  index: number;
}

interface IStates {
  releaseOpened: boolean;
}

class ReleaseNote extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      releaseOpened: this.props.index == 0,
    };
  }

  private blockClicked = () => {
    const releaseOpened = !this.state.releaseOpened;
    this.setState({ releaseOpened });
  };

  render() {
    return (
      <div id="release-note">
        <div className="release-block" onClick={() => this.blockClicked()}>
          <div className="date-version">
            <div>v{this.props.release.version}</div>&nbsp;-&nbsp;
            <div>{DateUtils.formatDate(this.props.release.date)}</div>
          </div>
          {this.state.releaseOpened ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {this.state.releaseOpened && (
          <>
            {this.props.release.changes.map((change: string, index: number) => (
              <li key={`release-change-${index}`} className="change">
                {change}
              </li>
            ))}
          </>
        )}
      </div>
    );
  }
}

export default ReleaseNote;
