import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { Table, TableBody, TableContainer } from "@material-ui/core";

import {
  Api,
  IPackage,
  IPackageSelection,
} from "../../interfaces/package.interface";
import Loader from "../../../common/components/loader";
import OfferRow from "./offerRow";
import { apiFamilies } from "../../../productCatalog/constants/api-family.constant";
import { OfferSelection } from "../../enums/crm.enum";
import { eventFamilies } from "../../../productCatalog/constants/event-family.constant";
import { IProductsFamily } from "../../../productCatalog/interfaces/products-family.interface";
import { ProductType } from "../../../productCatalog/enums/product-family.enum";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";

export interface IPackageRouteProps {
  packageName: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps
  extends RouteComponentProps<IPackageRouteProps>,
    PropsFromRedux {
  currentPackage: IPackage;
  packageSelection: IPackageSelection;
  onOfferCheck(offerId: OfferSelection | string, offerName: OfferSelection | string): void;
  onCustomOfferMessageChange(
    offerId: OfferSelection | string,
    offerName: OfferSelection | string,
    message: string
  ): void;
}

interface IStates {
  activeFamilyIndex: number;
}

class Package extends Component<IProps, IStates> {
  private readonly noneOffer = {
    id: OfferSelection.NONE,
    name: "none",
    consumptionLimit: 500000,
    otherLimits: undefined,
    overconsumptionRate: 0,
    pricing: {
      period: "month",
      currency: "USD",
      amount: 0,
    },
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeFamilyIndex: 0,
    };
  }

  private getApis = () => {
    const list: {
      familyName: JSX.Element;
      api: {
        id: string;
        title: string;
        type: ProductType;
      };
    }[] = [];

    this.props.currentPackage.productFamilies.forEach((family) => {
      const find = (el: IProductsFamily) =>
        el.productFamily === family.name.toUpperCase();

      const fullApiFamily = apiFamilies.find(find);
      const fullEventFamily = eventFamilies.find(find);

      const addApi = (familyName: JSX.Element, api: Api, type: ProductType) => {
        list.push({
          familyName,
          api: {
            id: api.id,
            title: api.title,
            type: type,
          },
        });
      };

      if (fullApiFamily) {
        family.apis.forEach((api) =>
          addApi(fullApiFamily.name, api, ProductType.API)
        );
      } else if (fullEventFamily) {
        family.apis.forEach((api) =>
          addApi(fullEventFamily.name, api, ProductType.EVENT)
        );
      }
    });

    return list.map((el) => (
      <div
        className="packages-api-tile api-tile"
        key={`api-tile-${el.api.title}`}
        onClick={() => this.props.openPanel(el.api.id, el.api.type)}
      >
        <div className="family-name">{el.familyName}</div>
        <div className="name">{el.api.title}</div>
      </div>
    ));
  };

  render() {
    const {
      currentPackage,
      packageSelection,
      onOfferCheck,
      onCustomOfferMessageChange,
    } = this.props;

    if (!currentPackage) {
      return <Loader />;
    }

    const offers = [this.noneOffer,...currentPackage.offers];

    return (
      <div id="packages" className={this.props.screenType}>
        {currentPackage.description && (
          <div className="block">
            <div className="title">
              <FormattedMessage id="offers.information" />
            </div>

            <div className="block-content information">
              {currentPackage.description}
            </div>
          </div>
        )}

        <div className="block">
          <div className="title">
            <FormattedMessage id="offers.products" />
          </div>

          <div className="block-content products">
            <div className="apis-container">{this.getApis()}</div>
          </div>
        </div>

        <div className="block offers">
          <div className="title">
            <FormattedMessage id="offers" />
          </div>

          <TableContainer className="block-content offers-table">
            <Table aria-label="simple table">
              <TableBody>
                {offers.map((offer) => (
                  <OfferRow
                    key={`offer-row-${offer.id}`}
                    offer={offer}
                    hasOtherLimits={offers.some((offer) => !!offer.otherLimits)}
                    packageSelection={packageSelection}
                    unitOfMeasure={currentPackage.unitOfMeasure}
                    onOfferCheck={() => onOfferCheck(offer.id, offer.name)}
                    onCustomOfferMessageChange={(message: string) =>{
                      onCustomOfferMessageChange(offer.id, offer.name, message)
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const mapDispatch = {
  openPanel: (id: string, productType: ProductType) => ({
    type: ReducerAction.OPEN_DESCRIPTION_PANEL,
    payload: {
      id,
      productType,
    },
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(withRouter(Package));
