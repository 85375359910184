let apiBaseUrl = "";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_FRONT_MODE === "start"
) {
  apiBaseUrl = "https://localhost:8080";
}

export const config = {
  apiUrl: `${apiBaseUrl}/api/v1`,
  loginUrl: `${apiBaseUrl}/auth/sso/login`,
  logoutUrl: `${apiBaseUrl}/auth/sso/logout`,
};
