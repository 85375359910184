import React, { Component } from "react";
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Radio, TableCell, TableRow, TextField } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import {
  IPackageSelection,
  Offer,
} from "../../../interfaces/package.interface";
import Utils from "../../../../../utils";
import { OfferSelection } from "../../../enums/crm.enum";
import { customConsumptionLimits } from "../../../constants/crm.constant";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import "./styles.scss";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, WrappedComponentProps {
  offer: Offer;
  packageSelection: IPackageSelection;
  unitOfMeasure: string;
  hasOtherLimits: boolean;
  onOfferCheck(): void;
  onCustomOfferMessageChange(message: string): void;
}

class OfferRow extends Component<IProps> {

  private getConsumptionLimitMessage = (isCustom: boolean): JSX.Element => {
    const { offer, packageSelection, unitOfMeasure } = this.props;

    const pluralUnit = `${unitOfMeasure}s`;
    let consumptionLimit = offer.consumptionLimit;

    if (isCustom) {
      const customConsumptionLimit = customConsumptionLimits.find(
        (el) => el.name === packageSelection.name
      );

      if (!!customConsumptionLimit) {
        consumptionLimit = customConsumptionLimit.value;
      }
    }

    if (consumptionLimit === undefined || consumptionLimit === null) {
      return <FormattedMessage id="offers.noConsumptionLimit" />;
    }

    return (
      <>
        <FormattedMessage
          id="offers.consumptionLimit"
          values={{
            pluralUnit,
            consumptionLimit: Utils.formatLargeNumber(consumptionLimit!, false),
          }}
        />
      </>
    );
  };

  private displayCardOffers = (
    offer: Offer,
    unitOfMeasure: string,
    isSelected: boolean,
    isCustom: boolean,
    hasOtherLimits: boolean,
    onOfferCheck: any,
    onCustomOfferMessageChange: any,
    packageSelection: IPackageSelection,
    fullRowColSpan: number
  ) => {
    const moreInfoNeededInputPlaceholder = this.props.intl.formatMessage({
      id: "offers.moreInfoNeeded",
    });
    return (
      <>
        <div
          className={`offer-card ${isSelected ? "checked" : ""} ${
            isCustom ? "custom" : ""
          } `}
          onClick={onOfferCheck}
        >
          {offer.id === OfferSelection.NONE ? (
            <div className="cell">
              <Radio checked={isSelected} />
              <FormattedMessage id="offers.notThisPackage" />
            </div>
          ) : (
            <div className="cell">
              <Radio checked={isSelected} />
              <div className="cell-content">
                <div className="name-cell">
                  {offer.name}
                </div>
                <div className="consum-cell">
                  {this.getConsumptionLimitMessage(isCustom)}
                </div>
                {hasOtherLimits && (
                  <div className="other-cell">{offer.otherLimits}</div>
                )}
                <div className="over-cell">
                  {!isCustom && (
                    <FormattedMessage
                      id="offers.overconsumptionRate"
                      values={{
                        unitOfMeasure,
                        overconsumptionRate: offer.overconsumptionRate,
                      }}
                    />
                  )}
                </div>
                <div className="price-cell">
                  {isCustom ? (
                    <>
                      <span className="price">
                        <FormattedMessage id="offers.custom" />
                      </span>
                      &nbsp;
                      <span className="per-month">
                        <FormattedMessage id="offers.prices" />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="price">
                        <FormattedNumber
                          value={+offer.pricing.amount}
                          style="currency"
                          currency={offer.pricing.currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      </span>
                      &nbsp;
                      <span className="per-month">
                        <FormattedMessage id="offers.perMonth" />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {isCustom &&
          isSelected &&
          this.displayTextForCustom(
            fullRowColSpan,
            packageSelection.customOfferMessage || "",
            moreInfoNeededInputPlaceholder,
            onCustomOfferMessageChange
          )}
      </>
    );
  };

  private displayTextForCustom = (
    col: number | undefined,
    value: any,
    placeholder: any,
    onCustomOfferMessageChange: any
  ) => {
    return (
      <TableRow className="custom-message-row">
        <TableCell colSpan={col}>
          <div className="label">
            <FormattedMessage id="offers.yourMessage" />
          </div>
          <TextField
            multiline
            rows={3}
            value={value}
            placeholder={placeholder}
            onChange={(event) => {
              onCustomOfferMessageChange(event.target.value)}}
          />
        </TableCell>
      </TableRow>
    );
  };

  private displayTableOffers = (
    offer: Offer,
    packageSelection: IPackageSelection,
    unitOfMeasure: string,
    hasOtherLimits: boolean,
    onOfferCheck: any,
    onCustomOfferMessageChange: any,
    isCustom: boolean,
    isSelected: boolean,
    fullRowColSpan: number
  ) => {
    const moreInfoNeededInputPlaceholder = this.props.intl.formatMessage({
      id: "offers.moreInfoNeeded",
    });

    return (
      <>
        <TableRow
          className={`offer-row ${isSelected ? "checked" : ""} ${
            isCustom ? "custom" : ""
          } ${hasOtherLimits ? "five-col" : ""}`}
          onClick={onOfferCheck}
        >
          {offer.id === OfferSelection.NONE ? (
            <TableCell className="name-cell" colSpan={fullRowColSpan}>
              <div>
                <Radio checked={isSelected} />
                <FormattedMessage id="offers.notThisPackage" />
              </div>
            </TableCell>
          ) : (
            <>
              <TableCell className="name-cell">
                <div>
                  <Radio checked={isSelected} />
                  {offer.name}
                </div>
              </TableCell>
              <TableCell className="consumption-limit-cell">
                <div className="cell-content">
                  {this.getConsumptionLimitMessage(isCustom)}
                </div>
              </TableCell>

              <TableCell
                className={`other-limit-cell ${hasOtherLimits ? "" : "empty"}`}
              >
                {hasOtherLimits && (
                  <div className="cell-content">{offer.otherLimits}</div>
                )}
              </TableCell>
              <TableCell className="over-consumption-cell">
                <div className="cell-content">
                  {!isCustom && (
                    <FormattedMessage
                      id="offers.overconsumptionRate"
                      values={{
                        unitOfMeasure,
                        overconsumptionRate: offer.overconsumptionRate,
                      }}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell className="pricing-cell" align="right">
                <div className="cell-content">
                  { isCustom?
                    <>
                      <span className="price">
                        <FormattedMessage id="offers.custom" /> 
                      </span>
                      &nbsp;
                      <span className="per-month">
                        <FormattedMessage id="offers.prices" />
                      </span>
                    </>
                      :
                    <>
                      <span className="price">
                        <FormattedNumber
                          value={+offer.pricing.amount}
                          style="currency"
                          currency={offer.pricing.currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      </span>
                      &nbsp;
                      <span className="per-month">
                        <FormattedMessage id="offers.perMonth" />
                      </span>
                    </>
                  }
                </div>
              </TableCell>
            </>
          )}
        </TableRow>
        {isCustom &&
          isSelected &&
          this.displayTextForCustom(
            fullRowColSpan,
            packageSelection.customOfferMessage || "",
            moreInfoNeededInputPlaceholder,
            onCustomOfferMessageChange
          )}
      </>
    );
  };

  render() {
    const {
      offer,
      packageSelection,
      unitOfMeasure,
      hasOtherLimits,
      onOfferCheck,
      onCustomOfferMessageChange,
    } = this.props;
    const isCustom = offer.name.toLowerCase() === OfferSelection.CUSTOM;
    const isSelected = packageSelection.offerId === offer.id;

    const fullRowColSpan = 5;

    return (
      <>
        {this.props.screenType == ScreenType.PHONE
          ? this.displayCardOffers(
              offer,
              unitOfMeasure,
              isSelected,
              isCustom,
              hasOtherLimits,
              onOfferCheck,
              onCustomOfferMessageChange,
              packageSelection,
              fullRowColSpan
            )
          : this.displayTableOffers(
              offer,
              packageSelection,
              unitOfMeasure,
              hasOtherLimits,
              onOfferCheck,
              onCustomOfferMessageChange,
              isCustom,
              isSelected,
              fullRowColSpan
            )}
      </>
    );
  }
}
const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default connector(injectIntl(OfferRow));
