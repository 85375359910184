import "./styles.scss";

import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";

import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import { ScreenType } from "../../../../../common/enums/screen-type.enum";
import PartnersAPI from "../../../apis/business-partners.api";
import Loader from "../../../../../common/components/loader";
import { ELoaderSize } from "../../../../../common/enums/loader.enum";
import { ReducerAction } from "../../../../../reducer/enums/reducer-action.enum";
import { ToastType } from "../../../../../common/submodules/toast/enums/toast-type.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  inBehalfOf?: string;
}

interface IStates {
  isLoading: boolean;
}

class CsvExport extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  private onClick = (event: any) => {
    event.stopPropagation();

    this.setState(
      {
        isLoading: true,
      },
      () => {
        PartnersAPI.exportCsv(this.props.inBehalfOf)
          .then((file) => {
            if (file && file.url) {
              const link = document.createElement("a");
              link.href = file.url as string;
              link.download = "business-partners.csv";
              link.click();
              setTimeout(() => URL.revokeObjectURL(link.href), 0);

              let message = <FormattedMessage id="partner.exportCsv.success" />;
              let type = ToastType.SUCCESS;

              if (file.status === 206) {
                message = (
                  <FormattedMessage id="partner.exportCsv.ultimateOwnerError" />
                );
                type = ToastType.WARNING;
              }

              this.props.displayToast(message, type);
            }

            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.props.displayToast(
              <FormattedMessage id="error.errorOccurredFull" />,
              ToastType.ERROR
            );

            this.setState({ isLoading: false });
          });
      }
    );
  };

  public render() {
    return (
      <div
        className={`button export-csv ${this.props.screenType} ${
          this.state.isLoading ? "loading" : ""
        }`}
        onClick={this.onClick}
      >
        {this.state.isLoading ? (
          <Loader size={ELoaderSize.SMALL} />
        ) : this.props.screenType === ScreenType.PHONE ? (
          <VerticalAlignBottomIcon />
        ) : (
          <FormattedMessage id="partner.exportCsv" />
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const mapDispatch = {
  displayToast: (toastMessage: JSX.Element, toastType = ToastType.SUCCESS) => ({
    type: ReducerAction.TOAST,
    payload: { toastMessage, toastType },
  }),
};

const connector = connect(mapState, mapDispatch);
export default connector(CsvExport);
