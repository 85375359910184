import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import HeaderBanner from "../../../common/components/headerBanner";
import BracedTitle from "../../../common/components/bracedTitle";

interface IProps {}

class PrivacyNotice extends Component<IProps> {
  render() {
    const CMA_CGM_PRIVACY_POLICY_LINK =
      "https://www.cma-cgm.com/static/eCommerce/Attachments/CC-BCR-Binding%20Corporate%20Rules.pdf";
    const CODE_OF_CONDUCT_LINK =
      "https://www.cma-cgm.com/static/eCommerce/Attachments/CMA%20CGM%20-%20Code%20of%20Ethics_EN-28102020_0.pdf";

    return (
      <div id="privacy-notice">
        <HeaderBanner>
          <FormattedMessage id="privacyNotice" />
        </HeaderBanner>

        <div className="App-content">
          <div className="category">
            <div className="title">
              <BracedTitle
                text={<FormattedMessage id="privacyNotice.introduction" />}
                size="large"
              />
            </div>

            <div className="message">
              <p>
                <FormattedMessage id="privacyNotice.introduction.message.1" />
              </p>
              <p>
                <FormattedMessage id="privacyNotice.introduction.message.2" />
              </p>
              <p>
                <FormattedMessage
                  id="privacyNotice.introduction.message.3"
                  values={{
                    a: (link: any) => (
                      <a
                        className="link"
                        href={CMA_CGM_PRIVACY_POLICY_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage id="privacyNotice.introduction.message.4" />
              </p>

              <ul>
                <li>
                  <FormattedMessage
                    id="privacyNotice.introduction.list.1.item.1"
                    values={{
                      a: (link: any) => (
                        <a
                          className="link"
                          href={CODE_OF_CONDUCT_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link}
                        </a>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.introduction.list.1.item.2" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.introduction.list.1.item.3" />
                </li>
              </ul>
            </div>
          </div>

          <div className="category">
            <div className="title">
              <BracedTitle
                text={<FormattedMessage id="privacyNotice.cookiesPolicy" />}
                size="large"
              />
            </div>

            <div className="message">
              <p>
                <FormattedMessage id="privacyNotice.cookiesPolicy.message.1" />
              </p>
              <p>
                <FormattedMessage id="privacyNotice.cookiesPolicy.message.2" />
              </p>

              <ul>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.1.item.1" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.1.item.2" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.1.item.3" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.1.item.4" />
                </li>
              </ul>

              <p>
                <FormattedMessage id="privacyNotice.cookiesPolicy.message.3" />
              </p>

              <ul>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.2.item.1" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.2.item.2" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.2.item.3" />
                </li>
                <li>
                  <FormattedMessage id="privacyNotice.cookiesPolicy.list.2.item.4" />
                </li>
              </ul>
            </div>
          </div>

          {/*OneTrust Cookies List*/}
          <div id="ot-sdk-cookie-policy" />
        </div>
      </div>
    );
  }
}

export default PrivacyNotice;
