export enum TabValues {
  PRODUCTS = 0,
  ROADMAP = 1,
  COMMERCIAL_OFFERS = 2,
  MY_ORGANIZATION = 3,
  HELP = 4,
}

export enum HeaderErrors {
  USER_NOT_DEACTIVATED = "User exists on other CMA applications, personal data related to portal were deleted but user is not deactivated",
}
